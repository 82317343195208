import request from '@utils/request'

export const useUserCoupon = (data = {}) => {
  return request.post('/couponUsed/useUserCoupon', data)
}

export const getScanList = (params) => {
  return request.get('/couponUsed/getListByPage', { params })
}

export const getScanGoodsList = (params) => {
  return request.get('/couponUsed/getGoodsListByPage', { params })
}
