import Layout from '@views/layout/layout'

const expenseRouter = {
  path: '/expense',
  component: Layout,
  name: 'Expense',
  redirect: '/Expense/type',
  meta: {
    title: '费用类型',
    noCache: true,
    requireAuth: false,
    show: true
  },
  children: [
    {
      path: 'type',
      name: 'expenseType',
      components: {
        default: () => import('@views/expense/type')
      },
      meta: {
        title: '费用类型',
        noCache: true,
        requireAuth: false,
        show: true
        // keepAlive: true
      }
    },
    {
      path: 'verification',
      name: 'expenseVerification',
      components: {
        default: () => import('@views/expense/verification')
      },
      meta: {
        title: '身份验证',
        noCache: true,
        requireAuth: false,
        show: true
        // keepAlive: true
      }
    },
    {
      path: 'info',
      name: 'expenseInfo',
      components: {
        default: () => import('@views/expense/createInfo')
      },
      meta: {
        title: '费用信息',
        noCache: true,
        requireAuth: false,
        show: true
        // keepAlive: true
      }
    },
    {
      path: 'otherInfo',
      name: 'createOtherInfo',
      components: {
        default: () => import('@views/expense/createOtherInfo')
      },
      meta: {
        title: '费用缴纳',
        noCache: true,
        requireAuth: false,
        show: true
        // keepAlive: true
      }
    }
  ]
}

export default expenseRouter
