import * as types from './mutations_types'

export default {
  /**
   * 保存列表数据
   * @param commit
   * @param state
   * @param data
   * @constructor
   */
  SetCheckOutList({ commit, state }, data = []) {
    return new Promise((resolve, reject) => {
      commit(types.SET_CHECK_OUT_LIST, data)
      resolve()
    })
  },
  /**
   * 根据ID获取详情数据
   * @param commit
   * @param state
   * @param params
   * @returns {Promise<unknown>}
   * @constructor
   */
  GetCheckOutDetail({ commit, state }, params = {}) {
    let { checkOutList } = state
    let { id } = params
    return new Promise((resolve, reject) => {
      let response = {}
      if (id) {
        response = checkOutList.find(item => `${item.id}` === `${id}`)
      }
      resolve({
        data: response || {},
        msg: '成功',
        code: '10000'
      })
    })
  }
}
