import Layout from '@views/layout/layout'

const couponRouter = {
  path: '/coupon',
  component: Layout,
  name: 'Coupon',
  children: [
    {
      path: 'scan',
      name: 'CouponScan',
      components: {
        default: () => import('@views/coupon/scan')
      },
      meta: {
        title: '扫码核销',
        noCache: true,
        requireAuth: true
      }
    },
    {
      path: 'scanList',
      name: 'CouponScanList',
      components: {
        default: () => import('@views/coupon/scanList')
      },
      meta: {
        title: '核销记录',
        noCache: true,
        requireAuth: true
      }
    },
  ]
}

export default couponRouter
