import request from '@utils/request_gd'
import requestNormal from '@utils/request'

export const getBillCorrectionListAPI = (params = {}) => {
  return request.get('/steward/correctRecord/getList', { params })
}

export const getBillCorrectionDetail = (params = {}) => {
  return request.get('/steward/correctRecord/getById', { params })
}

export const submitCorrection = (params = {}, data) => {
  return request.post('/steward/correctRecord/correctRecordSubmit', params)
}

export const getFeeTypes = () => {
  return request.get('/steward/getDictItems/cost_type', {})
}

export const getCheckoutDetail = (dcId) => {
  return requestNormal.get(`/checkout/checkOutDetailDcId?dcId=${dcId}`,{ })
}

export const getHouseList = (params={}) => {
  return request.get('/steward/houseList', { params })
}

export const getAccountType = (params={}) => {
  return request.get('/steward/getDictItems/account_type', { params })
}



