<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader"
                     :title="headerTitle"
                     @go-back="goBack"
                     @on-close="handleClosePage">
          <template #headerRight>
            <div></div>
          </template>
        </cyui-header>
        <div class="cyui-house-navbar">
          <div>共{{count}}个</div>
        </div>
      </template>
      <template #default>
        <div class="cyui-scroller-panel">
          <cyui-scroller
            class="wrapper"
            ref="scroller"
            :scrollbar="scrollbarConfig"
            :pullDownRefresh="pullDownRefreshConfig"
            :pullUpLoad="pullUpLoadConfig"
            :startY="parseInt(startY)"
            @pullingDown="handlePullingDown"
            @pullingUp="handlePullingUp"
          >
            <template #default>
              <div class="cyui-list cyui-house-list" v-if="listData.length">
                <div class="cyui-list-body">
                  <house-list-item v-for="(item, index) in listData"
                                   :key="index"
                                   :detail-info="item"></house-list-item>
                </div>
              </div>
            </template>
          </cyui-scroller>
        </div>
        <no-data
          :msg="noDataMessage"
          v-if="listData.length === 0 && isLoading === false"
        >
          <template #btn>
            <div
              class="cyui-btn cyui-btn-default cyui-btn-inline cyui-btn-radius"
              @click="handleRefreshPage"
              v-if="isRefreshPage"
            >
              刷新重试
            </div>
          </template>
        </no-data>
      </template>
    </cyui-layout>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import common from '@mixins/common'
  import scrollList from '@mixins/scrollList'
  import getPageList from '@mixins/getPageList'
  import filters from '@filters/filters'
  import { HouseListItem } from './components'
  import { getListAPI } from '@api/house'

  export default {
    name: 'HouseList',
    mixins: [common, filters, scrollList, getPageList],
    components: {
      HouseListItem
    },
    data() {
      return {
        headerTitle: '房源查看',
        initParams: { // 初始化参数
          page: 1,
          size: 20
        },
        searchKey: {}, // 搜索参数
        count: 0 // 总条数
      }
    },
    props: {},
    computed: {
      ...mapGetters('user', [
        'projectInfo'
      ])
    },
    methods: {
      // 进入详情
      goDetail(data) {
        let { id } = data
        this.$router.push({
          name: 'HouseDetail',
          query: {
            id
          }
        })
      },
      async getListData({ isPullDown, isShowLoading } = { isPullDown: false, isShowLoading: true }) { // 获取列表数据
        try {
          this.isLoading = true
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let { listData } = this
          let { projectId } = this.projectInfo
          let { page, size } = this.searchKey
          let response = await getListAPI({
            pageNum: page,
            pageSize: size,
            projectId
          })
          let result = response.data
          // console.log(result)
          if (`${result.code}` === '10000') { // 请求成功
            let resultData = result.data || []
            // let hasMore = resultData.length > 0
            let hasMore = page < result.pages
            this.listData = !isPullDown
              ? [...listData, ...resultData]
              : [...resultData]
            this.count = result.count || 0
            if (!hasMore) {
              this.isLastPage = true
              // this.$toast('没有更多数据了！')
            }
            await this.handleCallback({
              isError: false,
              hasMore
            })
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '获取列表失败!'
            })
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '获取列表失败!'
          })
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async initData() {
        await this.resetParams()
        await this.getListData()
      }
    },
    filters: {},
    watch: {
      initParams: {
        handler: function(newVal, oldVal) {
          if (JSON.stringify(newVal) !== JSON.stringify(this.searchKey)) {
            this.searchKey = JSON.parse(JSON.stringify(newVal))
          }
        },
        deep: true,
        immediate: true
      }
    },
    created () {
      const { token, projectId, userId, userName } = this.$route.query
      if (token && projectId) {
        this.$store.commit('user/SET_TOKEN', token)
        this.$store.commit('user/SET_USER_INFO', { dcProjectId: projectId, userId, userName })
        this.$store.commit('user/SET_PROJECT_INFO', { dcProjectId: projectId })
      }
    },
    mounted() {
      this.initData()
    },
    beforeRouteEnter(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    },
    beforeRouteUpdate(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    }
  }
</script>

<style lang="less" scoped>
  @import '../../assets/styles/themes/default/index';
  @import '../../assets/styles/mixins/index';

  @housePrefixCls: ~'@{css-prefix}house';

  .@{css-prefix}scroller-panel {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    & /deep/ .pulldown-wrapper {
      top: 5px !important;
    }
  }

  .@{housePrefixCls} {
    &-navbar {
      height: 44px;
      padding: 0 15px;
      line-height: 44px;
      font-size: 12px;
      color: #999999;
      background-color: #FFFFFF;
    }

    &-list {
      padding: 0;

      .@{css-prefix}list-body {
        .hairline('top', 1PX, #E8E8E8);
        .hairline('bottom', 1PX, #E8E8E8);

        .@{css-prefix}list-item {
          &:not(:first-child) {
            .hairline('top', 1PX, #E8E8E8);
          }
        }
      }
    }
  }

</style>
