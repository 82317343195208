<template>
  <div class='cyui-layout-wrapper'>
    <cyui-layout>
      <template #header>
        <cyui-header v-if='isCustomHeader'
                     :title='headerTitle'
                     @go-back='goBack'
                     @on-close='handleClosePage'>
          <template #headerRight>
            <div></div>
          </template>
        </cyui-header>
      </template>
      <template #default>
        <van-cell-group inset class='cell-group'>
          <van-cell title='租客姓名' :value='detail.customerName' />
          <van-cell title='房号' :value='detail.roomNo' />
          <van-cell title='开始时间' :value='detail.contractStartTime' />
          <van-cell title='结束时间' :value='detail.contractEndTime' />
          <van-cell title='退房类型'>
            <template #default>
              <span v-if='detail.customerType==1' style='color: #21D788'>正常退房</span>
              <span v-if='detail.customerType==2' style='color: #FDA93E'>提前退房</span>
              <span v-if='detail.customerType==3' style='color: #FDA93E'>逾期退房</span>
            </template>
          </van-cell>
          <van-cell title='离店日期' :value='detail.leaveTime' />
          <van-cell title='退房原因' title-style='flex:0.5;' :value='detail.personalReason' />
          <!--          <van-cell title="最后一次收款日期" :value="detail.latestPayRentDate" />-->
          <!--          <van-cell title="最后一次收款账号类型" :value="detail.accountType" />-->
          <!--          <van-cell title="退款时间" is-link :value="date" @click="show = true" />-->
          <!--<van-action-sheet v-if='detail.status==4' v-model="show" >-->
          <!--  <van-datetime-picker-->
          <!--    v-model="checkOutPayDate"-->
          <!--    @cancel='show = false'-->
          <!--    @confirm='onConfirm'-->
          <!--    type="date"-->
          <!--    title="选择年月日"-->
          <!--    :min-date="minDate"-->
          <!--    :max-date="maxDate"-->
          <!--    style='z-index: 99999'-->
          <!--  />-->
          <!--</van-action-sheet>-->
          <van-cell title='退款时间' title-style='flex:0.5;' value='7个工作日内' />
        </van-cell-group>
        <van-cell-group inset class='cell-group'>
          <van-cell>
            <template #title>
              <span style='color: #999999'>水费标准：{{ detail.waterPrice }}元/吨</span>
            </template>
            <template #default>
              <span style='color: #999999'>电费标准：{{ detail.wattPrice }}元/度</span>
            </template>
          </van-cell>
          <van-cell title='签约时水表度数' :value="(detail.checkInWaterDegree || '')+'吨'" />
          <van-cell title='签约时电表度数' :value="(detail.checkInElectricityDegree || '')+'度'" />
          <van-cell title='当前水表抄表度数' :value="(detail.waterMaterDegree || '')+'吨'" />
          <van-cell title='当前电表抄表度数' :value="(detail.wattMaterDegree || '')+'度'" />
        </van-cell-group>
        <van-cell-group inset class='cell-group'>
          <van-cell :border='false'>
            <template #title>
              <span style='font-size: 14px;'>水表图片</span>
            </template>
            <template #default>
              <span style='font-size: 14px;display:block;width: 100%;text-align: left;color: #323233'>电表图片</span>
            </template>
          </van-cell>
          <van-cell>
            <template #title>
              <span style='font-size: 14px;display:block;'>
                <van-image
                  width='140'
                  height='77'
                  lazy-load
                  :src='detail.wattMaterDegreeImg'
                />
              </span>
            </template>
            <template #default>
              <span style='font-size: 14px;display:block;width: 100%;text-align: left;color: #323233'>
                <van-image
                  width='140'
                  height='77'
                  lazy-load
                  :src='detail.waterMaterDegreeImg'
                />
              </span>
            </template>
          </van-cell>
        </van-cell-group>
        <van-cell-group inset class='cell-group'>
          <van-cell :border='false'>
            <template #title>
              <span style='font-size:15px;font-weight: 400;'>退房费明细</span>
            </template>
          </van-cell>
          <van-cell
            v-if='detail.customerType==2 || detail.customerType==3'
            :border='false'>
            <template #title>
              <span v-if='detail.customerType==2' style='font-size:15px;font-weight: 400;'>剩余月份租金明细</span>
              <span v-if='detail.customerType==3' style='font-size:15px;font-weight: 400;'>退房逾期租金明细</span>
            </template>
            <template #label>
              <span
                v-if='detail.customerType==2'>{{ (detail.afterCheckOutRentStartDate ? detail.afterCheckOutRentStartDate : '') + (detail.afterCheckOutRentStartDate || detail.afterCheckOutRentEndDate ? '~' : '') + (detail.afterCheckOutRentEndDate ? detail.afterCheckOutRentEndDate : '')
                }}</span>
              <span
                v-if='detail.customerType==3'>{{ (detail.overdueStartDate ? detail.overdueStartDate : '') + (detail.overdueStartDate || detail.leaveTime ? '~' : '') + (detail.leaveTime ? detail.leaveTime : '')
                }}</span>
            </template>
          </van-cell>
          <van-cell
            v-if='detail.customerType==3'
            :border='false'>
            <template #title>
              <span style='color: #666666;'>月总综合服务费</span>
            </template>
            <template #default>
              <span style='color: #666666;'>{{ detail.monthRentAmount }}元</span>
            </template>
          </van-cell>
          <van-cell
            v-if='detail.customerType==3'
            :border='false'>
            <template #title>
              <span style='color: #666666;'>日均综合服务费</span>
            </template>
            <template #default>
              <span style='color: #666666;'>{{ detail.dailyRentAmount }}元</span>
            </template>
          </van-cell>
          <van-cell
            :border='false'>
            <template #title>
              <span style='color: #666666;'>应收滞纳金</span>
            </template>
            <template #default>
              <span style='color: #666666;'>{{ detail.delayAmountAll }}元</span>
            </template>
          </van-cell>
          <van-cell
            :border='false'>
            <template #title>
              <span style='color: #666666;'>已收滞纳金</span>
            </template>
            <template #default>
              <span style='color: #666666;'>{{ detail.delayAmount }}元</span>
            </template>
          </van-cell>
          <!--          <van-cell-->
          <!--            v-if="detail.customerType==3">-->
          <!--            <template #title>-->
          <!--              <span style='color: #666666;'>滞纳金</span>-->
          <!--            </template>-->
          <!--            <template #default>-->
          <!--              <span style='color: #666666;'>{{ zhinajin }}元</span>-->
          <!--            </template>-->
          <!--            <template #label>-->
          <!--              <span style='color: #666666;'>({{ detail.monthRentAmount || '' }}/{{ detail.monthMaxDay || '' }}天*{{ zhinaday || '' }}={{ zhinajin || '' }}元)</span>-->
          <!--            </template>-->
          <!--          </van-cell>-->
          <van-cell v-if='detail.customerType == 3' title='逾期租金'>
            <template #default>
              <span style='color: #FF3333;'>{{ detail.rentBreakAmount }}元</span>
            </template>
          </van-cell>
          <van-cell
            v-if='detail.customerType==2 || detail.customerType==3'
            title='违约金'>
            <template #default>
              <span style='color: #FF3333;'>{{ detail.breakAmount }}元</span>
            </template>
          </van-cell>
          <van-cell v-if='detail.customerType == 3' title='已付违约金'>
            <template #default>
              <span style='color: #dbae74;'>{{ detail.paidBreakAmount }}元</span>
            </template>
          </van-cell>
          <van-cell v-if='detail.customerType == 3' title='已付逾期租金'>
            <template #default>
              <span style='color: #dbae74;'>{{ detail.paidExceedRentAmount }}元</span>
            </template>
          </van-cell>
          <van-cell
            title='已收综合服务费'>
            <template #default>
              <span style='color: #323233;'>{{ detail.rentPaidAmount }}元</span>
            </template>
          </van-cell>

          <van-cell
            title='应收综合服务费'>
            <template #default>
              <span style='color: #323233;'>{{ detail.rentAmount }}元</span>
            </template>
          </van-cell>
          <van-cell v-if='detail.customerType==2'
                    title='违约租金'>
            <template #default>
              <span style='color: #323233;'>{{ detail.rentBreakAmount }}元</span>
            </template>
          </van-cell>
          <van-cell
            v-if='detail.customerType==2'
            title='应退综合服务费'>
            <template #default>
              <span style='color: #DBAE74;'>{{ detail.afterRentBreakAmount }}元</span>
            </template>
          </van-cell>
          <van-cell
            title='退还保证金'>
            <template #default>
              <span style='color: #DBAE74;'>{{ detail.inDeposit }}元</span>
            </template>
          </van-cell>
          <van-cell
            v-if="type === 'detail'"
            title='已收水费'>
            <template #default>
              <span style='color: #DBAE74;'>{{ waterPaidAmount }}元</span>
            </template>
          </van-cell>
          <van-field
            v-if="type === 'check' && (detail.customerType==1||detail.customerType==2 || detail.customerType==3)"
            v-model='waterPaidAmount'
            @input='e => waterPaidAmount = prohibitNegative(e,2,10)'
            type='number'
            placeholder='请输入已收水费'
            :disabled='true'
            input-align='right'>
            <template #label>
              <span style='color:#323233;'>已收水费</span>
            </template>
            <template #extra>
              <span style='color:#DBAE74;padding: 0 10px;'>元</span>
            </template>
          </van-field>
          <van-cell
            v-if="type === 'detail'"
            title='应收水费'>
            <template #default>
              <span style='color: #DBAE74;'>{{ waterTotalAmount }}元</span>
            </template>
          </van-cell>
          <van-field
            v-if="type === 'check' && (detail.customerType==1||detail.customerType==2 || detail.customerType==3)"
            v-model='waterTotalAmount'
            @input='e => waterTotalAmount = prohibitNegative(e,2,10)'
            placeholder='请输入应收水费'
            :disabled='detail.status > 4'
            input-align='right'>
            <template #label>
              <span style='color:#323233;'>应收水费</span>
            </template>
            <template #extra>
              <span style='color:#DBAE74;padding: 0 10px;'>元</span>
            </template>
          </van-field>
          <van-cell title='水费结余'>
            <template #default>
              <span style='color: #DBAE74;'>{{ waterReturnAmount }}元</span>
            </template>
          </van-cell>
          <van-cell
            v-if="type === 'detail'"
            title='已收电费'>
            <template #default>
              <span style='color: #DBAE74;'>{{ powerPaidAmount }}元</span>
            </template>
          </van-cell>
          <van-field
            v-if="type === 'check' && (detail.customerType==1||detail.customerType==2 || detail.customerType==3)"
            v-model='powerPaidAmount'
            @input='e => powerPaidAmount = prohibitNegative(e,2,10)'
            type='number'
            placeholder='请输入已收电费'
            :disabled='true'
            input-align='right'>
            <template #label>
              <span style='color:#323233;'>已收电费</span>
            </template>
            <template #extra>
              <span style='color:#DBAE74;padding: 0 10px;'>元</span>
            </template>
          </van-field>
          <van-cell
            v-if="type === 'detail'"
            title='应收电费'>
            <template #default>
              <span style='color: #DBAE74;'>{{ powerTotalAmount }}元</span>
            </template>
          </van-cell>
          <van-field
            v-if="type === 'check' && (detail.customerType==1||detail.customerType==2 || detail.customerType==3)"
            v-model='powerTotalAmount'
            @input='e => powerTotalAmount = prohibitNegative(e,2,10)'
            placeholder='请输入应收电费'
            :disabled='detail.status > 4'
            input-align='right'>
            <template #label>
              <span style='color:#323233;'>应收电费</span>
            </template>
            <template #extra>
              <span style='color:#DBAE74;padding: 0 10px;'>元</span>
            </template>
          </van-field>
          <van-cell title='电费结余'>
            <template #default>
              <span style='color: #DBAE74;'>{{ powerReturnAmount }}元</span>
            </template>
          </van-cell>
          <van-cell
            v-if="type === 'detail'"
            title='水电结余费'>
            <template #default>
              <span style='color: #DBAE74;'>{{ detail.waterElectricity }}元</span>
            </template>
          </van-cell>
          <van-field
            v-if="type === 'check' && (detail.customerType==1||detail.customerType==2 || detail.customerType==3)"
            v-model='waterElectricity'
            :disabled='true'
            input-align='right'>
            <template #label>
              <span style='color:#323233;'>水电结余费</span>
            </template>
            <template #extra>
              <span style='color:#DBAE74;padding: 0 10px;'>元</span>
            </template>
          </van-field>
          <van-cell title='挂账总金额' v-if='detail.chargeAmount !== 0'>
            <template #default>
              <span style='color: #FF3333;'>{{ detail.chargeAmount }}元</span>
            </template>
          </van-cell>
          <van-cell title='赔偿费'>
            <template #default>
              <span style='color: #FF3333;'>{{ detail.damages }}元</span>
            </template>
          </van-cell>
          <van-cell title='打扫费'>
            <template #default>
              <span style='color: #FF3333;'>{{ detail.cleanFee }}元</span>
            </template>
          </van-cell>
          <van-cell title='额外增值费'>
            <template #default>
              <span style='color: #FF3333;'>{{ detail.otherFee }}元</span>
            </template>
          </van-cell>
          <van-cell title='应退还总费用' title-style='flex:2.5;'>
            <template #default>
              <!--yingtuihuan 等于 returnPaidAmount一致-->
              <span style='color: #DBAE74;'>{{ yingtuihuan }}元</span>
            </template>
            <template #label>
              <span v-if='detail.customerType==1' style='color: #DBAE74;'>({{ detail.inDeposit }}+{{ ~~waterElectricity
                }}={{ yingtuihuan }}元)</span>
              <span v-if='detail.customerType==2' style='color: #DBAE74;'>({{ detail.afterRentBreakAmount
                }}+{{ detail.inDeposit }}+{{ ~~waterElectricity }}={{ yingtuihuan }}元)</span>
              <span v-if='detail.customerType==3' style='color: #DBAE74;'>({{ detail.inDeposit }}+{{ ~~waterElectricity
                }}+{{ detail.paidBreakAmount }}+{{ detail.paidExceedRentAmount }}={{ yingtuihuan }}元)</span>
            </template>
          </van-cell>
          <van-cell title='应缴纳总费用' title-style='flex:2.5;'>
            <template #default>
              <!--yingjiaona 等于 rentTotalAmount-->
              <span style='color: #FF3333;'>{{ yingjiaona }}元</span>
            </template>
            <template #label>
              <span style='color: #FF3333;'>({{ detail.delayAmountUnpaid }}+</span>
              <span v-if='detail.customerType==1 && detail.chargeAmount !== 0'
                    style='color: #FF3333;'>{{ detail.damages }}+{{ detail.cleanFee }}+{{ detail.otherFee
                }}+{{ detail.chargeAmount }}={{ yingjiaona }}元)</span>
              <span v-else-if='detail.customerType==2  && detail.chargeAmount !== 0'
                    style='color: #FF3333;'>{{ detail.breakAmount }}+{{ detail.damages }}+{{ detail.cleanFee
                }}+{{ detail.otherFee }}+{{ detail.chargeAmount }}={{ yingjiaona }}元)</span>
              <span v-else-if='detail.customerType==3  && detail.chargeAmount !== 0'
                    style='color: #FF3333;'>{{ detail.breakAmount }}+{{ detail.breakAmount }}+{{ detail.damages
                }}+{{ detail.cleanFee }}+{{ detail.otherFee }}+{{ detail.chargeAmount }}={{ yingjiaona }}元)</span>
              <span v-else-if='detail.customerType==1' style='color: #FF3333;'>{{ detail.damages }}+{{ detail.cleanFee
                }}+{{ detail.otherFee }}={{ yingjiaona }}元)</span>
              <span v-else-if='detail.customerType==2' style='color: #FF3333;'>{{ detail.breakAmount
                }}+{{ detail.damages }}+{{ detail.cleanFee }}+{{ detail.otherFee }}={{ yingjiaona }}元)</span>
              <span v-else-if='detail.customerType==3' style='color: #FF3333;'>{{ detail.breakAmount
                }}+{{ detail.rentBreakAmount }}+{{ detail.damages }}+{{ detail.cleanFee }}+{{ detail.otherFee
                }}={{ yingjiaona
                }}元)</span>
            </template>
          </van-cell>
          <van-cell title='实际总退房费用' title-style='flex:2.5;'>
            <template #default>
              <!--shiji 等于 checkOutAmount-->
              <span style='color: #323233;'>{{ shiji }}元</span>
            </template>
            <template #label>
              <span style='color: #323233;'>({{ yingtuihuan }}-{{ yingjiaona }}={{ shiji
                }}元)</span>
            </template>
          </van-cell>
          <van-field
            v-if="type === 'detail'"
            :value='detail.remark'
            autosize
            label='备注(选填)'
            type='textarea'
            readonly
          />
          <van-field
            v-if="type === 'check' && (detail.customerType==1||detail.customerType==2 || detail.customerType==3)"
            v-model='remark'
            autosize
            label='备注'
            type='textarea'
            placeholder='请输入备注'
          />
        </van-cell-group>
        <van-cell-group inset class='cell-group'>
          <van-cell :border='false'>
            <template #title>
              <span style='font-size:15px;font-weight: 400;'>请选择租户退房协议签署方式</span>
            </template>
          </van-cell>
          <van-radio-group v-model='radio'>
            <van-cell-group v-if="type === 'check' && `${detail.status}` === '4'">
              <van-cell clickable @click='radio = 0'>
                <template #title>
                  <van-radio :name='0'>租户线上签署</van-radio>
                </template>
              </van-cell>
              <van-cell clickable @click='radio = 1'>
                <template #title>
                  <van-radio :name='1'>租户线下签署</van-radio>
                </template>
              </van-cell>
            </van-cell-group>
            <van-cell-group v-else>
              <van-cell v-if='detail.isOnLine === 0'>
                <template #title>
                  <van-radio :name='0'>租户线上签署</van-radio>
                </template>
              </van-cell>
              <van-cell v-else>
                <template #title>
                  <van-radio :name='1'>租户线下签署</van-radio>
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
        </van-cell-group>
      </template>
      <template #footer>
        <div class='cyui-actionbar' v-if="type === 'check' && `${detail.status}` === '4'">
          <div class='cyui-actionbar-item cyui-actionbar-btns'>
            <van-button class='cyui-btn cyui-btn-primary cyui-btn-block' round block type='info' @click='handleSubmit'>
              确认
            </van-button>
          </div>
        </div>
      </template>
    </cyui-layout>
  </div>
</template>

<script>
  import { debounce } from 'lodash'
  import common from '@mixins/common'
  import filters from '@filters/filters'
  import { formatPx2Rem } from '@utils/index'
  import { dateFilter, floatSub, isIOS, prohibitNegative } from '@utils/utils'
  import { statusMap } from '@/dictionaries/checkOut'
  import {
    getDetailAPI,
    submitCheckOutCheckAPI,
    submitCheckOutCheckOffAPI,
    getHouseIotAbilityApi,
    getWaterElectricityApi
  } from '@api/checkOut'

  export default {
    name: 'CheckOutDetail',
    mixins: [common, filters],
    components: {},
    data() {
      return {
        id: '',
        type: '',
        detail: {},
        headerTitle: '退房信息',
        statusMap,
        isLoading: false, // 是否在加载数据
        isRefreshPage: false, // 是否需要刷新页面
        noDataMessage: '', // 列表为空时的提示文案
        checkOutPayDate: '', // 退款时间
        waterPaidAmount: 0, // 已收水费
        waterTotalAmount: undefined, // 应收水费
        powerPaidAmount: 0, // 已收电费
        powerTotalAmount: undefined, // 应收电费
        // waterElectricity: 0, // 水电的金额
        remark: undefined, // 备注
        minDate: new Date(),
        maxDate: new Date(2099, 12, 31),
        date: '',
        show: false,
        radio: 0,
        useBack: false
      }
    },
    computed: {
      yingtuihuan() {
        if (this.detail.customerType == 1) {
          return (this.detail.inDeposit * 1 + this.waterElectricity * 1).toFixed(2)
        } else if (this.detail.customerType == 2) {
          return (this.detail.afterRentBreakAmount * 1 + this.detail.inDeposit * 1 + this.waterElectricity * 1).toFixed(2)
        } else if (this.detail.customerType == 3) {
          return (this.detail.afterRentBreakAmount * 1 + this.detail.inDeposit * 1 + this.waterElectricity * 1 + this.detail.paidBreakAmount * 1 + this.detail.paidExceedRentAmount * 1).toFixed(2)
        } else {
          return 0
        }
      },
      yingjiaona() {
        console.log(this.detail, 'detail')
        let amount = this.getYingShouAmount()
        return Number(amount) + Number(this.detail.delayAmountUnpaid)
      },
      shiji() {
        return (this.yingtuihuan * 1 - this.yingjiaona * 1).toFixed(2)
      },
      zhinaday() {
        return this.detail.overdueDays * 1
      },
      zhinajin() {
        // return (this.detail.monthRentAmount / 30 * this.zhinaday).toFixed(2)
        return this.detail.rentBreakAmount * 1
      },
      // 水费结余
      waterReturnAmount() {
        return (this.waterPaidAmount * 100000 - this.waterTotalAmount * 100000) / 100000 || 0
      },
      // 电费结余
      powerReturnAmount() {
        return (this.powerPaidAmount * 100000 - this.powerTotalAmount * 100000) / 100000 || 0
      },
      // 水电结余费
      waterElectricity: {
        get() {
          return (this.waterReturnAmount + this.powerReturnAmount).toFixed(2)
        },
        set(val) {
          return val
        }
      }
    },
    methods: {
      prohibitNegative,
      onConfirm(date) {
        this.show = false
        this.date = this.dateFilter(date, '-', 'YYYY-MM-DD')
      },
      formatPx2Rem,
      // 日期格式化
      dateFilter,
      async handleCallback({ isError, errorMessage = undefined }) { // 回调处理
        if (isError) {
          this.$toast(errorMessage || '获取失败!')
          /* if (errorMessage && errorMessage.includes('timeout')) {
            // 判断是否是网络请求超时
            this.isRefreshPage = true
            this.noDataMessage = '网络请求超时'
          } else if (errorMessage && errorMessage.includes('502')) {
            // 判断是否是服务器错误
            this.isRefreshPage = true
            this.noDataMessage = '服务器错误，502错误'
          } */
        }
      },
      getYingShouAmount() {
        if (this.detail.customerType == 1 && this.detail.chargeAmount !== 0) {
          return (this.detail.damages * 1 + this.detail.cleanFee * 1 + this.detail.otherFee * 1 + this.detail.chargeAmount * 1).toFixed(2)
        } else if (this.detail.customerType == 2 && this.detail.chargeAmount !== 0) {
          return (this.detail.breakAmount * 1 + this.detail.damages * 1 + this.detail.cleanFee * 1 + this.detail.otherFee * 1 + this.detail.chargeAmount * 1).toFixed(2)
        } else if (this.detail.customerType == 3 && this.detail.chargeAmount !== 0) {
          return (this.detail.breakAmount * 2 + this.detail.damages * 1 + this.detail.cleanFee * 1 + this.detail.otherFee * 1 + this.detail.chargeAmount * 1).toFixed(2)
        } else if (this.detail.customerType == 1) {
          return (this.detail.damages * 1 + this.detail.cleanFee * 1 + this.detail.otherFee * 1).toFixed(2)
        } else if (this.detail.customerType == 2) {
          return (this.detail.breakAmount * 1 + this.detail.damages * 1 + this.detail.cleanFee * 1 + this.detail.otherFee * 1).toFixed(2)
        } else if (this.detail.customerType == 3) {
          return (this.detail.breakAmount * 1 + this.detail.damages * 1 + this.detail.cleanFee * 1 + this.detail.otherFee * 1 + this.detail.rentBreakAmount * 1).toFixed(2)
        } else {
          return 0
        }
      },
      handleSubmit: debounce(function() {
        //        if (!this.date) {
        //          this.$toast('请选择退款时间')
        //          return
        //        }

        if (this.waterPaidAmount == undefined) {
          this.$toast('请填写已收水费')
          return
        }

        if (!this.waterTotalAmount) {
          this.$toast('请填写应收水费')
          return
        }

        if (this.powerPaidAmount == undefined) {
          this.$toast('请填写已收电费')
          return
        }

        if (!this.powerTotalAmount) {
          this.$toast('请填写应收电费')
          return
        }

        // if (!this.waterElectricity) {
        //   this.$toast('请填写水电结余费')
        //   return
        // }
        this.$dialog.confirm({
          title: '提示',
          message: '是否确认？',
          confirmButtonColor: '#D8B084'
        }).then(async () => {
          // on confirm
          await this.submitCheckOutCheckData()
        }).catch(() => {
          // on cancel
        })
      }, 200),
      async submitCheckOutCheckData({ isShowLoading } = { isShowLoading: true }) { // 完成接待
        try {
          this.isLoading = true
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let {
            id,
            waterElectricity
            //            date
          } = this
          let response = null
          const {
            waterTotalAmount,
            waterPaidAmount,
            waterReturnAmount,
            powerTotalAmount,
            powerPaidAmount,
            powerReturnAmount,
            remark
          } = this
          if (this.radio === 0) {
            response = await submitCheckOutCheckAPI({
              id,
              waterElectricity,
              waterTotalAmount,
              waterPaidAmount,
              waterReturnAmount,
              powerTotalAmount,
              powerPaidAmount,
              powerReturnAmount,
              remark
              //            checkOutPayDate: date
            })
          } else {
            response = await submitCheckOutCheckOffAPI({
              id,
              waterElectricity,
              waterTotalAmount,
              waterPaidAmount,
              waterReturnAmount,
              powerTotalAmount,
              powerPaidAmount,
              powerReturnAmount,
              remark
              //            checkOutPayDate: date
            })
          }
          let result = response.data
          // console.log(result)
          if (`${result.code}` === '10000') { // 请求成功
            // 接口请求处理成功之后回跳
            this.$toast({
              message: '提交成功！',
              onClose: () => {
                // this.$emit('checkOutDetailUpdate') // 更新上一级页面的数据
                // setTimeout(() => {
                //   this.$router.go(-1)
                // }, 500)

                if (this.useBack) {
                  if (isIOS() === 'ios') {
                    window.webkit.messageHandlers.backPage.postMessage(null)
                  } else {
                    if (window.commonJS) {
                      window.commonJS.backPage('')
                    }
                  }
                } else {
                  this.$router.replace({
                    path: '/checkOut'
                  })
                }
              }
            })
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '失败!'
            })
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '失败!'
          })
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async initData() {
      },
      getHouseIotAbility(houseId) {
        getHouseIotAbilityApi({ houseId }).then(res => {
          if (res.data.code === '10000' && res.data.data.ifIot === 1) {
            // 获取水电信息
            this.getWaterElectricity()
          }
        })
      },
      getWaterElectricity() {
        getWaterElectricityApi({ dcContractId: this.detail.dcContractId }).then(res => {
          if (res.data.code === '10000') {
            let result = res.data.data
            this.waterPaidAmount = result.waterPaidAmount
            this.waterTotalAmount = result.waterTotalAmount
            // this.waterReturnAmount = 1
            this.powerPaidAmount = result.powerPaidAmount
            this.powerTotalAmount = result.powerTotalAmount
            // this.powerReturnAmount = 1
            // this.waterElectricity = 1
          }
        })
      }
    },
    filters: {},
    watch: {},
    created() {
    },
    async mounted() {
      const { token, projectId, projectName, userId, userName, useBack, id } = this.$route.query
      if (useBack) {
        this.useBack = true
      }
      if (token && projectId) {
        this.$store.commit('user/SET_TOKEN', token)
        this.$store.commit('user/SET_USER_INFO', { dcProjectId: projectId, userId, userName, projectName })
        this.$store.commit('user/SET_PROJECT_INFO', { dcProjectId: projectId, projectName })
      }
      this.id = id
      let res = await getDetailAPI({ id })
      this.detail = res.data.data || {}
      let {
        rentPaidAmount,
        rentAmount,
        rentBreakAmount
      } = res.data.data
      if (this.detail.customerType == 2) {
        let amount = floatSub(rentPaidAmount, rentAmount)
        this.detail.afterRentBreakAmount = floatSub(amount, rentBreakAmount)
      } else {
        this.detail.afterRentBreakAmount = 0
      }
      if (res.data.data.status == 4) {
        this.type = 'check'
        if (res.data.data.genWaterTotalAmount) {
          this.waterTotalAmount = res.data.data.genWaterTotalAmount
        }
        if (res.data.data.genPowerTotalAmount) {
          this.powerTotalAmount = res.data.data.genPowerTotalAmount
        }
        this.getHouseIotAbility(res.data.data.houseId)
      } else if (res.data.data.status == 5 || res.data.data.status == 6 || res.data.data.status == 7) {
        this.type = 'detail'
      }

      if (this.detail.status > 4) {
        this.waterTotalAmount = this.detail.waterTotalAmount
        this.waterPaidAmount = this.detail.waterPaidAmount
        this.powerTotalAmount = this.detail.powerTotalAmount
        this.powerPaidAmount = this.detail.powerPaidAmount
        this.waterElectricity = this.detail.waterElectricity
        this.date = this.detail.checkOutPayDate
      }

      console.log(this.detail, 'detail')
      this.initData()
      this.waterPaidAmount = this.detail.waterPaidAmount || 0
      this.powerPaidAmount = this.detail.powerPaidAmount || 0
      this.radio = this.detail.isOnLine || 0
    },
    beforeRouteEnter(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    },
    beforeRouteUpdate(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    }
  }
</script>

<style lang='less' scoped>
  @import '../../assets/styles/themes/default/index';
  @import '../../assets/styles/mixins/index';

  @sectionPrefixCls: ~'@{css-prefix}section';

  .@{css-prefix}detail-header {
    width: 100%;
    height: 100px;
    background-color: #D8B084;

    &-content {
      padding-left: 18px;
      line-height: 24px;
      color: #ffffff;

      .@{css-prefix}detail-header-title {
        margin-bottom: 10px;
        font-size: 16px;
      }

      .@{css-prefix}detail-header-brief {
        font-size: 12px;

        > span {
          &:not(:last-of-type) {
            margin-right: 10px;
          }
        }
      }
    }

    &-extra {
      padding-right: 30px;
      line-height: 24px;
      font-size: 16px;
      color: #ffffff;

      .@{css-prefix}detail-header-status {
        position: relative;

        .svg-icon {
          display: block;
          width: 54px;
          height: 54px;
          vertical-align: middle;
        }
      }
    }
  }

  .@{css-prefix}section {
    &-item {
      overflow: hidden;

      .@{css-prefix}card-header {
        min-height: 50px;

        &-line {
          .hairline('bottom', 1px, #EEEEEE);

          .@{css-prefix}card-header-thumb {
            margin-right: 10px;

            .svg-icon {
              display: block;
              width: 18px;
              height: 18px;
              vertical-align: middle;
            }
          }

          .@{css-prefix}card-header-content {
            font-size: 15px;
            font-weight: bold;
            color: #333333;
          }
        }
      }

      .@{css-prefix}card-body {
        padding: 15px;
      }

      & + & {
        margin-top: 12px;
      }
    }
  }

  .@{sectionPrefixCls}-remark {
    padding: 0 5px;
    line-height: 24px;
    color: #333333;
    font-size: 12px;
    overflow: hidden;

    &-content {
      padding: 10px 0 20px;
    }

    &-pic {
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 -5px;
      padding: 10px 0;
      .hairline('top', 1PX, #EEEEEE);

      &-item {
        display: block;
        width: 86px;
        height: 86px;
        padding: 10px 5px 0;
        overflow: hidden;

        > img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }
    }
  }

  .@{sectionPrefixCls}-client {
    padding: 0 10px;
    background-color: #FFFFFF;
  }

  .@{css-prefix}actionbar {
    position: relative;
    height: 65px;
    background-color: transparent;

    .@{css-prefix}actionbar-btns {
      padding: 10px 43px 15px;

      .@{css-prefix}btn {
        height: 40px;
        line-height: 40px;
        background-color: #FFFFFF;
        font-size: 15px;
        border: 1px solid #D1A36F;
        border-radius: 20px;
        color: #D1A36F;

        &.@{css-prefix}btn-primary {
          color: #FFFFFF;
          background-color: #D1A36F;
        }

        &.@{css-prefix}btn-ghost {
        }
      }

      .@{css-prefix}btn + .@{css-prefix}btn {
        margin-left: 30px;
      }
    }
  }

  .no-data {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
  }

  .cell-group {
    margin-top: 15px;
  }
</style>
