<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="routeMetaTitle" @go-back="goBack">
        </cyui-header>
      </template>
      <template #default>
        <div class="page">
          <van-cell-group class='cell-group'>
            <van-cell title="调入申请员" :value="inApplicant"></van-cell>
            <van-cell title="申请时间" :value="inTime"></van-cell>
            <van-cell title="调入项目" :value="projectName" v-if="reviewStatus>1"></van-cell>
          </van-cell-group>
          <div class="list">
            <div class="item" v-for="(item,index) in productList" :key="index">
              <div class="title"><span>申请明细：第{{ index+1 }}条</span></div>
              <van-cell-group class='cell-group'>
                <van-cell title="资产属性"  :value="productPropertyMap[item.productProperty]||'-'"></van-cell>
                <van-cell title="资产名称" :value="item.productName||'-'"></van-cell>
                <van-cell title="主规格" :value="item.standard||'-'"></van-cell>
                <!--<van-cell title="资产编号" :value="item.productNo||'-'"></van-cell>-->
                <van-cell title="单位" :value="item.unit||'-'" ></van-cell>
                <van-cell title="申请数量" :value="item.applicationNum||'-'"></van-cell>
              </van-cell-group>
            </div>
          </div>
          <van-cell-group class='cell-group'>
            <van-cell title="调出项目" :value="outProjectName||'-'"></van-cell>
            <van-cell title="调出管理员" :value="outKeeper||'-'"></van-cell>
            <van-field class="vtextarea" type='textarea' readonly label='说明' v-model='remark' rows='3' autosize
                       input-align='left'
            />
            <van-cell title="状态">
              <template #default>
                <span v-if="reviewStatus==1">未处理</span>
                <span v-else-if="reviewStatus==2">已驳回</span>
                <span v-else-if="reviewStatus==3&&allocateStatus==0">审批通过</span>
                <span v-else-if="reviewStatus==3&&allocateStatus==1">审批通过并已调拨出库</span>
                <span v-else-if="reviewStatus==4">已撤回</span>
              </template>
            </van-cell>
          </van-cell-group>

        </div>
      </template>
      <template #footer>
        <div class="footer" v-if="from=='other'&&reviewStatus==1">
          <van-button block type="primary" size="large" @click="onPass" style="margin-bottom: 10px">同意</van-button>
          <van-button block type="danger" size="large" @click="onReject">驳回</van-button>
        </div>
      </template>
    </cyui-layout>
    <van-dialog v-model="showReject" title="驳回" show-cancel-button :before-close="onRejectConfirm">
      <div class="rejectPanel">
        <div class="tip">您驳回了调拨申请，请填写驳回原因 </div>
        <van-field label="原因" required class="vtextarea" type='textarea' v-model='rejectReason' rows='3' autosize maxlength='200'
                   placeholder='必填' input-align='left' show-word-limit
        ></van-field>
      </div>
    </van-dialog>
  </div>
</template>

<script>
  import common from '@mixins/common'
  import { mapGetters } from 'vuex'
  import _ from 'lodash'
  import * as assetsApi from '@api/assets'

  const productPropertyList = [
    {label: "易耗品", value: 1},
    {label: "低值品", value: 2},
    {label: "固定资产", value: 3},
  ]
  const productPropertyMap = _.mapValues(_.keyBy(productPropertyList, i=>i.value), j=>j.label);

  export default {
    name: 'AssetsDispatchApplyDetail',
    mixins: [common],
    computed: {
      ...mapGetters('user', [
        'userInfo',
        'projectInfo',
      ])
    },
    data() {
      return {
        from: '',
        allocateApplyId: '',
        inApplicant:'',
        inTime: '',
        projectName: '',
        productList: [],
        outProjectName: '',
        outKeeper: '',
        outSpaceProjectId: '',
        remark: '',
        reviewStatus: '',
        allocateStatus: '',
        productPropertyMap,
        showReject: false,
        rejectReason: '',
      }
    },
    created() {
      this.from = this.$route.query.from;
      this.allocateApplyId = this.$route.query.allocateApplyId;
      this.getData();
    },
    methods: {
      getData(){
        assetsApi.getAllocateApplyDetail(this.allocateApplyId).then(res=>{
          let item = res.data.data.result;
          this.inApplicant = item.inApplicant;
          this.inTime = item.applicationDate;
          this.projectName = item.projectName;
          this.productList = item.productList;
          this.outProjectName = item.outProjectName;
          this.outKeeper = item.outKeeper;
          this.remark = item.remark;
          this.reviewStatus = item.reviewStatus;
          this.allocateStatus = item.allocateStatus;
        })
      },
      onPass() {
        this.$dialog.confirm({
          message: '请确认是否同意该调拨申请？',
        }).then(()=>{
          assetsApi.passAllocateApply({
            allocateApplyId: this.allocateApplyId,
          }).then(res=>{
            this.$toast('提交成功');
            this.$router.back();
          })
        }).catch(() => {
          // on cancel
        });

      },
      onReject() {
        this.rejectReason = '';
        this.showReject = true;
      },
      onRejectConfirm(action, done) {
        if (action === 'confirm') {
          if (!this.rejectReason) {
            this.$toast('请填写原因');
            done(false);
            return
          }
          assetsApi.rejectAllocateApply({
            allocateApplyId: this.allocateApplyId,
            reason: this.rejectReason,
          }).then(res=>{
            done();
            this.$toast('提交成功');
            this.$router.back();
          }).catch(e=>{
            done(false);
          })
        } else {
          done();
        }

      }
    }
  }
</script>

<style lang="less" scoped>
  @import "./common.less";
  .cyui-layout-wrapper {
    background: #F6F6F6;
  }
  .page {
    padding: 10px;
  }
  .cell-group {
    margin-bottom: 10px;
  }
  .list {
    margin-bottom: 10px;
    .item {
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 3px 0 5px;
        margin-bottom: 10px;
        font-size: 14px;
        color: #6D6868;
        /deep/ .van-icon {
          font-size: 18px;
          color: #999999;
        }
      }
    }
  }
  .rejectPanel {
    display: flex;
    flex-direction: column;
    .tip {
      font-size: 17px;
      color: #999999;
      padding: 0 10px;
    }
    /deep/ .van-field {
      &__label {
        color: #999;
      }
      &__value {
        border: none;
        background: #f5f5f5;
      }
    }
  }
  .footer {
    width: 100%;
    padding: 10px;
  }
</style>
