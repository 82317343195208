<template>
  <div class='container'>
    <div class='card mt-8'>
      <div class='card-header'>
        <van-image width='25'
                   height='25'
                   :src="require('../../assets/images/payExpence/payment.png')" />
        <span class='tenantName ml-12'>{{ paymentData.createName }}</span>
        <span class='tentType ml-12'
              v-if='paymentData.businessType === 2'>邀请您支付{{ paymentData.paymentUnionpayCostBusinessEnt.typeName
          }}</span>
        <span class='tentType ml-12' v-else>邀请您支付房屋账单</span>
      </div>
      <div class='line'></div>
      <div class='card-amount'>
        <span>¥ {{ paymentData.totalAmount }}</span>
      </div>
      <div class='card-pay'>
        <span>需付款</span>
      </div>
      <div style='margin: 20px 20px 0 20px;'>
        <van-button v-if='disabled===true' round block type='primary'>
          支付已完成
        </van-button>
        <van-button v-else round block type='info' @click='handleClickPay'>
          立即支付
        </van-button>
      </div>
    </div>

    <div class='card mt-12' v-if='paymentData.paymentUnionpayUnnaturalBusiness&&paymentData.unnaturalMonthRentAmount'>
      <div class='card-title'>
        <div class='card-title__field'>{{ paymentData.projectName + paymentData.roomNo }}</div>
      </div>
      <div class='card-content'>
        <div class='card-content-item'>
          <div class='card-content-item__field'>账单周期</div>
          <div class='card-content-item__value'>
            {{ paymentData.unnaturalMonthRentAmount.startDate + ' 至 ' + paymentData.unnaturalMonthRentAmount.endDate }}
          </div>
        </div>
        <div class='card-content-item'>
          <div class='card-content-item__field'>账单金额</div>
          <div class='card-content-item__value'> {{ paymentData.paymentUnionpayUnnaturalBusiness.totalAmount }}</div>
        </div>
        <div class='card-content-item'
             v-if='paymentData.paymentUnionpayUnnaturalBusiness.depositAmount&&paymentData.paymentUnionpayUnnaturalBusiness.depositAmount!=0'>
          <div class='card-content-item__field'>押金账单</div>
          <div class='card-content-item__value'> {{ paymentData.paymentUnionpayUnnaturalBusiness.depositAmount }}</div>
        </div>
        <div class='card-content-item'
             v-if='paymentData.paymentUnionpayUnnaturalBusiness.rentAmount&&paymentData.paymentUnionpayUnnaturalBusiness.rentAmount!=0'>
          <div class='card-content-item__field'>租金金额</div>
          <div class='card-content-item__value'> {{ paymentData.paymentUnionpayUnnaturalBusiness.rentAmount }}</div>
        </div>
        <div class='card-content-item'
             v-if='paymentData.paymentUnionpayUnnaturalBusiness.delayAmount&&paymentData.paymentUnionpayUnnaturalBusiness.delayAmount!=0'>
          <div class='card-content-item__field'>滞纳金</div>
          <div class='card-content-item__value'> {{ paymentData.paymentUnionpayUnnaturalBusiness.delayAmount }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import { paymentData } from '@api/expense'

  export default {
    name: 'paymentDetail',
    components: {},
    props: {},
    data() {
      return {
        paymentData: {},
        merOrderId: '',
        disabled: false
      }
    },
    computed: {},
    watch: {},
    created() {
      this.getPaymentData()
    },
    mounted() {

    },
    methods: {
      getPaymentData() {
        let url = window.location.href
        this.merOrderId = url.substring(url.indexOf('=') + 1, url.length)
        paymentData({ order: this.merOrderId }).then(res => {
          let result = res.data
          if (result.code === '10000') {
            this.paymentData = result.data
            if (this.paymentData.notifyStatus === 'TRADE_SUCCESS') {
              this.disabled = true
            }
          }
        })
      },
      handleClickPay() {
        console.log('12')
        let { merOrderId } = this
        let { totalAmount } = this.paymentData
        this.$router.push({
          name: 'paymentIndex',
          query: {
            erjiaOderId: merOrderId,
            erjiaTotalAmount: totalAmount
          }
        })
      }
    }
  }
</script>

<style scoped lang='less'>
  .container {
    .card {
      margin-bottom: 20px;
      background-color: #fff;
      padding: 20px 12px;

      &-header {
        display: flex;
        align-items: center;

        .tenantName {
          color: #669AF0;
          font-size: 15px;
          font-weight: 600;
        }

        .tentType {
          color: #444444;
          font-size: 15px;
        }
      }

      &-amount {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;

        span {
          font-size: 36px;
          color: #333333;
          font-weight: 600;
        }
      }

      &-pay {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;

        span {
          font-size: 15px;
          color: #999999;
          font-weight: 400;
        }
      }

      .line {
        height: 0.5px;
        background-color: #E5E5E5;
        margin-top: 10px;
      }

      .card-title {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e5e5e5;
        padding-bottom: 11px;

        &__field {
          font-size: 17px;
          color: #333;
        }

        &__value {
          font-size: 15px;
        }
      }

      .card-content {
        position: relative;

        .card-content-item {
          display: flex;
          justify-content: space-between;
          margin-top: 13px;

          &__field {
            font-size: 15px;
            color: #333;
          }

          &__value {
            font-size: 15px;
            color: #999;
          }
        }

        .signStatus {
          position: absolute;
          right: 12px;
          bottom: 10px;
        }
      }
    }

    .mt-8 {
      margin-top: 15px;
    }

    .mt-20 {
      margin-top: 30px;
    }

    .space-between {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .ml-12 {
      margin-left: 12px;
    }

    .van-button--round {
      border-radius: 10px;
    }

    .van-button--normal {
      padding: 0 60px;
      font-size: 16px;
    }

    .van-button--info {
      background-color: #669AF0;
      border: 0.02667rem solid #669AF0;
    }

    .van-button--primary {
      background-color: #999999;
      border: 0.02667rem solid #999999;
    }
  }
</style>
