<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="routeMetaTitle" @go-back="goBack">
        </cyui-header>
      </template>
      <template #default>
        <div class="page">
          <van-cell-group class='cell-group'>
            <van-cell title="调入申请员" :value="inApplicant"></van-cell>
            <!--<van-cell title="申请时间" :value="inTime"></van-cell>-->
            <!--<van-cell title="调入仓库" required is-link @click="onInSpaceListShow">-->
            <!--  <template #default>-->
            <!--    <div class="pickerText" :class="{disabled:inSpaceName===''}">{{inSpaceName || '请选择'}} </div>-->
            <!--  </template>-->
            <!--</van-cell>-->
          </van-cell-group>
          <div class="list">
            <div class="item" v-for="(item,index) in productList" :key="index">
              <div class="title"><span>申请明细：第{{ index+1 }}条</span><van-icon name="delete-o" @click="onDelItem(index)" /></div>
              <van-cell-group class='cell-group'>
                <van-cell title="资产属性" required is-link :value="productPropertyMap[item.productProperty]||'请选择'" @click="onProductPropertyShow(index)"></van-cell>
                <van-cell title="资产名称" required is-link :value="item.productName||'请选择'" @click="onProductListShow(index)"></van-cell>
                <van-cell title="主规格" :value="item.standard===null?'根据选择的资产自动带入':(item.standard || '-')"></van-cell>
                <!--<van-cell title="资产编号" value="-" v-if="item.productProperty==1||item.productProperty==2"></van-cell>-->
                <!--<van-cell title="资产编号" :value="item.productNo===null?'根据选择的资产自动带入':(item.productNo||'-')" v-else></van-cell>-->
                <van-cell title="单位" :value="item.unit===null?'根据资产名称自动填充':(item.unit||'-')" ></van-cell>
                <van-cell title="库存数量" :value="item.count===null?'根据资产名称自动填充':(item.count||'-')" ></van-cell>
                <van-cell title="申请数量" required>
                  <template #default>
                    <!--固定资产最多选1个，其他的资产最多为库存数量-->
                    <van-stepper v-model="item.applicationNum" integer :max="item.productProperty===3?1:item.count" />
                  </template>
                </van-cell>
              </van-cell-group>
            </div>
            <div class="addBtn">
              <van-button block plain type="primary" icon="add-o" @click="onAddItem">添加物资</van-button>
            </div>
          </div>
          <van-cell-group class='cell-group'>
            <van-cell title="调出项目" :value="outProjectName||'由第一条资产选择带入'"></van-cell>
            <van-field label="调出管理员" required input-align="right" v-model="outKeeper" placeholder="请输入"></van-field>
            <van-field class="vtextarea" type='textarea' label='说明' v-model='remark' rows='3' autosize maxlength='200' placeholder='请输入'
                       input-align='left' show-word-limit
            />
          </van-cell-group>
        </div>
      </template>
      <template #footer>
        <div class="footer">
          <van-button block type="primary" size="large" @click="onSubmit">提交</van-button>
        </div>
      </template>
    </cyui-layout>
    <!--<van-popup v-model="inSpaceListShow" position="bottom">-->
    <!--  <van-picker show-toolbar value-key="spaceName" :columns="inSpaceList" @confirm="onInSpaceListSelect" @cancel="inSpaceListShow = false" />-->
    <!--</van-popup>-->
    <van-popup v-model="productPropertyListShow" position="bottom">
      <van-picker ref="productPropertyList" show-toolbar value-key="label" :columns="productPropertyList" @confirm="onProductPropertySelect" @cancel="productPropertyListShow = false" />
    </van-popup>
    <van-popup v-model="productListShow" position="bottom" :style="{ height: '70vh' }" closeable>
      <div class="productListPanel">
        <van-search v-model="productListSearchKeyword" show-action shape="round" @search="onProductListSearch" placeholder="请输入资产名称">
          <template #action>
            <div class="searchBtn" @click="onProductListSearch">搜索</div>
          </template>
        </van-search>
        <div class="productListResult">
          <van-cell v-for="(item,index) in productListGets" :key="item.id" :label="item.projectName" clickable @click="onProductListSelect(item)">
            <template #title>
              <div class="productListResultTitle">
                <span>{{item.productName}}</span>
                <span>{{'库存：'+item.count}}</span>
              </div>
            </template>
          </van-cell>
          <van-empty v-if="!productListGets.length" description="无数据"></van-empty>
        </div>
        <van-pagination v-model="productListSearchPageNum" :total-items="productListSearchTotal" :items-per-page="productListSearchPageSize" @change="onProductListSearchPage"/>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import common from '@mixins/common'
  import { mapGetters } from 'vuex'
  import moment from 'moment'
  import _ from 'lodash'
  import * as assetsApi from '@api/assets'

  const defaultProduct = {
    spaceFullCode: null,
    stockId: null,
    productId: null,
    productNo: null,
    productProperty: null,
    productName: null,
    standard: null,
    unit: null,
    productPrice: null,
    count: null,
    applicationNum: 1,
  }

  const productPropertyList = [
    {label: "易耗品", value: 1},
    {label: "低值品", value: 2},
    {label: "固定资产", value: 3},
  ]
  const productPropertyMap = _.mapValues(_.keyBy(productPropertyList, i=>i.value), j=>j.label);

  export default {
    name: 'AssetsDispatchApply',
    mixins: [common],
    computed: {
      ...mapGetters('user', [
        'userInfo',
        'projectInfo',
      ])
    },
    data() {
      return {
        inApplicant:'',
        inTime: '',
        inSpaceCode: '',
        inSpaceName: '',
        productList: [
          { ...defaultProduct }
        ],
        outProjectId: '',
        outProjectName: '',
        outKeeper: '',
        outSpaceProjectId: '',
        remark: '',

        inSpaceListShow: false,
        inSpaceList:[],
        currentProductIndex: -1,
        productPropertyListShow: false,
        productPropertyList,
        productPropertyMap,
        productListShow: false,
        productListGets: [],
        productListSearchKeyword: '',
        productListSearchPageNum:1,
        productListSearchPageSize:10,
        productListSearchTotal:0,
      }
    },
    mounted() {
      this.inApplicant = this.userInfo.userName;
      this.inTime = moment().format('YYYY-MM-DD HH:mm:ss');
    },
    methods: {
      // onInSpaceListShow() {
      //   let { dcProjectId } = this.projectInfo;
      //   assetsApi.getSpaceListByRole(dcProjectId).then(res=>{
      //     if (res.data.data.result) {
      //       this.inSpaceList = res.data.data.result
      //     }
      //   })
      //   this.inSpaceListShow=true;
      // },
      // onInSpaceListSelect(item) {
      //   this.inSpaceCode = item.spaceFullCode;
      //   this.inSpaceName = item.spaceFullName;
      //   this.inSpaceListShow=false;
      // },
      onProductPropertyShow(index) {
        this.productPropertyListShow = true;
        this.currentProductIndex = index;
        this.$nextTick(()=>{
          let value = this.productList[index].productProperty;
          let selectIndex = this.productPropertyList.findIndex(i=>i.value===value);
          this.$refs.productPropertyList.setIndexes([selectIndex]);
        })
      },
      onProductPropertySelect(item) {
        if (this.productList[this.currentProductIndex].productProperty !== item.value) {

          let product = this.productList[this.currentProductIndex];
          Object.assign(product, defaultProduct);
          product.productProperty = item.value;
        }
        this.productPropertyListShow = false;
      },
      onProductListShow(index) {
        // if (!this.inSpaceCode) {
        //   this.$toast('请选择调入仓库');
        //   return
        // }
        this.currentProductIndex = index;
        let productProperty = this.productList[this.currentProductIndex].productProperty;
        if (!productProperty) {
          this.$toast('请先选择资产属性')
          return
        }
        this.productListShow = true;
        this.onProductListSearch();
      },
      onProductListSearch() {
        let productProperty = this.productList[this.currentProductIndex].productProperty;
        if (!productProperty) {
          this.$toast('请先选择资产属性')
          return
        }
        let params = {
          productProperty: productProperty,
          notInProjectId:this.projectInfo.dcProjectId,
          productNameProductNo:this.productListSearchKeyword,
          pageNum:this.productListSearchPageNum,
          pageSize:this.productListSearchPageSize,
        }
        assetsApi.getProjectStockProductList(params).then(res=>{
          this.productListGets = res.data.data;
          this.productListSearchTotal = res.data.count;
        })
      },
      onProductListSearchPage() {
        this.onProductListSearch();
      },
      onProductListSelect(product) {
        let item = this.productList[this.currentProductIndex];
        item.stockId = product.id;
        item.productNo = product.productNo || '';
        item.productId = product.productId;
        item.productName = product.productName|| '';
        item.standard = product.standard|| '';
        item.unit = product.unit || '';
        item.count = product.count || 0;
        item.productPrice = product.actPrice|| '';
        item.productProperty = product.productProperty;
        item.spaceFullCode = product.spaceFullCode || '';
        this.productListShow = false;
        if(this.currentProductIndex == 0) {
          this.outProjectId = product.projectId;
          this.outProjectName = product.projectName;
          // this.outSpaceCode = product.spaceFullCode;
          // this.outSpaceName= product.spaceName;
          // this.outSpaceProjectId= product.projectId;
        }
      },
      onAddItem() {
        this.productList.push({
          ...defaultProduct
        })
      },
      onDelItem(index) {
        this.$dialog.confirm({
          message: '是否确认删除这条记录？',
        }).then(()=>{
          this.productList.splice(index, 1);
        }).catch(() => {
          // on cancel
        });
      },
      onSubmit() {
        // if (!this.inSpaceCode) {
        //   this.$toast('请选择调入仓库')
        //   return
        // }
        if (this.productList.length === 0) {
          this.$toast('请添加物资')
          return
        } else if (this.productList.length) {
          for(let i=0;i<this.productList.length;i++) {
            let item = this.productList[i];
            if (!item.productProperty) {
              this.$toast(`请选择第${i+1}条明细的资产属性`)
              return
            }
            if (!item.productId) {
              this.$toast(`请选择第${i+1}条明细的资产名称`)
              return
            }
          }
        }
        if (!this.outKeeper) {
          this.$toast('请填写调出管理员')
          return
        }
        this.$dialog.confirm({
          message: '是否确认提交？',
        }).then(()=>{
          let { dcProjectId } = this.projectInfo;
          assetsApi.stockAllocateApply({
            inApplicant: this.inApplicant,
            projectId: dcProjectId,
            inSpaceCode: this.inSpaceCode,
            inSpaceName: this.inSpaceName,
            outProjectId: this.outProjectId,
            outProjectName: this.outProjectName,
            outKeeper: this.outKeeper,
            outSpaceProjectId: this.outSpaceProjectId,
            remark: this.remark,
            productList: this.productList,
          }).then(res=>{
            this.$router.replace({name: 'AssetsDispatchApplySuccess'})
          })
        }).catch(() => {
          // on cancel
        });

      }
    }
  }
</script>

<style lang="less" scoped>
  @import "./common.less";
  .cyui-layout-wrapper {
    background: #F6F6F6;
  }
  .page {
    padding: 10px;
  }
  .cell-group {
    margin-bottom: 10px;
  }
  .list {
    margin-bottom: 10px;
    .item {
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 3px 0 5px;
        margin-bottom: 10px;
        font-size: 14px;
        color: #6D6868;
        /deep/ .van-icon {
          font-size: 18px;
          color: #999999;
        }
      }
    }
    .addBtn {
      margin-top: 10px;
      /deep/ .van-button {
        border-radius: 10px;
      }
    }
  }
  .productListPanel {
    padding-top: 40px;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    .productListResult {
      height: 0;
      flex: 1;
      overflow-y: auto;
      &Title {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .footer {
    width: 100%;
    padding: 10px;
  }
</style>
