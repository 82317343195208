const getters = {
  cachedViews: state => state.cachedViews,
  routes: state => {
    const { records, index } = state
    if (records.length > 0 && index < records.length) {
      return records.slice(0, index + 1)
    }
    return []
  }
}
export default getters
