import dayjs from 'dayjs'

/**
 * 验证是否为空
 * @param data
 * @returns {boolean}
 */
export const isEmpty = (data) => {
  return !(data && data !== 'null' && data !== 'undefined')
}

/**
 * 日期格式化
 * @param data
 * @param returnEmpty
 * @param format
 * @returns {string}
 */
export const dateFilter = (data, returnEmpty = '-', format = 'YYYY-MM-DD HH:mm') => {
  return !isEmpty(data) && dayjs(data).isValid() ? dayjs(data).format(format) : returnEmpty
}

/*
* 日期相差天数
* getDaysBetween('2022-02-21','2022-02-28') // 7
* */
export const getDaysBetween = (startDate, enDate) => {
  const sDate = Date.parse(startDate)
  const eDate = Date.parse(enDate)
  if (sDate > eDate) return 0 // 开始日期大于结束日期，返回0
  if (sDate === eDate) return 1 // 如果日期相同 返回一天
  return (eDate - sDate) / (1 * 24 * 60 * 60 * 1000)
}

/**
 * 限制输入小数
 * decimal：限制小数位。0：不允许输入小数点；-1：不限制；其他正整数。默认不限制。
 * maxLength：限制最大长度。包含小数位和负号。默认值255
 **/
export const prohibitNegative = (value, decimal = 1, maxLength = 255) => {
  if (!value) {
    return value
  }
  if (value == '.' || value == '。') {
    return ''
  }
  value = value.replace('。', '.')
  // 限制输入小数点
  if (decimal == 0) {
    value = value.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, '').replace(/\s/g, '').replace('^[-]{0,1}\d+$', '').replace(/[a-zA-Z]+/, '')
  } else {
    value = value.replace(/[`~!@#$%^&*()_+=<>?:"{}|,/;'\\[\]·~！@#￥%……&*（）——+={}|《》？：“”【】、；‘’，。、]/g, '').replace(/\s/g, '').replace('^[-]{0,1}\d+$', '').replace(/[a-zA-Z]+/, '')
  }
  // 限制小数位
  if (decimal > 0) {
    let regex = new RegExp(`^\\d+(?:\\.\\d{0,${decimal}})?`, 'g')
    value = (value && value.match(regex)[0]) || null
  }
  // 限制长度
  if (maxLength > 0 && value && value.length >= maxLength) {
    value = value.slice(0, maxLength)
  }
  return value
}
/**
 * 验证是否为ios
 * */
export const isIOS = () => {
  let u = navigator.userAgent
  let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios
  if (isIOS) {
    return 'ios'
  } else {
    return 'android'
  }
}

// 小数相减通用方法
export const floatSub = (arg1, arg2) => {
  let r1, r2, m, n
  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  m = Math.pow(10, Math.max(r1, r2))
  // 动态控制精度长度
  n = (r1 >= r2) ? r1 : r2
  return ((arg1 * m - arg2 * m) / m).toFixed(n)
}

// 获取给定值的千分位表示
export const getThousandsValue = function(value) {
  if (!value) {
    return '-'
  }
  const amount = Number(value)
  return amount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
}
