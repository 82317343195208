import Layout from '@views/layout/layout'

const maintenanceRouter = {
  path: '/maintenance',
  component: Layout,
  name: 'Maintenance',
  redirect: '/maintenance/index',
  meta: {
    title: '工程详情',
    noCache: true,
    requireAuth: true
  },
  children: [
    {
      path: 'index',
      name: 'maintenanceIndex',
      components: {
        default: () => import('@views/maintenance/index')
      },
      meta: {
        title: '工程详情',
        noCache: true,
        requireAuth: true
      }
    },
    {
      path: 'detail',
      name: 'maintenanceDetail',
      components: {
        default: () => import('@views/maintenance/detail')
      },
      meta: {
        title: '工程详情',
        noCache: true,
        requireAuth: true
      }
    }
  ]
}

export default maintenanceRouter
