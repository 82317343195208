<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="routeMetaTitle" @go-back="goBack">
        </cyui-header>
      </template>
      <template #default>
        <div class="page">
          <img src="./images/success.png">
          <div class="tip">盘点成功</div>
          <div class="backBtn">
            <van-button type="primary" block plain size="large" @click="onNext" style="margin-bottom: 10px;">选择下一区域盘点</van-button>
            <van-button type="primary" block  size="large" @click="goBack(-2)">盘点完成</van-button>
          </div>
        </div>
      </template>
    </cyui-layout>
  </div>
</template>

<script>
  import common from '@mixins/common'
  import router from '@/router'

  export default {
    name: 'AssetsCheckSuccess',
    mixins: [common],
    data() {
      return {}
    },
    methods: {
      onNext() {
        this.goBack(-2);
        setTimeout(()=>{
          router.push({name: 'AssetsCheckSelect'})
        }, 500)
      }
    }
  }
</script>

<style lang="less" scoped>
  @import "./common.less";

  .page {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20vh;

    img {
      width: 108px;
      height: auto;
      margin-bottom: 6px;
    }

    .tip {
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
      color: #333333;
    }
  }
</style>
