<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="routeMetaTitle" @go-back="goBack">
        </cyui-header>
        <date-range-picker title="调出时间" v-model="searchKey.times" @change="onDateChange"></date-range-picker>
      </template>
      <template #default>
        <div class="cyui-scroller-panel">
          <cyui-scroller
            class="wrapper"
            ref="scroller"
            :scrollbar="scrollbarConfig"
            :pullDownRefresh="pullDownRefreshConfig"
            :pullUpLoad="pullUpLoadConfig"
            :startY="parseInt(startY)"
            @pullingDown="handlePullingDown"
            @pullingUp="handlePullingUp"
          >
            <template #default>
              <div class="assets-list">
                <div class="assets-card" v-for="(item,index) in listData" :key="index" @click="goDetail(item)">
                  <div class="row">
                    <div class="label">标题</div>
                    <div class="value">{{item.title}}</div>
                  </div>
                  <div class="row">
                    <div class="label">调出时间</div>
                    <div class="value">{{item.outTime}}</div>
                  </div>
                  <div class="row">
                    <div class="label">调出申请员</div>
                    <div class="value">{{item.outKeeper}}</div>
                  </div>
                  <div class="row">
                    <div class="label">调入项目</div>
                    <div class="value">{{item.inProjectName}}</div>
                  </div>
                  <div class="row">
                    <div class="label">调入管理员</div>
                    <div class="value">{{item.inApplicant}}</div>
                  </div>
                </div>
              </div>
            </template>
          </cyui-scroller>
        </div>
        <no-data
          :msg="noDataMessage"
          v-if="listData.length === 0 && isLoading === false"
        >
          <template #btn>
            <div
              class="cyui-btn cyui-btn-default cyui-btn-inline cyui-btn-radius"
              @click="handleRefreshPage"
              v-if="isRefreshPage"
            >
              刷新重试
            </div>
          </template>
        </no-data>
      </template>
    </cyui-layout>
  </div>
</template>

<script>
  import common from '@mixins/common'
  import scrollList from '@mixins/scrollList'
  import getPageList from '@mixins/getPageList'
  import { mapGetters } from 'vuex'
  import * as assetsApi from '@api/assets'
  import DateRangePicker from './components/DateRangePicker'
  import moment from 'moment'

  export default {
    name: 'AssetsDispatchOutLog',
    mixins: [common,scrollList, getPageList],
    components: {
      DateRangePicker,
    },
    computed: {
      ...mapGetters('user', [
        'projectInfo'
      ])
    },
    data() {
      return {
        // initParams: { // 初始化参数
        //
        // },
        searchKey: {
          page: 1,
          size: 20,
          times: null,
        }, // 搜索参数
        listData: [],
      }
    },
    created () {
      this.initData()
    },
    methods: {
      async initData() {
        await this.resetParams()
        await this.getListData()
      },
      goDetail(item) {
        this.$router.push({
          name: 'AssetsDispatchOutLogDetail',
          query: {
            allocateOutId: item.allocateOutId,
          }
        })
      },
      onDateChange() {
        this.initData()
      },
      async getListData ({ isPullDown, isShowLoading } = { isPullDown: false, isShowLoading: true }) { // 获取列表数据
        try {
          this.isLoading = true
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true);
          let { page, size, times } = this.searchKey;
          let { dcProjectId } = this.projectInfo;
          let response = await assetsApi.getAllocateOutList({
            pageNum: page,
            pageSize: size,
            projectId: dcProjectId,
            startDate: times&&times[0] ? moment(times[0]).format('YYYY-MM-DD HH:mm:ss') : undefined,
            endDate: times&&times[1] ? moment(times[1]).format('YYYY-MM-DD HH:mm:ss') : undefined,
          })
          let result = response.data
          // console.log(result)
          if (`${result.code}` === '200') { // 请求成功
            let hasMore = false
            let resultData = result.data.result.records || []
            hasMore = page < result.data.result.pages
            if (!isPullDown) {
              this.listData.push(...resultData);
            } else {
              this.listData = [...resultData];
            }
            if (!hasMore) {
              this.isLastPage = true
            }
            await this.handleCallback({
              isError: false,
              hasMore,
              // noDataMessage: ' ',
            })
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '获取列表失败!'
            })
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '获取列表失败!'
          })
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
    }
  }
</script>

<style lang="less" scoped>
  @import "./common.less";
  .cyui-scroller-panel {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    & /deep/ .pulldown-wrapper {
      top: 5px !important;
    }
  }
.assets-list {
  padding: 0 12px;
  .assets-card {
    background: #fff;
    box-shadow: 0 4px 10px rgba(240,237,237,0.5);
    border-radius: 10px;
    padding: 10px 10px 20px 10px;
    margin-bottom: 10px;
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }
    .row{
      display: flex;
      padding: 5px 0;
      font-size: 15px;
      line-height: 20px;
      .label {
        flex: 0 0 80px;
        white-space: nowrap;
        margin-right: 5px;
        color: #999999;
      }
      .value {
        color: #333333;
        &.status {
          color: @brand-primary2;
        }
      }
    }
    .actions{
      position: absolute;
      bottom: 20px;
      right: 10px;
      /deep/ .van-button {
        width: 70px;
        height: 36px;
        line-height: 36px;
        border-radius: 6px;
      }
    }
  }
}
</style>
