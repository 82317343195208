<template>
  <div class="cyui-field-list">
    <van-cell-group>
      <template v-for="(item, index) in fields">
        <template v-if="item.filterMethod">
          <van-cell
            :[item.type]="(item.filterMethod)(`${data[item.key]}`) || '-'"
            :key="`baseInfo${index}`"
          >
            <div slot="icon" class="cyui-field-icon" v-if="item.icon">
              <svg-icon :icon-class="item.icon.svgName"></svg-icon>
            </div>
            <div slot="title" v-html="item.title" :style="{ width: titleWidth }"></div>
          </van-cell>
        </template>
        <template v-else>
          <van-cell
            :[item.type]="!isEmpty(data[item.key]) ? data[item.key] : '-'"
            :key="`baseInfo${index}`"
          >
            <div slot="icon" class="cyui-field-icon" v-if="item.icon">
              <svg-icon :icon-class="item.icon.svgName"></svg-icon>
            </div>
            <div slot="title" v-html="item.title" :style="{ width: titleWidth }"></div>
          </van-cell>
        </template>
      </template>
    </van-cell-group>
  </div>
</template>

<script>
  export default {
    name: 'FieldListInfo',
    mixins: [],
    components: {},
    data() {
      return {}
    },
    props: {
      fields: {
        type: Array,
        default() {
          return []
        }
      },
      data: {
        type: Object,
        default() {
          return {}
        }
      },
      titleWidth: {
        type: String,
        default: 'auto'
      }
    },
    computed: {},
    methods: {
      // 验证是否为空
      isEmpty(data) {
        return !(data && data !== 'null' && data !== 'undefined')
      }
    },
    watch: {},
    created() {
    },
    mounted() {
    }
  }
</script>

<style lang="less" scoped>
  @import '../../../assets/styles/themes/default/index';
  @import '../../../assets/styles/mixins/index';

  .@{css-prefix}field-list {

    & /deep/ .van-cell-group {
      &::after {
        display: none;
      }

      .van-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 50px;
        padding: 5px 0;

        &.@{css-prefix}opinion {
          .van-field {
            padding: 0;
          }
        }

        .@{css-prefix}field-icon {
          padding: 0 15px 0 0;

          .svg-icon {
            position: relative;
            top: -2px;
            display: block;
            width: 14px;
            height: 14px;
            vertical-align: middle;
            fill: #D8B084;
          }

          img {
            position: relative;
            top: -2px;
            display: block;
            width: 10px;
            height: auto;
          }
        }
      }

      .van-cell__title {
        flex: none;
        font-size: 12px;
        color: #333333;

        div {
          line-height: 20px;
        }
      }

      .van-cell__value {
        font-size: 12px;
        color: #999999;
      }

      .van-cell__label {
        margin-top: 5px;
        font-size: 15px;
        line-height: 20px;
      }

      .van-cell:not(:last-child)::after {
        left: 0;
        right: 0;
        border-color: #EEEEEE;
      }
    }
  }
</style>
