<template>
  <div class='cyui-layout-wrapper' @click='showRoom=false'>
    <cyui-layout>
      <template #header>
        <cyui-header v-if='isCustomHeader' :title='headerTitle'
                     @go-back='goBack' @on-close='handleClosePage'>
          <template #headerRight>
            <div></div>
          </template>
        </cyui-header>
      </template>
      <template #default>
        <div v-if='activeStep == 1'>
          <van-cell-group inset class='cell-group'>
            <van-cell title='租客房号' title-style='flex:0.5;'>
              <template #default>
                <van-field style='padding: 0 0;' input-align='right' v-model='form.oldRoomNo'
                           placeholder='请输入房号' />
              </template>
              <template #right-icon>
                <div @click='handleSearchRoom' style='margin-left:10px;'>搜索</div>
                <!-- <van-icon name='search' class='search-icon'
                          @click='handleSearchRoom' /> -->
              </template>
            </van-cell>
            <van-cell title='租赁合同日期' title-style='flex:0.5;'>
              <template #default>
                <van-field style='padding: 0 0;' input-align='right' v-model='form.oldRoomContarctTime'
                           placeholder='' disabled />
              </template>
            </van-cell>
            <van-cell title='租客姓名' title-style='flex:0.5;'>
              <template #default>
                <van-field style='padding: 0 0;' input-align='right' v-model='form.tenantName' placeholder=''
                           disabled />
              </template>
            </van-cell>
            <van-cell title='新房源入住日期' is-link :value='form.newRoomStayInTime'
                      @click='newRoomStayInTimeShow = true' />
            <van-action-sheet v-model='newRoomStayInTimeShow'>
              <van-datetime-picker v-model='newRoomStayInTime' @cancel='newRoomStayInTimeShow = false'
                                   @confirm='newRoomStayInTimeConfirm' type='date' title='选择年月日'
                                   :min-date='newRoomMinDate' :max-date='newRoomMaxDate' />
            </van-action-sheet>
          </van-cell-group>
          <van-cell-group inset class='cell-group'>
            <div class='field-box'>
              <div class='title'>新房源信息</div>
              <div class='item-title'></div>
              <van-radio-group v-model='newRoomCode' checked-color='#E4C477' v-if='newRoomList.length'
                               class='room-select'>
                <van-radio :name='room.houseCode' shape='square' :class="{'mt5':idx > 0}"
                           v-for='(room,idx) in newRoomList' :key='room.houseCode'>
                  {{ room.projectName + ' ' + room.roomNo }}
                </van-radio>
              </van-radio-group>
              <div v-else>
                暂无房源信息
              </div>
            </div>
          </van-cell-group>
          <van-cell-group inset class='cell-group'>
            <van-cell title='原房源保证金' title-style='flex:1;'>
              <template #default>
                <van-field v-model='form.oldHouseDeposit' input-align='right' placeholder='请输入'
                           readonly
                           type='number'
                           @input='prohibitNegative'
                           style='padding: 0 0;'>
                  <template #right-icon>
                    <div>元</div>
                  </template>
                </van-field>
              </template>
            </van-cell>
            <van-cell title='新房源保证金' title-style='flex:1;'>
              <template #default>
                <van-field v-model='form.newHouseDeposit' input-align='right' placeholder='请输入'
                           style='padding: 0 0;'
                           type='number'
                           @input='(e)=>form.newHouseDeposit=prohibitNegative(e,2)'
                           @blur='extraDepositCompute'>
                  <template #right-icon>
                    <div>元</div>
                  </template>
                </van-field>
              </template>
            </van-cell>
            <van-cell title='保证金差额' title-style='flex:1;'>
              <template #default>
                <van-field v-model='form.extraDeposit' input-align='right'
                           readonly
                           type='number'
                           style='padding: 0 0;'>
                  <template #right-icon>
                    <div>元</div>
                  </template>
                </van-field>
              </template>
            </van-cell>
            <van-cell title='新房源每月综合服务费' title-style='flex:1;'>
              <template #default>
                <van-field v-model='form.newRoomRentFeeMonth' input-align='right' placeholder='请输入'
                           type='number'
                           @input='(e)=>form.newRoomRentFeeMonth=prohibitNegative(e,2)'
                           style='padding: 0 0;'>
                  <template #right-icon>
                    <div>元</div>
                  </template>
                </van-field>
              </template>
            </van-cell>
            <van-cell title='新房源电表度数' title-style='flex:1;'>
              <template #default>
                <van-field style='padding: 0 0;' input-align='right' v-model='form.newRoomElectricQuantity'
                           placeholder='请输入'>
                  <template #right-icon>
                    <div>度</div>
                  </template>
                </van-field>
              </template>
            </van-cell>
            <van-cell title='新房源电表抄表时间' is-link :value='form.newRoomElectricMeterTime'
                      @click='newRoomElectricMeterTimeShow = true' />
            <van-action-sheet v-model='newRoomElectricMeterTimeShow'>
              <van-datetime-picker v-model='newRoomElectricMeterTime' @cancel='newRoomElectricMeterTimeShow = false'
                                   @confirm='newRoomElectricMeterTimeConfirm' type='datetime' title='选择年月日'
                                   :min-date='minDate1' :max-date='maxDate1' />
            </van-action-sheet>
            <van-cell title='新房源水表度数' title-style='flex:1;'>
              <template #default>
                <van-field style='padding: 0 0;' input-align='right' v-model='form.newRoomWaterQuantity'
                           placeholder='请输入'>
                  <template #right-icon>
                    <div>吨</div>
                  </template>
                </van-field>
              </template>
            </van-cell>
            <van-cell title='新房源水表抄表时间' is-link :value='form.newRoomWaterMeterTime'
                      @click='newRoomWaterMeterTimeShow = true' />
            <van-action-sheet v-model='newRoomWaterMeterTimeShow'>
              <van-datetime-picker v-model='newRoomWaterMeterTime' @cancel='newRoomWaterMeterTimeShow = false'
                                   @confirm='newRoomWaterMeterTimeConfirm' type='datetime' title='选择年月日'
                                   :min-date='minDate1' :max-date='maxDate1' />
            </van-action-sheet>
          </van-cell-group>
          <van-cell-group inset class='cell-group'>
            <div class='field-box'>
              <div class='title'>验房信息</div>
            </div>
            <van-cell title='资产破损赔偿费用' title-style='flex:1;'>
              <template #default>
                <van-field style='padding: 0 0;' input-align='right' v-model='form.damagesFee'
                           type='number'
                           @input='(e)=>form.damagesFee=prohibitNegative(e,2)'
                           placeholder='请输入'>
                  <template #right-icon>
                    <div>元</div>
                  </template>
                </van-field>
              </template>
            </van-cell>
            <van-field
              v-model='form.damagesFeeRemark'
              label='备注'
              placeholder='请输入'
              input-align='right'
            />
            <van-cell title='额外增值服务费用' title-style='flex:1;'>
              <template #default>
                <van-field style='padding: 0 0;' input-align='right' v-model='form.otherFee'
                           type='number'
                           @input='(e)=>form.otherFee=prohibitNegative(e,2)'
                           placeholder='请输入'>
                  <template #right-icon>
                    <div>元</div>
                  </template>
                </van-field>
              </template>
            </van-cell>
            <van-field
              v-model='form.otherFeeRemark'
              label='备注'
              placeholder='请输入'
              input-align='right'
            />
            <van-cell title='保洁费用' title-style='flex:1;'>
              <template #default>
                <van-field style='padding: 0 0;' input-align='right' v-model='form.cleanFee'
                           type='number'
                           @input='(e)=>form.cleanFee=prohibitNegative(e,2)'
                           placeholder='请输入'>
                  <template #right-icon>
                    <div>元</div>
                  </template>
                </van-field>
              </template>
            </van-cell>
            <van-field
              v-model='form.cleanFeeRemark'
              label='备注'
              placeholder='请输入'
              input-align='right'
            />
            <div class='field-box'>
              <div class='title'>水电表度数</div>
            </div>
            <van-cell title='原房源当前电表度数' title-style='flex:1;'>
              <template #default>
                <van-field style='padding: 0 0;' input-align='right' v-model='form.oldRoomElectricQuantity'
                           placeholder='请输入'>
                  <template #right-icon>
                    <div>度</div>
                  </template>
                </van-field>
              </template>
            </van-cell>
            <van-cell title='原房源当前电表抄表时间' is-link :value='form.oldRoomElectricMeterTime'
                      @click='oldRoomElectricMeterTimeShow = true' title-style='flex:1.3;' />
            <van-action-sheet v-model='oldRoomElectricMeterTimeShow'>
              <van-datetime-picker v-model='oldRoomElectricMeterTime' @cancel='oldRoomElectricMeterTimeShow = false'
                                   @confirm='oldRoomElectricMeterTimeConfirm' type='datetime' title='选择年月日'
                                   :min-date='minDate1' :max-date='maxDate1' />
            </van-action-sheet>
            <van-cell title='原房源当前水表度数' title-style='flex:1;'>
              <template #default>
                <van-field style='padding: 0 0;' input-align='right' v-model='form.oldRoomWaterQuantity'
                           placeholder='请输入'>
                  <template #right-icon>
                    <div>吨</div>
                  </template>
                </van-field>
              </template>
            </van-cell>
            <van-cell title='原房源当前水表抄表时间' is-link :value='form.oldRoomWaterMeterTime'
                      @click='oldRoomWaterMeterTimeShow = true' title-style='flex:1.3;' />
            <van-action-sheet v-model='oldRoomWaterMeterTimeShow'>
              <van-datetime-picker v-model='oldRoomWaterMeterTime' @cancel='oldRoomWaterMeterTimeShow = false'
                                   @confirm='oldRoomWaterMeterTimeConfirm' type='datetime' title='选择年月日'
                                   :min-date='minDate1' :max-date='maxDate1' />
            </van-action-sheet>
            <van-row class='upload-container'>
              <van-col span='12'>
                <div class='uploader-col'>
                  <div>电表图片</div>
                  <van-uploader max-count='1' v-model='oldRoomElectricMeterImg'
                                :after-read="file=>afterFileRead(file,'oldRoomElectricMeterImgUrl')" />
                </div>
              </van-col>
              <van-col span='12'>
                <div class='uploader-col'>
                  <div>水表图片</div>
                  <van-uploader max-count='1' v-model='oldRoomWaterMeterImg'
                                :after-read="file=>afterFileRead(file,'oldRoomWaterMeterImgUrl')" />
                </div>
              </van-col>
            </van-row>
          </van-cell-group>
          <van-cell-group inset class='cell-group'>
            <div class='field-box'>
              <div class='title'>原房源水电明细</div>
              <!-- <div class="fee-standard">
                <span>电费标准：1.00元/度</span>
                <span>水费标准：1.00元/吨</span>
              </div> -->
            </div>
            <van-cell title='签约电表抄表' title-style='flex:1;'>
              <template #default>
                <van-field style='padding: 0 0;' input-align='right' v-model='searchedRoom.electricQuantity'
                           placeholder='请输入' readonly>
                  <template #right-icon>
                    <div>度</div>
                  </template>
                </van-field>
              </template>
            </van-cell>
            <van-cell title='签约水表抄表' title-style='flex:1;'>
              <template #default>
                <van-field style='padding: 0 0;' input-align='right' v-model='searchedRoom.waterQuantity'
                           placeholder='请输入' readonly>
                  <template #right-icon>
                    <div>吨</div>
                  </template>
                </van-field>
              </template>
            </van-cell>
            <van-cell title='已收水费' title-style='flex:1;'>
              <template #default>
                <van-field v-model='form.oldRoomWaterFee' input-align='right' placeholder='请输入' style='padding: 0 0;'
                           type='number'
                           @input='(e)=>form.oldRoomWaterFee=prohibitNegative(e,2)'
                           @blur='waterFeeLeftCompute'>
                  <template #right-icon>
                    <div>元</div>
                  </template>
                </van-field>
              </template>
            </van-cell>

            <van-cell title='应收水费' title-style='flex:1;'>
              <template #default>
                <van-field v-model='form.oldRoomWaterFeeShould' input-align='right' placeholder='请输入'
                           style='padding: 0 0;'
                           type='number'
                           @input='(e)=>form.oldRoomWaterFeeShould=prohibitNegative(e,2)'
                           @blur='waterFeeLeftCompute'>
                  <template #right-icon>
                    <div>元</div>
                  </template>
                </van-field>
              </template>
            </van-cell>

            <van-cell title='水费结余' title-style='flex:1;'>
              <template #default>
                <van-field v-model='form.waterFeeLeft' input-align='right' readonly style='padding: 0 0;'
                           type='number'>
                  <template #right-icon>
                    <div>元</div>
                  </template>
                </van-field>
              </template>
            </van-cell>

            <van-cell title='已收电费' title-style='flex:1;'>
              <template #default>
                <van-field v-model='form.oldRoomElectricFee' input-align='right' placeholder='请输入'
                           style='padding: 0 0;'
                           type='number'
                           @input='(e)=>form.oldRoomElectricFee=prohibitNegative(e,2)'
                           @blur='electricFeeLeftCompute'>
                  <template #right-icon>
                    <div>元</div>
                  </template>
                </van-field>
              </template>
            </van-cell>

            <van-cell title='应收电费' title-style='flex:1;'>
              <template #default>
                <van-field v-model='form.oldRoomElectricFeeShould' input-align='right' placeholder='请输入'
                           style='padding: 0 0;'
                           type='number'
                           @input='(e)=>form.oldRoomElectricFeeShould=prohibitNegative(e,2)'
                           @blur='electricFeeLeftCompute'>
                  <template #right-icon>
                    <div>元</div>
                  </template>
                </van-field>
              </template>
            </van-cell>

            <van-cell title='电费结余' title-style='flex:1;'>
              <template #default>
                <van-field v-model='form.electricFeeLeft' input-align='right'
                           style='padding: 0 0;'
                           type='number'>
                  <template #right-icon>
                    <div>元</div>
                  </template>
                </van-field>
              </template>
            </van-cell>
          </van-cell-group>
          <van-cell-group inset class='cell-group'>
            <div class='field-box'>
              <div class='title'>换房原因</div>
              <!-- <van-radio-group v-model="mode" direction="horizontal">
                <van-radio :name="0"  icon-size="16px">客户原因</van-radio>
                <van-radio :name="1" shape="square" icon-size="16px">项目原因</van-radio>
              </van-radio-group> -->
              <div class='reason'>
                <van-radio-group v-model='form.exchangeRoomCause' icon-size='22px' checked-color='#CEA370'
                                 direction='horizontal'>
                  <van-radio name='2'>客户原因</van-radio>
                  <van-radio name='1'>项目原因</van-radio>
                </van-radio-group>
              </div>
            </div>
          </van-cell-group>
        </div>
        <div v-if='activeStep==2'>
          <van-cell-group inset class='cell-group'>
            <van-cell title='租客姓名' title-style='flex:0.5;' :value='roomInfo.tenantName' />
            <van-cell title='租赁合同日期' title-style='flex: 1;'
                      :value='`${roomInfo.oldRoomStartDate}-${roomInfo.oldRoomEndDate}`' />
            <van-cell title='原房源' title-style='flex:0.5;' :value='roomInfo.oldRoomNo' />
            <van-cell :value='roomInfo.oldHouseDeposit' :value-class='calcValueColor(roomInfo.oldHouseDeposit)'
                      title='每月保证金'
                      title-style='flex:0.5;'>
              <template #right-icon>
                <div :class='calcValueColor(roomInfo.oldHouseDeposit)'>元</div>
              </template>
            </van-cell>
            <van-cell title='每月综合服务费' title-style='flex:0.5;' value-class='color_gold'
                      :value='roomInfo.oldRoomRentPrice'>
              <template #right-icon>
                <div class='color_gold'>元</div>
              </template>
            </van-cell>
            <van-cell title='新房源' title-style='flex:0.5;' :value='roomInfo.newRoomNo' />
            <van-cell :value='roomInfo.newHouseDeposit' :value-class='calcValueColor(roomInfo.newHouseDeposit)'
                      title='每月保证金'
                      title-style='flex:0.5;'>
              <template #right-icon>
                <div :class='calcValueColor(roomInfo.newHouseDeposit)'>元</div>
              </template>
            </van-cell>
            <van-cell title='每月综合服务费' title-style='flex:0.5;' value-class='color_gold'
                      :value='(Number(roomInfo.newRoomRentFeeMonth)+Number(roomInfo.newRoomServiceFeeMonth)).toFixed(2)'>
              <template #right-icon>
                <div class='color_gold'>元</div>
              </template>
            </van-cell>
            <van-cell title='新房源入住日期' title-style='flex:0.5;' :value='roomInfo.newRoomStayInTime' />
          </van-cell-group>
          <van-cell-group inset class='cell-group'>
            <div class='field-box'>
              <div class='title'>换房信息明细</div>
            </div>
            <van-cell title='原房源结余综合服务费' title-style='flex:0.5;'
                      :value-class='calcValueColor(roomInfo.balanceFee)'
                      :value='roomInfo.balanceFee'>
              <template #right-icon>
                <div :class='calcValueColor(roomInfo.balanceFee)'>元</div>
              </template>
            </van-cell>
            <van-cell :value='roomInfo.extraDeposit' value-class='color_red'
                      title='保证金差额'
                      title-style='flex:0.5;'>
              <template #right-icon>
                <div class='color_red'>元</div>
              </template>
            </van-cell>
            <van-cell title='资产破损配赔偿费' title-style='flex:1;' value-class='color_red'
                      :value='`-${roomInfo.damagesFee}`'>
              <template #right-icon>
                <div class='color_red'>元</div>
              </template>
            </van-cell>
            <van-cell title='额外增值服务费' title-style='flex:0.5;' value-class='color_red'
                      :value='`-${roomInfo.otherFee}`'>
              <template #right-icon>
                <div class='color_red'>元</div>
              </template>
            </van-cell>
            <van-cell title='保洁费' title-style='flex:0.5;' value-class='color_red' :value='`-${roomInfo.cleanFee}`'>
              <template #right-icon>
                <div class='color_red'>元</div>
              </template>
            </van-cell>
            <van-cell :value='roomInfo.oldRoomWaterFee' :value-class='calcValueColor(roomInfo.oldRoomWaterFee)'
                      title='已收水费'
                      title-style='flex:0.5;'>
              <template #right-icon>
                <div :class='calcValueColor(roomInfo.oldRoomWaterFee)'>元</div>
              </template>
            </van-cell>
            <van-cell :value='roomInfo.oldRoomWaterFeeShould'
                      :value-class='calcValueColor(roomInfo.oldRoomWaterFeeShould)'
                      title='应收水费'
                      title-style='flex:0.5;'>
              <template #right-icon>
                <div :class='calcValueColor(roomInfo.oldRoomWaterFeeShould)'>元</div>
              </template>
            </van-cell>
            <van-cell title='水费结余' title-style='flex:0.5;' :value-class='calcValueColor(roomInfo.waterFeeLeft)'
                      :value='roomInfo.waterFeeLeft'>
              <template #right-icon>
                <div :class='calcValueColor(roomInfo.waterFeeLeft)'>元</div>
              </template>
            </van-cell>
            <van-cell :value='roomInfo.oldRoomElectricFee' :value-class='calcValueColor(roomInfo.oldRoomElectricFee)'
                      title='已收电费'
                      title-style='flex:0.5;'>
              <template #right-icon>
                <div :class='calcValueColor(roomInfo.oldRoomElectricFee)'>元</div>
              </template>
            </van-cell>
            <van-cell :value='roomInfo.oldRoomElectricFeeShould'
                      :value-class='calcValueColor(roomInfo.oldRoomElectricFeeShould)'
                      title='应收电费'
                      title-style='flex:0.5;'>
              <template #right-icon>
                <div :class='calcValueColor(roomInfo.oldRoomElectricFeeShould)'>元</div>
              </template>
            </van-cell>
            <van-cell :value='roomInfo.electricFeeLeft' :value-class='calcValueColor(roomInfo.electricFeeLeft)'
                      title='电费结余'
                      title-style='flex:0.5;'>
              <template #right-icon>
                <div :class='calcValueColor(roomInfo.electricFeeLeft)'>元</div>
              </template>
            </van-cell>
            <van-cell title='换房违约金' title-style='flex:0.5;' value-class='color_red'
                      :value='roomInfo.exchangeRuleFee ? `-${roomInfo.exchangeRuleFee}` : 0'>
              <template #right-icon>
                <div class='color_red'>元</div>
              </template>
            </van-cell>
            <van-cell title='换房服务费' title-style='flex:0.5;' value-class='color_red'
                      :value='roomInfo.exchangeServiceFee ? `-${roomInfo.exchangeServiceFee}` : 0'>
              <template #right-icon>
                <div class='color_red'>元</div>
              </template>
            </van-cell>
            <van-cell title='新房源当月应收综合服务费' title-style='flex:1.2;' value-class='color_red'
                      :value='`-${roomInfo.firstMonthPayAmount}`'>
              <template #right-icon>
                <div class='color_red'>元</div>
              </template>
            </van-cell>
            <van-cell title='剩余金额' title-style='flex:0.5;' :value-class='calcValueColor(roomInfo.remainingFee)'
                      :value='roomInfo.remainingFee'>
              <template #right-icon>
                <div :class='calcValueColor(roomInfo.remainingFee)'>元</div>
              </template>
            </van-cell>
          </van-cell-group>
          <van-cell-group inset class='cell-group' v-if='roomInfo.isCanSignOnline || $route.query.id'>
            <div class='field-box'>
              <div class='title'>请选择租户换房合同签署方式</div>
              <div class='reason'>
                <van-radio-group v-model='form.signType' icon-size='22px' checked-color='#CEA370'
                                 :disabled='!!$route.query.id'>
                  <van-radio :name='1'>租户线上签署</van-radio>
                  <van-radio :name='2' style='margin-top:5px'>租户线下签署</van-radio>
                </van-radio-group>
              </div>
            </div>
          </van-cell-group>
        </div>
      </template>
      <template #footer v-if='!$route.query.id'>
        <div class='cyui-actionbar'>
          <div class='cyui-actionbar-item cyui-actionbar-btns'>
            <button v-if='activeStep == 2'
                    type='button'
                    class='cyui-btn cyui-btn-info cyui-btn-block'
                    @click='activeStep = 1'>
              返回上一步
            </button>
            <button v-if='activeStep == 2'
                    type='button'
                    class='cyui-btn cyui-btn-primary cyui-btn-block'
                    @click='handleSubmit'>
              确认提交
            </button>
            <button v-if='activeStep == 1'
                    type='button'
                    class='cyui-btn cyui-btn-primary cyui-btn-block'
                    @click='handleApply'>
              下一步
            </button>
          </div>
        </div>
      </template>
    </cyui-layout>
    <div v-if='showRoom'
         style='position: absolute;top: 100px;left: 0;color: #323233;z-index: 999;width: 100vw;padding: 0 30px;'>
      <van-cell-group inset style='box-shadow: 0 0 5px #888888;'>
        <template>
          <van-cell v-for='(item,index) in roomList' :key='index' :title='item.tenantName'
                    @click='handleSelectRoom(item)' />
        </template>
      </van-cell-group>
    </div>
  </div>
</template>

<script>
  import common from '@mixins/common'
  import filters from '@filters/filters'
  import { formatPx2Rem } from '@utils/index'
  import { isEmpty, dateFilter, prohibitNegative } from '@utils/utils'
  import { getLastRentAPI, getHouseListAPI, calculateFeeAPI, submitAPI, getDetailAPI, getQueryWaterElectricityAPI } from '@api/roomChange'
  import imageCompression from 'browser-image-compression'
  import * as assetsApi from '@api/assets'
  import { mapGetters } from 'vuex'
  import { debounce } from 'lodash'
  import dayjs from 'dayjs'

  export default {
    name: 'RoomChangeCreate',
    mixins: [common, filters],
    components: {},
    data() {
      return {
        activeStep: 1,
        roomInfo: {},
        headerTitle: this.$route.query.id ? '换房详情' : '提交换房申请',
        isLoading: false, // 是否在加载数据
        isRefreshPage: false, // 是否需要刷新页面
        noDataMessage: '', // 列表为空时的提示文案
        detailInfo: {}, // 详情信息
        roomList: [],
        newRoomCode: '',
        newRoomList: [],
        showRoom: true,
        roomSearchText: '',
        roomNo: '',
        roomUser: '',
        roomUserDate: '',
        contractId: '',
        mobile: '',
        bankName: '',
        bankAccount: '',
        bankDeposit: '',
        otherReason: '',
        date1: '',
        date2: '',
        form: {
          exchangeRoomCause: '2',
          signType: 2,
          extraDeposit: 0,
          waterFeeLeft: 0,
          electricFeeLeft: 0
        },
        submitForm: {},
        searchedRoom: {},
        newRoomMinDate: new Date(),
        newRoomMaxDate: new Date(),
        minDate1: new Date('2022-01-01'),
        maxDate1: new Date(),
        minDate2: new Date(),
        maxDate2: new Date(2099, 12, 31),
        newRoomStayInTime: new Date(),
        newRoomStayInTimeShow: false,
        newRoomElectricMeterTime: new Date(),
        newRoomElectricMeterTimeShow: false,
        newRoomWaterMeterTime: new Date(),
        newRoomWaterMeterTimeShow: false,
        oldRoomElectricMeterTime: new Date(),
        oldRoomElectricMeterTimeShow: false,
        oldRoomWaterMeterTime: new Date(),
        oldRoomWaterMeterTimeShow: false,
        // 图片
        oldRoomElectricMeterImg: [],
        oldRoomWaterMeterImg: []
      }
    },
    computed: {
      ...mapGetters('user', [
        'projectInfo'
      ])
    },
    methods: {
      prohibitNegative,
      formatPx2Rem,
      // 日期格式化
      dateFilter,
      // 空字段过滤
      fieldEmptyFilter(data, returnEmpty = '无') {
        return !isEmpty(data) ? data : returnEmpty
      },
      // 字母数字过滤
      formatterField(value) {
        return value.replace(/[^\u4E00-\u9FA5]/g, '')
      },
      // 只能输入数字字母
      formatterField2(value) {
        return value.replace(/[^\w\.\/]/ig, '')
      },
      calcValueColor(val) {
        if (parseInt(val) < 0) {
          return 'color_red'
        } else {
          return 'color_gold'
        }
      },
      getDetail(id) {
        getDetailAPI({ id }).then(res => {
          // console.log(res)
          const result = res.data
          if (result.code === '10000') {
            this.roomInfo = result.data
            this.form.signType = this.roomInfo.signType
            this.activeStep = 2
          } else {
            this.handleCallback({
              isError: true,
              errorMessage: result.msg || '失败!'
            })
          }
        })
      },
      async handleSearchRoom() { // 完成接待
        if (!this.form.oldRoomNo) {
          this.$toast('请输入房号')
          return
        }
        if (this.form.oldRoomNo.length < 3) {
          this.$toast('请输入至少3位房号')
          return
        }
        try {
          this.isLoading = true
          await this.$store.dispatch('base/SetLoading', true)
          // let { roomSearchText } = this
          let { dcProjectId } = this.projectInfo
          let response = await getLastRentAPI({
            projectId: dcProjectId,
            roomNo: this.form.oldRoomNo
          })
          let result = response.data
          // console.log(result)
          if (`${result.code}` === '10000') { // 请求成功
            // 接口请求处理成功之后回跳
            this.roomList = result.data
            this.showRoom = true
            if (this.roomList.length == 0) {
              this.$toast('无搜索结果')
            }
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '失败!'
            })
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '失败!'
          })
        } finally {
          this.isLoading = false
          await this.$store.dispatch('base/SetLoading', false)
        }
      },
      handleSelectRoom(room) {
        console.log(room, 'room')
        this.searchedRoom = { ...room }
        this.form.oldRoomNo = room.roomNo
        // this.roomNo = room.roomNo
        this.form.tenantName = room.tenantName
        this.form.oldHouseDeposit = room.deposit
        this.form.newHouseDeposit = room.deposit
        this.form.oldRoomContarctTime = this.dateFilter(room.startDate, '-', 'YYYY-MM-DD') + '~' + this.dateFilter(room.endDate, '-', 'YYYY-MM-DD')
        if (room.contractType === '续签') {
          this.newRoomMinDate = new Date(dayjs(room.startDate).format('YYYY/MM/DD'))
        } else {
          this.newRoomMinDate = new Date(dayjs(room.startDate).add(1, 'day').format('YYYY/MM/DD'))
        }
        this.newRoomMaxDate = new Date(dayjs(room.endDate).format('YYYY/MM/DD'))
        // this.contractId = room.contractId
        // this.mobile = room.mobile
        getQueryWaterElectricityAPI({ roomId: room.roomId }).then(res => {
          // this.$set(this.roomInfo, this.roomInfo.oldRoomWaterFee, res.data.data.waterPaidAmount)
          this.form.oldRoomWaterFee = res.data.data.waterPaidAmount
          this.form.oldRoomWaterFeeShould = res.data.data.waterTotalAmount
          this.form.waterFeeLeft = res.data.data.waterReturnAmount
          this.form.oldRoomElectricFee = res.data.data.powerPaidAmount
          this.form.oldRoomElectricFeeShould = res.data.data.powerTotalAmount
          this.form.electricFeeLeft = res.data.data.powerReturnAmount
          console.log(res.data.data, 'res---')
        })
        this.showRoom = false
      },
      hourFilter(type, options) {
        if (type === 'hour') {
          // 验房时间是早9晚6
          return options.filter((option) => ['09', '10', '11', '12', '13', '14', '15', '16', '17', '18'].includes(option))
        }
        return options
      },
      async newRoomStayInTimeConfirm(date) {
        try {
          this.isLoading = true
          await this.$store.dispatch('base/SetLoading', true)
          let { dcProjectId } = this.projectInfo
          getHouseListAPI({
            startDate: dayjs(date).format('YYYY-MM-DD'),
            endDate: dayjs(this.searchedRoom.endDate).format('YYYY-MM-DD'),
            roomTypeName: '',
            projectId: dcProjectId
          }).then(res => {
            let result = res.data
            this.newRoomList = result.data
            // console.log('house', res.data)
          }).finally(async () => {
            this.isLoading = false
            await this.$store.dispatch('base/SetLoading', false)
          })
          this.newRoomStayInTimeShow = false
          this.form.newRoomStayInTime = this.dateFilter(date, '-', 'YYYY-MM-DD')
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '失败!'
          })
        }
      },
      newRoomWaterMeterTimeConfirm(date) {
        this.newRoomWaterMeterTimeShow = false
        this.form.newRoomWaterMeterTime = this.dateFilter(date, '-', 'YYYY-MM-DD HH:mm')
      },
      newRoomElectricMeterTimeConfirm(date) {
        this.newRoomElectricMeterTimeShow = false
        this.form.newRoomElectricMeterTime = this.dateFilter(date, '-', 'YYYY-MM-DD HH:mm')
      },
      oldRoomElectricMeterTimeConfirm(date) {
        this.oldRoomElectricMeterTimeShow = false
        this.form.oldRoomElectricMeterTime = this.dateFilter(date, '-', 'YYYY-MM-DD HH:mm')
      },
      oldRoomWaterMeterTimeConfirm(date) {
        this.oldRoomWaterMeterTimeShow = false
        this.form.oldRoomWaterMeterTime = this.dateFilter(date, '-', 'YYYY-MM-DD HH:mm')
      },
      // 保证金差额结算
      extraDepositCompute() {
        let {
          oldHouseDeposit,
          newHouseDeposit
        } = this.form
        if (oldHouseDeposit && newHouseDeposit) {
          if (Number(newHouseDeposit) < Number(oldHouseDeposit)) {
            this.$toast('新房源保证金不能小于原房源')
            this.$set(this.form, 'newHouseDeposit', oldHouseDeposit)
            this.$set(this.form, 'extraDeposit', 0)
          } else {
            let sum = this.floatSub(newHouseDeposit, oldHouseDeposit)
            this.$set(this.form, 'extraDeposit', sum)
          }
        }
      },
      // 水费结余计算
      waterFeeLeftCompute() {
        let {
          oldRoomWaterFee,
          oldRoomWaterFeeShould
        } = this.form
        if (oldRoomWaterFee && oldRoomWaterFeeShould) {
          let sum = this.floatSub(oldRoomWaterFee, oldRoomWaterFeeShould)
          this.$set(this.form, 'waterFeeLeft', sum)
        }
      },
      // 电费结余计算
      electricFeeLeftCompute() {
        let {
          oldRoomElectricFee,
          oldRoomElectricFeeShould
        } = this.form
        if (oldRoomElectricFee && oldRoomElectricFeeShould) {
          let sum = this.floatSub(oldRoomElectricFee, oldRoomElectricFeeShould)
          this.$set(this.form, 'electricFeeLeft', sum)
        }
      },
      // 小数相减通用方法
      floatSub(arg1, arg2) {
        let r1, r2, m, n
        try {
          r1 = arg1.toString().split('.')[1].length
        } catch (e) {
          r1 = 0
        }
        try {
          r2 = arg2.toString().split('.')[1].length
        } catch (e) {
          r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2))
        // 动态控制精度长度
        n = (r1 >= r2) ? r1 : r2
        return ((arg1 * m - arg2 * m) / m).toFixed(n)
      },
      handleApply: debounce(function() {
        // console.log(this.form)
        const priceLimit = /^([\-\+]?[0-9]+[\d]*(.[0-9]{1,2})?)$/
        const numReg = /^[0-9]+([.]{1}[0-9]+){0,1}$/
        // const numNegReg = /^(-)?[0-9]+([.]{1}[0-9]+){0,1}$/
        const intReg = /^-?[0-9]\d*$/

        if (!this.form.oldRoomNo) {
          this.$toast('请选择房号')
          return
        }
        if (!this.form.newRoomStayInTime) {
          this.$toast('新房源入住日期不能为空')
          return
        }
        if (!this.newRoomCode) {
          this.$toast('新房源不能为空')
          return
        }
        if (!this.form.extraDeposit) {
          this.$toast('保证金差额不能为空')
          return
        }
        if (!this.form.newRoomRentFeeMonth || this.form.newRoomRentFeeMonth == 0) {
          this.$toast('新房源每月综合服务费应大于0')
          return
        }
        // if (!intReg.test(this.form.newRoomRentFeeMonth)) {
        //   this.$toast('新房源每月综合服务费请输入整数')
        //   return
        // }
        if (!this.form.newRoomElectricQuantity) {
          this.$toast('新房源电表度数不能为空')
          return
        }
        if (!numReg.test(this.form.newRoomElectricQuantity)) {
          this.$toast('度数请输入整数或小数')
          return
        }
        if (!this.form.newRoomElectricMeterTime) {
          this.$toast('新房源电表抄表时间不能为空')
          return
        }
        if (!this.form.newRoomWaterQuantity) {
          this.$toast('新房源水表度数不能为空')
          return
        }
        if (!numReg.test(this.form.newRoomWaterQuantity)) {
          this.$toast('度数请输入整数或小数')
          return
        }
        if (!this.form.newRoomWaterMeterTime) {
          this.$toast('新房源水表抄表时间不能为空')
          return
        }
        if (!this.form.damagesFee) {
          this.$toast('资产破损赔偿费用不能为空')
          return
        }
        if (this.form.damagesFee) {
          if (this.form.damagesFee < 0) {
            this.$toast('资产破损赔偿费用不能为负数')
            return
          }
          if (!priceLimit.test(this.form.damagesFee)) {
            this.$toast('资产破损赔偿费用请输入数字，支持最多2位小数')
            return
          }
        }
        if (Number(this.form.damagesFee) > 0) {
          if (!this.form.damagesFeeRemark) {
            this.$toast('资产破损赔偿费用备注不能为空')
            return
          }
        }
        if (!this.form.otherFee) {
          this.$toast('额外增值服务费用不能为空')
          return
        }
        if (this.form.otherFee) {
          if (this.form.otherFee < 0) {
            this.$toast('额外增值服务费用不能为负数')
            return
          }
          if (!priceLimit.test(this.form.otherFee)) {
            this.$toast('额外增值服务费用请输入数字，支持最多2位小数')
            return
          }
        }
        if (Number(this.form.otherFeeRemark) > 0) {
          if (!this.form.otherFeeRemark) {
            this.$toast('额外增值服务费用备注不能为空')
            return
          }
        }
        if (!this.form.cleanFee) {
          this.$toast('保洁收费不能为空')
          return
        }
        if (this.form.cleanFee) {
          if (this.form.cleanFee < 0) {
            this.$toast('保洁收费不能为负数')
            return
          }
          if (!priceLimit.test(this.form.cleanFee)) {
            this.$toast('保洁收费请输入数字，支持最多2位小数')
            return
          }
        }
        if (Number(this.form.cleanFeeRemark) > 0) {
          if (!this.form.cleanFeeRemark) {
            this.$toast('保洁收费备注不能为空')
            return
          }
        }
        if (!this.form.oldRoomElectricQuantity) {
          this.$toast('旧房源电表度数不能为空')
          return
        }
        if (!numReg.test(this.form.oldRoomElectricQuantity)) {
          this.$toast('度数请输入整数或小数')
          return
        }
        if (!this.form.oldRoomElectricMeterTime) {
          this.$toast('旧房源电表抄表时间不能为空')
          return
        }
        if (!this.form.oldRoomWaterQuantity) {
          this.$toast('旧房源水表度数不能为空')
          return
        }
        if (!numReg.test(this.form.oldRoomWaterQuantity)) {
          this.$toast('度数请输入整数或小数')
          return
        }
        if (!this.form.oldRoomWaterMeterTime) {
          this.$toast('旧房源水表抄表时间不能为空')
          return
        }
        if (!this.oldRoomElectricMeterImg || !this.oldRoomElectricMeterImg[0] || !this.oldRoomElectricMeterImg[0].fileUrl) {
          this.$toast('旧房源电表抄表图片不能为空')
          return
        }
        if (!this.oldRoomWaterMeterImg || !this.oldRoomWaterMeterImg[0] || !this.oldRoomWaterMeterImg[0].fileUrl) {
          this.$toast('旧房源水表抄表图片不能为空')
          return
        }
        if (!this.form.electricFeeLeft) {
          this.$toast('电费结余不能为空')
          return
        }
        if (!priceLimit.test(this.form.electricFeeLeft)) {
          this.$toast('金额请输入数字，支持最多2位小数')
          return
        }
        if (!this.form.waterFeeLeft) {
          this.$toast('水费结余不能为空')
          return
        }
        if (!priceLimit.test(this.form.waterFeeLeft)) {
          this.$toast('金额请输入数字，支持最多2位小数')
          return
        }
        if (!this.form.exchangeRoomCause) {
          this.$toast('换房原因不能为空')
          return
        }
        // if (!this.bankName) {
        //   this.$toast('请输入账号名')
        //   return
        // }
        // const bankNameReg = /[^\u4E00-\u9FA5]/
        // if (bankNameReg.test(this.bankName)) {
        //   this.$toast('账号名只能输入中文')
        //   return
        // }
        // if (!this.bankAccount) {
        //   this.$toast('请输入账号')
        //   return
        // }
        // const bankAccountReg = /[^\w\.\/]/
        // if (bankAccountReg.test(this.bankAccount)) {
        //   this.$toast('账号只能输数字及英文字母')
        //   return
        // }
        // if (!this.bankDeposit) {
        //   this.$toast('请输入开户行')
        //   return
        // }
        // const bankDepositReg = /[^\u4E00-\u9FA5]/
        // if (bankDepositReg.test(this.bankDeposit)) {
        //   this.$toast('开户行只能输入中文')
        //   return
        // }
        // if (!(this.reason1.length > 0 || this.reason2.length > 0 || this.reason3.length > 0 || this.otherReason)) {
        //   this.$toast('退房原因不能为空')
        //   return
        // }
        // if (!this.date2) {
        //   this.$toast('上门验房时间不能为空')
        //   return
        // }
        // if (new Date(this.date1).getTime() < new Date(this.date2).getTime()) {
        //   this.$toast('离店时间不能早于验房时间')
        //   return
        // }
        // this.$dialog.confirm({
        //   title: '提示',
        //   message: '是否确认？',
        //   confirmButtonColor: '#D8B084'
        // }).then(async () => {
        //   // on confirm
        //   await this.submitCheckOutApplyData()
        // }).catch(() => {
        //   // on cancel
        // })
        let params = { ...this.form }
        params.oldRoomId = this.searchedRoom.roomId
        params.tenantId = this.searchedRoom.tenantId
        params.oldRoomNo = this.searchedRoom.roomNo
        params.dcContractId = this.searchedRoom.dcContractId
        params.tenantMobile = this.searchedRoom.tenantMobile
        params.deposit = this.searchedRoom.deposit
        const newRoom = this.newRoomList.find(item => item.houseCode === this.newRoomCode)
        params.newRoomId = newRoom.houseCode
        params.newRoomNo = newRoom.roomNo
        params.oldRoomRentPrice = this.searchedRoom.rentPrice
        params.oldRoomStartDate = this.searchedRoom.startDate
        params.oldRoomEndDate = this.searchedRoom.endDate
        params.oldSignElectricQuantity = this.searchedRoom.electricQuantity
        params.oldSignWaterQuantity = this.searchedRoom.waterQuantity
        params.oldRoomElectricMeterImgUrl = this.oldRoomElectricMeterImg[0].fileUrl
        params.oldRoomWaterMeterImgUrl = this.oldRoomWaterMeterImg[0].fileUrl
        params.damagesFee = this.form.damagesFee || 0
        params.otherFee = this.form.otherFee || 0
        params.cleanFee = this.form.cleanFee || 0
        params.damagesFeeRemark = this.form.damagesFeeRemark || '无'
        params.otherFeeRemark = this.form.otherFeeRemark || '无'
        params.cleanFeeRemark = this.form.cleanFeeRemark || '无'
        params.newRoomServiceFeeMonth = 0

        // console.log(params)
        this.submitForm = { ...params }
        calculateFeeAPI(params).then(res => {
          const result = res.data
          if (result.code == '10000') {
            this.roomInfo = result.data
            this.activeStep = 2
          } else {
            this.handleCallback({
              isError: true,
              errorMessage: result.msg
            })
          }
        })
      }, 200),
      handleSubmit() {
        if (!this.form.signType) {
          this.$toast('请选择签署方式')
          return
        }

        this.$dialog.confirm({
          title: '提示',
          message: `您确定选择${this.form.signType == 1 ? '线上签署' : '线下签署'}`,
          confirmButtonColor: '#D8B084'
        }).then(async () => {
          // on confirm
          this.submitData()
        }).catch(() => {
          // on cancel
        })
      },
      async submitData() { // 完成接待
        try {
          this.isLoading = true
          await this.$store.dispatch('base/SetLoading', true)
          let { dcProjectId, projectName } = this.projectInfo
          const params = { ...this.submitForm, signType: this.form.signType }
          params.tenantMobile = this.roomInfo.tenantMobile
          params.projectId = dcProjectId
          params.projectName = projectName
          params.firstMonthPayAmount = this.roomInfo.firstMonthPayAmount
          params.balanceFee = this.roomInfo.balanceFee
          params.remainingFee = this.roomInfo.remainingFee
          params.exchangeCost = this.roomInfo.exchangeCost
          params.exchangeServiceFee = this.roomInfo.exchangeServiceFee || 0
          params.exchangeRuleFee = this.roomInfo.exchangeRuleFee || 0
          params.deposit = this.roomInfo.deposit
          params.isZheKou = this.roomInfo.isZheKou
          params.zheKouAmount = this.roomInfo.zheKouAmount
          params.appUserId = this.roomInfo.appUserId

          let response = await submitAPI(params)
          let result = response.data
          // console.log(result)
          if (`${result.code}` === '10000') { // 请求成功
            // 接口请求处理成功之后回跳
            this.$toast({
              message: '提交成功！',
              onClose: () => {
                this.$router.go(-1)
              }
            })
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '提交失败!'
            })
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '提交失败!'
          })
        } finally {
          this.isLoading = false
          await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async handleCallback({
                             isError,
                             errorMessage = undefined
                           }) { // 回调处理
        if (isError) {
          this.$toast(errorMessage || '获取失败!')
        }
      },
      afterFileRead(item, field) {
        let up = this.$toast.loading({ forbidClick: true, duration: 0 })
        console.log('item', item, field)
        imageCompression(item.file, {
          maxSizeMB: 0.2,
          initialQuality: 0.7
        }).then(file => {
          assetsApi.fileUpload(item).then(res => {
            this.$toast.clear()
            const cb = res.data
            if (cb.code === 200) {
              item.fileType = 1
              item.url = cb.data.url
              item.fileUrl = cb.data.url
              // this.form[field] = cb.data.url
              item.status = 'success'
              item.message = '上传成功'
            } else {
              item.status = 'failed'
              item.message = '上传失败'
            }
            this.$toast.clear()
          }).catch(e => {
            this.$toast.clear()
            item.status = 'failed'
            item.message = '上传失败'
          })
        }).catch((err) => {
          console.log(err)
          this.$toast.clear()
          item.status = 'failed'
          item.message = '上传失败'
        })
      }
    },
    filters: {},
    watch: {},
    created() {
      if (this.$route.query.id) {
        this.getDetail(this.$route.query.id)
      }
    },
    mounted() {
    },
    beforeRouteEnter(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    },
    beforeRouteUpdate(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    }
  }
</script>

<style lang='less' scoped>
  @import '../../assets/styles/themes/default/index';
  @import '../../assets/styles/mixins/index';

  @sectionPrefixCls: ~'@{css-prefix}section';

  .@{css-prefix}detail-header {
    width: 100%;
    height: 100px;
    background-color: #d8b084;

    &-content {
      padding-left: 18px;
      line-height: 24px;
      color: #ffffff;

      .@{css-prefix}detail-header-title {
        margin-bottom: 10px;
        font-size: 16px;
      }

      .@{css-prefix}detail-header-brief {
        font-size: 12px;

        > span {
          &:not(:last-of-type) {
            margin-right: 10px;
          }
        }
      }
    }

    &-extra {
      padding-right: 30px;
      line-height: 24px;
      font-size: 16px;
      color: #ffffff;

      .@{css-prefix}detail-header-status {
        position: relative;

        .svg-icon {
          display: block;
          width: 54px;
          height: 54px;
          vertical-align: middle;
        }
      }
    }
  }

  .@{css-prefix}section {
    &-item {
      overflow: hidden;

      .@{css-prefix}card-header {
        min-height: 50px;

        &-line {
          .hairline('bottom', 1px, #EEEEEE);

          .@{css-prefix}card-header-thumb {
            margin-right: 10px;

            .svg-icon {
              display: block;
              width: 18px;
              height: 18px;
              vertical-align: middle;
            }
          }

          .@{css-prefix}card-header-content {
            font-size: 15px;
            font-weight: bold;
            color: #333333;
          }
        }
      }

      .@{css-prefix}card-body {
        padding: 15px;
      }

      & + & {
        margin-top: 12px;
      }
    }
  }

  .@{sectionPrefixCls}-remark {
    padding: 0 5px;
    line-height: 24px;
    color: #333333;
    font-size: 12px;
    overflow: hidden;

    &-content {
      padding: 10px 0 20px;
    }

    &-pic {
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 -5px;
      padding: 10px 0;
      .hairline('top', 1px, #EEEEEE);

      &-item {
        display: block;
        width: 86px;
        height: 86px;
        padding: 10px 5px 0;
        overflow: hidden;

        > img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }
    }
  }

  .@{sectionPrefixCls}-client {
    padding: 0 10px;
    background-color: #ffffff;
  }

  .@{css-prefix}actionbar {
    position: relative;
    height: 65px;
    background-color: transparent;

    .@{css-prefix}actionbar-btns {
      padding: 10px 43px 15px;

      .@{css-prefix}btn {
        height: 40px;
        line-height: 40px;
        background-color: #ffffff;
        font-size: 15px;
        border: 1px solid #d1a36f;
        border-radius: 20px;
        color: #d1a36f;

        &.@{css-prefix}btn-primary {
          color: #ffffff;
          background-color: #d1a36f;
        }
      }

      .@{css-prefix}btn + .@{css-prefix}btn {
        margin-left: 30px;
      }
    }
  }

  .no-data {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
  }

  .color_gold {
    color: #D2A36D
  }

  .color_red {
    color: #FF3333
  }

  .room-select {
    max-height: 200px;
    overflow-y: auto;
  }

  .mt5 {
    margin-top: 5px;
  }

  .cell-group {
    margin-top: 15px;
  }

  .upload-container {
    margin-top: 12px;
    margin-bottom: 12px;

    .uploader-col {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // margin-top: 10px;
    }
  }

  .search-icon {
    font-size: 16px;
    line-height: inherit;
  }

  .field-box {
    padding: 24px 14px;
    font-size: 15px;

    .title {
      font-weight: bold;
    }

    .item-title {
      margin-top: 17px;
      color: #999999;
    }

    .fee-standard {
      display: flex;
      justify-content: space-between;
      color: #999999;
      font-size: 14px;
    }
  }

  .van-checkbox {
    padding: 5px 0;
  }

  .reason {
    margin: 10px;
    font-size: 16px;
    // margin-left: 10px;
    // margin-bottom: 10px;
  }

  & /deep/ .van-checkbox__icon--square .van-icon {
    border-color: #e4c477 !important;
    border-radius: 5px !important;
    background-color: #ffffff !important;
  }

  & /deep/ .van-checkbox__icon--checked .van-icon {
    color: #e4c477;
  }

  /deep/ .van-radio--disabled {
    .van-radio__label {
      color: #323233;
    }

    .van-radio__icon--checked {
      .van-icon {
        border-color: rgb(206, 163, 112);
        background-color: rgb(206, 163, 112);
      }
    }
  }

  .other {
    border: 1px solid #d3d3d3;
    border-radius: 6px;
  }
</style>
