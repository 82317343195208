<template>
  <cyui-field-record-info class="cyui-custom-item"
                          :data="detailInfo"
                          :header-extra-field="headerExtraField"
                          :footer-content-field="footerContentField"
                          :footer-extra-field="footerExtraField"
                          :body-fields="bodyFields"
                          @go-detail="goDetail"></cyui-field-record-info>
</template>

<script>
  import filters from '@filters/filters'
  import { dateFilter } from '@utils/utils'
  import { statusMap } from '@/dictionaries/custom'

  export default {
    name: 'CustomListItem',
    mixins: [filters],
    components: {},
    data() {
      return {
        headerExtraField: {
          isShow: true,
          title: '预约时间：',
          key: 'visitingTime',
          filterMethod: dateFilter
        },
        footerContentField: {
          isShow: true,
          title: '服务进度'
        },
        footerExtraField: {
          isShow: true,
          title: '',
          key: 'status',
          filterMethod: (data) => statusMap[data]['text'],
          getColorMethod: (data) => statusMap[data]['colorType']
        },
        bodyFields: [
          {
            title: '房号：',
            key: 'houseName'
          },
          {
            title: '姓名：',
            key: 'contacts'
          },
          {
            title: '联系方式：',
            key: 'phone'
          },
          {
            title: '服务类型：',
            key: 'typeName'
          },
          {
            title: '提交时间：',
            key: 'createTime',
            filterMethod: dateFilter
          }
        ]
      }
    },
    props: {
      detailInfo: {
        type: Object,
        require: true,
        default() {
          return {}
        }
      }
    },
    computed: {},
    methods: {
      goDetail() {
        this.$emit('go-detail', this.detailInfo)
      },
      async initData() {
      }
    },
    filters: {},
    watch: {},
    created() {
      this.initData()
    },
    mounted() {
    }
  }
</script>

<style lang="less" scoped>
  @import '../../../assets/styles/themes/default/index';
  @import '../../../assets/styles/mixins/index';

  .@{css-prefix}custom-item {
  }

</style>
