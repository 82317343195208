import Layout from '@views/layout/layout'

const billRouter = {
  path: '/bill',
  component: Layout,
  name: 'bill',
  redirect: '/bill/list',
  meta: {
    title: '账单纠正',
    noCache: true,
    requireAuth: true
  },
  children: [
    {
      path: 'list',
      name: 'billCorrection',
      components: {
        default: () => import('@views/bill/list')
      },
      props: {
        default: (route) => ({
          status: route.query.status
        })
      },
      meta: {
        title: '账单纠正',
        noCache: true,
        requireAuth: true
      }
    },
    {
      path: 'detail',
      name: 'billDetail',
      components: {
        default: () => import('@views/bill/detail')
      },
      props: {
        default: (route) => ({
          id: route.query.id
        })
      },
      meta: {
        title: '纠正详情',
        noCache: true,
        requireAuth: true
      }
    },
  ]
}

export default billRouter