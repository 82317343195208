<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="routeMetaTitle" @go-back="goBack" @on-close="handleClosePage">
        </cyui-header>
      </template>
      <template #default>
        <van-cell-group class='cell-group form'>
          <van-cell title="盘点区域" required class="picker" @click="onSpaceSelect">
            <template #default>
              <div class="pickerText" :class="{disabled:spaceFullCode===''}">{{spaceFullName || spaceFullCode || '请选择'}} <van-icon name="arrow" /></div>
            </template>
          </van-cell>
        </van-cell-group>
      </template>
      <template #footer>
        <div class="footer">
          <van-button block type="primary" size="large" @click="onNext">下一步</van-button>
        </div>
      </template>
    </cyui-layout>
    <van-popup v-model="spaceListShow" position="bottom">
      <van-picker
        show-toolbar
        value-key="spaceFullName"
        :columns="spaceList"
        @confirm="onSelectSpace"
        @cancel="spaceListShow = false"
      />
    </van-popup>
  </div>
</template>

<script>
  import common from '@mixins/common'
  import { mapGetters } from 'vuex'
  import * as assetsApi from '@api/assets'

  export default {
    name: 'AssetsCheckSelect',
    mixins: [common],
    computed: {
      ...mapGetters('user', [
        'projectInfo'
      ])
    },
    data() {
      return {
        spaceFullCode: '',
        spaceFullName: '',
        spaceType: '',
        spaceListShow: false,
        spaceList: [],
      }
    },

    methods: {
      onSpaceSelect(){
        let { dcProjectId } = this.projectInfo;
        assetsApi.getAllCangKuGongQuByProjectId(dcProjectId).then(res=>{
          let list = res.data.data || [];
          this.spaceList = list;
          if (!this.spaceList.length) {
            this.$toast('暂无数据');
          } else {
            this.spaceListShow = true;
          }
        })
      },
      onSelectSpace(item) {
        this.spaceFullCode = item.spaceFullCode;
        this.spaceFullName = item.spaceFullName;
        this.spaceType = item.spaceType;
        this.spaceListShow = false;
      },
      onNext() {
        if (!this.spaceFullCode) {
          this.$toast('请选择盘点区域')
          return
        }
        this.$router.push({
          name:'AssetsCheckIndex',
          query: {
            projectId: this.projectInfo.dcProjectId,
            projectName: this.projectInfo.projectName,
            spaceFullCode: this.spaceFullCode,
            spaceFullName:this.spaceFullName,
            spaceType:this.spaceType
          }
        });
      },
    }
  }
</script>

<style lang="less" scoped>
  @import "./common.less";
  .cyui-layout-wrapper {
    background: #fff;
  }

  .footer {
    width: 100%;
    padding: 10px;
  }
</style>
