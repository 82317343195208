<template>
  <div class="cyui-card cyui-sign-contract-item">
    <div class="cyui-card-header">
      <div class="cyui-card-header-line">
        <div class="cyui-card-header-content">
          <div class="cyui-sign-contract-box">
            <div class="cyui-sign-contract-box-header">
              <div style="display: flex; justify-content: space-between">
                <div v-if="`${status}` === '1'"
                     style="color: #D8B084;font-weight: bolder;">等待管家确认</div>
                <div v-else-if="`${status}` === '2'"
                     style="color: #D8B084;font-weight: bolder;">等待管家确认续签</div>
                <div v-else-if="`${status}` === '4'"
                     style="color: #D8B084;font-weight: bolder;">等待客户签署</div>
                <div v-else-if="`${status}` === '5'"
                     style="color: #D8B084;font-weight: bolder;">等待客户支付</div>
                <!-- <div v-else-if="`${status}` === '9'" style="color: #999999;">签约已完成</div> -->
                <div v-else-if="`${status}` === '9'" class="cyui-list-title">
                  <span> {{ detailInfo.projectName }} </span><br>
                  <span>
                  房号：{{ detailInfo.apiContractVO?detailInfo.apiContractVO.roomNo:'' }}
                </span>
                </div>
                <div
                  v-else-if="`${status}` === '3' || `${status}` === '6' || `${status}` === '7' || `${status}` === '8'"
                  style="color: #D40909;font-weight: bolder;">签约已取消
                </div>
                <div v-else-if="`${status}` === '10'"
                     style="color: #D40909;font-weight: bolder;">合同已到期
                </div>
                <div v-if="detailInfo.checkState === 1">审核中</div>
                <div v-else-if="detailInfo.checkState === 2">审核通过</div>
                <div v-else-if="detailInfo.checkState === 3">审核未通过</div>
              </div>
            </div>
            <div class="cyui-sign-contract-box-body">
              <div class="cyui-list">
                <div class="cyui-list-body">
                  <div class="cyui-list-item">
                    <div class="cyui-list-line">
                      <div class="cyui-list-label cyui-list-label-5">申请时间：</div>
                      <div class="cyui-list-content">
                        {{ detailInfo.createTime || '-' }}</div>
                    </div>
                  </div>
                  <div class="cyui-list-item"
                    v-if="`${status}` === '4' || `${status}` === '5' || `${status}` === '9'">
                    <div class="cyui-list-line">
                      <div class="cyui-list-label cyui-list-label-5">管家确认：</div>
                      <div class="cyui-list-content">
                        {{ detailInfo.passTime || '-' }}</div>
                    </div>
                  </div>
                  <!--已完成-->
                  <div class="cyui-list-item"
                    v-if="`${status}` === '5' || `${status}` === '9'">
                    <div class="cyui-list-line">
                      <div class="cyui-list-label cyui-list-label-5">签约完成：</div>
                      <div class="cyui-list-content">
                        {{ detailInfo.successTime || '-' }}</div>
                    </div>
                  </div>
                  <div class="cyui-list-item" v-if="`${status}` === '9'">
                    <div class="cyui-list-line">
                      <div class="cyui-list-label cyui-list-label-5">支付时间：</div>
                      <div class="cyui-list-content">
                        {{ detailInfo.payPaidTime || '-' }}</div>
                    </div>
                  </div>
                  <!--已失效-->
                  <template
                    v-if="`${status}` === '3' || `${status}` === '6' || `${status}` === '7' || `${status}` === '8' || `${status}` === '10'">
                    <div class="cyui-list-item">
                      <div class="cyui-list-line">
                        <div class="cyui-list-label cyui-list-label-5">
                          {{ `${status}` === '10' ? '失效时间：': '取消时间：' }}
                        </div>
                        <div class="cyui-list-content">
                          {{ detailInfo.failureTime || '-' }}</div>
                      </div>
                    </div>
                    <div class="cyui-list-item">
                      <div class="cyui-list-line">
                        <div class="cyui-list-label cyui-list-label-5">
                          {{ `${status}` === '10' ? '失效原因：': '取消原因：' }}
                        </div>
                        <div class="cyui-list-content">
                          {{ detailInfo.failureMsg || '-' }}</div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="`${status}` === '1' || `${status}` === '2' || `${status}` === '4' || `${status}` === '9'"
      class="cyui-card-body">
      <div class="cyui-list cyui-sign-contract-content">
        <div class="cyui-list-body">
          <div v-if="`${status}` === '1' || `${status}` === '2'"
            class="cyui-list-item cyui-list-multiple">
            <div v-if="`${status}` === '1'" class="cyui-list-line">
              <div class="cyui-list-content">
                <div>{{ roomInfo.projectName || '-' }}
                  {{ roomInfo.roomTypeName || '-' }}</div>
                <div>{{ roomInfo.area || '-' }}㎡ {{ roomInfo.houseType || '-' }}
                </div>
                <div>{{ roomInfo.roomArea || '-' }}·{{ roomInfo.sheet || '-' }}
                </div>
                <div>{{ roomInfo.monthRentPrice || '-' }}元/月</div>
              </div>
              <div class="cyui-list-thumb">
                <img :src="roomInfo.coverImg" alt="">
              </div>
            </div>

            <div v-if="`${status}` === '2' || `${status}` === '4'"
              class="cyui-list-title">
              <span class="cyui-icon">
                <svg-icon icon-class="house"></svg-icon>
              </span>
              <span> {{ roomInfo.projectName }}
                {{detailInfo.apiContractVO?detailInfo.apiContractVO.roomNo:''}}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="`${status}` === '9'"
        class="cyui-list cyui-sign-contract-content">
        <div class="cyui-list-body">
          <div class="cyui-list-item">
            <div class="cyui-list-line">
              <div class="cyui-list-content">
                <div>预计入住日期：{{ detailInfo.apiContractVO.expectedDate || '-' }}
                </div>
                <div>
                  租赁日期：{{ (detailInfo.apiContractVO.startDate +'~' + detailInfo.apiContractVO.endDate) || '-' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cyui-card-footer">
      <div class="cyui-card-footer-line cyui-hairline-top">
        <div class="cyui-card-footer-content">
          <div class="cyui-sign-contract-box">
            <div class="cyui-sign-contract-box-header">签约主体</div>
            <div class="cyui-sign-contract-box-body">
              <div class="cyui-list">
                <div class="cyui-list-body">
                  <div class="cyui-list-item">
                    <div class="cyui-list-line">
                      <div class="cyui-list-label cyui-list-label-5">姓名：</div>
                      <div class="cyui-list-content">
                        {{ detailInfo.realName || '-' }}</div>
                    </div>
                  </div>
                  <div class="cyui-list-item">
                    <div class="cyui-list-line">
                      <div class="cyui-list-label cyui-list-label-5">手机号：</div>
                      <div class="cyui-list-content">
                        {{ detailInfo.mobile || '-' }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cyui-sign-contract-btns">
            <button
              v-if="`${status}` === '1' || `${status}` === '2' || `${status}` === '4'"
              type="button" class="cyui-btn cyui-btn-primary cyui-btn-block"
              @click="handleCancelSignContract">
              取消申请
            </button>
            <button
              v-if="`${status}` === '4' || `${status}` === '5' || `${status}` === '9'"
              type="button" class="cyui-btn cyui-btn-primary cyui-btn-block"
              @click="goDetail">
              查看详情
            </button>

            <template v-if="`${status}` === '1' || `${status}` === '2'">
              <!-- <button
                type="button"
                class="cyui-btn cyui-btn-primary cyui-btn-block"
                @click="handleCancelSignContract"
              >
                {{`${status}` === '1'?'取消签约':'取消续签'}}
              </button> -->
<!--              this.detailInfo.isInBlacklist-->
              <button type="button"
                :class="['cyui-btn', 'cyui-btn-primary', 'cyui-btn-block', detailInfo.isInBlacklist === 1 ? 'disabled' : '']"
                @click="goDetail">
                确认信息
              </button>
            </template>
            <button type="button"
              class="cyui-btn cyui-btn-primary cyui-btn-block"
              v-if="detailInfo.contractId&&(`${status}` === '5' || `${status}` === '9')"
              @click="handleViewContract">
              查看合同
            </button>
            <button type="button"
              v-if="detailInfo.confirmCheckIn==0&&`${status}` === '9'"
              class="cyui-btn cyui-btn-primary cyui-btn-block"
              @click="handelClick_checkIn">
              确认办理入住
            </button>
            <a :href="`tel:${detailInfo.mobile}`"
              class="cyui-btn cyui-btn-primary cyui-btn-block"
              v-if="`${status}` === '3' || `${status}` === '6' || `${status}` === '10'">
              联系客户
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 *  status
 *  1等待审核
 *  2管家通过
 *  3管家取消签署
 *  4客户待签署
 *  5/9（客户签署完成）
 *  6客户取消签署
 */
import filters from '@filters/filters'
import { Dialog } from 'vant'

export default {
  name: 'SignContractListItem',
  mixins: [filters],
  components: {},
  data () {
    return {}
  },
  props: {
    detailInfo: {
      type: Object,
      require: true,
      default () {
        return {}
      }
    }
  },
  computed: {
    status () { // 当前状态
      let { detailInfo } = this
      return detailInfo.status
    },
    roomInfo () { // 户型信息
      let { detailInfo } = this
      return detailInfo.baseRoomTypeEnt || {}
    }
  },
  methods: {
    goDetail () {
      if (this.detailInfo.isInBlacklist === 1) {
        Dialog.alert({
          title: '请注意！',
          message: '当前客户为受限客户，无法进行签约'
        }).then(() => {
          // on close
        })
        return
      }
      this.$emit('go-detail', this.detailInfo)
    },
    // 查看合同
    handleViewContract () {
      this.$emit('on-view-contract', this.detailInfo)
    },
    // 确认办理入住
    handelClick_checkIn () {
      console.log(this.detailInfo)
      this.$emit('confirm-check-in', this.detailInfo)
    },

    // 取消签约
    handleCancelSignContract () {
      this.$emit('on-cancel-sign-contract', this.detailInfo)
    },
    async initData () {
    }
  },
  filters: {},
  watch: {},
  created () {
    this.initData()
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
  @import '../../../assets/styles/themes/default/index';
  @import '../../../assets/styles/mixins/index';

  .@{css-prefix}sign-contract-item {
    padding: 5px 0;
    border-radius: 5px;
    overflow: hidden;
  }

  .@{css-prefix}sign-contract-box {
    &-header {
      padding-top: 10px;
      line-height: 24px;
      font-size: 14px;
      color: #999999;
    }

    &-body {
      padding-top: 5px;
      padding-bottom: 10px;

      .@{css-prefix}list {
        &-body {
          .hairline-remove('top');
          .hairline-remove('bottom');

          .@{css-prefix}list-item {
            &:not(:first-child) {
              .hairline-remove('top');
            }
          }

          .@{css-prefix}list-item {
            min-height: 20px;
            padding-left: 0;

            .@{css-prefix}list-line {
              padding-right: 0;

              .@{css-prefix}list-content,
              .@{css-prefix}list-label {
                padding: 0;
                font-size: 12px;
                color: #666666;
              }

              .@{css-prefix}list-label {
                &.@{css-prefix}list-label-5 {
                  width: 60px;
                }
              }
            }
          }
        }
      }
    }
  }

  .@{css-prefix}sign-contract-content {
    padding: 0 15px;

    .@{css-prefix}list {
      &-body {
        .hairline-remove('top');
        .hairline-remove('bottom');

        .@{css-prefix}list-item {
          &:first-child {
            .hairline('top');
          }
        }

        .@{css-prefix}list-item {
          padding-left: 0;

          .@{css-prefix}list-line {
            padding-right: 0;

            .@{css-prefix}list-content {
              padding: 0;
              line-height: 18px;
              font-size: 12px;
              color: #666666;
            }

            .@{css-prefix}list-thumb {
              > img {
                display: block;
                width: 64px;
                height: 64px;
              }
            }
          }
        }
      }
    }
  }

  .@{css-prefix}sign-contract-btns {
    display: flex;
    width: 100%;
    padding-bottom: 10px;
    align-items: center;
    justify-content: center;

    > .@{css-prefix}btn {
      min-width: 80px;
      height: 28px;
      margin-top: 0;
      padding: 0 10px;
      line-height: 28px;
      font-size: 12px;
      background-color: #D8B084;
      border-color: #D1A36F;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);

      &:not(:last-of-type) {
        margin-right: 8px;
      }
    }
  }
.@{css-prefix}icon{
  position: relative;
  top:-1px;
  width: 21px;
  height: 21px;
}
.@{css-prefix}list-title{
  font-size: 15px;
  color: #D2A36D;
  font-weight:bolder;
}
.disabled{
  background: #ccc!important;
  border-color: #ccc!important;
}
</style>
