<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="routeMetaTitle" @go-back="goBack" @on-close="handleClosePage">
        </cyui-header>
      </template>
      <template #default>
        <van-cell-group class='cell-group form'>
          <van-cell title="选择入库类型" required class="picker" @click="sourceTypeShow=true">
            <template #default>
              <div class="pickerText" :class="{disabled:sourceTypeText===''}">{{sourceTypeText || '请选择'}} <van-icon name="arrow" /></div>
            </template>
          </van-cell>
          <van-cell title="选择入库单据" required class="picker" @click="onOrderSelect">
            <template #default>
              <div class="pickerText" :class="{disabled:sourceId===''}">{{sourceTitle || sourceId || '请选择'}} <van-icon name="arrow" /></div>
            </template>
          </van-cell>
        </van-cell-group>
      </template>
      <template #footer>
        <div class="footer">
          <van-button block type="primary" size="large" @click="onNext">下一步</van-button>
        </div>
      </template>
    </cyui-layout>
    <van-popup v-model="sourceTypeShow" position="bottom">
      <van-picker
        show-toolbar
        :columns="sourceTypeList"
        @confirm="onSelectSourceType"
        @cancel="sourceTypeShow = false"
      />
    </van-popup>
    <van-popup v-model="orderListShow" position="bottom">
      <van-picker
        show-toolbar
        value-key="sourceTitle2"
        :columns="orderList"
        @confirm="onSelectOrder"
        @cancel="orderListShow = false"
      />
    </van-popup>
  </div>
</template>

<script>
  import common from '@mixins/common'
  import { mapGetters } from 'vuex'
  import * as assetsApi from '@api/assets'

  export default {
    name: 'AssetsIn',
    mixins: [common],
    computed: {
      ...mapGetters('user', [
        'projectInfo'
      ])
    },
    data() {
      return {
        sourceType: '',
        sourceTypeText: '',
        sourceId: '',
        sourceTitle: '',
        sourceTypeShow: false,
        sourceTypeList: [
          {value:1,text:'采购入库'},
          {value:2,text:'调拨入库'},
        ],
        orderListShow: false,
        orderList: [],
      }
    },

    methods: {
      onSelectSourceType(item) {
        this.sourceType = item.value;
        this.sourceTypeText = item.text;
        this.sourceTypeShow = false;
      },
      onOrderSelect(){
        if (!this.sourceType) {
          this.$toast('请选择入库类型')
          return
        }
        let { dcProjectId } = this.projectInfo;
        assetsApi.getStockInOrderList({
          sourceType: this.sourceType,
          projectId: dcProjectId,
        }).then(res=>{
          let list = res.data.data || [];
          for (let item of list) {
            item.sourceTitle2 = item.sourceTitle || item.sourceId;
          }
          this.orderList = list;
          if (!this.orderList.length) {
            this.$toast('暂无数据');
          } else {
            this.orderListShow = true;
          }
        })
      },
      onSelectOrder(item) {
        this.sourceId = item.sourceId;
        this.sourceTitle = item.sourceTitle || '';
        this.orderListShow = false;
      },
      onNext() {
        if (!this.sourceId) {
          this.$toast('请选择入库单据')
          return
        }
        if(this.sourceType==1) {
          this.$router.push({
            name:'AssetsInDetail',
            query: {
              sourceId: this.sourceId,
              sourceTitle:this.sourceTitle,
              sourceType:this.sourceType
            }
          });
        } else {
          this.$router.push({
            name:'AssetsInDetail2',
            query: {
              sourceId: this.sourceId,
              sourceTitle:this.sourceTitle,
              sourceType:this.sourceType
            }
          });
        }


      },
    }
  }
</script>

<style lang="less" scoped>
  @import "./common.less";
  .cyui-layout-wrapper {
    background: #fff;
  }

  .footer {
    width: 100%;
    padding: 10px;
  }
</style>
