<script>
  export default {
    methods: {
      beforeRouterHook(to, from) {
        let { redirect } = to.query
        let nextInfo = {
          name: '404',
          query: {}
        }
        switch (redirect) {
          case 'HomeIndex': // 个人中心
            nextInfo.name = 'HomeIndex'
            nextInfo.query = {
              status: to.query.status
            }
            break
          default:
            nextInfo.name = '404'
        }
        return nextInfo
      }
    },
    created() {
    },
    render: function(h) {
      return h() // avoid warning message
    },
    beforeRouteEnter(to, from, next) {
      // console.log(to)
      // console.log(from)
      let { redirect } = to.query
      if (redirect) {
        next(vm => {
          let nextInfo = vm.beforeRouterHook(to, from)
          vm.$router.replace(nextInfo)
            .catch(() => {
            }) // 把error 抛出来
        })
      } else {
        next(vm => {
          vm.$nextTick(() => {
            vm.$router.replace({
              name: 'HomeIndex'
            }).catch((error) => {
              console.log(error)
            }) // 把error 抛出来
          })
        })
      }
    },
    beforeRouteUpdate(to, from, next) {
      // console.log(to)
      // console.log(from)
      let { redirect } = to.query
      if (redirect) {
        let nextInfo = this.beforeRouterHook(to, from)
        next({
          ...nextInfo,
          ...{
            replace: true
          }
        })
      } else {
        next({
          name: 'HomeIndex'
        })
      }
    }
  }
</script>
