<template>
  <div class="cyui-card cyui-todo-item" @click="goDetail">
    <div class="cyui-card-header">
      <div class="cyui-card-header-line cyui-hairline-bottom">
        <div class="cyui-card-header-content">
          <div class="cyui-flexbox">
            <div class="cyui-todo-header-icon">
              <svg-icon :icon-class="`home_todo_${type}`"></svg-icon>
            </div>
            <div class="cyui-flexbox-item cyui-ellipsis" v-if="type === '1'">预约记录</div>
            <div class="cyui-flexbox-item cyui-ellipsis" v-else-if="type === '2'">保洁记录</div>
            <div class="cyui-flexbox-item cyui-ellipsis" v-else-if="type === '3'">维修记录</div>
            <div class="cyui-flexbox-item cyui-ellipsis" v-else-if="type === '4'">定制记录</div>
            <div class="cyui-flexbox-item cyui-ellipsis" v-else-if="type === '5'">退房记录</div>
            <div class="cyui-flexbox-item cyui-ellipsis" v-else-if="type === '6'">报修记录</div>
          </div>
        </div>
        <div class="cyui-card-header-extra" v-if="type === '1'">预约时间：{{ detailInfo.bookVisitTime }}</div>
        <div class="cyui-card-header-extra" v-if=" type === '3' || type === '4'">预约时间：{{ dateFilter(detailInfo.visitingTime) }}</div>
        <div class="cyui-card-header-extra" v-if="type === '6'||type==='2'">创建时间：{{ dateFilter(detailInfo.createTime) }}</div>
      </div>
    </div>
    <div class="cyui-card-body">
      <div class="cyui-todo-info" v-if="type === '1'">
        <div>姓名：{{ detailInfo.bookerName || '-' }}</div>
        <div>性别：{{ detailInfo.bookerSex || '未知'  }}</div>
        <div>联系方式：{{ detailInfo.bookerPhone || '-' }}</div>
        <div>意向户型：{{ detailInfo.expectLayouts || '-' }}</div>
      </div>
      <div class="cyui-todo-info" v-if="type === '2'">
        <div>房号：{{ detailInfo.spaceName || '-' }}</div>
        <div>姓名：{{ detailInfo.contactsName || '-' }}</div>
        <div>联系方式：{{ detailInfo.contactsMobile || '-' }}</div>
        <div>保洁类型：{{ detailInfo.questionCategoryName || '-' }}</div>
        <div>期望上门时间：{{ detailInfo.expectRepairDate|| '-' }}</div>
      </div>
      <div class="cyui-todo-info" v-if=" type === '3' || type === '4'">
        <div>房号：{{ detailInfo.houseName || '-' }}</div>
        <div>姓名：{{ detailInfo.contacts || '-' }}</div>
        <div>联系方式：{{ detailInfo.phone || '-' }}</div>
        <div v-if="type === '2'">保洁类型：{{ detailInfo.typeName || '-' }}</div>
        <div v-else-if="type === '3'">维修类型：{{ detailInfo.typeName || '-' }}</div>
        <div v-else-if="type === '4'">服务类型：{{ detailInfo.typeName || '-' }}</div>
        <div>提交时间：{{ dateFilter(detailInfo.createTime) }}</div>
      </div>
      <div class="cyui-todo-info" v-if="type === '5'">
        <div>房号：{{ detailInfo.roomNo || '-' }}</div>
        <div>姓名：{{ detailInfo.customerName || '-' }}</div>
        <div>联系方式：{{ detailInfo.customerPhone || '-' }}</div>
        <div>上门验房时间：{{ detailInfo.checkTime || '-' }}</div>
        <div>创建时间：{{ detailInfo.userCreateTime|| '-' }}</div>
      </div>
      <div class="cyui-todo-info" v-if="type === '6'">
        <div>房号：{{ detailInfo.spaceName || '-' }}</div>
        <div>姓名：{{ detailInfo.contactsName || '-' }}</div>
        <div>联系方式：{{ detailInfo.contactsMobile || '-' }}</div>
        <div>问题类型：{{ detailInfo.questionCategoryName || '-' }}</div>
        <div>期望上门时间：{{ detailInfo.expectRepairDate|| '-' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import filters from '@filters/filters'
  import { isEmpty, dateFilter } from '@utils/utils'
  import { sexMap } from '@/dictionaries/appointment'

  export default {
    name: 'TodoListItem',
    mixins: [filters],
    components: {},
    data() {
      return {
        sexMap
      }
    },
    props: {
      detailInfo: {
        type: Object,
        require: true,
        default() {
          return {}
        }
      },
      type: {
        type: [String, Number],
        default: '1'
      }
    },
    computed: {},
    methods: {
      // 日期格式化
      dateFilter,
      // 空字段过滤
      fieldEmptyFilter(data, returnEmpty = '无') {
        return !isEmpty(data) ? data : returnEmpty
      },
      goDetail() {
        this.$emit('go-detail', this.detailInfo)
      },
      async initData() {
      }
    },
    filters: {},
    watch: {},
    created() {
      this.initData()
    },
    mounted() {
    }
  }
</script>

<style lang="less" scoped>
  @import '../../../assets/styles/themes/default/index';
  @import '../../../assets/styles/mixins/index';

  .@{css-prefix}todo-item {
    border: 1px solid #EFEFEF;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    &:not(:first-child) {
      margin-top: 10px;
    }

    .@{css-prefix}card-header {
      min-height: 34px;
      font-size: 12px;
      color: #333333;

      .@{css-prefix}card-header-content {
        .@{css-prefix}todo-header-icon {
          .svg-icon {
            display: block;
            width: 14px;
            height: 14px;
            margin-right: 8px;
            vertical-align: middle;
          }
        }
      }

      .@{css-prefix}card-header-extra {
        font-size: 9px;
        color: #666666;
      }

      .@{css-prefix}card-header-arrow {
        position: relative;
        display: block;
        width: 15 * @rpx;
        height: 15 * @rpx;
        margin-left: 5px;
        visibility: hidden;

        &:after {
          content: " ";
          .setArrow(right, 10 * @rpx, #cccccc, 2 * @rpx);
          position: absolute;
          top: 50%;
          left: 50%;
        }

        &-right {
          visibility: visible;

          &:after {
            transform: translate(-75%, -50%) rotate(45deg);
          }
        }

      }
    }

    .@{css-prefix}todo-info {
      padding: 5px 15px;
      line-height: 24px;
      font-size: 9px;
      color: #666666;
    }
  }

</style>
