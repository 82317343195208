import Layout from '@views/layout/layout'

const priceBreak = {
  path: '/priceBreak',
  component: Layout,
  name: 'PriceBreak',
  redirect: '/priceBreak/apply',
  meta: {
    title: '审批',
    noCache: true,
    requireAuth: true
  },
  children: [
    {
      path: 'apply',
      name: 'PriceBreakApply',
      components: {
        default: () => import('@views/priceBreak/apply')
      },
      meta: {
        title: '破底价申请',
        noCache: true,
        requireAuth: true
      }
    }
  ]
}

export default priceBreak
