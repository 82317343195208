<template>
  <div class='container'>
    <div class='page'>
      <span v-if='show' class='page-button' style='color:#FFFFFF;font-size: 16px' @click='clean'>退出</span>
      <div class='page-img'>
        <img class='back-img' src='../../assets/images/payExpence/home.png' alt=''>
      </div>
      <img class='page-logo' src='../../assets/images/payExpence/logo.png' alt=''>
      <div class='content'>
        <div class='card mt-8 space-between' v-for='item in payTypeList' :key='item.itemValue'
             @click='goVerification(item)'>
          <div class='card-content'>
            <van-image v-if="item.itemValue=== 'WARTER_FEE' "
                       width='44'
                       height='44'
                       :src="require('../../assets/images/payExpence/water.png')" />
            <van-image v-if="item.itemValue=== 'PREPOWER_FEE' "
                       width='44'
                       height='44'
                       :src="require('../../assets/images/payExpence/dian.png')" />
            <van-image v-if="item.itemValue=== 'PARK_FEE' "
                       width='44'
                       height='44'
                       :src="require('../../assets/images/payExpence/car.png')" />
            <span class='card-text ml-12'>{{ item.itemText }}</span>
          </div>
          <van-icon name='arrow'
                    size='18'
                    class='card-icon' />
        </div>
      </div>
      <img class='page-title' src='../../assets/images/payExpence/title.png' alt=''>
    </div>
  </div>
</template>

<script>
  import { getDictItems } from '@/api/expense'
  import { getStorage, removeStorage } from '@utils/storage'

  export default {
    name: '',
    components: {},
    props: {},
    data() {
      return {
        payTypeList: []
      }
    },
    computed: {
      show() {
        if (getStorage('tenantId', 'localStorage') !== null) {
          return true
        } else {
          return false
        }
      }
    },
    watch: {},
    created() {
    },
    mounted() {
      this.getPayType()
    },
    methods: {
      // 获取支付类型
      getPayType() {
        getDictItems({
          dictCode: 'tenant_qrcodr_type'
        }).then(res => {
          let result = res.data
          if (result.code === '10000') {
            this.payTypeList = result.data || []
          }
        })
      },
      // 跳转身份验证
      goVerification({ itemValue }) {
        if (getStorage('tenantId', 'localStorage') !== null) {
          this.$router.push({
            name: 'expenseInfo',
            query: {
              type: itemValue,
              tenantId: getStorage('tenantId', 'localStorage')
            }
          })
        } else {
          this.$router.push({
            name: 'expenseVerification',
            query: {
              type: itemValue
            }
          })
        }
      },
      clean() {
        this.$dialog.confirm({
          title: '提示',
          message: '请确认是否退出'
        })
          .then(() => {
            // on confirm
            removeStorage('tenantId', 'localStorage')
            this.show = false
            this.$router.replace({
              name: 'expenseType'
            })
          })
          .catch(() => {
            // on cancel
          })
      }
    }
  }
</script>

<style scoped lang='less'>
  .container {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;

    .page {
      position: relative;

      &-button {
        position: absolute;
        right: 10px;
        top: 10px;
      }

      &-img {
        .back-img {
          width: 100%;
          height: auto;
        }

      }

      &-logo {
        position: absolute;
        width: 250px;
        height: 50px;
        margin: 0 auto;
        top: 30px;
        left: 60px;
      }

      &-title {
        position: relative;
        top: 250px;
        left: 80px;
        width: 220px;
        height: 50px;
        margin: 0 auto;

      }

      .content {
        width: 100%;
        position: absolute;
        top: 140px;

        .card {
          background-color: #fff;
          border-radius: 8px;
          margin-left: 12px;
          margin-right: 12px;
          padding: 24px 12px;
          border: 1px solid #EFEFEF;
          box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);

          .card-content {
            display: flex;
            align-items: center;
            width: 100%;
          }

          .card-text {
            color: #333333;
            font-size: 18px;
          }
        }

      }
    }
  }

  .mt-8 {
    margin-top: 15px;
  }

  .ml-12 {
    margin-left: 12px;
  }

  .space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
