import * as types from './mutations_types'
import { setStorage } from '@/utils/storage.js'

export default {
  [types.SET_TOKEN](state, token) {
    state.token = token
    setStorage('token', state.token, 'localStorage')
  },
  [types.SET_USER_INFO](state, userInfo) {
    state.userInfo = userInfo
    setStorage('user_info', state.userInfo, 'localStorage')
  },
  [types.SET_PROJECT_LIST](state, projectList) {
    state.projectList = projectList
    setStorage('project_list', state.projectList, 'localStorage')
  },
  [types.SET_PROJECT_INFO](state, data) {
    state.projectInfo = data
    setStorage('project_info', state.projectInfo, 'localStorage')
  }
}
