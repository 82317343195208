<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader"
                     :title="headerTitle"
                     @go-back="goBack"
                     @on-close="handleClosePage">
          <template #headerRight>
            <div></div>
          </template>
        </cyui-header>
      </template>
      <template #default>
        <div class="cyui-flexbox cyui-detail-header">
          <div class="cyui-flexbox-item cyui-detail-header-content">
            <div class="cyui-detail-header-title">
              <span v-if="detailInfo.status===0">接待未完成</span>
              <span v-else-if="detailInfo.status===1">接待已完成</span>
            </div>
            <div class="cyui-detail-header-brief" v-if="detailInfo.status===0">
              <span>预计到访时间：</span>
              <span>{{ detailInfo.bookVisitTime }}</span>
            </div>
            <div class="cyui-detail-header-brief" v-else-if="detailInfo.status===1">
              <span>到访时间：</span>
              <span>{{ detailInfo.factVisitTime }}</span>
            </div>
          </div>
          <div class="cyui-detail-header-extra">
            <div class="cyui-detail-header-status">
              <svg-icon icon-class="status_4" v-if="detailInfo.status === 0"></svg-icon>
              <svg-icon icon-class="status_3" v-else-if="detailInfo.status === 1"></svg-icon>
            </div>
          </div>
        </div>
        <div class="cyui-section">
          <div class="cyui-card cyui-section-item">
            <div class="cyui-card-body" style="padding: 0;">
              <div class="cyui-section-client">
                <cyui-field-list-info :fields="clientInfo.baseInfoFields"
                                      :data="clientInfo.baseInfo"
                                      :title-width="`${formatPx2Rem(220)}rem`"></cyui-field-list-info>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="cyui-actionbar" v-if="detailInfo.status === 0">
          <div class="cyui-actionbar-btn">
            <van-button block type="primary" size="large" @click="goApply">补充调整信息</van-button>
          </div>
          <div class="cyui-actionbar-btn">
            <van-button block type="primary" plain size="large" @click="handleSubmit">确认到访并完成接待</van-button>
          </div>
        </div>
      </template>
    </cyui-layout>
  </div>
</template>

<script>
  import { debounce } from 'lodash'
  import common from '@mixins/common'
  import filters from '@filters/filters'
  import { formatPx2Rem } from '@utils/index'
  import { isEmpty, dateFilter } from '@utils/utils'
  import { admitBookAPI } from '@api/appointment'

  export default {
    name: 'AppointmentDetail',
    mixins: [common, filters],
    components: {},
    data() {
      return {
        headerTitle: '预约详情',
        clientInfo: { // 客户信息
          baseInfoFields: [ // 基本信息
            {
              title: '姓名',
              key: 'bookerName',
              type: 'value',
            },
            {
              title: '性别',
              key: 'bookerSex',
              type: 'value',
            },
            {
              title: '手机号码',
              key: 'bookerPhone',
              type: 'value',
            },
            {
              title: '意向户型/面积/朝向',
              key: 'expectLayouts',
              type: 'value',
            },
            {
              title: '职业',
              key: 'bookerProfession',
              type: 'value',
            },
            {
              title: '来源渠道',
              key: 'source',
              type: 'value',
            },
            {
              title: '备注',
              key: 'remark',
              type: 'value',
            },
          ],
          baseInfo: {}
        },
        isLoading: false, // 是否在加载数据
        isRefreshPage: false, // 是否需要刷新页面
        noDataMessage: '', // 列表为空时的提示文案
        detailInfo: {} // 详情信息
      }
    },
    props: {
      id: {
        type: [Number, String],
        default: ''
      }
    },
    computed: {},
    methods: {
      formatPx2Rem,
      // 日期格式化
      dateFilter,
      // 空字段过滤
      fieldEmptyFilter(data, returnEmpty = '无') {
        return !isEmpty(data) ? data : returnEmpty
      },
      // 格式化基本信息
      formatBaseInfo() {
        let { detailInfo } = this
        this.clientInfo.baseInfo = {
          bookerName: this.fieldEmptyFilter(detailInfo.bookerName),
          bookerSex: this.fieldEmptyFilter(detailInfo.bookerSex),
          bookerPhone: this.fieldEmptyFilter(detailInfo.bookerPhone),
          expectLayouts: this.fieldEmptyFilter(detailInfo.expectLayouts),
          bookerProfession: this.fieldEmptyFilter(detailInfo.bookerProfession),
          source: this.fieldEmptyFilter(detailInfo.source),
          remark: this.fieldEmptyFilter(detailInfo.remark),
        }
      },
      handleSubmit: debounce(function() {
        this.$dialog.confirm({
          title: '完成接待',
          message: '是否确认完成接待？',
          confirmButtonColor: '#D8B084'
        }).then(async () => {
          // on confirm
          await this.submitAppointmentData()
        }).catch(() => {
          // on cancel
        })
      }, 200),
      async handleCallback({ isError, errorMessage = undefined }) { // 回调处理
        if (isError) {
          this.$toast(errorMessage || '获取失败!')
          /* if (errorMessage && errorMessage.includes('timeout')) {
            // 判断是否是网络请求超时
            this.isRefreshPage = true
            this.noDataMessage = '网络请求超时'
          } else if (errorMessage && errorMessage.includes('502')) {
            // 判断是否是服务器错误
            this.isRefreshPage = true
            this.noDataMessage = '服务器错误，502错误'
          } */
        }
      },
      async submitAppointmentData({ isShowLoading } = { isShowLoading: true }) { // 完成接待
        try {
          this.isLoading = true
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let { id } = this
          let response = await admitBookAPI({ id })
          let result = response.data
          // console.log(result)
          if (`${result.code}` === '10000') { // 请求成功
            // 接口请求处理成功之后回跳
            this.$toast({
              message: '提交成功！',
              onClose: () => {
                this.$router.go(-1)
              }
            })
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '完成接待失败!'
            })
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '完成接待失败!'
          })
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async getDetailData({ isShowLoading } = { isShowLoading: true }) { // 获取详情数据
        try {
          this.isLoading = true
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let { id } = this
          let response = await this.$store.dispatch('appointment/GetAppointmentDetail', {
            id
          })
          // let result = response.data
          let result = response
          // console.log(result)
          if (`${result.code}` === '10000') { // 请求成功
            let resultData = result.data || {}
            this.detailInfo = resultData
            this.formatBaseInfo()
            await this.handleCallback({
              isError: false
            })
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '获取列表失败!'
            })
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '获取列表失败!'
          })
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async initData() {
        await this.getDetailData()
      },
      goApply() {
        let { id } = this;
        this.$router.push({
          name: 'AppointmentApply',
          query: {
            id
          }
        })
      }
    },
    filters: {},
    watch: {},
    created() {
    },
    mounted() {
      this.initData()
    },
    activated() {
      this.initData()
    },
    beforeRouteEnter(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    },
    beforeRouteUpdate(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    }
  }
</script>

<style lang="less" scoped>
  @import '../../assets/styles/themes/default/index';
  @import '../../assets/styles/mixins/index';

  @sectionPrefixCls: ~'@{css-prefix}section';

  .@{css-prefix}detail-header {
    width: 100%;
    height: 110px;
    padding-bottom: 10px;
    background-color: #D8B084;

    &-content {
      padding-left: 18px;
      line-height: 24px;
      color: #ffffff;

      .@{css-prefix}detail-header-title {
        margin-bottom: 10px;
        font-size: 16px;
      }

      .@{css-prefix}detail-header-brief {
        font-size: 12px;

        > span {
          &:not(:last-of-type) {
            margin-right: 10px;
          }
        }
      }
    }

    &-extra {
      padding-right: 30px;
      line-height: 24px;
      font-size: 16px;
      color: #ffffff;

      .@{css-prefix}detail-header-status {
        position: relative;

        .svg-icon {
          display: block;
          width: 54px;
          height: 54px;
          vertical-align: middle;
        }
      }
    }
  }

  .@{css-prefix}section {
    margin: -20px 10px 0 10px;
    border-radius: 10px;
    overflow: hidden;
    background: #fff;
    &-item {
      overflow: hidden;

      .@{css-prefix}card-header {
        min-height: 50px;

        &-line {
          .hairline('bottom', 1px, #EEEEEE);

          .@{css-prefix}card-header-thumb {
            margin-right: 10px;

            .svg-icon {
              display: block;
              width: 18px;
              height: 18px;
              vertical-align: middle;
            }
          }

          .@{css-prefix}card-header-content {
            font-size: 15px;
            font-weight: bold;
            color: #333333;
          }
        }
      }

      .@{css-prefix}card-body {
        padding: 15px;
      }

      & + & {
        margin-top: 12px;
      }
    }
  }

  .@{sectionPrefixCls}-remark {
    padding: 0 5px;
    line-height: 24px;
    color: #333333;
    font-size: 12px;
    overflow: hidden;

    &-content {
      padding: 10px 0 20px;
    }

    &-pic {
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 -5px;
      padding: 10px 0;
      .hairline('top', 1PX, #EEEEEE);

      &-item {
        display: block;
        width: 86px;
        height: 86px;
        padding: 10px 5px 0;
        overflow: hidden;

        > img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }
    }
  }

  .@{sectionPrefixCls}-client {
    padding: 0 10px;
    background-color: #FFFFFF;
  }

  .@{css-prefix}actionbar {
    position: relative;
    height: auto;
    background-color: transparent;
    flex-direction: column;
    padding-bottom: 15px;

    .@{css-prefix}actionbar-btn {
      width: 100%;
      padding: 0 15px;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .no-data {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
  }

</style>
