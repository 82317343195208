import Layout from '@views/layout/layout'

const customRouter = {
  path: '/custom',
  component: Layout,
  name: 'Custom',
  redirect: '/custom/list',
  meta: {
    title: '定制记录',
    noCache: true,
    requireAuth: true
  },
  children: [
    {
      path: 'list',
      name: 'CustomList',
      components: {
        default: () => import('@views/custom/list')
      },
      props: {
        default: (route) => ({
          status: route.query.status
        })
      },
      meta: {
        title: '定制记录',
        noCache: true,
        requireAuth: true
      }
    },
    {
      path: 'detail',
      name: 'CustomDetail',
      components: {
        default: () => import('@views/custom/detail')
      },
      props: {
        default: (route) => ({
          id: route.query.id
        })
      },
      meta: {
        title: '定制详情',
        noCache: true,
        requireAuth: true
      }
    }
  ]
}

export default customRouter
