import Layout from '@views/layout/layout'

const roomChangeRouter = {
  path: '/roomChange',
  component: Layout,
  name: 'roomChange',
  redirect: '/roomChange/list',
  meta: {
    title: '换房列表',
    noCache: true,
    requireAuth: true
  },
  children: [
    {
      path: 'list',
      name: 'roomChangeList',
      components: {
        default: () => import('@views/roomChange/list')
      },
      props: {
        default: (route) => ({
          status: route.query.status
        })
      },
      meta: {
        title: '换房列表',
        noCache: true,
        requireAuth: true
      }
    },{
      path: 'create',
      name: 'roomChangeCreate',
      components: {
        default: () => import('@views/roomChange/create')
      },
      // props: {
      //   default: (route) => ({
      //     status: route.query.status
      //   })
      // },
      meta: {
        title: '提交换房申请',
        noCache: true,
        requireAuth: true
      }
    }
  ]
}

export default roomChangeRouter
