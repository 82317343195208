<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="routeMetaTitle" @go-back="goBack" @on-close="handleClosePage">
        </cyui-header>
      </template>
      <template #default>
        <div class="details">
          <div class="title">资产明细</div>
          <table>
            <tr>
              <td>#</td>
              <td>资产名称</td>
              <td>主规格</td>
              <td>数量</td>
              <td>单位</td>
              <td>此次入库数量</td>
            </tr>
            <tr v-for="(item,index) in detailList" :key="index">
              <td>{{ index+1 }}</td>
              <td>{{ item.productName }}</td>
              <td>{{ item.standard }}</td>
              <td>{{ item.inNumTotal }}</td>
              <td>{{ item.unit }}</td>
              <td>{{ item.inNum }}</td>
            </tr>
          </table>
        </div>
        <van-cell-group inset class='cell-group form'>
          <van-cell title="选择该批资产的进入仓库" class="picker">
            <template #default>
              <div class="pickerText" :class="{disabled:spaceName ===''}">{{spaceName  || '-'}}</div>
            </template>
          </van-cell>
          <van-cell title="此次入库方式" class="picker" v-if="sourceType==1">
            <template #default>
              <div class="pickerText" :class="{disabled:stockMethodName===''}">{{stockMethodName || '-'}}</div>
            </template>
          </van-cell>
          <van-field v-model="remark" label="备注" readonly placeholder="-"  type="textarea" rows="2" autosize show-word-limit maxlength="200"/>
          <van-cell title="入库人签字">
            <template #default>
              <div class="signPanel">
                <img :src="stockUserSignUrl">
              </div>
            </template>
          </van-cell>
          <van-cell title="入库照片">
            <template #default>
              <div class="uploadPanel">
                <van-uploader v-model="stockInImgList" :show-upload="false" :deletable="false" />
              </div>
            </template>
          </van-cell>
        </van-cell-group>
        <div class="summaryProducts" v-if="productList2.length">
          <div class="title">此次入库的固定资产编号</div>
          <div class="list2">
            <table>
              <tr class="head">
                <td>资产名称</td>
                <td>主规格</td>
                <td>资产编号</td>
              </tr>
              <tr v-for="(item,index) in productList2" :key="index">
                <td>{{ item.productName || '-' }}</td>
                <td>{{ item.standard || '-' }}</td>
                <td>{{ item.productNo || '-' }}</td>
              </tr>
            </table>
          </div>
        </div>
      </template>
    </cyui-layout>
  </div>
</template>

<script>
  import common from '@mixins/common'
  import vueEsign from 'vue-esign'
  import * as assetsApi from '@api/assets'
  import { mapGetters } from 'vuex'

  export default {
    name: 'AssetsInLogDetail',
    mixins: [common],
    components: {
      vueEsign
    },
    computed: {
      ...mapGetters('user', [
        'userInfo',
        'projectInfo',
      ])
    },
    data() {
      return {
        sourceId: '',
        sourceTitle: '',
        sourceType: '',
        detailList: [],
        spaceFullCode: '',
        spaceName: '',
        stockMethod: '',
        projectId: '',
        stockUser: '',
        remark: '',
        stockUserSignUrl: '',
        stockInImgList: [],

        productList2: [],

        stockMethodName: '',
        stockMethodList: [
          {value:1,text:'部分入库'},
          {value:2,text:'全部入库'},
        ],
      }
    },
    created() {
      this.id = this.$route.query.id;
      this.getData();
    },
    methods: {
      getData() {
        assetsApi.getStockInDetail(this.id).then(res=>{
          let item = res.data.data;
          this.sourceId = item.sourceId;
          this.sourceTitle = item.sourceTitle;
          this.sourceType = item.sourceType;
          this.detailList = item.detailList || [];
          let productList2 = [];
          for (let product of this.detailList) {
            if (product.productNo) {
              productList2.push(product);
            }
          }
          this.spaceFullCode = item.spaceFullCode;
          this.spaceName = item.spaceName;
          this.stockMethod = item.stockMethod;
          let stock = this.stockMethodList.find(i=>i.value==item.stockMethod);
          this.stockMethodName = stock ? stock.text : '';
          this.projectId = item.projectId;
          this.stockUser = item.stockUser;
          this.stockUserSignUrl = item.stockUserSignUrl;
          this.stockInImgList = item.stockInImgList ? item.stockInImgList.map(i=>{return {url:i.fileUrl}}) : [];
          this.remark = item.remark;
        })
      },
    }
  }
</script>

<style lang="less" scoped>
  @import "./common.less";
  .details {
    padding: 0 15px;
    margin-bottom: 10px;
    .title {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: #6D6868;
      padding: 20px 0 5px 0;
    }
    table {
      width: 100%;
      border-spacing: 0 7px;
      font-size: 10px;
      tr {
        background: #fff;
        box-shadow: 0 1px 1px 0 #ccc;
        td {
          padding: 10px 5px;
          text-align: center;
          &:first-child {
            padding-left: 8px;
          }
          &:last-child {
            padding-right: 8px;
          }
          &.required {
            padding-left: 0;
            > i {
              color: red;
            }
          }
          &.input {
            padding: 5px;
            input {
              border: none;
              width: 50px;
              height: 100%;
              display: inline-block;
              text-align: center;
              &::-webkit-input-placeholder {
                color: #ccc;
              }
            }
          }

        }
      }
    }
  }
  .form {
    /deep/ .van-cell {
      flex-direction: column;
      &__title {
        margin-bottom: 10px;
        width: auto;
      }
      &.picker {
        .van-cell__value {
          border: 1px solid #CCCCCC;
          border-radius: 6px;
          padding: 10px;
          &:active {
            background-color: #f2f3f5;
          }
        }
      }
    }

    /deep/ .van-field--disabled .van-field__label {
      color: #323233;
    }
    .pickerText {
      width: 100%;
      text-align: left;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.disabled {
        color: #c8c9cc;
      }
    }
    /deep/ .van-field {
      &__value {
        border: 1px solid #CCCCCC;
        border-radius: 6px;
        padding: 10px;
      }
      &__word-limit {
        font-size: 15px;
        color: #ccc;
      }
    }
    .signPanel {
      background: #F5F5F5;
      border-radius: 6px;
      overflow: hidden;
      height: 120px;
      position: relative;
      img {
        height: 100%;
        width: auto;
      }
    }
    .uploadPanel {
      text-align: left;
    }
  }
  .summaryProducts {
    .title {
      font-size: 14px;
      font-weight: 400;
      color: #6D6868;
      margin-bottom: 15px;
    }
    .list2  {
      background: #fff;
      border-radius: 6px;
      table {
        tr {
          td {
            font-size: 14px;
            color: #333333;
            padding: 10px 5px;
            &:first-child {
              padding-left: 10px;
            }
            &:last-child {
              padding-right: 10px;
            }
          }
          &.head td{
            white-space: nowrap;
          }
        }
      }
    }
  }
</style>
