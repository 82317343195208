<template>
  <div class='cyui-layout-wrapper'>
    <cyui-layout>
      <template #header>
        <cyui-header v-if='isCustomHeader' :title='headerTitle'
                     @go-back='goBack' @on-close='handleClosePage'>
          <template #headerRight>
            <div></div>
          </template>
        </cyui-header>
      </template>
      <template #default>
        <div class='cyui-driections-navbar'>
          <van-form @submit='onSubmit'>
            <van-field readonly
                       clickable
                       v-model='form.spaceId'
                       label='工单编号'
                       input-align='right'
            />
            <van-field readonly
                       clickable
                       v-model='form.spaceName'
                       label='空间'
                       input-align='right'
            />
            <van-field readonly
                       clickable
                       v-model='form.questionCategoryName'
                       label='问题类型'
                       input-align='right'
            />
            <van-field v-if='form.questionDes'
                       readonly
                       clickable
                       rows='3'
                       autosize
                       maxlength='100'
                       type='textarea'
                       v-model='form.questionDes'
                       label='问题描述'
                       input-align='left'
            />
            <van-field readonly
                       clickable
                       label='图片'
                       input-align='left'>
              <template #input>
                <img class='image'
                     v-for='(item,index) in form.questionDesImgList'
                     @click='reviewImg(form.questionDesImgList)'
                     :key='index'
                     :src='item.fileUrl'
                     alt=''>
              </template>
            </van-field>
            <van-field readonly
                       clickable
                       v-model='form.expectRepairDate'
                       label='期望上门日期'
                       input-align='right'
            />
            <!--            <van-field readonly-->
            <!--                       label-width='200px'-->
            <!--                       clickable-->
            <!--                       v-model='form.expectRepairTimeSpan'-->
            <!--                       label='期望上门时间段'-->
            <!--                       input-align='right'-->
            <!--            />-->
            <van-field readonly
                       clickable
                       v-model='form.contactsName'
                       label='联系人'
                       input-align='right'
            />
            <van-field readonly
                       clickable
                       v-model='form.contactsMobile'
                       label='联系方式'
                       input-align='right'
            />
            <van-field v-if='form.contactsRemark'
                       readonly
                       clickable
                       rows='3'
                       autosize
                       maxlength='100'
                       type='textarea'
                       v-model='form.contactsRemark'
                       label='备注'
                       input-align='right'
            />
          </van-form>
          <div class='steps'>
            <div class='steps_header'>
              <div>工单处理明细</div>
              <div></div>
            </div>
            <div class='steps_content'>
              <van-steps direction='vertical' :active='0' active-color='#333333'>
                <van-step v-for='item in form.orderNodeList' :key='item.id'>
                  <div class='steps-item' style='display: flex;justify-content: space-between'>
                    <span>{{ item.des }}</span>
                    <span>{{ item.operUserName }}</span>
                  </div>
                  <p style='color:#999999'>{{ item.createTime }}</p>
                </van-step>
              </van-steps>
            </div>
          </div>
          <div class='responsibility card house' v-if='form.status==4'>
            <!--              <div class='headerTitle'>收费明细</div>-->
            <div class='card-header'>
              <div class='card-header-title'>收费明细</div>
              <div class='card-header-data'>
                <div v-if='form.payStatus==1' class='total' style='color:limegreen'>
                  已支付
                </div>
                <div v-else class='total' style='color:red'>
                  未支付
                </div>
              </div>
            </div>
            <div class='card-content'>
              <div class='scroll'>
                <table class='spec_table table'>
                  <thead>
                  <th>序号</th>
                  <th>物资明细</th>
                  <th>物资数目</th>
                  <th>物资单价</th>
                  </thead>
                  <tbody>
                  <tr v-for='(item,index) in shouFeiAllList' :key='index'>
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.total }}</td>
                    <td>{{ item.price }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class='table-total'>合计：<span class='total'>{{ disposeTotalShouFei() }}</span></div>
            </div>
          </div>
          <van-button block style='margin: 20px 0;' @click.stop='handleShowReaseOrder' v-if='form.status === 1'>
            取消工单
          </van-button>
        </div>
      </template>
    </cyui-layout>
    <!-- 取消工单弹框 -->
    <van-dialog v-model='showReasonOrder' title='取消工单' show-cancel-button :before-close='reasonBeforeClose'>
      <van-field label='取消原因' type='textarea' v-model='orderReasonValue' rows='3' autosize maxlength='200'
                 placeholder='请输入' input-align='left' show-word-limit
      ></van-field>
    </van-dialog>
  </div>
</template>

<script>
  import common from '@mixins/common'
  import {
    getDetailById,
    getSpaceList,
    fileUpload,
    getQuestionCategoryList,
    createOrder,
    cancelOrder, getShouFeiList
  } from '@api/maintenance'
  import { getStorage } from '@utils/storage.js'
  import { mapGetters } from 'vuex'
  import { ImagePreview } from 'vant'

  export default {
    name: 'DirectionsList',
    mixins: [common],
    data() {
      return {
        headerTitle: '发起工单',
        userInfo: JSON.parse(getStorage('user_info', 'localStorage')),
        // 空间一级分类
        fieldNames: {
          text: 'spaceName',
          value: 'spaceId',
          children: 'children'
        },
        questionfieldNames: {
          text: 'name',
          value: 'id',
          children: 'children'
        },
        // 层级
        showSpace: false,
        showQuestion: false,
        showDatePicker: false,
        spaceList: [],
        questionList: [],
        expectRepairDate: new Date(),
        shouFeiList: [],
        shouFeiAllList: [],
        form: {
          spaceName: null,
          spaceId: null,
          questionDes: null,
          questionCategoryId: null,
          questionCategoryName: null,
          expectRepairDate: null,
          expectRepairTimeSpan: null,
          contactsName: null,
          contactsMobile: null,
          contactsRemark: null,
          questionDesImgList: []
        },
        // 取消工单
        showReasonOrder: false,
        orderReasonValue: ''
      }
    },
    props: {},
    computed: {
      ...mapGetters('user', [
        'projectInfo'
      ])
    },
    created() {
      const { token, projectId, userId, userName } = this.$route.query
      if (token && projectId) {
        this.$store.commit('user/SET_TOKEN', token)
        this.$store.commit('user/SET_USER_INFO', { dcProjectId: projectId, userId, userName })
        this.$store.commit('user/SET_PROJECT_INFO', { dcProjectId: projectId })
      }
      console.log(this.projectInfo, this.$route.query.id)
      this.getDetail()
      this.getSpaceList()
      this.getQusitonList()
      this.getShouFeiList()
    },
    mounted() {
    },
    methods: {
      getDetail() {
        this.$store.dispatch('base/SetLoading', true)
        getDetailById({
          id: this.$route.query.id
        }).then(res => {
          const cb = res.data
          if (cb.code === 200) {
            console.log(cb.data, '详情数据')
            this.form = cb.data
          }
        }).finally(() => {
          this.$store.dispatch('base/SetLoading', false)
        })
      },
      // 空间层级
      getSpaceList() {
        getSpaceList({
          projectId: this.projectInfo.dcProjectId
        }).then(res => {
          let data = res.data.data
          this.spaceList = this.listToTree(data)
          console.log(this.spaceList)
        })
      },
      listToTree(oldArr) {
        oldArr.forEach((element) => {
          const parentId = element.parentId
          if (parentId !== 0) {
            oldArr.forEach((ele) => {
              if (ele.spaceId === parentId) {
                if (!ele.children) {
                  ele.children = []
                }
                ele.children.push(element)
              }
            })
          }
        })
        // console.log('1711', oldArr);
        // 清除重复的
        oldArr = oldArr.filter((ele) => ele.parentId === '0')
        oldArr = this.arrSort(oldArr)
        return oldArr
      },
      getShouFeiList() {
        let {
          id
        } = this.$route.query
        getShouFeiList({
          orderId: id
        }).then(res => {
          let result = res.data
          if (result.code == 200) {
            this.shouFeiList = result.result || []
            let shouFeiAllList = []
            this.shouFeiList.forEach(i => {
              shouFeiAllList.push(...i.shouFeiList)
            })
            this.shouFeiAllList = shouFeiAllList.filter(i => i.total != 0)
          }
        })
      },
      disposeTotalShouFei() {
        let sum = 0
        this.shouFeiAllList.forEach(i => {
          sum += Number(i.price) * Number(i.total)
        })
        return sum
      },
      arrSort(arr) {
        const newArr = arr.sort((a, b) => {
          return a.orderNum - b.orderNum
        })
        newArr.map(item => {
          if (item.children && item.children.length > 0) {
            item.children = this.arrSort(item.children)
          }
        })
        return newArr
      },
      spaceChange(e) {
        this.form.spaceName = e.selectedOptions[e.selectedOptions.length - 1].spaceFullName
      },
      questionChange(e) {
        this.form.questionCategoryName = e.selectedOptions[e.selectedOptions.length - 1].name
      },
      // 问题类型
      getQusitonList() {
        getQuestionCategoryList().then(res => {
          const data = res.data.data
          if (data.code == 200) {
          }
          this.questionList = data
        })
      },
      changeDate(e) {
        this.form.expectRepairDate = this.timeFormat(this.expectRepairDate)
        this.showDatePicker = false
      },
      timeFormat(time) {
        let year = time.getFullYear()
        let month = time.getMonth() + 1
        let date = time.getDate()
        if (month < 10) month = '0' + month
        if (date < 10) date = '0' + date
        return year + '-' + month + '-' + date
      },
      onSubmit() {
        let data = {
          ...this.form,
          projectId: this.projectInfo.dcProjectId
        }
        createOrder(data).then(res => {
          const cb = res.data
          if (cb.code === 200) {
            this.$toast('创建成功')
          }
        })
      },
      // 图片预览
      reviewImg(imgList) {
        const result = imgList.map(item => {
          return item.url
        })
        ImagePreview(result)
        // ImagePreview([img[0].fileUrl, img[1].fileUrl])
      },
      afterIDRead(file) {
        console.log(file)
        file.status = 'uploading'
        file.message = '上传中...'
        fileUpload(file).then(res => {
          const cb = res.data
          if (cb.code === 200) {
            this.form.questionDesImgList = [{
              fileType: 1,
              url: cb.data.url,
              fileUrl: cb.data.url
            }]
          }
        })
      },
      onOversize() {
        this.$toast('文件大小不能超过5M')
      },
      // 取消工单
      handleShowReaseOrder() {
        this.orderReasonValue = ''
        this.showReasonOrder = true
      },
      reasonBeforeClose(action, done) {
        if (action === 'confirm') {
          // if (this.orderReasonValue === '') {
          //   this.$toast('请填写原因')
          //   done(false)
          //   return
          // }

          cancelOrder({
            id: this.$route.query.id,
            closeDes: this.orderReasonValue
          }).then((res) => {
            if (res.data.code === 200) {
              done()
              this.$toast('保存成功')
              this.getDetail()
            }
          }).catch(e => {
            done(false)
          })
        } else {
          done()
        }
      }
    },
    filters: {}
    //

  }
</script>

<style lang='less' scoped>
  .cyui-layout-wrapper {
    height: 100%;
    overflow: auto;

    .cyui-driections-navbar {
      .image {
        width: 100px;
        height: auto;
        margin-right: 10px;
      }

      .steps {
        margin-top: 10px;
        height: auto;
        background-color: #FFFFFF;
        padding-top: 20px;

        .steps_header {
          width: 95%;
          margin: 0 auto;
          display: flex;
          font-size: 15px;
          font-weight: 550;
          justify-content: space-between;
          align-items: center;

          .steps_header_btn {
            color: #5296F6;
            width: 60px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            font-weight: 400;
            border: 1px solid #5296F6;
            border-radius: 15px;
          }
        }

        .steps_content {
          margin-top: 20px;

          .steps-item {
            display: flex;
            justify-content: space-between;
          }
        }
      }

      .responsibility {
        margin-top: 10px;

        .headerTitle {
          font-size: 16px;
          background-color: #FFFFFF;
          text-indent: 15px;
          padding-top: 10px;
          font-weight: 550;

          .steps-item {
            display: flex;
            justify-content: space-between;
          }
        }
      }

      .card {
        padding: 16px;
        background: #ffffff;
        border: 1px solid #EFEFEF;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        margin: 10px 0;
        font-size: 12px;

        &-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &-title:before {
            display: inline-block;
            content: '';
            border-radius: 20px;
            margin-right: 10px;
            width: 4px;
            height: 16px;
            background: #669ff4;
          }

          &-title {
            font-size: 16px;
            font-family: Alibaba PuHuiTi;
            font-weight: 600;
            color: #414762;
            line-height: 12px;
            display: flex;
            align-items: center;
          }

          &-data {
            display: flex;

            .total {
              margin-left: 10px;
              font-size: 12px;
              color: #999999;

              span {
                color: #F67D2D;
              }
            }
          }
        }

        &-content {
          margin-top: 15px;

          .table-total {
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;

            .total {
              font-size: 14px;
              color: red;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  /deep/ .van-field__label {
    color: #282828;
  }

  /deep/ .van-field__control {
    color: #666666;
  }

  /deep/ .van-step--vertical .van-step__line {
    background-color: #D8AD83;
  }

  /deep/ .van-icon-checked:before {
    color: #D8AD83;
  }

  .house {
    .scroll {
      max-height: 240px;
      overflow-y: scroll;

      .spec_table {
        width: 100%;
        //border: solid #cccccc;
        border-spacing: 0;
        //border-width: 1px 0 0 0;

        th {
          padding: 8px 5px;
        }

        td {
          border: solid #E5E5E5;
          border-width: 0 0 1px 0;
          padding: 8px 5px;
          text-align: center;
        }

        thead {
          position: sticky;
          top: 0;
          background: #f5f5f5;
        }
      }
    }
  }
</style>
