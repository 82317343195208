<template>
  <div class='cyui-layout-wrapper' style='background-color: #FFFFFF;'>
    <cyui-layout>
      <template #header>
        <!--<cyui-header v-if="isCustomHeader"
                     :title="headerTitle"
                     @go-back="goBack"
                     @on-close="handleClosePage">
          <template #headerRight>
            <div></div>
          </template>
        </cyui-header>-->
      </template>
      <template #default>
        <!--<div class="cyui-scroller-panel">
          <cyui-scroller
            class="wrapper"
            ref="scroller"
            :scrollbar="scrollbarConfig"
            :pullDownRefresh="pullDownRefreshConfig"
            :pullUpLoad="pullUpLoadConfig"
            :startY="parseInt(startY)"
            @pullingDown="handlePullingDown"
            @pullingUp="handlePullingUp"
          >
            <template #default>
            </template>
          </cyui-scroller>
        </div>-->
        <div class='cyui-banner'>
          <div class='cyui-banner-img' style='position:relative;'>
            <img src='../../assets/images/home/banner.png' alt=''>
            <span @click='exit' class='cyui-exit'>
              退出
              <svg-icon icon-class='exit'></svg-icon>
            </span>
          </div>
          <div class='cyui-banner-title' @click='projectPopupInfo.showPopup = true'>
            <div class='cyui-flexbox cyui-banner-title-text'>
              <div>{{ projectInfo.cityName }}</div>
              <div class='cyui-banner-title-arrow'><img src='../../assets/images/home/img_003.png' alt=''></div>
            </div>
            <div class='cyui-banner-title-describe'>{{ projectInfo.projectName }}</div>
          </div>
          <div class='cyui-menu-box'>
            <div class='cyui-flexbox cyui-flexbox-wrap cyui-menu'>
              <template  v-for='(item, index) in menuInfo.listData'>
                <div class='cyui-menu-item'
                     :key='`menuInfo${index}`'
                     @click='goModule(item)' v-if='item.isShow'>
                    <div class='cyui-menu-icon' :style='{backgroundColor: item.backgroundColor,width: "34px",height: "34px"}'>
                      <svg-icon :icon-class='item.svgName' v-if="item.size" :style="{width: item.size,height: item.size}"></svg-icon>
                      <svg-icon :icon-class='item.svgName' v-else :style='{width: "20px",height: "20px"}'></svg-icon>
                    </div>
                    <div class='cyui-menu-label'>{{ item.name }}</div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <van-sticky>
          <div class='cyui-titlebar cyui-titlebar-default cyui-todo-title'>
            <div class='cyui-titlebar-title'>
              待办事项
            </div>
            <div class='cyui-titlebar-nav'>
            </div>
          </div>
          <div class='cyui-todo-navbar'>
            <cyui-navbar
              v-model='categoryInfo.value'
              :swipe-threshold='2'
              show-title-number
              :list-data='categoryInfo.listData'
              @change='handleTabChange'
            >
            </cyui-navbar>
          </div>
        </van-sticky>
        <div class='cyui-todo-body'>
          <template v-if='listData.length'>
            <div class='cyui-todo-list'>
              <todo-list-item v-for='(item, index) in listData'
                              :key='index'
                              :detail-info='item'
                              :type='status'
                              @go-detail='goModuleDetail(item)'></todo-list-item>
            </div>
            <div class='cyui-todo-more'
                 v-if='listTotalCount > 3'
                 @click='goModuleList'>
              查看更多
            </div>
          </template>
          <no-data
            :msg='noDataMessage'
            v-if='listData.length === 0 && isLoading === false'
          >
            <template #btn>
              <div
                class='cyui-btn cyui-btn-default cyui-btn-inline cyui-btn-radius'
                @click='handleRefreshPage'
                v-if='isRefreshPage'
              >
                刷新重试
              </div>
            </template>
          </no-data>
        </div>
      </template>
    </cyui-layout>
    <project-select
      :show.sync='projectPopupInfo.showPopup'
      :title='projectPopupInfo.title'
      type='1'
      from='home'
      :cache-data='projectPopupInfo.cacheData'
      @on-confirm-select='handleConformSelectProject'></project-select>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import common from '@mixins/common'
  import scrollList from '@mixins/scrollList'
  import getPageList from '@mixins/getPageList'
  import filters from '@filters/filters'
  import { TodoListItem, ProjectSelect } from './components'
  import { menuListData, categoryListData } from '@dictionaries/home'
  import { getBookListAPI as getAppointmentListAPI } from '@api/appointment'
  import { getListAPI as getCleanListAPI } from '@api/clean'
  import { getListAPI as getMaintainListAPI } from '@api/maintain'
  import { getListAPI as getCustomListAPI } from '@api/custom'
  import { getListAPI as getMaintenanceListAPI } from '@api/maintenance'
  import { returnLogin } from '@utils/common'
  import { getCheckOutListAPI } from '@api/checkOut'

  export default {
    name: 'HomeIndex',
    mixins: [common, filters, scrollList, getPageList],
    components: {
      TodoListItem,
      ProjectSelect
    },
    data() {
      return {
        headerTitle: '首页',
        menuInfo: { // 菜单数据
          value: '1', //
          listData: menuListData
        },
        categoryInfo: { // 分类数据
          value: '1', //
          listData: categoryListData
        },
        initParams: { // 初始化参数
          page: 1,
          size: 3
        },
        searchKey: {}, // 搜索参数
        projectPopupInfo: { // 项目选择
          title: '项目',
          showPopup: false, // 是否显示项目弹出层
          value: [],
          label: [],
          cacheData: [], // 选中的缓存数据
          selectedListData: [] // 选中的列表数据
        },
        listTotalCount: 0
      }
    },
    props: {
      status: {
        type: [Number, String],
        default: '1'
      }
    },
    computed: {
      ...mapGetters('user', [
        'projectInfo', 'userInfo'
      ])
    },
    methods: {
      exit() {
        returnLogin({})
      },
      // 进入模块
      goModule(data = {}) {
        let { routeName } = data
        console.log(routeName, 'abc')
        this.$router.push({
          name: routeName
        })
      },
      // 进入详情
      goModuleDetail(data) {
        let { id } = data
        let { status } = this
        let routeName = ''
        switch (status) {
          case '1':
            routeName = 'AppointmentDetail'
            break
          case '2':
            routeName = 'CleanDetail'
            break
          case '3':
            routeName = 'MaintainDetail'
            break
          case '4':
            routeName = 'CustomDetail'
            break
          case '5':
            routeName = 'CheckOutDetail'
            break
          case '6':
            routeName = 'maintenanceDetail'
            break
        }
        this.$router.push({
          name: routeName,
          query: {
            id
          }
        })
      },
      // 进入待办列表
      goModuleList() {
        let { status } = this
        let routeName = ''
        let moduleStatus = ''
        switch (status) {
          case '1':
            routeName = 'AppointmentList'
            moduleStatus = '0'
            break
          case '2':
            routeName = 'CleanList'
            moduleStatus = '2'
            break
          case '3':
            routeName = 'MaintainList'
            moduleStatus = ''
            break
          case '4':
            routeName = 'CustomList'
            moduleStatus = '2'
            break
          case '5':
            routeName = 'CheckOutList'
            moduleStatus = ''
            break
        }
        this.$router.push({
          name: routeName,
          query: {
            status: moduleStatus
          }
        })
      },
      handleTabChange({ name }) {
        if (name !== this.status) {
          this.$router.replace({
            name: 'HomeIndex',
            query: {
              status: name
            }
          })
        }
      },
      handleConformSelectProject(data = {}) { // 选择项目操作
        let {
          cacheData,
          listData
        } = data
        this.projectPopupInfo.cacheData = cacheData
        this.projectPopupInfo.selectedListData = listData
        let projectInfo = listData[0]
        this.$store.dispatch('user/SetProjectInfo', {
          projectId: projectInfo.id,
          cityName: projectInfo.label,
          projectName: projectInfo.projectName,
          dcProjectId: projectInfo.dcProjectId,
          isHospital: projectInfo.isHospital
        })
        this.setIconVisibility(projectInfo);
        this.initData()
      },
      async getListData({
        isPullDown,
        isShowLoading
      } = {
        isPullDown: false,
        isShowLoading: true
      }) { // 获取列表数据
        try {
          this.isLoading = true
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let {
            status,
            listData
          } = this
          let {
            projectId,
            dcProjectId
          } = this.projectInfo
          let {
            page,
            size
          } = this.searchKey
          let response
          switch (status) {
            case '1':
              response = await getAppointmentListAPI({
                pageNum: page,
                pageSize: size,
                projectId,
                state: '0' // 待办
              })
              break
            case '2':
              response = await getMaintenanceListAPI({
                pageNum: page,
                pageSize: size,
                projectId: dcProjectId,
                type: 2,
                status: -1
              })
              break
            case '3':
              response = await getMaintainListAPI({
                pageNum: page,
                pageSize: size,
                projectId,
                status: '0,1,2' // 待办
              })
              break
            case '4':
              response = await getCustomListAPI({
                pageNum: page,
                pageSize: size,
                projectId,
                status: '2' // 待办
              })
              break
            case '5':
              response = await getCheckOutListAPI({
                pageNum: page,
                pageSize: size,
                projectId,
                status: '' // 全部
              })
              break
            case '6':
              response = await getMaintenanceListAPI({
                pageNum: page,
                pageSize: size,
                projectId: dcProjectId,
                type: 1,
                status: -1 // 全部
              })
              break
          }
          let result = response.data
          // console.log(result)
          if (`${result.code}` === '10000' || `${result.code}` == 200) { // 请求成功
            let resultData = result.data || []
            // let hasMore = resultData.length > 0
            // let hasMore = page < result.pages
            let hasMore = false
            this.listData = !isPullDown
              ? [...listData, ...resultData]
              : [...resultData]
            this.listTotalCount = result.count
            if (!hasMore) {
              this.isLastPage = true
              // this.$toast('没有更多数据了！')
            }
            if (status === '1') {
              await this.$store.dispatch('appointment/SetAppointmentList', this.listData)
            }
            if (status === '5') {
              await this.$store.dispatch('checkOut/SetCheckOutList', this.listData)
            }
            await this.handleCallback({
              isError: false,
              hasMore
            })
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '获取列表失败!'
            })
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '获取列表失败!'
          })
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async initProjectPopupInfo() {
        let { projectInfo } = this
        let listData = [{
          id: projectInfo.projectId,
          parentId: '',
          label: projectInfo.cityName,
          projectName: projectInfo.projectName,
          hasChildren: false,
          children: [],
          isSelected: true,
          dcProjectId: projectInfo.dcProjectId,
          isHospital: projectInfo.isHospital
        }]
        this.projectPopupInfo.cacheData = [{
          from: 'home',
          listData
        }]
        this.projectPopupInfo.selectedListData = listData
        this.setIconVisibility(projectInfo)
      },
      setIconVisibility(projectInfo) {
        let hosIcon = this.menuInfo.listData.find(i=>i.id=='13');
        if (hosIcon) {
          hosIcon.isShow = projectInfo.isHospital ? true : false;
        }
      },
      async initData() {
        await this.resetParams()
        await this.getListData()
      }
    },
    filters: {},
    watch: {
      initParams: {
        handler: function(newVal, oldVal) {
          if (JSON.stringify(newVal) !== JSON.stringify(this.searchKey)) {
            this.searchKey = JSON.parse(JSON.stringify(newVal))
          }
        },
        deep: true,
        immediate: true
      },
      status: {
        handler: function(newVal, oldVal) {
          this.searchKey = JSON.parse(JSON.stringify(this.initParams))
          this.categoryInfo.value = newVal
          this.initProjectPopupInfo()
          this.initData()
        },
        immediate: true
      }
    },
    created() {
    },
    mounted() {
    },
    beforeRouteEnter(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    },
    beforeRouteUpdate(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    }
  }
</script>

<style lang='less' scoped>
  @import '../../assets/styles/themes/default/index';
  @import '../../assets/styles/mixins/index';

  @bannerPrefixCls: ~'@{css-prefix}banner';
  @menuPrefixCls: ~'@{css-prefix}menu';
  @todoPrefixCls: ~'@{css-prefix}todo';

  .@{css-prefix}scroller-panel {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    & /deep/ .pulldown-wrapper {
      top: 5px !important;
    }
  }

  .@{bannerPrefixCls} {
    position: relative;
    height: 450px;

    &-img {
      > img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    &-title {
      position: absolute;
      left: 0;
      top: 55px;
      width: 100%;
      padding-left: 26px;
      color: #FFFFFF;

      &-text {
        align-items: center;
        justify-content: flex-start;
        line-height: 50px;
        font-size: 27px;

        .@{bannerPrefixCls}-title-arrow {
          margin-left: 8px;

          > img {
            display: block;
            width: 20px;
            height: 20px;
          }
        }
      }

      &-describe {
        display: block;
        line-height: 24px;
        font-size: 15px;
      }
    }
  }

  .@{menuPrefixCls}-box {
    position: absolute;
    left: 0;
    top: 158px;
    width: 100%;
    padding: 0 10px;
  }

  .@{menuPrefixCls} {
    padding: 5px 15px 15px;
    background-color: #FFFFFF;
    border: 1px solid #EFEFEF;
    border-radius: 10px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);

    &-item {
      width: 25%;
      padding-top: 10px;
      text-align: center;

      .@{css-prefix}menu-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        line-height: 34px;
        margin: 0 auto;
        border-radius: 100%;
        overflow: hidden;

        .svg-icon {
          display: block;
          width: 20px;
          height: 20px;
          fill: #FFFFFF;
          // vertical-align: middle;
        }
      }

      .@{css-prefix}menu-label {
        line-height: 24px;
        font-size: 12px;
        color: #333333;
      }
    }
  }

  .@{css-prefix}titlebar {
    min-height: 30px;
    background-color: #FFFFFF;

    &-title {
      padding-left: 13px;
      font-size: 15px;
      color: #333333;

      &::before {
        border-width: 6px;
        border-color: #D19C70;
      }
    }

    &::after {
      display: none;
    }
  }

  .@{todoPrefixCls} {
    &-navbar {
      padding: 0 10px;
      background-color: #FFFFFF;

      & /deep/ .cyui-navbar {
        background-color: transparent;

        &-body {
          .van-tabs {
            &.van-tabs--line {
              .van-tabs__wrap {
                .van-tab {
                  padding: 0 5px;

                  &.van-tab--active {
                    .@{css-prefix}navbar-title {
                      border-color: #D19C70;
                      font-weight: normal;
                      color: #D19C70;
                      background-color: #FFFFFF;

                      &-number {
                        background: #e5563f;
                      }
                    }

                    &::after {
                      position: absolute;
                      left: 50%;
                      bottom: -4px;
                      transform: translate3d(-50%, 0, 0);
                      content: ' ';
                      width: 0;
                      height: 0;
                      border-width: 4px;
                      border-style: solid;
                      border-color: #D1A36F transparent transparent transparent;
                    }
                  }
                }
              }
            }

            .van-tabs__nav--line {
              .van-tabs__line {
                display: none;
              }
            }
          }
        }

        &-title {
          position: relative;
          display: block;
          min-width: 74px;
          height: 28px;
          padding: 0 12px;
          line-height: 28px;
          font-size: 12px;
          color: #b9b9b9;
          text-align: center;
          border-radius: 15px;
          border: 1PX solid #e3e3e3;
          background-color: #ffffff;

          &-number {
            position: absolute;
            top: 2px;
            right: 0;
            transform: translate3d(50%, -50%, 0);
            min-width: 18px;
            height: 18px;
            padding: 0 5px;
            line-height: 18px;
            border-radius: 18px;
            background: #e5563f;
          }
        }
      }
    }

    &-body {
      position: relative;
      min-height: 300px;
    }

    &-list {
      padding: 10px 10px 0;
    }

    &-more {
      height: 40px;
      margin-top: 5px;
      line-height: 40px;
      text-align: center;
      font-size: 12px;
      color: #999999;
    }
  }

  .@{css-prefix}exit {
    position: absolute;
    right: 15px;
    top: 15px;
    color: #FFFFFF;
  }

</style>
