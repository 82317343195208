<template>
  <cyui-field-record-info class='cyui-room-change-item'
                          :data='detailInfo'
                          :header-extra-field='headerExtraField'
                          :header-content-field='headerContentField'
                          :footer-content-field='footerContentField'
                          :footer-extra-field='footerExtraField'
                          :body-fields='bodyFields'
                          @go-detail='goDetail'></cyui-field-record-info>
</template>

<script>
  import filters from '@filters/filters'
  // import { dateFilter } from '@utils/utils'
  import dayjs from 'dayjs'
  import { statusMap } from '@/dictionaries/roomChange'

  export default {
    name: 'ListItem',
    mixins: [filters],
    components: {},
    data() {
      return {
        headerContentField: {
          isShow: false
        },
        headerExtraField: {
          isShow: false
        },
        footerContentField: {
          isShow: true,          
          title: '当前状态'
        },
        footerExtraField: {
          isShow: true,
          // title: '123',
          key: 'exchangeStatus',
          filterMethod: (data) => statusMap[data]['text'],
          getColorMethod: (data) => statusMap[data]['colorType']
        },
        bodyFields: [
          {
            title: '换房日期：',
            key: 'newRoomStayInTime',
            filterMethod: (data) => dayjs(data).format('YYYY-MM-DD'),
          },
          {
            title: '房号：',
            key: 'newRoomNo'
          },
          {
            title: '租客：',
            key: 'tenantName'
          },
          {
            title: '联系方式：',
            key: 'tenantMobile'
          },
          {
            title: '创建时间：',
            key: 'createTime'
          }
        ]
      }
    },
    props: {
      detailInfo: {
        type: Object,
        require: true,
        default() {
          return {}
        }
      }
    },
    computed: {},
    methods: {
      goDetail() {
        this.$emit('go-detail', this.detailInfo)
      },
      async initData() {
      }
    },
    filters: {},
    watch: {},
    created() {
      this.initData()
    },
    mounted() {
    }
  }
</script>

<style lang='less' scoped>
  @import '../../../assets/styles/themes/default/index';
  @import '../../../assets/styles/mixins/index';

  .@{css-prefix}room-change-item {    
    /deep/.cyui-card-header {
      display: none !important;
    }
  }

</style>