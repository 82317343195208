<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="headerTitle"
          @go-back="goBack" @on-close="handleClosePage">
          <template #headerRight>
            <div></div>
          </template>
        </cyui-header>
        <div class="cyui-driections-navbar">
          <div class="drictions-list" v-for="item in dataList"
            @click="goDetail(item)">
            <div class="drictions-list-row">
              <span>{{item.name}}</span>
              <van-icon name="arrow" />
            </div>
            <div class="van-hairline--bottom"></div>
          </div>
        </div>
      </template>
    </cyui-layout>
  </div>
</template>

<script>
import common from '@mixins/common'

export default {
  name: 'DirectionsList',
  mixins: [common],
  data () {
    return {
      headerTitle: '使用说明',
      dataList: [
        {
          name: '系统操作手册',
          id: 1
        },
        {
          name: '相关业务使用手册',
          id: 2
        },
        {
          name: 'Q&A手册',
          id: 3
        },
      ]
    }
  },
  props: {},

  methods: {
    // 进入详情
    goDetail (data) {
      let { id } = data
      if (id == 1 || id == 2) {
        this.$router.push({
          name: 'DirectionsSystem',
          query: {
            id
          }
        })
      } else {
        this.$router.push({
          name: 'DirectionsQA',
          query: {
            id
          }
        })
      }

    },
    //
    //
  },
  filters: {},
  //
  created () {
    const { token, projectId, userId, userName } = this.$route.query
    if (token && projectId) {
      this.$store.commit('user/SET_TOKEN', token)
      this.$store.commit('user/SET_USER_INFO', { dcProjectId: projectId, userId, userName })
      this.$store.commit('user/SET_PROJECT_INFO', { dcProjectId: projectId })
    }
  },
  mounted () {
  },
  beforeRouteEnter (to, from, next) {
    // console.log(to)
    // console.log(from)
    next()
  },
  beforeRouteUpdate (to, from, next) {
    // console.log(to)
    // console.log(from)
    next()
  }
}
</script>

<style lang="less" scoped>
.cyui-layout-wrapper {
  .cyui-driections-navbar {
    .drictions-list {
      .drictions-list-row {
        min-height: 40px;
        width: 90%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
