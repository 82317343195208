<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="routeMetaTitle" @go-back="goBack">
        </cyui-header>
      </template>
      <template #default>
        <div class="page">
          <div class="list">
            <div class="item" v-for="(item,index) in productList" :key="index">
              <div class="title"><span>申请明细：第{{ index+1 }}条</span><van-icon name="delete-o" @click="onDelItem(index)" /></div>
              <van-cell-group class='cell-group'>
                <van-cell title="资产属性" required is-link :value="productPropertyMap[item.productProperty]||'请选择'" @click="onProductPropertyShow(index)"></van-cell>
                <van-cell title="资产名称" required is-link :value="item.productName||'请选择'" @click="onProductListShow(index)"></van-cell>
                <van-cell title="主规格" :value="item.standard||'根据选择的资产自动带入'"></van-cell>
                <van-cell title="资产编号" value="-" v-if="item.productProperty==1||item.productProperty==2"></van-cell>
                <van-cell title="资产编号" :value="item.productNo||'根据选择的资产自动带入'" v-else></van-cell>
                <van-cell title="处置区域" :value="item.outSpaceName||'根据选择的的资产所在仓库自动带入'" ></van-cell>
                <van-cell title="处置价格/元" :value="item.actPrice||'该资产在库价格'" ></van-cell>
                <van-cell title="单位" :value="item.unit||'根据选择的资产自动带入'" ></van-cell>

                <van-cell title="处置数量" required>
                  <template #default>
                    <van-stepper v-model="item.disposeNum" integer :min="0" :max="item.stockNum" />
                  </template>
                </van-cell>
                <van-cell title="处置原因" required is-link @click="onDisposeReasonShow(index)">>
                  <template #default>
                    <div class="pickerText" :class="{disabled:item.disposeReasonText===''}">{{item.disposeReasonText || '请选择'}} </div>
                  </template>
                </van-cell>
              </van-cell-group>
            </div>
            <div class="addBtn">
              <van-button block plain type="primary" icon="add-o" @click="onAddItem">添加物资</van-button>
            </div>
          </div>
          <van-cell-group class='cell-group'>
            <van-cell title="处置人" :value="disposePerson||'-'"></van-cell>
            <van-cell title="处置总金额" :value="disposeTotalPrice||'根据处置资产金额自动计算'"></van-cell>
            <van-field class="vtextarea" type='textarea' label='备注' v-model='remark' rows='3' autosize maxlength='200' placeholder='请输入'
                       input-align='left' show-word-limit
            />
          </van-cell-group>
        </div>
      </template>
      <template #footer>
        <div class="footer">
          <van-button block type="primary" size="large" @click="onSubmit">提交</van-button>
        </div>
      </template>
    </cyui-layout>
    <van-popup v-model="disposeReasonListShow" position="bottom">
      <van-picker ref="disposeReasonList" show-toolbar value-key="label" :columns="disposeReasonList" @confirm="onDisposeReasonSelect" @cancel="disposeReasonListShow = false" />
    </van-popup>
    <van-popup v-model="productPropertyListShow" position="bottom">
      <van-picker ref="productPropertyList" show-toolbar value-key="label" :columns="productPropertyList" @confirm="onProductPropertySelect" @cancel="productPropertyListShow = false" />
    </van-popup>
    <van-popup v-model="productListShow" position="bottom" :style="{ height: '70vh' }" closeable>
      <div class="productListPanel">
        <van-search v-model="productListSearchKeyword" show-action shape="round" @search="onProductListSearch" placeholder="请输入资产名称">
          <template #action>
            <div class="searchBtn" @click="onProductListSearch">搜索</div>
          </template>
        </van-search>
        <div class="productListResult">
          <van-cell v-for="(item,index) in productListGets" :key="item.id" :label="item.spaceName" clickable @click="onProductListSelect(item)">
            <template #title>
              <div class="productListResultTitle">
                <span>{{item.productName}} {{item.productNo}}</span>
                <span>{{'库存：'+item.count}}</span>
              </div>
            </template>
          </van-cell>
          <van-empty v-if="!productListGets.length" description="无数据"></van-empty>
        </div>
        <van-pagination v-model="productListSearchPageNum" :total-items="productListSearchTotal" :items-per-page="productListSearchPageSize" @change="onProductListSearchPage"/>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import common from '@mixins/common'
  import { mapGetters } from 'vuex'
  import _ from 'lodash'
  import * as assetsApi from '@api/assets'
  import imageCompression from 'browser-image-compression'

  const defaultProduct = {
    outSpaceFullCode: '',
    outSpaceName: '',
    stockId: '',
    productId: '',
    productNo: '',
    productProperty: 0,
    productName: '',
    standard: '',
    unit: '',
    actPrice: 0,
    disposePrice: '',
    stockNum: '',
    disposeNum: 1,
    disposeReason: '',
    disposeReasonText: '',
  }

  const productPropertyList = [
    {label: "易耗品", value: 1},
    {label: "低值品", value: 2},
    {label: "固定资产", value: 3},
  ]
  const productPropertyMap = _.mapValues(_.keyBy(productPropertyList, i=>i.value), j=>j.label);

  const disposeReasonList = [
    {label: "资产报废", value: 1},
    {label: "冲销处理", value: 2},
    {label: "闲置变卖", value: 3},
  ];
  const disposeReasonMap = _.mapValues(_.keyBy(disposeReasonList, i=>i.value), j=>j.label);

  export default {
    name: 'AssetsDispose',
    mixins: [common],
    computed: {
      ...mapGetters('user', [
        'userInfo',
        'projectInfo',
      ]),
      disposeTotalPrice() {
        if (this.productList && this.productList.length) {
          let sum = 0;
          for(let product of this.productList) {
            try {
              sum += Math.round(parseFloat(product.actPrice) * 100 * product.disposeNum)
            } catch (e){

            }
          }
          return (sum / 100)+'';
        } else {
          return '';
        }
      }
    },
    data() {
      return {
        disposePerson:'',
        // disposeTotalPrice:'',
        remark:'',
        projectId: '',
        projectName: '',
        productList: [
          { ...defaultProduct }
        ],

        disposeReasonListShow: false,
        disposeReasonList,

        currentProductIndex: -1,
        productPropertyListShow: false,
        productPropertyList,
        productPropertyMap,
        productListShow: false,
        productListGets: [],
        productListSearchKeyword: '',
        productListSearchPageNum:1,
        productListSearchPageSize:10,
        productListSearchTotal:0,
      }
    },
    mounted() {
      this.disposePerson = this.userInfo.userName;
      this.projectId = this.projectInfo.dcProjectId;
      this.projectName = this.projectInfo.projectName;
    },
    methods: {
      onDisposeReasonShow(index) {
        this.disposeReasonListShow = true;
        this.currentProductIndex = index;
        this.$nextTick(()=>{
          let value = this.productList[index].disposeReason
          let selectIndex = this.disposeReasonList.findIndex(i=>i.value===value);
          this.$refs.disposeReasonList.setIndexes([selectIndex]);
        })
      },
      onDisposeReasonSelect(item) {
        this.productList[this.currentProductIndex].disposeReason = item.value;
        this.productList[this.currentProductIndex].disposeReasonText = item.label;
        this.disposeReasonListShow = false;
      },
      onProductPropertyShow(index) {
        this.productPropertyListShow = true;
        this.currentProductIndex = index;
        this.$nextTick(()=>{
          let value = this.productList[index].productProperty;
          let selectIndex = this.productPropertyList.findIndex(i=>i.value===value);
          this.$refs.productPropertyList.setIndexes([selectIndex]);
        })
      },
      onProductPropertySelect(item) {
        if (this.productList[this.currentProductIndex].productProperty !== item.value) {
          let product = {
            productProperty: item.value
          }
          this.onProductListSelect(product)
        }
        this.productPropertyListShow = false;
      },
      onProductListShow(index) {
        this.currentProductIndex = index;
        let productProperty = this.productList[this.currentProductIndex].productProperty;
        if (!productProperty) {
          this.$toast('请先选择资产属性')
          return
        }
        this.productListShow = true;
        this.onProductListSearch();
      },
      onProductListSearch() {
        let productProperty = this.productList[this.currentProductIndex].productProperty;
        if (!productProperty) {
          this.$toast('请先选择资产属性')
          return
        }
        let params = {
          productProperty: productProperty,
          productNameProductNo:this.productListSearchKeyword,
          pageNum:this.productListSearchPageNum,
          pageSize:this.productListSearchPageSize,
        }
        assetsApi.getStockProductList(params).then(res=>{
          this.productListGets = res.data.data;
          this.productListSearchTotal = res.data.count;
        })
      },
      onProductListSearchPage() {
        this.onProductListSearch();
      },
      onProductListSelect(product) {
        let item = this.productList[this.currentProductIndex];
        item.stockId = product.id;
        item.productNo = product.productNo || '';
        item.productId = product.productId;
        item.productName = product.productName|| '';
        item.standard = product.standards|| '';
        item.unit = product.unit || '';
        item.actPrice = product.actPrice|| 0;
        item.disposePrice = product.actPrice|| 0;
        item.productProperty = product.productProperty;
        item.outSpaceFullCode = product.spaceFullCode || '';
        item.outSpaceName = product.spaceName || '';
        item.stockNum = product.count || 0;
        item.disposeNum = product.count && product.count > 0 ? 1 : 0;
        this.productListShow = false;
      },
      onAddItem() {
        this.productList.push({
          ...defaultProduct
        })
      },
      afterFileRead(item) {
        let up = this.$toast.loading({forbidClick: true,duration:0})
        imageCompression(item.file, {
          maxSizeMB: 0.2,
          initialQuality: 0.7
        }).then(file=>{
          assetsApi.fileUpload(item).then(res=>{
            this.$toast.clear()
            const cb = res.data
            if (cb.code === 200) {
              item.fileType = 1;
              item.url = cb.data.url;
              item.fileUrl = cb.data.url;
              item.status = 'success'
              item.message = '上传成功'
            } else {
              item.status = 'failed';
              item.message = '上传失败';
            }
          }).catch(e=>{
            this.$toast.clear()
            item.status = 'failed';
            item.message = '上传失败';
          })
        }).catch(()=>{
          this.$toast.clear()
          item.status = 'failed';
          item.message = '上传失败';
        });
      },
      onDelItem(index) {
        this.$dialog.confirm({
          message: '是否确认删除这条记录？',
        }).then(()=>{
          this.productList.splice(index, 1);
        }).catch(() => {
          // on cancel
        });
      },
      onSubmit() {
        if (this.productList.length === 0) {
          this.$toast('请添加物资')
          return
        } else if (this.productList.length) {
          for(let i=0;i<this.productList.length;i++) {
            let item = this.productList[i];
            if (!item.productProperty) {
              this.$toast(`请选择第${i+1}条明细的资产属性`)
              return
            }
            if (!item.stockId) {
              this.$toast(`请选择第${i+1}条明细的资产名称`)
              return
            }
            if (!item.disposeNum) {
              this.$toast(`请设置第${i+1}条明细的处置数量`)
              return
            }
            if (!item.disposeReason) {
              this.$toast(`请选择第${i+1}条明细的处置原因`)
              return
            }
          }
        }
        this.$dialog.confirm({
          message: '是否确认提交？',
        }).then(()=>{
          assetsApi.addDispose({
            projectId: this.projectId,
            projectName: this.projectName,
            disposePerson: this.disposePerson,
            disposeTotalPrice: this.disposeTotalPrice,
            remark: this.remark,
            disposeDetailVos: this.productList,
          }).then(res=>{
            this.$router.replace({name: 'AssetsDisposeSuccess'})
          })
        }).catch(() => {
          // on cancel
        });

      }
    }
  }
</script>

<style lang="less" scoped>
  @import "./common.less";
  .cyui-layout-wrapper {
    background: #F6F6F6;
  }
  .page {
    padding: 10px;
  }
  .cell-group {
    margin-bottom: 10px;
  }
  .list {
    margin-bottom: 10px;
    .item {
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 3px 0 5px;
        margin-bottom: 10px;
        font-size: 14px;
        color: #6D6868;
        /deep/ .van-icon {
          font-size: 18px;
          color: #999999;
        }
      }
    }
    .addBtn {
      margin-top: 10px;
      /deep/ .van-button {
        border-radius: 10px;
      }
    }
  }
  .productListPanel {
    padding-top: 40px;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    .productListResult {
      height: 0;
      flex: 1;
      overflow-y: auto;
      &Title {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .form {
    .uploadPanel {
      text-align: left;
    }
  }
  .footer {
    width: 100%;
    padding: 10px;
  }
</style>
