import Layout from '@views/layout/layout'

const maintainRouter = {
  path: '/maintain',
  component: Layout,
  name: 'Maintain',
  redirect: '/maintain/list',
  meta: {
    title: '维修记录',
    noCache: true,
    requireAuth: true
  },
  children: [
    {
      path: 'list',
      name: 'MaintainList',
      components: {
        default: () => import('@views/maintain/list')
      },
      props: {
        default: (route) => ({
          status: route.query.status
        })
      },
      meta: {
        title: '维修记录',
        noCache: true,
        requireAuth: true
      }
    },
    {
      path: 'detail',
      name: 'MaintainDetail',
      components: {
        default: () => import('@views/maintain/detail')
      },
      props: {
        default: (route) => ({
          id: route.query.id
        })
      },
      meta: {
        title: '维修详情',
        noCache: true,
        requireAuth: true
      }
    }
  ]
}

export default maintainRouter
