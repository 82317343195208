<template>
  <!-- <cyui-field-record-info class="cyui-clean-item"
                          :data="detailInfo"
                          :header-content-field="headerContentField"
                          :header-extra-field="headerExtraField"
                          :footer-content-field="footerContentField"
                          :footer-extra-field="footerExtraField"
                          :body-fields="bodyFields"
                          @go-detail="goDetail"></cyui-field-record-info> -->
    <div class="report-item-card" @click="goDetail">
      <div class="report-date">
        日期：2022-08-02
      </div>
      <div class="report-action">
        查看详情 >
      </div>
    </div>
</template>

<script>
  import filters from '@filters/filters'
  import { dateFilter } from '@utils/utils'
  import { statusMap } from '@/dictionaries/clean'

  export default {
    name: 'ReportItem',
    mixins: [filters],
    components: {},
    data() {
      return { }
    },
    props: {
      detailInfo: {
        type: Object,
        require: true,
        default() {
          return {}
        }
      }
    },
    computed: {},
    methods: {
      goDetail() {
        this.$emit('go-detail', this.detailInfo)
      },
      async initData() {
      }
    },
    filters: {},
    watch: {},
    created() {
      this.initData()
    },
    mounted() {
    }
  }
</script>

<style lang="less" scoped>
  @import '../../../assets/styles/themes/default/index';
  @import '../../../assets/styles/mixins/index';

  .report-item-card {
    background: #fff;
    border: 1px solid #EFEFEF;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 24px 12px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;

    .report-action {
      display: flex;
      align-items: center;
    }

    &:not(:first-child) {
      margin-top: 10px;
    }
  }

</style>
