<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header></template>
      <template #default>
        <van-cell-group inset class='cell-group'>
          <van-cell title='项目' :value='detail.projectName' />
          <van-cell title='房源' :value='detail.roomNo' />
        </van-cell-group>

        <van-cell-group inset class='cell-group'>
          <van-cell>
            <template #title>
              <span style='font-size:15px;'>破底价信息</span>
            </template>
          </van-cell>

          <van-field
            v-model='detail.customerName'
            placeholder='请输入'
            input-align='right'
            required>
            <template #label>
              <span class='field-label'>客户姓名</span>
            </template>
          </van-field>

          <van-field
            v-model.number='detail.customerMobile'
            type='number'
            placeholder='请输入'
            input-align='right'
            required>
            <template #label>
              <span class='field-label'>客户手机号</span>
            </template>
          </van-field>

          <van-field
            v-model.number='detail.period'
            type='number'
            placeholder='请输入'
            input-align='right'
            required
            @input='handlePeriodInput'>
            <template #label>
              <span class='field-label'>签约租期</span>
            </template>
            <template #extra>
              <span style='padding-left: 10px;'>个月</span>
            </template>
          </van-field>

          <van-cell title='付款方式' :value="filterPayMethod(detail.payMethod)" is-link @click='handleShowPayment' required />
          <van-cell title='系统定价'>
            <template #default>
              <a href='#' @click.stop.prevent='handleShowPriceDetail' style='color: #356be8;text-decoration: underline;'>{{ detail.currentPrice || 0 }}元/月</a>
            </template>
          </van-cell>
          <van-cell title='系统底价'>
            <template #default>
              <span>{{ detail.minAmount || 0 }}元/月</span>
            </template>
          </van-cell>

          <van-field
            v-model.number='detail.approvalPrice'
            type='number'
            placeholder='请输入'
            input-align='right'
            required>
            <template #label>
              <span class='field-label'>申请签约价</span>
            </template>
          </van-field>
          <van-field
            v-model='detail.remark'
            rows="3"
            autosize
            type="textarea"
            placeholder="请输入"
            maxlength="2000"
            required
          >
            <template #label>
              <span class='field-label'>备注</span>
            </template>
          </van-field>
        </van-cell-group>
        <p class='tips'>注意：申请通过后，需一个工作日内完成签约！</p>
      </template>
      <template #footer>
        <div class='cyui-actionbar'>
          <div class='cyui-actionbar-item cyui-actionbar-btns'>
            <button
              type='button'
              class='cyui-btn cyui-btn-ghost cyui-btn-block field-apply'
              @click='handleSubmit'
            >
              提交审批
            </button>
          </div>
        </div>
      </template>
    </cyui-layout>

    <!-- 弹窗 -->
    <van-popup v-model='rentalTypeInfo.show'
               :get-container='getContainer'
               position='bottom'>
      <van-picker
        :columns='rentalTypeInfo.listData'
        :title='rentalTypeInfo.title'
        item-height='1.22667rem'
        show-toolbar
        @cancel='rentalTypeInfo.show = false'
        @confirm='handleSelectRentalType'
      />
    </van-popup>
    <van-dialog v-model='priceInfo.show' confirmButtonColor='#356be8'>
      <template slot='title'>
        {{ priceInfo.title }}
      </template>
      <van-cell-group inset>
        <van-cell v-if="priceInfo.data && priceInfo.data.layoutPrice">
          <div slot='title'>
            <p>户型定价：</p>
          </div>
          <div slot='default'>
            <p :class='priceColor(priceInfo.data.layoutPrice)'>{{ priceFilter(priceInfo.data.layoutPrice) }}元/月</p>
          </div>
        </van-cell>
        <van-cell v-if="priceInfo.data && priceInfo.data.floorPrice">
          <div slot='title'>
            <p>楼层波动：</p>
          </div>
          <div slot='default'>
            <p :class='priceColor(priceInfo.data.floorPrice)'>{{ priceFilter(priceInfo.data.floorPrice) }}元/月</p>
          </div>
        </van-cell>
        <van-cell v-if="priceInfo.data && priceInfo.data.orientationPrice">
          <div slot='title'>
            <p>朝向波动：</p>
          </div>
          <div slot='default'>
            <p :class='priceColor(priceInfo.data.orientationPrice)'>{{ priceFilter(priceInfo.data.orientationPrice) }}元/月</p>
          </div>
        </van-cell>
        <van-cell v-if="priceInfo.data && priceInfo.data.periodPrice">
          <div slot='title'>
            <p>{{ detail.period }}个月租期：</p>
          </div>
          <div slot='default'>
            <p :class='priceColor(priceInfo.data.periodPrice)'>{{ priceFilter(priceInfo.data.periodPrice) }}元/月</p>
          </div>
        </van-cell>
        <van-cell v-if="priceInfo.data && priceInfo.data.payPrice">
          <div slot='title'>
            <p>付款方式：</p>
          </div>
          <div slot='default'>
            <p :class='priceColor(priceInfo.data.payPrice)'>{{ priceFilter(priceInfo.data.payPrice) }}元/月</p>
          </div>
        </van-cell>
        <van-cell v-if="priceInfo.data && priceInfo.data.currentPrice">
          <div slot='title'>
            <p>综合总价：</p>
          </div>
          <div slot='default'>
            <p :class='priceColor(priceInfo.data.currentPrice)'>{{ priceInfo.data.currentPrice }}元/月</p>
          </div>
        </van-cell>
      </van-cell-group>
    </van-dialog>
  </div>
</template>

<script>
  import { getApplyDetailApi, getApplyPriceDetailApi, setApplySubmitApi } from "@/api/priceBreak"
  import common from '@mixins/common'
  import filters from '@filters/filters'
  import { formatPx2Rem } from '@utils/index'
  import { debounce } from 'lodash'
  import { Dialog } from 'vant'
  import { mapGetters } from 'vuex'
  export default {
    name: 'PriceBreakApply',
    mixins: [common, filters],
    computed: {
      ...mapGetters('user', [
        'userInfo',
        'projectInfo'
      ])
    },
    data() {
      return {
        houseId: '',
        detail: {},
        rentalTypeInfo: { // 付款方式modeOfPayment
          show: false,
          title: '请选择付款方式',
          listData: [{ text: '月付', value: 1 }, { text: '季付', value: 2 }, { text: '半年付', value: 3 }, { text: '年付', value: 4 }, { text: '全额支付', value: 6 }]
        },
        priceInfo: {
          show: false,
          title: '系统定价详情',
          data: {}
        }
      }
    },
    created() {
    },
    mounted() {
      const { token, projectId, projectName, userId, userName, houseId } = this.$route.query
      if (token && projectId) {
        this.$store.commit('user/SET_TOKEN', token)
        this.$store.commit('user/SET_USER_INFO', { dcProjectId: projectId, userId, userName, projectName })
        this.$store.commit('user/SET_PROJECT_INFO', { dcProjectId: projectId, projectName })
      }
      if (houseId) {
        this.houseId = houseId
      }
      this.getDetail()
    },
    methods: {
      formatPx2Rem,
      getDetail() {
        this.$store.dispatch('base/SetLoading', true)
        getApplyDetailApi({ houseId: this.houseId }).then((res) => {
          if (res.data.code === '10000') {
            this.detail = res.data.data
          }
        }).finally(() => {
          this.$store.dispatch('base/SetLoading', false)
        })
      },
      getPrice() {
        if (this.detail.period && this.detail.payMethod) {
          const query = {
            houseId: this.houseId,
            period: this.detail.period,
            payMethod: this.detail.payMethod
          }
          getApplyPriceDetailApi(query).then((res) => {
            if (res.data.code === '10000') {
              this.$set(this.detail, 'currentPrice', res.data.data.currentPrice)
              this.$set(this.detail, 'minAmount', res.data.data.rentMinAmount)
              this.$set(this.priceInfo, 'data', res.data.data)
            }
          })
        }
      },
      handlePeriodInput: debounce(function (value) { // 查询，防抖
        this.getPrice()
      }, 500),
      handleShowPayment() {
        this.rentalTypeInfo.show = true
      },
      handleSelectRentalType(value) {
        this.rentalTypeInfo.show = false
        this.$set(this.detail, 'payMethod', value.value)
        this.getPrice()
      },
      handleShowPriceDetail() {
        if (!this.detail.period) {
          this.$toast('请输入签约租期！')
          return
        }
        if (!this.detail.payMethod) {
          this.$toast('请选择付款方式！')
          return
        }
        this.priceInfo.show = true
      },
      handleSubmit() {
        const { customerName, customerMobile, period, payMethod, approvalPrice, remark } = this.detail
        if (!customerName) {
          this.$toast('请输入用户姓名!')
          return
        }
        if (!customerMobile) {
          this.$toast('请输入手机号!')
          return
        }
        if (!period) {
          this.$toast('请输入签约租期!')
          return
        }
        if (!payMethod) {
          this.$toast('请选择付款方式!')
          return
        }
        if (!approvalPrice) {
          this.$toast('请输入申请签约价!')
          return
        }
        if (!remark) {
          this.$toast('请输入备注!')
          return
        }

        this.$store.dispatch('base/SetLoading', true)
        const query = Object.assign({}, this.detail, { housekeeperId: this.userInfo.userId, housekeeper: this.userInfo.userName, houseId: this.houseId })
        setApplySubmitApi(query).then((res) => {
          if (res.data.code === '10000') {
            Dialog.alert({
              message: '提交成功！',
              confirmButtonColor: '#356be8'
            }).then(() => {
              // on close
              this.$set(this.detail, 'customerName', '')
              this.$set(this.detail, 'customerMobile', '')
              this.$set(this.detail, 'period', '')
              this.$set(this.detail, 'payMethod', '')
              this.$set(this.detail, 'minAmount', 0)
              this.$set(this.detail, 'currentPrice', 0)
              this.$set(this.detail, 'approvalPrice', '')
              this.$set(this.detail, 'remark', '')
              this.$set(this.priceInfo, 'data', {})
            })
          } else {
            this.$toast.fail(res.data.msg)
          }
        }).finally(() => {
          this.$store.dispatch('base/SetLoading', false)
        })
      },

      // 返回一个特定的 DOM 节点，作为挂载的父节点
      getContainer() {
        return document.querySelector('body')
      },
      filterPayMethod(value) {
        if (value) {
          return this.rentalTypeInfo.listData.find(item => item.value === value).text
        }
      },
      priceColor(value) {
        let signValue = Math.sign(value)
        if (signValue === 1) {
          return 'red'
        } else if (signValue === -1) {
          return 'green'
        } else {
          return ''
        }
      },
      priceFilter(value) {
        let signValue = Math.sign(value)
        if (signValue === 1) {
          return `+${value}`
        } else if (signValue === -1) {
          return `${value}`
        } else {
          return ''
        }
      }
    }
  }
</script>

<style lang='less' scoped>
  @import '../../assets/styles/themes/default/index';
  @import '../../assets/styles/mixins/index';

  @sectionPrefixCls: ~'@{css-prefix}section';

  .@{css-prefix}detail-header {
    width: 100%;
    height: 100px;
    background-color: #D8B084;

    &-content {
      padding-left: 18px;
      line-height: 24px;
      color: #ffffff;

      .@{css-prefix}detail-header-title {
        margin-bottom: 10px;
        font-size: 16px;
      }

      .@{css-prefix}detail-header-brief {
        font-size: 12px;

        > span {
          &:not(:last-of-type) {
            margin-right: 10px;
          }
        }
      }
    }

    &-extra {
      padding-right: 30px;
      line-height: 24px;
      font-size: 16px;
      color: #ffffff;

      .@{css-prefix}detail-header-status {
        position: relative;

        .svg-icon {
          display: block;
          width: 54px;
          height: 54px;
          vertical-align: middle;
        }
      }
    }
  }

  .@{css-prefix}section {
    &-item {
      overflow: hidden;

      .@{css-prefix}card-header {
        min-height: 50px;

        &-line {
          .hairline('bottom', 1px, #EEEEEE);

          .@{css-prefix}card-header-thumb {
            margin-right: 10px;

            .svg-icon {
              display: block;
              width: 18px;
              height: 18px;
              vertical-align: middle;
            }
          }

          .@{css-prefix}card-header-content {
            font-size: 15px;
            font-weight: bold;
            color: #333333;
          }
        }
      }

      .@{css-prefix}card-body {
        padding: 15px;
      }

      & + & {
        margin-top: 12px;
      }
    }
  }

  .@{sectionPrefixCls}-remark {
    padding: 0 5px;
    line-height: 24px;
    color: #333333;
    font-size: 12px;
    overflow: hidden;

    &-content {
      padding: 10px 0 20px;
    }

    &-pic {
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 -5px;
      padding: 10px 0;
      .hairline('top', 1PX, #EEEEEE);

      &-item {
        display: block;
        width: 86px;
        height: 86px;
        padding: 10px 5px 0;
        overflow: hidden;

        > img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }
    }
  }

  .@{sectionPrefixCls}-client {
    padding: 0 10px;
    background-color: #FFFFFF;
  }

  .@{css-prefix}actionbar {
    position: relative;
    height: 65px;
    background-color: transparent;

    .@{css-prefix}actionbar-btns {
      padding: 10px 43px 15px;

      .@{css-prefix}btn {
        height: 40px;
        line-height: 40px;
        background-color: #FFFFFF;
        font-size: 15px;
        border: 1px solid #D1A36F;
        border-radius: 20px;
        color: #D1A36F;

        &.@{css-prefix}btn-primary {
          color: #FFFFFF;
          background-color: #D1A36F;
        }

        &.@{css-prefix}btn-ghost {
        }
      }

      .@{css-prefix}btn + .@{css-prefix}btn {
        margin-left: 30px;
      }
    }
  }

  .cell-group {
    margin: 15px;
    overflow: hidden;
  }
  .field-label{
    color: #323233;
  }
  .field-apply{
    background: #356be8!important;
    border-color: #356be8!important;
    color: #fff!important;
  }
  .tips{
    font-size: 14px;
    font-weight: bold;
    color: #ff5858;
    padding: 0 15px;
  }

  .red {
    color: #FF5858;
  }

  .green {
    color: #80CF66;
  }
</style>
