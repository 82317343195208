
  import { getSelectRent, tenantPayOtherFee } from '@api/expense'
  import { removeStorage } from '@utils/storage'

  export default {
    name: '',
    components: {},
    props: {},
    data() {
      return {
        form: {
          typeName: '加卡到哪'
        },
        showPicker: false,
        columns: [],
        contractData: []
      }
    },
    computed: {
      typeName() {
        let { type } = this.$route.query
        return type === 'WARTER_C_FEE' ? '水费' : type === 'PREPOWER_C_FEE' ? '电费' : type === 'PARK_FEE' ? '停车费' : ''
      }
    },
    watch: {},
    created() {
      this.initData()
    },
    mounted() {
    },
    methods: {
      initData() {
        let { tenantId, type } = this.$route.query
        getSelectRent({ tenantId, type }).then(res => {
          let result = res.data
          if (result.code === '10000') {
            // this.contractData = [...result.data]
            this.contractData = result.data.map(item => {
              item.name = item.projectName + '-' + item.roomNo + '房间'
              return item
            })
            console.log(this.contractData)
          }
        })
      },
      handleChange(e) {
        this.form.rentName = e.name
        this.form.rentId = e.id
        this.form.projectId = e.projectId
        this.form.houseId = e.houseId
        this.showPicker = false
      },
      onSubmit() {
        let { tenantId, type } = this.$route.query
        let params = {
          ...this.form,
          tenantId,
          type
        }
        tenantPayOtherFee({ ...params }).then(res => {
          let result = res.data
          if (result.code === '10000') {
            let { id, totalAmount } = result.data
            this.$router.push({
              name: 'paymentIndex',
              query: {
                erjiaOderId: id,
                erjiaTotalAmount: totalAmount
              }
            })
          }
        })
      }
      // clean() {
      //   removeStorage('phone', 'localStorage')
      //   removeStorage('tenantId', 'localStorage')
      //   this.$router.push({
      //     name: 'expenseType'
      //   })
      // }
    }
  }
