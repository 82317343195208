import request from '@utils/request'
import request_gd from '@utils/request_gd'
import request_app from '@utils/request_app'
// import qs from 'qs'

/**
 * Clean-获取列表数据
 * url:'/service/getCleaningList'
 * method:GET
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 *   pageSize: '' //  分页大小
 *   pageNum: '' //  当前页数
 *   projectId: '' //  项目id
 *   status: '' //  进度 2:进行中 3:已完成 全部则不传
 * }
 * @returns {*}
 */
export const getListAPI = (params = {}) => {
  return request.get('/service/getCleaningList', { params })
}

/**
 * Clean-获取详情数据
 * url:'/service/getCleaningDetail'
 * method:GET
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 *   id: '' //  ID
 * }
 * @returns {*}
 */
export const getDetailAPI = (params = {}) => {
  return request.get('/service/getCleaningDetail', { params })
}

/**
 * Clean-完成保洁
 * url:'/appointment/finishAppointment'
 * method:GET
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 *   id: '' //  ID
 * }
 * @returns {*}
 */
export const submitCleanAPI = (params = {}) => {
  return request.post('/service/completeCleaning', params)
}

export const getCost = (params = {}) => {
  return request_gd({
    url: '/api/v1/workOrder/getCost',
    method: 'get',
    params
  })
}

export const getPayQrCodeByCost = (data = {}) => {
  return request_app({
    url: '/openapi/qrCode/payQrCodeByCost',
    method: 'post',
    data
  })
}
