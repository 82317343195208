<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="routeMetaTitle" @go-back="goBack">
        </cyui-header>
      </template>
      <template #default>
        <div class="page">

          <div class="list">
            <div class="item" v-for="(item,index) in productList" :key="index">
              <div class="title"><span>申请明细：第{{ index+1 }}条</span></div>
              <van-cell-group class='cell-group'>
                <van-cell title="资产属性"  :value="productPropertyMap[item.productProperty]||'-'"></van-cell>
                <van-cell title="资产名称" :value="item.productName||'-'"></van-cell>
                <van-cell title="主规格" :value="item.standard||'-'"></van-cell>
                <van-cell title="单位" :value="item.unit||'-'" ></van-cell>
                <van-cell title="调出仓库" required is-link @click="onOutSpaceListShow(index)">
                  <template #default>
                    <div class="pickerText" :class="{disabled:item.outSpaceName===''}">{{item.outSpaceName || '请选择'}} </div>
                  </template>
                </van-cell>
                <van-cell title="资产编号">
                  <template #default>
                    <div v-if="item.productProperty===3&&item.guDingList&&item.guDingList.length>0">
                      <div v-for="(obj,j) in item.guDingList">{{obj.productNo}}</div>
                    </div>
                    <div v-else-if="item.productProperty!==3&&item.productNo">{{item.productNo}}</div>
                    <div v-else>-</div>
                  </template>
                </van-cell>
                <van-cell title="调出价格/元" v-if="item.productProperty!==3" :value="item.outPrice||'-'"></van-cell>
                <van-cell title="库存数量" :value="item.stockCount||'-'" v-if="item.productProperty!==3"></van-cell>
                <van-cell title="调拨申请数量" :value="item.applicationNum||'-'"></van-cell>
                <van-cell title="调出数量" v-if="item.productProperty===3">
                  <template #default>
                    <van-stepper v-model="item.outNum" integer disabled/>
                  </template>
                </van-cell>
                <van-cell title="调出数量" v-else>
                  <template #default>
                    <van-stepper v-model="item.outNum" integer :min="0" :max="item.stockCount||0" />
                  </template>
                </van-cell>
              </van-cell-group>
            </div>
          </div>
          <van-cell-group class='cell-group'>
            <van-cell title="调出管理员" :value="outKeeper||'-'"></van-cell>
            <van-cell title="调出时间" :value="outTime||'-'"></van-cell>
          </van-cell-group>
          <van-cell-group class='cell-group'>
            <van-cell title="调入申请员" :value="inApplicant||'-'"></van-cell>
            <van-cell title="调入项目" :value="inProjectName||'-'"></van-cell>
            <van-cell title="调拨总金额" :value="totalPrice||'-'"></van-cell>
          </van-cell-group>
          <van-cell-group class='cell-group'>
            <template #title>
              <div class="cell-group-title"><span>其他信息</span></div>
            </template>
            <van-field class="vtextarea" type='textarea' label='备注' v-model='remark' rows='3' autosize
                       input-align='left'
            />
          </van-cell-group>
        </div>
      </template>
      <template #footer>
        <div class="footer">
          <van-button block type="primary" size="large" @click="onConfirm">确认调拨</van-button>
        </div>
      </template>
    </cyui-layout>
    <!--<van-popup v-model="outSpaceListShow" position="bottom">-->
    <!--  <van-picker ref="outSpaceList" show-toolbar value-key="spaceName" :columns="outSpaceList" @confirm="onSpaceListSelect" @cancel="outSpaceListShow = false" />-->
    <!--</van-popup>-->
    <van-popup v-model="outSpaceListShow" position="bottom" :style="{ height: '50vh' }">
      <div class="productListPanel">
        <div class="van-picker__toolbar">
          <button type="button" class="van-picker__cancel" @click="onProductCancel">取消</button>
          <div class="van-ellipsis van-picker__title"></div>
          <button type="button" class="van-picker__confirm" @click="onProductConfirm">确认</button>
        </div>
        <div class="productListResult">
          <van-cell v-for="(item,index) in outSpaceList" :key="index" clickable @click="onProductCheck(item)">
            <template #title>
              <div class="productListResultRow">
                <span class="c1">{{item.spaceName}}</span>
                <span class="c2" v-if="currentProductProperty==3">{{item.productNo}}</span>
                <span class="c3" v-if="currentProductProperty==3">￥{{item.actPrice}}</span>
                <span class="c3" v-else>{{item.count}}</span>
                <van-checkbox :value="checks[item.id]"></van-checkbox>
              </div>
            </template>
          </van-cell>
          <van-empty v-if="!outSpaceList.length" description="无数据"></van-empty>
        </div>
        <van-pagination v-model="productListSearchPageNum" :total-items="productListSearchTotal" :items-per-page="productListSearchPageSize" @change="onOutSpaceListPage"/>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import common from '@mixins/common'
  import { mapGetters } from 'vuex'
  import _ from 'lodash'
  import * as assetsApi from '@api/assets'
  import moment from 'moment'
  import Vue from 'vue'

  const productPropertyList = [
    {label: "易耗品", value: 1},
    {label: "低值品", value: 2},
    {label: "固定资产", value: 3},
  ]
  const productPropertyMap = _.mapValues(_.keyBy(productPropertyList, i=>i.value), j=>j.label);

  export default {
    name: 'AssetsDispatchOutDetail',
    mixins: [common],
    computed: {
      ...mapGetters('user', [
        'userInfo',
        'projectInfo',
      ]),
      totalPrice() {
        let sum = 0;
        this.productList.forEach(product => {
          try {
            if (product.productProperty === 3 && product.guDingList && product.guDingList.length) {
              for (let gu in product.guDingList) {
                sum +=  Math.round(parseFloat((product.outPrice || 0)) * 100);
              }

            } else {
              let price = Math.round(parseFloat((product.outPrice || 0)) * 100);
              sum += price * (product.outNum || 0);
            }

          } catch (e){

          }
        })
        return (sum / 100);
      }
    },
    data() {
      return {
        from: '',
        allocateApplyId: '',
        inApplicant:'',
        inProjectId: '',
        inProjectName: '',
        productList: [],
        outProjectId: '',
        outProjectName: '',
        outKeeper: '',
        outTime: '',
        remark: '',
        productPropertyMap,
        outSpaceListShow: false,
        outSpaceList:[],
        currentProductIndex: -1,
        currentProductProperty: 0,
        productListSearchPageNum:1,
        productListSearchPageSize:10,
        productListSearchTotal:0,
        checks: {},
      }
    },
    created() {
      this.allocateApplyId = this.$route.query.allocateApplyId;
      this.outTime = moment().format('YYYY-MM-DD HH:mm:ss');
      this.getData();
    },
    methods: {
      getData(){
        assetsApi.getOutAllocateApplyDetail(this.allocateApplyId).then(res=>{
          let item = res.data.data.result;
          this.inApplicant = item.inApplicant;
          this.inProjectId = item.inProjectId;
          this.inProjectName = item.inProjectName;
          this.outProjectId = item.outProjectId;
          this.outProjectName = item.outProjectName;
          this.outKeeper = item.outKeeper;
          for (let product of item.productList) {
            product.outNum = product.applicationNum || 0;
            product.stockCount = 0;
            product.guDingList = [];
          }
          this.productList = item.productList;
        })
      },
      onOutSpaceListShow(index) {
        this.currentProductIndex = index;
        this.currentProductProperty =  this.productList[index].productProperty;
        this.outSpaceList = [];
        this.productListSearchPageNum = 0;
        this.productListSearchTotal = 0;
        this.checks = this.productList[index].checks || {};
        this.outSpaceListShow = true;
        this.onOutSpaceListPage();
      },
      onOutSpaceListPage() {
        let index = this.currentProductIndex;
        let productId = this.productList[index].productId;
        assetsApi.getStockProductList({
          projectId:this.projectInfo.dcProjectId,
          productId,
          minCount: 0,
          pageNum:this.productListSearchPageNum,
          pageSize:this.productListSearchPageSize,
        }).then(res=>{
          let list = res.data.data || [];
          this.productListSearchTotal = res.data.count;
          this.outSpaceList = list;
        })
      },
      onProductCheck(item) {
        let obj = {
          productNo: item.productNo,
          outPrice: item.actPrice,
          stockCount: item.count,
          spaceFullCode: item.spaceFullCode,
          outSpaceName: item.spaceName,
          stockId: item.id,
        }
        if (this.currentProductProperty === 3) {
          let checked = this.checks[item.id];
          if (checked) {
            Vue.delete(this.checks, item.id)
          } else {
            Vue.set(this.checks, item.id, obj)
          }
        } else {
          let checked = this.checks[item.id];
          if (!checked) {
            this.checks = {
              [item.id]: obj
            }
          }
        }
      },
      onProductCancel() {
        this.outSpaceListShow = false;
        this.checks = {};
      },
      onProductConfirm() {
        this.outSpaceListShow = false;
        this.productList[this.currentProductIndex].guDingList = [];
        this.productList[this.currentProductIndex].checks = this.checks;
        for (let key in this.checks) {
          let obj = this.checks[key];
          if (obj) {
            this.productList[this.currentProductIndex].stockId = obj.stockId;
            this.productList[this.currentProductIndex].spaceFullCode = obj.spaceFullCode;
            this.productList[this.currentProductIndex].outSpaceName = obj.outSpaceName;
            this.productList[this.currentProductIndex].outPrice = obj.outPrice;
            this.productList[this.currentProductIndex].stockCount = obj.stockCount;
            if (this.currentProductProperty === 3) {
              this.productList[this.currentProductIndex].guDingList.push(obj);
            }
          }
        }
        if (this.currentProductProperty === 3) {
          this.productList[this.currentProductIndex].outNum =  this.productList[this.currentProductIndex].guDingList.length
        }
        this.productList = [...this.productList]
      },
      onConfirm() {
        this.$dialog.confirm({
          message: '请确认是否同意该调拨出库？',
        }).then(()=>{
          let productList = [];
          for (let item of this.productList) {
            let product = {
              ...item
            }
            delete product.checks;
            productList.push(product);
          }
          assetsApi.addOutAllocateApply({
            projectId: this.projectInfo.dcProjectId,
            allocateApplyId: this.allocateApplyId,
            inApplicant: this.inApplicant,
            inProjectId: this.inProjectId,
            inProjectName: this.inProjectName,
            outKeeper: this.outKeeper,
            totalPrice: this.totalPrice,
            remark: this.remark,
            productList,
          }).then(res=>{
            this.$router.replace({name: 'AssetsDispatchOutSuccess'})
          })
        }).catch(() => {
          // on cancel
        });

      },
    }
  }
</script>

<style lang="less" scoped>
  @import "./common.less";
  .cyui-layout-wrapper {
    background: #F6F6F6;
  }
  .page {
    padding: 10px;
  }
  .cell-group {
    margin-bottom: 10px;
  }
  .list {
    margin-bottom: 10px;
    .item {
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 3px 0 5px;
        margin-bottom: 10px;
        font-size: 14px;
        color: #6D6868;
        /deep/ .van-icon {
          font-size: 18px;
          color: #999999;
        }
      }
    }
  }
  .rejectPanel {
    display: flex;
    flex-direction: column;
    .tip {
      font-size: 17px;
      color: #999999;
      padding: 0 10px;
    }
    /deep/ .van-field {
      &__label {
        color: #999;
      }
      &__value {
        border: none;
        background: #f5f5f5;
      }
    }
  }
  .productListPanel {
    padding-top: 0;
  }
  .productListResultRow {
    display: flex;
    align-items: center;
    > span {
      padding: 0px 3px;
      display: block;
      word-break: break-all;
    }
    .c1 {
      width: 0;
      flex: 1;
    }
    .c2 {
      white-space: nowrap;
      text-align: center;
    }
    .c3 {
      min-width: 60px;
      flex-shrink: 0;
      white-space: nowrap;
      text-align: center;
    }
  }
  .footer {
    width: 100%;
    padding: 10px;
  }
</style>
