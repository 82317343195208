<template>
  <div class='cyui-layout-wrapper'>
    <cyui-layout>
      <template #header>
        <cyui-header v-if='isCustomHeader' :title='headerTitle'
                     @go-back='goBack' @on-close='handleClosePage'>
          <template #headerRight>
            <div></div>
          </template>
        </cyui-header>
        <div class='cyui-driections-navbar'>
          <van-form @submit='onSubmit'>
            <van-field readonly
                       clickable
                       name='picker'
                       v-model='form.spaceName'
                       label='空间选择'
                       placeholder='请选择'
                       input-align='right'
                       right-icon='arrow'
                       @click='showSpace = true'
                       required
                       :rules='[{ required: true}]'
            />
            <!-- 空间类型 -->
            <van-popup v-model='showSpace'
                       round
                       position='bottom'>
              <van-cascader v-model='form.spaceId'
                            title='空间选择'
                            :options='spaceList'
                            :field-names='fieldNames'
                            @close='showSpace=false'
                            @change='spaceChange'
              />
            </van-popup>
            <!-- 问题类型 -->
            <van-field readonly
                       clickable
                       name='picker'
                       v-model='form.questionCategoryName'
                       label='问题类型'
                       placeholder='请选择'
                       input-align='right'
                       right-icon='arrow'
                       @click='showQuestion = true'
                       required
                       :rules='[{ required: true}]'
            />
            <!-- 空间类型 -->
            <van-popup v-model='showQuestion'
                       round
                       position='bottom'>
              <van-cascader v-model='form.questionCategoryId'
                            title='问题类型'
                            :options='questionList'
                            :field-names='questionfieldNames'
                            @close='showQuestion=false'
                            @change='questionChange'
              />
            </van-popup>
            <van-field label='问题描述'
                       v-model='form.questionDes'
                       rows='3'
                       autosize
                       maxlength='100'
                       type='textarea'
                       placeholder='请输入'
                       input-align='left'
                       error-message-align='left'
                       show-word-limit
                       required
                       :rules='[{ required: true}]'
            />
            <!--            label-width='14em'
                                   required
                                   input-align='right'>-->
            <van-field name='uploader'
                       label='图片'
                       input-align='right'
                       required>
              <template #input>
                <van-uploader v-model='imgList'
                              :after-read='afterIDRead'
                              :max-count='2'
                              :max-size='50 * 1024 * 1024'
                              @oversize='onOversize' />
              </template>
            </van-field>
            <van-field readonly
                       clickable
                       name='picker'
                       label='期望上门日期'
                       v-model='form.expectRepairDate'
                       placeholder='请选择'
                       input-align='right'
                       right-icon='arrow'
                       @click='showDatePicker = true'
                       required
                       :rules='[{ required: true}]'
            />
            <!-- 时间选择 -->
            <van-popup v-model='showDatePicker'
                       round
                       position='bottom'>
              <van-datetime-picker v-model='expectRepairDate'
                                   type='datehour'
                                   title='选择年月日小时'
                                   @confirm='changeDate'
                                   @cancel='showDatePicker=false'
              />
            </van-popup>
            <van-field label='联系人'
                       v-model='form.contactsName'
                       placeholder='请输入'
                       input-align='left'
                       required
                       :rules='[{ required: true}]'
            />
            <van-field label='联系电话'
                       v-model='form.contactsMobile'
                       placeholder='请输入'
                       input-align='left'
                       required
                       :rules='[{ required: true}]'
            />
            <van-field label='备注'
                       v-model='form.contactsRemark'
                       rows='3'
                       autosize
                       maxlength='100'
                       type='textarea'
                       placeholder='请输入'
                       input-align='left'
                       error-message-align='left'
                       show-word-limit
            />
            <div style='margin: 16px;'>
              <van-button round block type='info' color='#d1a36f' native-type='submit' :loading='loading'>提交
              </van-button>
            </div>
          </van-form>
        </div>
      </template>
    </cyui-layout>
  </div>
</template>

<script>
  import common from '@mixins/common'
  import { getCreateOrder, getSpaceList, fileUpload, getQuestionCategoryList, createOrder } from '@api/maintenance'
  import { getStorage } from '@utils/storage.js'
  import { mapGetters } from 'vuex'

  export default {
    name: 'DirectionsList',
    mixins: [common],
    data() {
      return {
        loading: false,
        headerTitle: '发起工单',
        userInfo: JSON.parse(getStorage('user_info', 'localStorage')),
        // 空间一级分类
        fieldNames: {
          text: 'spaceName',
          value: 'spaceId',
          children: 'children'
        },
        questionfieldNames: {
          text: 'name',
          value: 'id',
          children: 'children'
        },
        // 层级
        showSpace: false,
        showQuestion: false,
        showDatePicker: false,
        spaceList: [],
        questionList: [],
        imgList: [],
        expectRepairDate: new Date(),
        form: {
          spaceName: null,
          spaceId: null,
          questionDes: null,
          questionCategoryId: null,
          questionCategoryName: null,
          expectRepairDate: null,
          expectRepairTimeSpan: null,
          contactsName: null,
          contactsMobile: null,
          contactsRemark: null,
          questionDesImgList: []
        }
      }
    },
    props: {},
    computed: {
      ...mapGetters('user', [
        'projectInfo'
      ])
    },
    created() {
      console.log(this.projectInfo)
      this.getSpaceList()
      this.getQusitonList()
    },
    mounted() {
    },
    methods: {
      // 空间层级
      getSpaceList() {
        getSpaceList({
          projectId: this.projectInfo.dcProjectId
        }).then(res => {
          let data = res.data.data
          this.spaceList = this.listToTree(data)
          console.log(this.spaceList)
        })
      },
      listToTree(oldArr) {
        oldArr.forEach((element) => {
          const parentId = element.parentId
          if (parentId !== 0) {
            oldArr.forEach((ele) => {
              if (ele.spaceId === parentId) {
                if (!ele.children) {
                  ele.children = []
                }
                ele.children.push(element)
              }
            })
          }
        })
        // console.log('1711', oldArr);
        // 清除重复的
        oldArr = oldArr.filter((ele) => ele.parentId === '0')
        oldArr = this.arrSort(oldArr)
        return oldArr
      },
      arrSort(arr) {
        const newArr = arr.sort((a, b) => {
          return a.orderNum - b.orderNum
        })
        newArr.map(item => {
          if (item.children && item.children.length > 0) {
            item.children = this.arrSort(item.children)
          }
        })
        return newArr
      },
      spaceChange(e) {
        this.form.spaceName = e.selectedOptions[e.selectedOptions.length - 1].spaceFullName
      },
      questionChange(e) {
        this.form.questionCategoryName = e.selectedOptions[e.selectedOptions.length - 1].name
      },
      // 问题类型
      getQusitonList() {
        getQuestionCategoryList({
          type: 1
        }).then(res => {
          const data = res.data
          if (data.code == 200) {
            this.questionList = this.listToQuestion(data.data)
          }
        })
      },
      listToQuestion(oldArr) {
        oldArr.forEach((element) => {
          const parentId = element.leaderId
          if (parentId !== 0) {
            oldArr.forEach((ele) => {
              if (ele.id === parentId) {
                if (!ele.children) {
                  ele.children = []
                }
                ele.children.push(element)
              }
            })
          }
        })
        // console.log('1711', oldArr);
        // 清除重复的
        oldArr = oldArr.filter((ele) => ele.leaderId === '0')
        oldArr = this.arrSort(oldArr)
        return oldArr
      },
      changeDate(e) {
        this.form.expectRepairDate = this.timeFormat(this.expectRepairDate)
        this.showDatePicker = false
      },
      timeFormat(time) {
        let year = time.getFullYear()
        let month = time.getMonth() + 1
        let date = time.getDate()
        let hour = time.getHours()
        if (month < 10) month = '0' + month
        if (date < 10) date = '0' + date
        if (hour < 10) hour = '0' + hour
        return year + '-' + month + '-' + date + ' ' + hour + ':00:00'
      },
      async onSubmit() {
        try {
          this.$toast.loading({
            message: '加载中...',
            forbidClick: true,
            loadingType: 'spinner'
          })
          this.loading = true
          let data = {
            ...this.form,
            type: 1,
            projectId: this.projectInfo.projectId
          }
          const res = await createOrder(data)
          if (res.data.code === 200) {
            this.$toast('创建成功')
            this.goBack()
          } else {
            // 请求失败
            await this.$toast.clear()
            await this.handleCallback({
              isError: true,
              errorMessage: res.data.message || '创建失败!'
            })
          }
        } catch (err) {
          // 请求失败
          await this.$toast.clear()
        } finally {
          this.loading = false
        }
      },
      afterIDRead(file) {
        console.log(file)
        file.status = 'uploading'
        file.message = '上传中...'
        fileUpload(file).then(res => {
          const cb = res.data
          if (cb.code === 200) {
            console.log(this.form.questionDesImgList, 'this.form.questionDesImgList')
            this.form.questionDesImgList.push({
              fileType: 1,
              url: cb.data.url,
              fileUrl: cb.data.url
            })
            file.status = 'none'
            file.message = '上传成功'
          }
          console.log(this.form.questionDesImgList, 'this.form.questionDesImgList')
        })
      },
      onOversize() {
        this.$toast('文件大小不能超过5M')
      },
      deleteImg(item) {
        console.log(item)
        this.imgList = this.imgList.filter((v) => v.content != item.content)
        console.log(this.form.questionDesImgList, '珊瑚')
      }
    },
    filters: {}
    //

  }
</script>

<style lang='less' scoped>
  .cyui-layout-wrapper {
    .cyui-driections-navbar {
      width: 90%;
      margin: 20px auto;

      .drictions-list {
        .drictions-list-row {
          min-height: 40px;
          width: 90%;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            font-size: 14px;
          }
        }
      }
    }
  }

  /deep/ .field-title[data-v-068a4460] {
    height: auto;
    margin: 5px auto;
  }

  /deep/ .van-field__label {
    color: #282828;
  }

  /deep/ .van-field__control {
    color: #666666;
  }
</style>
