<template>
  <div class='cyui-layout-wrapper'>
    <cyui-layout>
      <template #header>
        <cyui-header v-if='isCustomHeader' :title='headerTitle' @go-back='goBack' @on-close='handleClosePage'>
          <template #headerRight>
            <div></div>
          </template>
        </cyui-header>
        <div class='cyui-driections-navbar'>          
        </div>
      </template>
      <template #default>
        <div>
          <div class="info-wrapper">
            <div class="info-card">
              <div class="info-card-header">房源信息</div>
              <van-row class="mt10">
                <van-col span="12">总房源数: 8</van-col>
                <van-col span="12">委托房源数: 8</van-col>
              </van-row>
              <van-row class="mt10">
                <van-col span="12">可运营房源数: 8</van-col>
                <van-col span="12">在住房数: 8</van-col>
              </van-row>
              <van-row class="mt10">
                <van-col span="12">自用房数: 8</van-col>
                <van-col span="12">维修房数: 8</van-col>
              </van-row>
            </div>
            <div class="info-card mt10">
              <div class="info-card-header">运营数据</div>
              <van-row class="mt10">
                <van-col span="12">在住房数: 8</van-col>
                <van-col span="12">日租房数: 8</van-col>
              </van-row>
              <van-row class="mt10">
                <van-col span="12">已预订房数: 8</van-col>
                <van-col span="12">空房数: 8</van-col>
              </van-row>
              <van-row class="mt10">
                <van-col span="12">出租率: 8</van-col>
                <van-col span="12">月均值: 8</van-col>
              </van-row>
            </div>
            <div class="info-card mt10">
              <div class="info-card-header">客户数据</div>
              <van-row class="mt10">
                <van-col span="24">新增企业微信客户: 8</van-col>                
              </van-row>
              <van-row class="mt10">
                <van-col span="24">当日来访数: </van-col>
                <van-col span="24">自访: 10 </van-col>
                <van-col span="24">贝壳: 8</van-col>
                <van-col span="24">企业客户: 8</van-col>
              </van-row>
              <van-row class="mt10 pb5 border-bottom">
                <van-col span="12">月度来访数: 8</van-col>
                <van-col span="12">年度来访数: 8</van-col>
              </van-row>
              <van-row class="mt10">
                <van-col span="12">当日签约数: 8</van-col> 
                <van-col span="12">月度签约数: 8</van-col>                
              </van-row>
              <van-row class="mt10 pb5 border-bottom">
                <van-col span="12">年度签约数: 8</van-col> 
              </van-row>
              <van-row class="mt10">
                <van-col span="12">当日退房数: 8</van-col> 
                <van-col span="12">月度退房数: 8</van-col>                
              </van-row>
              <van-row class="mt10">
                <van-col span="12">年度退房数: 8</van-col> 
              </van-row>
            </div>
            <div class="info-card mt10">
              <div class="info-card-header">客户画像</div>
              <van-row class="mt10">
                <van-col span="12">新签客户: 8</van-col>
                <van-col span="12">预定客户: 8</van-col>                
              </van-row>
              <van-row class="mt10">
                <van-col span="12">未成交客户: 8</van-col>
                <van-col span="12">退订客户: 8</van-col>                
              </van-row>
            </div>
            <div class="info-card mt10">
              <div class="info-card-header">团队业绩</div>
              <van-row class="mt10 pb5 border-bottom">
                <van-col span="12">总目标: 8</van-col>             
              </van-row>
              <van-row class="mt10">
                <van-col span="12">当日销冠: 8</van-col>
                <van-col span="12">周销冠: 8</van-col>                
              </van-row>
              <van-row class="mt10 pb5 border-bottom">
                <van-col span="12">月销冠: /</van-col>                               
              </van-row>
              <van-row class="mt10">
                <van-col span="12">当日末尾: 8</van-col>
                <van-col span="12">周末尾: 8</van-col>                
              </van-row>
              <van-row class="mt10">
                <van-col span="12">月末尾: /</van-col>                               
              </van-row>
            </div>
          
            <div class="info-card mt10">
              <div class="info-card-header">当日工作重点</div>
              <van-form @submit='onSubmit'>            
                <van-field class="my-textarea"
                   v-model="form.yunying"
                   label="运营部"
                   placeholder="请输入"
                   type="textarea"
                   rows="3"
                   autosize
                   maxlength="200"
                   show-word-limit
                   required
                   autofocus />
                   <van-field class="my-textarea"
                   v-model="form.yunying"
                   label="财务部"
                   placeholder="请输入"
                   type="textarea"
                   rows="3"
                   autosize
                   maxlength="200"
                   show-word-limit
                   required
                   autofocus />
                   <van-field class="my-textarea"
                   v-model="form.yunying"
                   label="服务管理部"
                   placeholder="请输入"
                   type="textarea"
                   rows="3"
                   autosize
                   maxlength="200"
                   show-word-limit
                   required
                   autofocus />
              </van-form>
            </div>
            <div class="info-card mt10">
              <div class="info-card-header">需公司解决问题</div>
              <van-form @submit='onSubmit'>            
                <van-field class="my-textarea"
                   v-model="form.yunying"
                   placeholder="请输入"
                   type="textarea"
                   rows="3"
                   autosize
                   maxlength="200"
                   show-word-limit
                   required
                   autofocus />                   
              </van-form>
            </div>
          </div>
          <div style='margin: 16px;'>
            <van-button round block type='info' color='#d1a36f' native-type='submit'>提交</van-button>
          </div>
        </div>
      </template>
    </cyui-layout>
  </div>
</template>

<script>
import common from '@mixins/common'
export default {
  mixins: [common],
  data () {
    return {
      headerTitle: '填写日报',
      form: {}
    }
  },

  mounted () { },

  methods: {
    onSubmit(){}
  }
}

</script>
<style lang='less' scoped>
@import '../../assets/styles/themes/default/index';
@import '../../assets/styles/mixins/index';

.info-wrapper {
  .info-card {
    background: #fff;
    border: 1px solid #EFEFEF;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 10px 8px;
    padding: 10px;
    .info-card-header{
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.mt10{
  margin-top: 10px;
}

.mb5 {
  margin-bottom: 5px;
}

.pb5 {
  padding-bottom: 5px;
}

.border-bottom {
  border-bottom: 1px solid #ddd;
}
</style>