import * as types from './mutations_types'

// 假定route的meta里包含keepAlive和componentName属性
const formRecord = (vueRoute) => {
  return {
    name: vueRoute.name,
    path: vueRoute.fullPath,
    keepAlive: vueRoute.meta && vueRoute.meta.keepAlive,
    componentName: vueRoute.name ? vueRoute.name : '',
    meta: vueRoute.meta || {}
  }
}

export default {
  AddCachedView({ commit }, view) {
    commit(types.SET_CACHED_VIEW, view)
  },
  DeleteCachedView({ commit, state }, view) {
    return new Promise(resolve => {
      commit(types.DELETE_CACHED_VIEW, view)
      resolve([...state.cachedViews])
    })
  },
  // 记录 router.push
  PushRoute({ commit, state }, vueRoute) {
    let record = formRecord(vueRoute)
    let { records, index } = state
    if (index + 1 < records.length) {
      records = records.slice(0, index + 1)
    }
    records.push(record)
    commit(types.PUSH_ROUTE, {
      records,
      index: records.length - 1,
      direction: 'forward'
    })
  },
  // 记录 router.replace
  ReplaceRoute({ commit, state }, vueRoute) {
    let record = formRecord(vueRoute)
    let { records, index } = state
    if (index + 1 < records.length) {
      records = records.slice(0, index + 1)
    }
    records.pop()
    records.push(record)
    commit(types.REPLACE_ROUTE, {
      records,
      index,
      direction: 'forward'
    })
  },
  // 记录 router.pop 前进/后退
  // count是跳跃的历史记录数, >0是前进, <0是回退，path是当前的location.href
  PopRoute({ commit, state }, { count, path }) {
    let { records, index, direction } = state
    if (count) {
      direction = count > 0 ? 'forward' : 'backward'
      index += count
      index = Math.min(records.length, index)
      index = Math.max(0, index)
    } else {
      if (index > 0 && records[index - 1].path === path) {
        // 后退
        direction = 'backward'
        index -= 1
      } else if (index < records.length - 1 && records[index + 1].path === path) {
        // 前进
        direction = 'forward'
        index += 1
      }
    }
    commit(types.POP_ROUTE, {
      records,
      index,
      direction
    })
  }
}
