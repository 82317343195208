export { sexMap } from './common'

/**
 * 状态
 0 未完成
 1 已完成
 * @type {{}}
 */
export const statusMap = {
  '0': {
    colorType: 'orange',
    text: '等待工程部处理验房'
  },
  '1': {
    colorType: 'orange',
    text: '验房进行中'
  },
  '2': {
    colorType: 'orange',
    text: '验房进行中'
  },
  '3': {
    colorType: 'orange',
    text: '验房进行中'
  },
  '4': {
    colorType: 'orange',
    text: '验房已完成并继续操作'
  },
  '5': {
    colorType: 'orange',
    text: '退房已完成并通知客户在小程序签署协议'
  },
  '6': {
    colorType: 'gray',
    text: '退房已完成'
  },
  '7': {
    colorType: 'gray',
    text: '退房已完成'
  }
}
