<template>
  <cyui-field-record-info class="cyui-appointment-item"
                          :data="detailInfo"
                          :header-extra-field="headerExtraField"
                          :footer-content-field="footerContentField"
                          :footer-extra-field="footerExtraField"
                          :body-fields="bodyFields"
                          @go-detail="goDetail"></cyui-field-record-info>
</template>

<script>
  import filters from '@filters/filters'
  import { dateFilter } from '@utils/utils'
  import { sexMap, statusMap } from '@/dictionaries/appointment'

  export default {
    name: 'AppointmentListItem',
    mixins: [filters],
    components: {},
    data() {
      return {
        headerExtraField: {
          isShow: true,
          title: '预约时间：',
          key: 'appointmentTime',
          filterMethod: dateFilter
        },
        footerContentField: {
          isShow: true,
          title: '接待任务'
        },
        footerExtraField: {
          isShow: true,
          title: '',
          key: 'state',
          filterMethod: (data) => statusMap[data]['text'],
          getColorMethod: (data) => statusMap[data]['colorType']
        },
        bodyFields: [
          {
            title: '姓名：',
            key: 'name'
          },
          {
            title: '称呼：',
            key: 'sex',
            filterMethod: (data) => sexMap[data]
          },
          {
            title: '联系方式：',
            key: 'mobile'
          },
          {
            title: '预约房型：',
            key: 'roomTypeName'
          }
        ]
      }
    },
    props: {
      detailInfo: {
        type: Object,
        require: true,
        default() {
          return {}
        }
      }
    },
    computed: {},
    methods: {
      goDetail() {
        this.$emit('go-detail', this.detailInfo)
      },
      async initData() {
      }
    },
    filters: {},
    watch: {},
    created() {
      this.initData()
    },
    mounted() {
    }
  }
</script>

<style lang="less" scoped>
  @import '../../../assets/styles/themes/default/index';
  @import '../../../assets/styles/mixins/index';

  .@{css-prefix}appointment-item {
  }

</style>
