import request from '@/utils/request'
// import qs from 'qs'

/**
 * 登录-登录
 * url:'/steward/login'
 * method:POST
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
    mobile: 19952777390, // 手机号
    password: 7390654321 // 登录密码
 * }
 * @returns {*}
 */
export const loginAPI = (params) => {
  return request.post('/steward/login', params, {
    headers: { 'INTERCEPT': 'false' }
  })
}
