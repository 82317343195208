<template>
  <div class="cyui-layout-wrapper" style="background-color: #fff;">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="routeMetaTitle" @go-back="goBack">
        </cyui-header>
      </template>
      <template #default>
        <div class="cyui-menu-box">
          <div class="cyui-flexbox cyui-flexbox-wrap cyui-menu">

            <template v-for="(item, index) in menuListData" >
              <div class="cyui-menu-item" :key="index" v-if="item.isShow&&(!item.auth || iconRoles[item.auth])" @click="goModule(item)">
                <div class='cyui-menu-icon' :style='{backgroundColor: item.backgroundColor,width: "48px",height: "48px"}'>
                  <svg-icon :icon-class='item.svgName' v-if="item.size" :style="{width: item.size,height: item.size}"></svg-icon>
                  <svg-icon :icon-class='item.svgName' v-else :style='{width: "48px",height: "48px"}'></svg-icon>
                  <van-badge :content="item.notifyNum" max="99" v-if="item.notifyNum" />
                </div>
                <div class="cyui-menu-label">{{ item.name }}</div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </cyui-layout>
  </div>
</template>

<script>
  import common from '@mixins/common'
  import * as assetsApi from '@api/assets'
  import Vue from 'vue'
  import { mapGetters } from 'vuex'

  export default {
    name: 'AssetsDispatch',
    mixins: [common],
    computed: {
      ...mapGetters('user', [
        'projectInfo'
      ])
    },
    data() {
      return {
        iconRoles: {},
        menuListData: [
          {
            id: '1',
            name: '调拨申请',
            svgName: 'assets_dispatch_apply',
            backgroundColor: 'transparent',
            routeName: 'AssetsDispatchApply',
            isShow: true,
            notifyNum: 0,
          },
          {
            id: '2',
            name: '我发起的\r\n申请记录',
            svgName: 'assets_dispatch_apply_log_commit',
            backgroundColor: 'transparent',
            routeName: 'AssetsDispatchApplyLogCommit',
            isShow: true,
            notifyNum: 0,
          },
          {
            id: '3',
            name: '调拨出库',
            svgName: 'assets_dispatch_out',
            backgroundColor: 'transparent',
            routeName: 'AssetsDispatchOut',
            isShow: true,
            auth: 'transferIssue',
            notifyNum: 0,
          },
          {
            id: '4',
            name: '出库记录',
            svgName: 'assets_dispatch_out_log',
            backgroundColor: 'transparent',
            routeName: 'AssetsDispatchOutLog',
            isShow: true,
            auth: 'transferIssue',
            notifyNum: 0,
          },
          {
            id: '5',
            name: '向我发起的\r\n申请记录',
            svgName: 'assets_dispatch_apply_log_receive',
            backgroundColor: 'transparent',
            routeName: 'AssetsDispatchApplyLogReceive',
            isShow: true,
            auth: 'toBeApprovedByMe',
            notifyNum: 0,
          },
        ]
      }
    },
    async created() {
      let res = await assetsApi.getIconList();
      if (res.data.data && res.data.data.iconList) {
        for (let role of res.data.data.iconList) {
          Vue.set(this.iconRoles,role,true);
        }
      }
      let { dcProjectId } = this.projectInfo;
      let res2 = await assetsApi.getOtherProjectCount({ projectId: dcProjectId,});
      let count = res2.data.data.result.count;
      this.menuListData[4].notifyNum = count;
    },
    methods: {
      goModule(data = {}) {
        let { routeName } = data
        this.$router.push({
          name: routeName
        })
      },
    }
  }
</script>

<style lang="less" scoped>
  @import "./common.less";

  @menuPrefixCls: ~'@{css-prefix}menu';
  .@{menuPrefixCls}-box {
    width: 100%;
    padding: 20px 10px;
  }

  .@{menuPrefixCls} {
    align-items: flex-start;
    &-item {
      width: 25%;
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .@{css-prefix}menu-icon {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        line-height: 34px;
        margin: 0 auto 5px auto;
        border-radius: 100%;
        .svg-icon {
          display: block;
          width: 20px;
          height: 20px;
          fill: #FFFFFF;
          // vertical-align: middle;
        }
        .van-badge {
          position: absolute;
          right: -5px;
          top: -5px;
        }
      }

      .@{css-prefix}menu-label {
        line-height: 1.2;
        font-size: 12px;
        color: #333333;
        text-align: center;
        white-space: pre-wrap;
      }
    }
  }
</style>
