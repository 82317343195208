import Layout from '@views/layout/layout'

const paymentRouter = {
  path: '/payment',
  component: Layout,
  name: 'Payment',
  redirect: '/Payment/index',
  meta: {
    title: '支付详情',
    noCache: true,
    requireAuth: false,
    show: true
  },
  children: [
    {
      path: 'index',
      name: 'paymentIndex',
      components: {
        default: () => import('@views/payment/index')
      },
      meta: {
        title: '支付详情',
        noCache: true,
        requireAuth: false,
        show: true
      }
    },
    {
      path: 'detail',
      name: 'paymentDetail',
      components: {
        default: () => import('@views/payment/detail')
      },
      meta: {
        title: '支付详情',
        noCache: true,
        requireAuth: false,
        show: true
      }
    }
  ]
}

export default paymentRouter
