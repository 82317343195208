<template>
  <cyui-field-record-info class="cyui-clean-item"
                          :data="detailInfo"
                          :header-extra-field="headerExtraField"
                          :footer-content-field="footerContentField"
                          :footer-extra-field="footerExtraField"
                          :body-fields="bodyFields"
                          @go-detail="goDetail"></cyui-field-record-info>
</template>

<script>
  import filters from '@filters/filters'
  import { dateFilter } from '@utils/utils'
  import { statusMap } from '@/dictionaries/bill'

  export default {
    name: 'BillListItem',
    mixins: [filters],
    components: {},
    data() {
      return {
        headerExtraField: {
          isShow: true,
          title: '发起时间：',
          key: 'createTime',
          filterMethod: dateFilter
        },
        footerContentField: {
          isShow: true,
          title: ''
        },
        footerExtraField: {
          isShow: true,
          title: '',
          key: 'correctStatus',
          filterMethod: (data) => statusMap[data]['text'],
          getColorMethod: (data) => statusMap[data]['colorType']
        },
        bodyFields: [
          {
            title: '发起人：',
            key: 'createBy'
          },
          {
            title: '纠正类型：',
            key: 'correctType',
            filterMethod: (data) => this.typeFilter(data),
          },
          {
            title: '纠正截止时间：',
            key: 'correctDeadline',
            filterMethod: dateFilter
          }
        ]
      }
    },
    props: {
      detailInfo: {
        type: Object,
        require: true,
        default() {
          return {}
        }
      }
    },
    computed: {},
    methods: {
      goDetail() {
        this.$emit('go-detail', this.detailInfo)
      },
      async initData() {
      },
      typeFilter(val) {
        switch(parseInt(val)) {
          case 1: return '退房纠正'
          case 2: return '其他费用纠正'
          case 3: return '补录纠正'
          case 4: return '退回纠正'
          case 5: return '账单纠正'
          default : return '--'
        }
      }
    },
    watch: {},
    created() {
      this.initData()
    },
    mounted() {
    }
  }
</script>

<style lang="less" scoped>
  @import '../../../assets/styles/themes/default/index';
  @import '../../../assets/styles/mixins/index';

  .@{css-prefix}clean-item {
  }

</style>
