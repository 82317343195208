<template>
  <div class='cyui-layout-wrapper'>
    <cyui-layout>
      <template #header>
        <cyui-header v-if='isCustomHeader' :title='headerTitle'
                     @go-back='goBack' @on-close='handleClosePage'>
          <template #headerRight>
            <div></div>
          </template>
        </cyui-header>
      </template>
      <template #default>
        <div class='cyui-driections-navbar'>
          <van-form @submit='onSubmit'>
            <van-field readonly
                       clickable
                       label='工单编号'
                       input-align='right'
            >
              <template #input>
                <span class='detail-id' style=''>{{ form.id }}</span>
                <span style='color:#5E79EB' @click='docopy(form.id)'>复制</span>
              </template>
            </van-field>
            <van-field readonly
                       clickable
                       v-model='form.spaceName'
                       label='空间'
                       input-align='right'
            />
            <van-field readonly
                       clickable
                       v-model='form.questionCategoryName'
                       label='问题类型'
                       input-align='right'
            />
            <van-field readonly
                       clickable
                       label='图片'
                       input-align='right'>
              <template #input>
                <img class='image'
                     v-for='(item,index) in form.questionDesImgList'
                     @click='reviewImg(form.questionDesImgList)'
                     :key='index'
                     :src='item.fileUrl'
                     alt=''>
              </template>
            </van-field>
            <van-field readonly
                       clickable
                       v-model='form.expectRepairDate'
                       label='期望上门日期'
                       input-align='right'
            />
            <van-field readonly
                       clickable
                       v-model='form.contactsName'
                       label='联系人'
                       input-align='right'
            />
            <van-field readonly
                       clickable
                       v-model='form.contactsMobile'
                       label='联系方式'
                       input-align='right'
            />
            <van-field v-if='form.contactsRemark'
                       readonly
                       clickable
                       rows='3'
                       autosize
                       maxlength='100'
                       type='textarea'
                       v-model='form.contactsRemark'
                       label='备注'
                       input-align='right'
            />
            <van-field v-if='form.amountYingShou'
                       readonly
                       clickable
                       v-model='form.amountYingShou'
                       label='订单金额'
                       input-align='right'
            >
              <template #button>
                元
              </template>
            </van-field>
            <div style='margin: 16px;' v-if='form.status!=4'>
              <van-button round block type='info' color='#D8B084' native-type='submit' v-if='form.payStatus === 0' @click.stop.prevent='onPayment'>发起线上收款</van-button>
              <van-button round block type='info' color='#D8B084' native-type='submit' v-else>确认完成</van-button>
            </div>
          </van-form>
        </div>
      </template>
    </cyui-layout>
  </div>
</template>

<script>
  import common from '@mixins/common'
  import { getDetailById, confirmComplete } from '@api/maintenance'
  import { getCost } from '@api/clean'
  import { getStorage } from '@utils/storage.js'
  import { mapGetters } from 'vuex'
  import { ImagePreview, Dialog } from 'vant'
  import { isIOS } from '@utils/utils'

  export default {
    name: 'CleanDetail',
    mixins: [common],
    data() {
      return {
        headerTitle: '工单详情',
        userInfo: JSON.parse(getStorage('user_info', 'localStorage')),
        // 空间一级分类
        // 层级
        form: {
          spaceName: null,
          spaceId: null,
          questionDes: null,
          questionCategoryId: null,
          questionCategoryName: null,
          expectRepairDate: null,
          expectRepairTimeSpan: null,
          contactsName: null,
          contactsMobile: null,
          contactsRemark: null,
          questionDesImgList: []
        },
        useBack: false
      }
    },
    props: {},
    computed: {
      ...mapGetters('user', [
        'projectInfo'
      ])
    },
    created() {
      const { token, projectId, userId, userName, useBack } = this.$route.query
      if (token && projectId) {
        this.$store.commit('user/SET_TOKEN', token)
        this.$store.commit('user/SET_USER_INFO', { projectId, userId, userName })
        this.$store.commit('user/SET_PROJECT_INFO', { projectId })
      }
      if (useBack) {
        this.useBack = true
      }
      this.getDetail()
    },
    mounted() {
    },
    methods: {
      getDetail() {
        this.$store.dispatch('base/SetLoading', true)
        getDetailById({
          id: this.$route.query.id
        }).then(res => {
          const cb = res.data
          if (cb.code === 200) {
            console.log(cb.data, '详情数据')
            this.form = cb.data
          }
        }).finally(() => {
          this.$store.dispatch('base/SetLoading', false)
        })
      },
      onPayment() {
        const { id } = this.form
        this.$router.push({
          name: 'CleanPayment',
          query: {
            id
          }
        })
      },
      onSubmit() {
        Dialog.alert({
          title: '是否确认保洁工单完成？',
          showCancelButton: true
        }).then(() => {
          confirmComplete({
            id: this.$route.query.id
          }).then(res => {
            const cb = res.data
            if (cb.code === 200) {
              this.$toast('处理完成')
              if (this.useBack) {
                console.log(222)
                if (isIOS() === 'ios') {
                  window.webkit.messageHandlers.backPage.postMessage(null)
                } else {
                  if (window.commonJS) {
                    window.commonJS.backPage('')
                  }
                }
              } else {
                this.$router.push({ path: '/clean/list' })
              }
            }
          })
        }).catch(() => {})
      },
      // 复制
      docopy(text) {
        var input = document.createElement('input')
        input.value = text
        document.body.appendChild(input)
        input.select()
        document.execCommand('Copy')
        document.body.removeChild(input)
        this.$toast('复制成功')
      },
      // 图片预览
      reviewImg(imgList) {
        const result = imgList.map(item => {
          return item.url
        })
        ImagePreview(result)
      }
    },
    filters: {}
    //

  }
</script>

<style lang='less' scoped>
  .cyui-layout-wrapper {
    height: 100%;
    overflow: auto;

    .detail-id {
      width: 200px;
      margin-right: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .cyui-driections-navbar {
      .image {
        width: 100px;
        height: auto;
        margin-right: 10px;
      }

      .steps {
        margin-top: 10px;
        height: auto;
        background-color: #FFFFFF;
        padding-top: 20px;

        .steps_header {
          width: 95%;
          margin: 0 auto;
          display: flex;
          font-size: 15px;
          font-weight: 550;
          justify-content: space-between;
          align-items: center;

          .steps_header_btn {
            color: #5296F6;
            width: 60px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            font-weight: 400;
            border: 1px solid #5296F6;
            border-radius: 15px;
          }
        }

        .steps_content {
          margin-top: 20px;
        }
      }
    }
  }

  /deep/ .van-field__label {
    color: #282828;
  }

  /deep/ .van-field__control {
    color: #666666;
  }
</style>
