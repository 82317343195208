<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="routeMetaTitle" @go-back="goBack">
        </cyui-header>
      </template>
      <template #default>
        <div class="page">
          <van-cell-group class='cell-group'>
            <van-cell title="申请人" :value="applicationPerson||'-'"></van-cell>
            <van-cell title="申请部门" :value="projectName||'-'"></van-cell>
          </van-cell-group>
          <van-cell-group class='cell-group'>
            <van-cell title="领用类型" required class="picker" @click="receiveTypeShow=true">
              <template #default>
                <div class="pickerText" :class="{disabled:receiveTypeText===''}">{{receiveTypeText || '请选择'}} <van-icon name="arrow" /></div>
              </template>
            </van-cell>
            <van-field class="vtextarea" required type='textarea' label='申领原因' v-model='receiveReason' rows='3' autosize maxlength='200' placeholder='请输入'
                       input-align='left' show-word-limit
            />
          </van-cell-group>
          <div class="list">
            <div class="item" v-for="(item,index) in productList" :key="index">
              <div class="title"><span>申请明细：第{{ index+1 }}条</span><van-icon name="delete-o" @click="onDelItem(index)" /></div>
              <van-cell-group class='cell-group'>
                <van-cell title="资产属性" required is-link :value="productPropertyMap[item.productProperty]||'请选择'" @click="onProductPropertyShow(index)"></van-cell>
                <van-cell title="资产名称" required is-link :value="item.productName||'请选择'" @click="onProductListShow(index)"></van-cell>
                <van-cell title="主规格" :value="item.standard||'根据选择的资产自动带入'"></van-cell>
                <van-cell title="资产编号" value="-" v-if="item.productProperty==1||item.productProperty==2"></van-cell>
                <van-cell title="资产编号" :value="item.productNo||'根据选择的资产自动带入'" v-else></van-cell>
                <van-cell title="单位" :value="item.unit||'根据资产名称自动填充'" ></van-cell>
                <van-cell title="申领仓库" :value="item.outSpaceName||'根据资产名称自动填充'" ></van-cell>
                <van-cell title="库存数量" :value="item.stockNum||'根据资产名称自动填充'" ></van-cell>
                <van-cell title="数量" required>
                  <template #default>
                    <!--固定资产最多选1个，其他的资产最多为库存数量-->
                    <van-stepper v-model="item.receiveNum" integer :min="0" :max="item.stockNum" />
                  </template>
                </van-cell>
                <van-cell title="投放位置" required is-link @click="onInSpaceListShow(index)" v-if="item.productProperty==2||item.productProperty==3">
                  <template #default>
                    <div class="pickerText" :class="{disabled:item.inSpaceName===''}">{{item.inSpaceName || '请选择'}} </div>
                  </template>
                </van-cell>
              </van-cell-group>
            </div>
            <div class="addBtn">
              <van-button block plain type="primary" icon="add-o" @click="onAddItem">添加物资</van-button>
            </div>
          </div>
          <van-cell-group class='cell-group form'>
            <van-cell title="上传附件">
              <template #default>
                <div class="uploadPanel">
                  <van-uploader v-model="stockInImgList" :after-read='afterFileRead' />
                </div>
              </template>
            </van-cell>
          </van-cell-group>
        </div>
      </template>
      <template #footer>
        <div class="footer">
          <van-button block type="primary" size="large" @click="onSubmit">提交</van-button>
        </div>
      </template>
    </cyui-layout>
    <van-popup v-model="receiveTypeShow" position="bottom">
      <van-picker show-toolbar value-key="label" :columns="receiveTypeList" @confirm="onReceiveTypeSelect" @cancel="receiveTypeShow = false" />
    </van-popup>
    <van-popup v-model="productPropertyListShow" position="bottom">
      <van-picker ref="productPropertyList" show-toolbar value-key="label" :columns="productPropertyList" @confirm="onProductPropertySelect" @cancel="productPropertyListShow = false" />
    </van-popup>
    <van-popup v-model="inSpaceListShow" position="bottom">
      <van-search v-model="inSpaceVal" placeholder="请输入搜索关键词" />
      <van-picker ref="inSpaceList" show-toolbar value-key="spaceName" :columns="filterInSpaceList" @confirm="onInSpaceListSelect" @cancel="inSpaceListShow = false" />
    </van-popup>
    <van-popup v-model="productListShow" position="bottom" :style="{ height: '70vh' }" closeable>
      <div class="productListPanel">
        <van-search v-model="productListSearchKeyword" show-action shape="round" @search="onProductListSearch" placeholder="请输入资产名称">
          <template #action>
            <div class="searchBtn" @click="onProductListSearch">搜索</div>
          </template>
        </van-search>
        <div class="productListResult">
          <van-cell v-for="(item,index) in productListGets" :key="item.id" :label="item.spaceName" clickable @click="onProductListSelect(item)">
            <template #title>
              <div class="productListResultTitle">
                <span>{{item.productName}} {{item.productNo}}</span>
                <span>{{'库存：'+item.count}}</span>
              </div>
            </template>
          </van-cell>
          <van-empty v-if="!productListGets.length" description="无数据"></van-empty>
        </div>
        <van-pagination v-model="productListSearchPageNum" :total-items="productListSearchTotal" :items-per-page="productListSearchPageSize" @change="onProductListSearchPage"/>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import common from '@mixins/common'
  import { mapGetters } from 'vuex'
  import _ from 'lodash'
  import * as assetsApi from '@api/assets'
  import imageCompression from 'browser-image-compression'

  const defaultProduct = {
    outSpaceFullCode: '',
    outSpaceName: '',
    stockId: '',
    productId: '',
    productNo: '',
    productProperty: 0,
    productName: '',
    standard: '',
    unit: '',
    actPrice: '',
    stockNum: '',
    receiveNum: 1,
    inSpaceName: '',
    inSpaceFullCode: ''
  }

  const productPropertyList = [
    { label: "易耗品", value: 1 },
    { label: "低值品", value: 2 },
    { label: "固定资产", value: 3 }
  ]
  const productPropertyMap = _.mapValues(_.keyBy(productPropertyList, i => i.value), j => j.label)

  const receiveTypeList = [
    { label: "维修领用", value: 1 },
    { label: "保洁领用", value: 2 },
    { label: "办公使用", value: 3 },
    { label: "客户维系", value: 4 }
  ]
  const receiveTypeMap = _.mapValues(_.keyBy(receiveTypeList, i => i.value), j => j.label)

  export default {
    name: 'AssetsTake',
    mixins: [common],
    computed: {
      ...mapGetters('user', [
        'userInfo',
        'projectInfo'
      ]),
      filterInSpaceList() {
        return this.inSpaceList.filter(item => item.spaceName.includes(this.inSpaceVal))
      }
    },
    data() {
      return {
        applicationPerson: '',
        receiveType: '',
        receiveReason: '',
        projectId: '',
        projectName: '',
        inSpaceCode: '',
        inSpaceName: '',
        productList: [
          { ...defaultProduct }
        ],
        outSpaceCode: '',
        outSpaceName: '',
        outKeeper: '',
        outSpaceProjectId: '',
        remark: '',
        attachment: '',

        receiveTypeShow: false,
        receiveTypeText: '',
        receiveTypeList,

        inSpaceListShow: false,
        inSpaceVal: '',
        inSpaceList: [],
        currentProductIndex: -1,
        productPropertyListShow: false,
        productPropertyList,
        productPropertyMap,
        productListShow: false,
        productListGets: [],
        productListSearchKeyword: '',
        productListSearchPageNum: 1,
        productListSearchPageSize: 10,
        productListSearchTotal: 0,
        stockInImgList: []
      }
    },
    mounted() {
      this.applicationPerson = this.userInfo.userName
      this.projectId = this.projectInfo.dcProjectId
      this.projectName = this.projectInfo.projectName
    },
    methods: {
      onReceiveTypeSelect(item) {
        this.receiveType = item.value
        this.receiveTypeText = item.label
        this.receiveTypeShow = false
      },
      onInSpaceListShow(index) {
        let { dcProjectId } = this.projectInfo
        assetsApi.getTakeSpaceList(dcProjectId).then(res => {
          if (res.data.data) {
            this.inSpaceList = res.data.data
          }
        })
        this.inSpaceListShow = true
        this.currentProductIndex = index
        this.$nextTick(() => {
          let value = this.productList[index].inSpaceFullCode
          let selectIndex = this.inSpaceList.findIndex(i => i.spaceFullCode === value)
          this.$refs.inSpaceList.setIndexes([selectIndex])
        })
      },
      onInSpaceListSelect(item) {
        if (!item) {
          this.$toast('无投放位置')
          return
        }
        this.productList[this.currentProductIndex].inSpaceFullCode = item.spaceFullCode
        this.productList[this.currentProductIndex].inSpaceName = item.spaceFullName
        this.inSpaceListShow = false
      },
      onProductPropertyShow(index) {
        this.productPropertyListShow = true
        this.currentProductIndex = index
        this.$nextTick(() => {
          let value = this.productList[index].productProperty
          let selectIndex = this.productPropertyList.findIndex(i => i.value === value)
          this.$refs.productPropertyList.setIndexes([selectIndex])
        })
      },
      onProductPropertySelect(item) {
        if (this.productList[this.currentProductIndex].productProperty !== item.value) {
          let product = {
            productProperty: item.value
          }
          this.onProductListSelect(product)
        }
        this.productPropertyListShow = false
      },
      onProductListShow(index) {
        this.currentProductIndex = index
        let productProperty = this.productList[this.currentProductIndex].productProperty
        if (!productProperty) {
          this.$toast('请先选择资产属性')
          return
        }
        this.productListShow = true
        this.onProductListSearch()
      },
      onProductListSearch() {
        let productProperty = this.productList[this.currentProductIndex].productProperty
        if (!productProperty) {
          this.$toast('请先选择资产属性')
          return
        }
        let params = {
          productProperty: productProperty,
          productNameProductNo: this.productListSearchKeyword,
          spaceType: '仓库',
          pageNum: this.productListSearchPageNum,
          pageSize: this.productListSearchPageSize,
          projectId: this.projectId
        }
        assetsApi.getStockProductList(params).then(res => {
          this.productListGets = res.data.data
          this.productListSearchTotal = res.data.count
        })
      },
      onProductListSearchPage() {
        this.onProductListSearch()
      },
      onProductListSelect(product) {
        let item = this.productList[this.currentProductIndex]
        item.stockId = product.id
        item.productNo = product.productNo || ''
        item.productId = product.productId
        item.productName = product.productName || ''
        item.standard = product.standards || ''
        item.unit = product.unit || ''
        item.actPrice = product.actPrice || ''
        item.productProperty = product.productProperty
        item.outSpaceFullCode = product.spaceFullCode || ''
        item.outSpaceName = product.spaceName || ''
        item.stockNum = product.count || 0
        item.receiveNum = product.count && product.count > 0 ? 1 : 0
        this.productListShow = false
      },
      onAddItem() {
        this.productList.push({
          ...defaultProduct
        })
      },
      afterFileRead(item) {
        let up = this.$toast.loading({ forbidClick: true, duration: 0 })
        imageCompression(item.file, {
          maxSizeMB: 0.2,
          initialQuality: 0.7
        }).then(file => {
          assetsApi.fileUpload(item).then(res => {
            this.$toast.clear()
            const cb = res.data
            if (cb.code === 200) {
              item.fileType = 1
              item.url = cb.data.url
              item.fileUrl = cb.data.url
              item.status = 'success'
              item.message = '上传成功'
            } else {
              item.status = 'failed'
              item.message = '上传失败'
            }
          }).catch(e => {
            this.$toast.clear()
            item.status = 'failed'
            item.message = '上传失败'
          })
        }).catch(() => {
          this.$toast.clear()
          item.status = 'failed'
          item.message = '上传失败'
        })
      },
      onDelItem(index) {
        this.$dialog.confirm({
          message: '是否确认删除这条记录？'
        }).then(() => {
          this.productList.splice(index, 1)
        }).catch(() => {
          // on cancel
        })
      },
      onSubmit() {
        if (!this.receiveType) {
          this.$toast('请选择领用类型')
          return
        }
        if (!this.receiveReason) {
          this.$toast('请填写申领原因')
          return
        }
        if (this.productList.length === 0) {
          this.$toast('请添加物资')
          return
        } else if (this.productList.length) {
          for (let i = 0; i < this.productList.length; i++) {
            let item = this.productList[i]
            if (!item.productProperty) {
              this.$toast(`请选择第${i + 1}条明细的资产属性`)
              return
            }
            if (!item.stockId) {
              this.$toast(`请选择第${i + 1}条明细的资产名称`)
              return
            }
            if (!item.receiveNum) {
              this.$toast(`请选择第${i + 1}条明细的数量`)
              return
            }
            if ((item.productProperty == 2 || item.productProperty == 3) && !item.inSpaceFullCode) {
              this.$toast(`请选择第${i + 1}条明细的投放位置`)
              return
            }
          }
        }
        this.$dialog.confirm({
          message: '是否确认提交？'
        }).then(() => {
          assetsApi.addTake({
            projectId: this.projectId,
            projectName: this.projectName,
            applicationPerson: this.applicationPerson,
            receiveType: this.receiveType,
            receiveReason: this.receiveReason,
            takeDetailVos: this.productList,
            fileList: this.stockInImgList.map(i => { return { fileUrl: i.fileUrl } })
          }).then(res => {
            this.$router.replace({ name: 'AssetsTakeSuccess' })
          })
        }).catch(() => {
          // on cancel
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  @import "./common.less";
  .cyui-layout-wrapper {
    background: #F6F6F6;
  }
  .page {
    padding: 10px;
  }
  .cell-group {
    margin-bottom: 10px;
  }
  .list {
    margin-bottom: 10px;
    .item {
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 3px 0 5px;
        margin-bottom: 10px;
        font-size: 14px;
        color: #6D6868;
        /deep/ .van-icon {
          font-size: 18px;
          color: #999999;
        }
      }
    }
    .addBtn {
      margin-top: 10px;
      /deep/ .van-button {
        border-radius: 10px;
      }
    }
  }
  .productListPanel {
    padding-top: 40px;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    .productListResult {
      height: 0;
      flex: 1;
      overflow-y: auto;
      &Title {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .form {
    .uploadPanel {
      text-align: left;
    }
  }
  .footer {
    width: 100%;
    padding: 10px;
  }
</style>
