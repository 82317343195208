export { sexMap } from './common'

/**
 * 状态
 0 已创建
 1 已受理
 2 处理中
 3 已完成
 * @type {{}}
 */
export const statusMap = {
  '1': {
    colorType: 'green',
    text: '待接单'
  },
  '2': {
    colorType: 'orange',
    text: '待处理'
  },
  '3': {
    colorType: 'orange',
    text: '处理中'
  },
  '4': {
    colorType: 'gray',
    text: '已完成'
  },
  '5': {
    colorType: 'gray',
    text: '已取消'
  },
  '6': {
    colorType: 'gray',
    text: '已关闭'
  }
}
