<template>
  <van-popup v-model="showPopup"
             position="bottom"
             :lock-scroll="false"
             :lazy-render="true"
             :get-container="getContainer"
             :style="{ height: '100%' }">
    <cyui-layout style="background-color: #f8f8f8;">
      <template #header>
        <cyui-header v-if="isCustomHeader"
                     :title="`请选择${title}`"
                     @go-back="showPopup = !showPopup">
          <template #headerRight>
            <div></div>
          </template>
        </cyui-header>
        <!--<div class="cyui-search">
          <div id="search-box" class="cyui-search-box">
            <van-search :placeholder="placeholder"
                        v-model="searchKey.projectName"
                        show-action
                        clearable>
              <div slot="left-icon">
                <svg-icon icon-class="search"></svg-icon>
              </div>
              <div slot="right-icon"></div>
              <div slot="action" @click="handleFilter">搜索</div>
            </van-search>
          </div>
        </div>-->
      </template>
      <template #default>
        <div class="cyui-scroller-panel">
          <cyui-scroller
            class="wrapper"
            ref="scroller"
            :scrollbar="scrollbarConfig"
            :pullDownRefresh="pullDownRefreshConfig"
            :pullUpLoad="pullUpLoadConfig"
            :startY="parseInt(startY)"
            @pullingDown="handlePullingDown"
            @pullingUp="handlePullingUp"
          >
            <template #default>
              <div class="cyui-project-list" v-if="listData.length > 0">
                <van-cell-group>
                  <van-cell
                    v-for="(item) in listData"
                    :key="`search${item.id}`"
                    @click="handleSelectProject(item)"
                  >
                    <template #icon>
                      <template v-if="type === '1' && from">
                        <div class="cyui-project-list-thumb">
                          <svg-icon :icon-class="item.isSelected ? 'radio_checked' : 'radio'"></svg-icon>
                        </div>
                      </template>
                      <template v-else-if="type === '2' && from">
                        <div class="cyui-project-list-thumb">
                          <svg-icon :icon-class="item.isSelected ? 'checkbox_checked' : 'checkbox'"></svg-icon>
                        </div>
                      </template>
                    </template>
                    <template #title>
                      <div class="cyui-flexbox cyui-project-info">
                        <div class="cyui-flexbox-item">
                          <div class="cyui-project-name">{{item.label}}</div>
                          <div class="cyui-project-job">{{`${item.projectName || '-' }`}}
                          </div>
                        </div>
                      </div>
                    </template>
                    <template #right-icon>
                      <!--<div class="cyui-project-list-extra"
                           v-if="item.hasChildren"
                           @click.stop="goHandover(item, '2')">
                        <svg-icon icon-class="nextlevel"></svg-icon>
                        <div>下级</div>
                      </div>-->
                    </template>
                  </van-cell>
                </van-cell-group>
              </div>
            </template>
          </cyui-scroller>
        </div>
        <no-data
          v-if="listData.length === 0 && isLoading === false"
          :msg="noDataMessage"
        >
          <template #btn>
            <div
              class="cyui-btn cyui-btn-default cyui-btn-inline cyui-btn-radius"
              @click="handleRefreshPage"
              v-if="isRefreshPage"
            >
              刷新重试
            </div>
          </template>
        </no-data>
      </template>
      <template #footer>
        <div class="cyui-actionbar">
          <div class="cyui-actionbar-item cyui-actionbar-btns">
            <button
              type="button"
              class="cyui-btn cyui-btn-ghost cyui-btn-block"
              @click="showPopup = !showPopup"
            >
              取消
            </button>
            <button
              type="button"
              class="cyui-btn cyui-btn-primary cyui-btn-block"
              @click="handleConfirmSelect"
            >
              确定
            </button>
          </div>
        </div>
      </template>
    </cyui-layout>
  </van-popup>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { debounce } from 'lodash'
  import common from '@mixins/common'
  import scrollList from '@mixins/scrollList'
  import getPageList from '@mixins/getPageList'
  import selectActionMixins from '@mixins/selectAction'
  import filters from '@filters/filters'
  // import { getProjectListDataAPI } from '@api/shouwen_approve'

  export default {
    name: 'ProjectSelect',
    mixins: [common, filters, scrollList, getPageList, selectActionMixins],
    components: {},
    data() {
      return {
        searchKey: { // 查询参数
          page: 1,
          size: 20,
          projectName: ''
        },
        cartInfo: { // 购物车
          show: false,
          cacheData: [], // 缓存数据
          listData: [] // 列表数据
        },
        showPopup: false // 是否显示弹出层
      }
    },
    props: {
      show: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: ''
      },
      type: { // 判断是否选择 1、单选 2、多选
        type: [Number, String],
        default: ''
      },
      from: { // 如果是选择 需要判断来源
        type: [Number, String],
        default: ''
      },
      cacheData: { // 缓存数据
        type: Array,
        default() {
          return []
        }
      },
      placeholder: {
        type: String,
        default: '请输入关键字'
      }
    },
    computed: {
      ...mapGetters('user', [
        'projectList'
      ])
    },
    methods: {
      getContainer() {
        return document.querySelector('body')
      },
      async resetCartParams() { // 重置购物车参数
        let { cacheData } = this
        this.cartInfo.show = false
        this.cartInfo.listData = []
        this.cartInfo.cacheData = cacheData // 把人员列表页的缓存数据更新到搜索页面缓存中
      },
      updateListData() { // 更新列表数据
        let { listData, cartInfo } = this
        if (cartInfo.listData.length > 0) {
          listData.map((item, index) => {
            let currentData = cartInfo.listData.find(dept => item.id === dept.id)
            this.$set(listData[index], 'isSelected', !!currentData)
          })
        } else {
          listData.map((item, index) => {
            this.$set(listData[index], 'isSelected', false)
          })
        }
      },
      handleConfirmSelect: debounce(async function() { // 确认选择
        let { cacheData, listData } = this.cartInfo
        this.$emit('on-confirm-select', {
          cacheData,
          listData
        })
        this.showPopup = false
      }, 200),
      handleRefreshPage: debounce(async function() { // 刷新页面
        await this.initData()
      }, 200),
      handleFilter: debounce(async function() { // 查询，防抖
        await this.initData()
      }, 200),
      async handlePullingDown() { // 下拉刷新
        await this.initData({
          isPullDown: true,
          isShowLoading: false
        })
      },
      async handlePullingUp() { // 上拉加载
        this.searchKey.page++
        await this.getListData({
          isShowLoading: false
        })
        await this.resetCartParams()
        await this.getProjectSelectedData()
      },
      async handleSelectProject(data) { // 选择项目
        let { type, from } = this
        let { cacheData } = this.cartInfo
        let response = []
        if (type === '1' && from) { // 单选
          response = await this.UpdateRadioList({
            data,
            from,
            cacheData
          })
        } else if (type === '2' && from) { // 多选
          response = await this.UpdateCheckboxList({
            data,
            from,
            cacheData
          })
        }
        this.cartInfo.cacheData = response // 保存缓存数据
      },
      async getProjectSelectedData() { // 获取选中的数据
        let { type, from } = this
        let { cacheData } = this.cartInfo
        let response = []
        if (type === '1' && from) { // 单选
          response = await this.GetRadioList({
            from,
            cacheData
          })
        } else if (type === '2' && from) { // 多选
          response = await this.GetCheckboxList({
            from,
            cacheData
          })
        }
        this.cartInfo.listData = response
        return Promise.resolve(response)
      },
      async getListData({ isPullDown, isShowLoading } = {
        isPullDown: false,
        isShowLoading: true
      }) { // 获取列表数据
        try {
          this.isLoading = true
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let { projectList, listData } = this
          let resultData = projectList || []
          resultData = resultData.map(item => {
            return {
              id: item.projectId,
              parentId: '',
              label: item.cityName,
              projectName: item.projectName,
              hasChildren: false,
              children: [],
              dcProjectId: item.dcProjectId,
              isHospital: item.isHospital
            }
          })
          let hasMore = false
          this.listData = !isPullDown
            ? [...listData, ...resultData]
            : [...resultData]
          if (!hasMore) {
            this.isLastPage = true
            // this.$toast('没有更多数据了！')
          }
          await this.handleCallback({
            isError: false,
            hasMore
          })
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '获取失败!'
          })
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async initData({ isPullDown, isShowLoading } = {
        isPullDown: false,
        isShowLoading: true
      }) {
        await this.resetParams({ isPullDown })
        // this.noDataMessage = '暂无数据哦～'
        await this.getListData({
          isPullDown,
          isShowLoading
        })
        await this.resetCartParams()
        await this.getProjectSelectedData()
        // this.updateListData()
      }
    },
    watch: {
      show: {
        handler: function(newVal, oldVal) {
          if (newVal !== this.showPopup) {
            this.showPopup = newVal
          }
        },
        immediate: true
      },
      showPopup: {
        handler: async function(newVal, oldVal) {
          if (newVal) {
            this.searchKey.projectName = ''
            await this.initData()
          }
          this.$emit('update:show', newVal)
        }
      },
      'cartInfo.cacheData': {
        handler: function(newVal, oldVal) {
          // console.log(newVal)
          this.getProjectSelectedData()
        },
        deep: true
        // immediate: true
      },
      'cartInfo.listData': {
        handler: function(newVal, oldVal) {
          if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
            this.updateListData()
          }
        },
        deep: true
        // immediate: true
      }
    },
    created() {
    },
    mounted() {
      // this.initData()
    }
  }
</script>

<style lang="less" scoped>
  @import '../../../assets/styles/themes/default/index';
  @import '../../../assets/styles/mixins/index';

  .@{css-prefix}scroller-panel {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    & /deep/ .pulldown-wrapper {
      top: 5px !important;
    }
  }

  .@{css-prefix}search {
    position: relative;
    padding: 10px 15px 10px 15px;
    background-color: #fff;
    z-index: 100;

    &::before {
      z-index: 20 !important;
    }

    &-box {
      position: relative;
      top: -1px;
      z-index: 10;

      & /deep/ .van-search {
        position: relative;
        padding: 0 0 0 0;
        background-color: #fff;
        z-index: 6000;

        .van-search__content {
          padding-left: 12px;
          border: 1PX solid #E6E6E6;
          border-radius: 15px;
          background-color: #FFFFFF;

          .van-cell {
            padding: 3px 0 3px 0;
          }

          .van-field__left-icon {
            margin-right: 5px;

            .svg-icon {
              position: relative;
              top: 5px;
              display: block;
              width: 14px;
              height: 14px;
              fill: #B9B9B9;
            }
          }
        }

        .van-search__action {
          font-size: 14px;
          color: #333333;

          &:active {
            background-color: #fff;
          }
        }
      }

      & /deep/ .van-popup {
        position: absolute;
        top: 54px;
        width: 100%;
        max-height: 300px;
      }
    }
  }

  // 列表
  .@{css-prefix}project-list {
    padding: 0 0 10px;

    & /deep/ .van-cell-group {
      padding: 10px 0;

      &.van-hairline--top-bottom,
      &.van-hairline-unset--top-bottom {
        &::after {
          display: none;
        }
      }

      .van-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 48px;
        padding: 0 0 0 20px;

        .van-cell__title {
          font-size: 15px;
          color: #333333;
        }

        &::after {
          display: none;
        }

        &:not(:first-child) {
          .hairline('top', 1px, #F6F6F6);

          &::before {
            width: auto !important;
            left: 50px !important;
            right: 20px !important;
            z-index: 2;
          }
        }
      }
    }

    &-thumb {
      margin-right: 10px;

      > .svg-icon {
        display: block;
        width: 18px;
        height: 18px;
      }
    }

    &-extra {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      min-height: 48px;
      padding: 0 20px 0 15px;
      font-size: 13px;
      color: #D19C70;

      .hairline('left', 1px, #F6F6F6);

      &::before {
        height: auto !important;
        top: 12px !important;
        bottom: 12px !important;
      }

      .svg-icon {
        display: block;
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }
    }
  }

  .@{css-prefix}actionbar {
    position: relative;
    height: 64px;
    background-color: #FFFFFF;
    .hairline('top', 1px, #F6F6F6);

    .@{css-prefix}actionbar-btns {
      padding: 15px 30px;

      .@{css-prefix}btn {
        height: 34px;
        line-height: 34px;
        font-size: 13px;
        background-color: #FFFFFF;
        border: 1px solid #D19C70;
        border-radius: 17px;
        color: #D19C70;

        &.@{css-prefix}btn-primary {
          color: #FFFFFF;
          background-color: #D19C70;
        }

        &.@{css-prefix}btn-ghost {
        }
      }

      .@{css-prefix}btn + .@{css-prefix}btn {
        margin-left: 30px;
      }
    }
  }

  .@{css-prefix}project-info {
    padding: 10px 0;

    .@{css-prefix}project-thumb {
      margin-right: 10px;

      > img {
        display: block;
        width: 36px;
        height: 36px;
      }
    }

    .@{css-prefix}project-name {
      line-height: 20px;
      font-size: 15px;
      color: #333333;
    }

    .@{css-prefix}project-job {
      line-height: 20px;
      font-size: 13px;
      color: #B9B9B9;
    }
  }
</style>
