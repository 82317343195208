<template>
  <div class='cyui-layout-wrapper'>
    <cyui-layout>
      <template #header>
        <cyui-header v-if='isCustomHeader'
                     :title='headerTitle'
                     @go-back='goBack'
                     @on-close='handleClosePage'>
          <template #headerRight>
            <div></div>
          </template>
        </cyui-header>
        <div class='cyui-clean-navbar'>
          <cyui-navbar
            v-model='categoryInfo.value'
            :swipe-threshold='2'
            show-title-number
            :list-data='categoryInfo.listData'
            @change='handleTabChange'
          >
          </cyui-navbar>
        </div>
        <!-- 保洁类型 -->
        <div class='cyui-clean-search'>
          <!-- 保洁类型 -->
          <van-field v-model='searchKey.questionCategoryName'
                     clickable
                     input-align='right'
                     label='保洁类型'
                     name='picker'
                     placeholder='请选择'
                     readonly
                     right-icon='arrow'
                     @click='onShowQuestion'
          />
          <!-- 保洁类型 -->
          <van-popup v-model='showQuestion' position='bottom'>
            <van-picker
              :columns='cleanTypeList'
              show-toolbar
              title='保洁类型'
              @cancel='showQuestion=false'
              @confirm='questionChange'
            />
          </van-popup>
        </div>
      </template>
      <template #default>
        <div class='cyui-scroller-panel'>
          <cyui-scroller
            class='wrapper'
            ref='scroller'
            :scrollbar='scrollbarConfig'
            :pullDownRefresh='pullDownRefreshConfig'
            :pullUpLoad='pullUpLoadConfig'
            :startY='parseInt(startY)'
            @pullingDown='handlePullingDown'
            @pullingUp='handlePullingUp'
          >
            <template #default>
              <!-- list -->
              <div class='cyui-clean-list' v-if='listData.length'>
                <clean-list-item v-for='(item, index) in listData'
                                 :key='index'
                                 :detail-info='item'
                                 @go-detail='goDetail(item)'></clean-list-item>
              </div>
            </template>
          </cyui-scroller>
        </div>
        <no-data
          :msg='noDataMessage'
          v-if='listData.length === 0 && isLoading === false'
        >
          <template #btn>
            <div
              class='cyui-btn cyui-btn-default cyui-btn-inline cyui-btn-radius'
              @click='handleRefreshPage'
              v-if='isRefreshPage'
            >
              刷新重试
            </div>
          </template>
        </no-data>
      </template>
      <template #footer>
        <div class='cyui-actionbar'>
          <div class='cyui-actionbar-item cyui-actionbar-btns'>
            <button type='button'
                    class='cyui-btn cyui-btn-primary cyui-btn-block'
                    @click='handleClick_goClean'>
              发起保洁
            </button>
          </div>
        </div>
      </template>
    </cyui-layout>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import common from '@mixins/common'
  import scrollList from '@mixins/scrollList'
  import getPageList from '@mixins/getPageList'
  import filters from '@filters/filters'
  import { CleanListItem } from './components'
  import { getListAPI, getPageOfManager } from '@api/maintenance'

  const categoryListData = [
    {
      id: '',
      label: '全部',
      number: 0,
      children: []
    },
    // {
    //   id: '-2',
    //   label: '待支付',
    //   number: 0,
    //   children: []
    // },
    {
      id: '-1',
      label: '进行中',
      number: 0,
      children: []
    },
    {
      id: '4',
      label: '已完成',
      number: 0,
      children: []
    }
    /* {
      id: '3',
      label: '已完成',
      number: 0,
      children: []
    } */
  ]

  export default {
    name: 'CleanList',
    mixins: [common, filters, scrollList, getPageList],
    components: {
      CleanListItem
    },
    data() {
      return {
        headerTitle: '保洁记录',
        categoryInfo: { // 分类数据
          value: '', //
          listData: categoryListData
        },
        showQuestion: false, // 保洁类型弹窗
        cleanTypeList: [], // 保洁类型列表
        initParams: { // 初始化参数
          page: 1,
          size: 20
        },
        searchKey: {} // 搜索参数
      }
    },
    props: {
      status: {
        type: [Number, String],
        default: ''
      }
    },
    computed: {
      ...mapGetters('user', [
        'projectInfo'
      ])
    },
    methods: {
      // 进入详情
      goDetail(data) {
        let { id } = data
        this.$router.push({
          name: 'CleanDetail',
          query: {
            id
          }
        })
      },
      handleClick_goClean() {
        this.$router.push({
          name: 'CleanSend'
        })
      },
      handleTabChange({ name }) {
        if (name !== this.status) {
          this.$router.replace({
            name: 'CleanList',
            query: {
              status: name
            }
          })
        }
      },
      questionChange(e) {
        this.searchKey.questionCategoryId = e.value
        this.searchKey.questionCategoryName = e.text
        this.showQuestion = false
        this.resetParams()
        this.getListData()
      },
      onShowQuestion() {
        this.showQuestion = true
        let { projectId } = this.projectInfo
        getPageOfManager({ projectId }).then(res => {
          if (res.data.code === 200) {
            let listData = [{ value: '', text: '全部' }, ...res.data.result.map(item => {
              return {
                value: item.id,
                text: item.name
              }
            })]
            this.cleanTypeList = listData
          }
        })
      },
      async getListData({
                          isPullDown,
                          isShowLoading
                        } = {
        isPullDown: false,
        isShowLoading: true
      }) { // 获取列表数据
        try {
          this.isLoading = true
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let {
            status,
            listData
          } = this
          let {
            page,
            size,
            questionCategoryName,
            questionCategoryId
          } = this.searchKey
          let { projectId } = this.projectInfo
          let response = await getListAPI({
            pageNum: page,
            pageSize: size,
            type: 2,
            projectId: projectId,
            status,
            questionCategoryName: questionCategoryId ? questionCategoryName : '',
            questionCategoryId
          })
          let result = response.data
          // console.log(result)
          if (`${result.code}` == 200) { // 请求成功
            let resultData = result.data || []
            // let hasMore = resultData.length > 0
            let hasMore = page < result.pages
            this.listData = !isPullDown
              ? [...listData, ...resultData]
              : [...resultData]
            if (!hasMore) {
              this.isLastPage = true
              // this.$toast('没有更多数据了！')
            }
            await this.handleCallback({
              isError: false,
              hasMore
            })
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '获取列表失败!'
            })
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '获取列表失败!'
          })
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async initData() {
        await this.resetParams()
        await this.getListData()
      }
    },
    filters: {},
    watch: {
      initParams: {
        handler: function(newVal, oldVal) {
          if (JSON.stringify(newVal) !== JSON.stringify(this.searchKey)) {
            this.searchKey = JSON.parse(JSON.stringify(newVal))
          }
        },
        deep: true,
        immediate: true
      },
      status: {
        handler: function(newVal, oldVal) {
          this.searchKey = JSON.parse(JSON.stringify(this.initParams))
          this.searchKey.questionCategoryId = ''
          this.searchKey.questionCategoryName = '全部'
          this.categoryInfo.value = newVal
          this.initData()
        },
        immediate: true
      }
    },
    created() {
      const { token, projectId, dcStewardId, nickName, userId, userName } = this.$route.query
      if (token && projectId) {
        this.$store.commit('user/SET_TOKEN', token)
        this.$store.commit('user/SET_USER_INFO', { projectId, dcStewardId, nickName, userId, userName })
        this.$store.commit('user/SET_PROJECT_INFO', { projectId })
      }
    },
    mounted() {
    },
    beforeRouteEnter(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    },
    beforeRouteUpdate(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    }
  }
</script>

<style lang='less' scoped>
  @import '../../assets/styles/themes/default/index';
  @import '../../assets/styles/mixins/index';

  @cleanPrefixCls: ~'@{css-prefix}clean';

  .@{css-prefix}scroller-panel {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    & /deep/ .pulldown-wrapper {
      top: 5px !important;
    }
  }

  .@{cleanPrefixCls} {
    &-navbar {
      padding: 0 5px;
      background-color: #FFFFFF;

      & /deep/ .cyui-navbar {
        background-color: #FFFFFF;

        &-body {
          .van-tabs {
            &.van-tabs--line {
              .van-tabs__wrap {
                .van-tab {
                  flex: none;
                  padding: 0 10px;

                  &.van-tab--active {
                    .@{css-prefix}navbar-title {
                      font-weight: normal;
                      color: #FFFFFF;
                      border-color: #D19C70;
                      background-color: #D19C70;

                      &-number {
                        background: #e5563f;
                      }
                    }
                  }
                }
              }
            }

            .van-tabs__nav--line {
              .van-tabs__line {
                display: none;
              }
            }
          }
        }

        &-title {
          position: relative;
          display: block;
          min-width: 74px;
          height: 26px;
          padding: 0 12px;
          line-height: 26px;
          font-size: 12px;
          color: #b9b9b9;
          text-align: center;
          border-radius: 14px;
          border: 1PX solid #CFCFCF;
          background-color: #F1F1F1;

          &-number {
            position: absolute;
            top: 2px;
            right: 0;
            transform: translate3d(50%, -50%, 0);
            min-width: 18px;
            height: 18px;
            padding: 0 5px;
            line-height: 18px;
            border-radius: 18px;
            background: #e5563f;
          }
        }
      }
    }

    &-list {
      padding: 10px 10px 0;
    }
  }

  .@{css-prefix}actionbar {
    position: relative;
    height: 65px;
    background-color: transparent;

    .@{css-prefix}actionbar-btns {
      padding: 10px 43px 15px;

      .@{css-prefix}btn {
        height: 40px;
        line-height: 40px;
        background-color: #ffffff;
        font-size: 15px;
        border: 1px solid #d1a36f;
        border-radius: 20px;
        color: #d1a36f;

        &.@{css-prefix}btn-primary {
          color: #ffffff;
          background-color: #d1a36f;
        }

        &.@{css-prefix}btn-ghost {
        }
      }

      .@{css-prefix}btn + .@{css-prefix}btn {
        margin-left: 30px;
      }
    }
  }

</style>
