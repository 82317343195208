<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="headerTitle"
          @go-back="goBack" @on-close="handleClosePage">
          <template #headerRight>
            <div></div>
          </template>
        </cyui-header>
        <div class="cyui-driections-navbar">
          <div class="system-title">
            <van-icon name="question" size="30" />
            <span>常见问题</span>
          </div>
        </div>
      </template>
      <template #default>
        <div>
          <div class="drictions-list" v-for="item in shouceList"
            @click="goDetail(item)">
            <div class="drictions-list-row">
              <span>{{item.question}}</span>
              <van-icon name="arrow" />
            </div>
            <div class="van-hairline--bottom"></div>
          </div>
        </div>
      </template>
    </cyui-layout>
  </div>
</template>

<script>
import common from '@mixins/common'
import { getquestionCategoryList } from '@api/directions'

export default {
  name: 'DirectionsSystem',
  mixins: [common],
  data () {
    return {
      headerTitle: '',
      shouceList: []
    }
  },
  props: {},

  methods: {
    async handleCallback ({ isError, errorMessage = undefined }) { // 回调处理
      if (isError) {
        this.$toast(errorMessage || '获取失败!')
        /* if (errorMessage && errorMessage.includes('timeout')) {
          // 判断是否是网络请求超时
          this.isRefreshPage = true
          this.noDataMessage = '网络请求超时'
        } else if (errorMessage && errorMessage.includes('502')) {
          // 判断是否是服务器错误
          this.isRefreshPage = true
          this.noDataMessage = '服务器错误，502错误'
        } */
      }
    },
    async getDetailData ({ isShowLoading } = { isShowLoading: true }) { // 获取详情数据
      try {
        this.isLoading = true
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
        let categoryId = this.$route.query.id
        this.headerTitle = this.$route.query.name
        // console.log(this.$route.query.id, '我是傻逼');
        // console.log(id);
        let response = await getquestionCategoryList({ categoryId })
        let result = response.data
        // console.log(result)
        if (`${result.code}` === '10000') { // 请求成功
          let resultData = result.data || []
          this.shouceList = resultData
          await this.handleCallback({
            isError: false
          })
        } else {
          // 请求失败
          await this.handleCallback({
            isError: true,
            errorMessage: result.msg || '获取列表失败!'
          })
        }
      } catch (error) {
        // console.log(error)
        await this.handleCallback({
          isError: true,
          errorMessage: error.message || '获取列表失败!'
        })
      } finally {
        this.isLoading = false
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
      }
    },
    async initData () {
      await this.getDetailData()
    },
    // 进入详情
    goDetail (data) {
      let { id, name } = data
      this.$router.push({
        name: 'DirectionsDetail',
        query: {
          id
        }
      })
    }
    //
    //
  },
  filters: {},
  //
  created () {
  },
  mounted () {
    this.initData()
  },
  beforeRouteEnter (to, from, next) {
    // console.log(to)
    // console.log(from)
    next()
  },
  beforeRouteUpdate (to, from, next) {
    // console.log(to)
    // console.log(from)
    next()
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/styles/themes/default/index';
@import '../../assets/styles/mixins/index';
.cyui-layout-wrapper {
  .system-title {
    width: 95%;
    height: 60px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    span {
      font-size: 14px;
    }
  }
  .drictions-list {
    .drictions-list-row {
      min-height: 40px;
      width: 90%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 14px;
      }
    }
  }
  .cyui-actionbar {
    .cyui-btn-primary {
      background-color: #96afd5;
    }
  }
}
</style>
