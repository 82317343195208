<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="routeMetaTitle" @go-back="goBack">
        </cyui-header>
      </template>
      <template #default>
        <div class="page">
          <van-cell-group class='cell-group'>
            <van-cell title="盘点区域" :value="spaceName||'-'"></van-cell>
          </van-cell-group>
          <div class="list">
            <div class="item" v-for="(item,index) in productList" :key="index">
              <div class="name">{{item.productName||'-'}}</div>
              <div class="infos">
                <div class="img"><van-image width="100%" height="100%" lazy-load :src="item.pictureUrl"></van-image></div>
                <div class="attrs">
                  <van-row>
                    <van-col span="15">主规格：{{item.standard||'-'}}</van-col>
                    <van-col span="9">单位：{{item.unit||'-'}}</van-col>
                    <van-col span="24">库存数量：{{item.stockNum||'-'}}</van-col>
                    <van-col span="24">资产编号：{{item.productNo||'-'}}</van-col>
                    <van-col span="24"><div class="stepper">盘点数量：<van-stepper disabled v-model="item.checkNum" :min="0" integer /></div></van-col>
                  </van-row>
                </div>
              </div>
              <img class="isSave" src="./images/isSave.png" v-if="item.isSave">
            </div>
          </div>
          <div class="notCorrect" v-if="productList2.length">
            <div class="title">以下资产的盘点库存与实际库存不符</div>
            <div class="list2">
              <table>
                <tr class="head">
                  <td>资产名称</td>
                  <td>主规格</td>
                  <td>资产编号</td>
                  <td>原因</td>
                </tr>
                <tr v-for="(item,index) in productList2" :key="index">
                  <td>{{ item.productName || '-' }}</td>
                  <td>{{ item.standard || '-' }}</td>
                  <td>{{ item.productNo || '-' }}</td>
                  <td>{{ item.reason || '-' }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </template>
    </cyui-layout>
  </div>
</template>

<script>
  import common from '@mixins/common'
  import { mapGetters } from 'vuex'
  import * as assetsApi from '@api/assets'

  export default {
    name: 'AssetsCheckIndex',
    mixins: [common],
    computed: {
      ...mapGetters('user', [
        'userInfo',
        'projectInfo',
      ])
    },
    data() {
      return {
        projectId: '',
        projectName: '',
        spaceFullCode:'',
        spaceName:'',
        spaceType:'',
        checkId: '',
        productList: [],
        productList2: [], // 数量异常的资产
      }
    },
    mounted() {
      this.projectId = this.projectInfo.dcProjectId;
      this.projectName = this.projectInfo.projectName;
      this.id = this.$route.query.id;
      this.getData();
    },
    methods: {
      getData(){
        assetsApi.getCheckDetail(this.id).then(res=>{
          let item = res.data.data;
          this.spaceName = item.spaceName;
          if (res.data.data.detailList) {

            let detailList = [];
            let detailList2 = [];
            for(let detail of item.detailList) {
              detail.oldCheckNum = detail.checkNum;
              detailList.push(detail)
              if (!detail.isCorrent) {
                detailList2.push(detail)
              }
            }
            this.productList = detailList;
            this.productList2 = detailList2;
          }
        })
      },

    }
  }
</script>

<style lang="less" scoped>
  @import "./common.less";
  .cyui-layout-wrapper {
    background: #F6F6F6;
  }
  .page {
    padding: 10px;
  }
  .cell-group {
    margin-bottom: 10px;
  }
  .list {
    margin-bottom: 10px;
    .item {
      padding: 15px;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0px 4px 10px rgba(240,237,237,0.5);
      margin-bottom: 15px;
      position: relative;
      .name {
        font-size: 15px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 15px;
      }
      .infos {
        display: flex;
        .img {
          width: 140px;
          height: 105px;
          border: 1px solid #CCCCCC;
          border-radius: 6px;
          overflow: hidden;
          margin-right: 20px;
        }
        .attrs {
          width: 0;
          flex: 1;
          font-size: 10px;
          font-weight: 600;
          line-height: 25px;
          color: #333333;
          .stepper {
            color: @brand-primary2;
            display: flex;
            align-items: center;
          }
        }
        /deep/ .van-stepper__input:disabled {
          color: #333;
          -webkit-text-fill-color: #333;
        }
      }
      .actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 10px;
        /deep/ .van-button {
          width: 60px;
          height: 60px;
          background: @brand-round-btn-bg;
          box-shadow: 0px 4px 10px rgba(0,0,0,0.16);
          border-radius: 50%;
        }
      }
      .isSave {
        position: absolute;
        top: 0;
        right: 0;
        width: 58px;
        height: 58px;
      }
    }

  }
  .notCorrect {
    .title {
      font-size: 14px;
      font-weight: 400;
      color: #6D6868;
      margin-bottom: 15px;
    }
    .list2  {
      background: #fff;
      border-radius: 6px;
      table {
        tr {
          td {
            font-size: 14px;
            color: #333333;
            padding: 10px 5px;
            &:first-child {
              padding-left: 10px;
            }
            &:last-child {
              padding-right: 10px;
            }
          }
          &.head td{
            white-space: nowrap;
          }
        }
      }
    }
  }
</style>
