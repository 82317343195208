<template>
  <div>日报详情</div>
</template>

<script>
export default {
  data () {
    return { }
  },

  mounted() { },

  methods: { }
}

</script>
<style lang='less' scoped>
</style>