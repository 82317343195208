<template>
  <div class="cyui-layout-wrapper" @click='showRoom=false'>
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="headerTitle"
          @go-back="goBack" @on-close="handleClosePage">
          <template #headerRight>
            <div></div>
          </template>
        </cyui-header>
      </template>
      <template #default>
        <div class="projectName"><van-icon name="location-o" size="22px" color="#D2A36D" />{{ projectInfo.projectName }}</div>
        <van-cell-group inset class='cell-group form'>
          <van-field v-model="bookerName" label="姓名" placeholder="请输入TA的姓名" required />
          <van-cell title="性别">
            <van-radio-group v-model="bookerSex" icon-size='22px' checked-color="#CEA370" direction="horizontal">
              <van-radio name="男" >男</van-radio>
              <van-radio name="女">女</van-radio>
              <van-radio name="未知">未知</van-radio>
            </van-radio-group>
          </van-cell>
          <van-field v-model="bookerPhone" label="手机号码" placeholder="请输入TA的手机号" :disabled="!!id" :required="!id"/>
          <van-cell title="来源渠道" :required="!id">
            <template #default>
              <div v-if="!id" class="pickerText" :class="{disabled:source===''}" @click="show2 = true">{{source || '请选择'}}  <van-icon name="arrow-down" /></div>
              <div v-else class="pickerText disabled">{{source}}</div>
            </template>
          </van-cell>
          <van-field v-model="expectLayouts" label="意向户型/面积/朝向" placeholder="三室一厅一厨一卫/65㎡/朝南" />
          <van-field v-model="bookerProfession" label="职业" placeholder="请输入职业" />
          <van-field v-model="remark" label="备注" placeholder="其余需要补充的信息"  type="textarea" rows="2" autosize show-word-limit maxlength="100"/>
        </van-cell-group>
      </template>
      <template #footer>
        <div class="cyui-actionbar">
          <div class="cyui-actionbar-btn">
            <van-button block type="primary" size="large" @click="handleApply">提交</van-button>
          </div>
        </div>
      </template>
    </cyui-layout>
    <van-action-sheet v-model="show2">
      <van-picker title="来源渠道" show-toolbar  :columns="sourceList" @confirm="onConfirmSource" @cancel="show2 = false"></van-picker>
    </van-action-sheet>
  </div>
</template>

<script>
import common from '@mixins/common'
import filters from '@filters/filters'
import { formatPx2Rem } from '@utils/index'
import { isEmpty, dateFilter } from '@utils/utils'
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'
import { updateBookAPI,addBookAPI } from '@api/appointment'

export default {
  name: 'AppointmentApply',
  mixins: [common, filters],
  components: {},
  data () {
    return {
      headerTitle: '接待登记',
      isLoading: false, // 是否在加载数据
      isRefreshPage: false, // 是否需要刷新页面
      noDataMessage: '', // 列表为空时的提示文案
      detailInfo: {}, // 详情信息
      show1: false,
      minDate1: new Date(),
      maxDate1: new Date(2099, 12, 31),
      currentDate1: new Date(),
      show2: false,
      sourceList: '同城58,安居客,中介,贝壳,公司协议客户,自然来访,小猪短租,爱彼迎,途家,美团民宿,老客户介绍,品牌公寓,携程,木鸟民宿,美团酒店,去哪儿网,艺龙酒店,飞猪,途牛旅游网'.split(','),
      dateIconUrl: require('../../assets/images/date.png'),
      bookerName: '',
      bookerSex: '',
      bookerPhone: '',
      bookerProfession: '',
      expectLayouts: '',
      source: '',
      remark: '',
      bookVisitTime: '',
    }
  },
  props: {
    id: {
      type: [Number, String],
      default: ''
    }
  },
  computed: {
    ...mapGetters('user', [
      'projectInfo'
    ])
  },
  methods: {
    formatPx2Rem,
    // 日期格式化
    dateFilter,
    // 空字段过滤
    fieldEmptyFilter (data, returnEmpty = '无') {
      return !isEmpty(data) ? data : returnEmpty
    },
    // 字母数字过滤
    formatterField (value) {
      return value.replace(/[^\u4E00-\u9FA5]/g, '');
    },
    // 只能输入数字字母
    formatterField2 (value) {
      return value.replace(/[^\w\.\/]/ig, '');
    },
    hourFilter (type, options) {
      if (type === 'hour') {
        // 验房时间是早9晚6
        return options.filter((option) => ['09', '10', '11', '12', '13', '14', '15', '16', '17', '18'].includes(option))
      }
      return options
    },
    onConfirmSource(val) {
      this.source = val;
      this.show2 = false;
    },
    handleApply: debounce(function () {
      if (!this.bookerName) {
        this.$toast('请输入姓名')
        return
      }
      if (!this.id) {
        if (!this.bookerPhone) {
          this.$toast('请输入手机号码')
          return
        }
        if (!this.source) {
          this.$toast('请选择来源渠道')
          return
        }
      }

      this.$dialog.confirm({
        title: '提示',
        message: '是否确认提交？',
        confirmButtonColor: '#D8B084'
      }).then(async () => {
        // on confirm
        await this.submitApplyData()
      }).catch(() => {
        // on cancel
      })
    }, 200),
    async submitApplyData ({ isShowLoading } = { isShowLoading: true }) { // 完成接待
      try {
        this.isLoading = true
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
        let response;
        if (this.id) {
          response = await updateBookAPI({
            id:this.id,
            remark:this.remark,
            bookerSex:this.bookerSex,
            bookerName:this.bookerName,
            expectLayouts:this.expectLayouts,
            bookerProfession:this.bookerProfession,
          })
        } else {
          response = await addBookAPI({
            projectId:this.projectInfo.projectId,
            bookerName:this.bookerName,
            bookerSex:this.bookerSex,
            bookerPhone:this.bookerPhone,
            expectLayouts:this.expectLayouts,
            remark:this.remark,
            source:this.source,
            bookerProfession:this.bookerProfession,
          })
        }
        let result = response.data
        // console.log(result)
        if (`${result.code}` === '10000') { // 请求成功
          // 接口请求处理成功之后回跳
          if (this.id) {
            this.$store.dispatch('appointment/UpdateAppointmentDetail', {
              id:this.id,
              remark:this.remark,
              bookerSex:this.bookerSex,
              bookerName:this.bookerName,
              expectLayouts:this.expectLayouts,
              bookerProfession:this.bookerProfession,
            })
          }
          this.$toast({
            message: '提交成功！',
            onClose: () => {
              this.$router.go(-1)
            }
          })
        } else {
          // 请求失败
          await this.handleCallback({
            isError: true,
            errorMessage: result.msg || '失败!'
          })
        }
      } catch (error) {
        // console.log(error)
        await this.handleCallback({
          isError: true,
          errorMessage: error.message || '失败!'
        })
      } finally {
        this.isLoading = false
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
      }
    },
    async handleCallback ({ isError, errorMessage = undefined }) { // 回调处理
      if (isError) {
      }
    },
    async getDetailData({ isShowLoading } = { isShowLoading: true }) { // 获取详情数据
      try {
        this.isLoading = true
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
        let { id } = this
        let response = await this.$store.dispatch('appointment/GetAppointmentDetail', {
          id
        })
        // let result = response.data
        let result = response
        // console.log(result)
        if (`${result.code}` === '10000') { // 请求成功
          let resultData = result.data || {}
          this.bookerName = resultData.bookerName || '';
          this.bookerSex = resultData.bookerSex || '';
          this.bookerPhone = resultData.bookerPhone || '';
          this.bookerProfession = resultData.bookerProfession || '';
          this.expectLayouts = resultData.expectLayouts || '';
          this.source = resultData.source || '';
          this.remark = resultData.remark || '';
        }
      } catch (error) {

      } finally {
        this.isLoading = false
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
      }
    },
    async initData () {
      await this.getDetailData()
    }
  },
  filters: {},
  watch: {},
  created () {
  },
  mounted () {
    this.initData()
  },
  beforeRouteEnter (to, from, next) {
    // console.log(to)
    // console.log(from)
    next()
  },
  beforeRouteUpdate (to, from, next) {
    // console.log(to)
    // console.log(from)
    next()
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/styles/themes/default/index';
@import '../../assets/styles/mixins/index';

@sectionPrefixCls: ~'@{css-prefix}section';

.@{css-prefix}detail-header {
  width: 100%;
  height: 100px;
  background-color: #d8b084;

  &-content {
    padding-left: 18px;
    line-height: 24px;
    color: #ffffff;

    .@{css-prefix}detail-header-title {
      margin-bottom: 10px;
      font-size: 16px;
    }

    .@{css-prefix}detail-header-brief {
      font-size: 12px;

      > span {
        &:not(:last-of-type) {
          margin-right: 10px;
        }
      }
    }
  }

  &-extra {
    padding-right: 30px;
    line-height: 24px;
    font-size: 16px;
    color: #ffffff;

    .@{css-prefix}detail-header-status {
      position: relative;

      .svg-icon {
        display: block;
        width: 54px;
        height: 54px;
        vertical-align: middle;
      }
    }
  }
}

.@{sectionPrefixCls}-client {
  padding: 0 10px;
  background-color: #ffffff;
}

.@{css-prefix}actionbar {
  position: relative;
  height: 65px;
  background-color: transparent;

  .@{css-prefix}actionbar-btn {
    width: 100%;
    padding: 15px;
  }
}

.no-data {
  position: relative;
  left: 0;
  top: 0;
  transform: none;
}

.cell-group {
  margin-top: 15px;
}
.search-icon {
  font-size: 16px;
  line-height: inherit;
}
.form {
  /deep/ .van-cell {
    flex-direction: column;
    &__title {
      margin-bottom: 10px;
      width: auto;
    }
  }
  /deep/ .van-field--disabled .van-field__label {
    color: #323233;
  }
  .pickerText {
    width: 100%;
    text-align: left;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.disabled {
      color: #c8c9cc;
    }
  }
}
.van-checkbox {
  padding: 5px 0;
}
& /deep/ .van-checkbox__icon--square .van-icon {
  border-color: #e4c477 !important;
  border-radius: 5px !important;
  background-color: #ffffff !important;
}
& /deep/ .van-checkbox__icon--checked .van-icon {
  color: #e4c477;
}
.other {
  border: 1px solid #d3d3d3;
  border-radius: 6px;
}
.projectName {
  padding: 15px 15px 0 15px;
  display: flex;
  align-items: center;
  font-size: 17px;
  /deep/ .van-icon {
    margin-right: 14px;
  }
}
</style>
