<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader"
                     :title="headerTitle"
                     @go-back="goBack"
                     @on-close="handleClosePage">
          <template #headerRight>
            <div></div>
          </template>
        </cyui-header>
      </template>
      <template #default>
        <div class="cyui-scroller-panel" v-if='houseInfo'>
          <div class='all'>
            <div class='all-title'>
              <p>{{ houseInfo.ALL_TOTAL }}</p>
              <p>防疫点总房数</p>
            </div>
            <div class='all-message'>
              <div>
                <p>{{ houseInfo.ALL_RENT }}</p>
                <p>在住中</p>
              </div>
              <div>
                <p>{{ houseInfo.ALL_READYOUT }}</p>
                <p>预离</p>
              </div>
              <div>
                <p>{{ houseInfo.ALL_FREE }}</p>
                <p>空闲</p>
              </div>
              <div>
                <p>{{ houseInfo.ALL_DIRTY }}</p>
                <p>脏房</p>
              </div>
            </div>
          </div>
          <div class='tabs'>
            <div class='tab-pane' v-for='(item, index) in buildList' :key='item.id' :class='{"tab-pane-active": index === tabIndex}' @click="toggleBuilding(item, index)">{{ item.itemText }}</div>
          </div>
          <div class='info' v-for='(item, index) in houseInfo.houses' :key='index'>
            <div class='info-layer'>{{ item.floor }}</div>
            <div class='info-room'>
              <p v-for='subItem in item.houses' :key='subItem.houseId' :style="{background: roomColor(subItem.state)}" @click='goDetail(subItem)'>{{ subItem.houseNo }}</p>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class='legend'>
          <div class='legend-title'>图例</div>
          <div class='legend-message'>
            <p><i style='background: #FF0000'></i>在住中</p>
            <p><i style='background: #EEC403'></i>预离</p>
            <p><i style='background: #77DD57'></i>空闲</p>
            <p><i style='background: #4534D7'></i>脏房</p>
          </div>
        </div>
        <div class='all foot' v-if='houseInfo'>
          <div class='all-title'>
            <p>{{ houseInfo.tOTAL }}</p>
            <p>楼栋总房数</p>
          </div>
          <div class='all-message'>
            <div>
              <p>{{ houseInfo.RENT }}</p>
              <p>在住中</p>
            </div>
            <div>
              <p>{{ houseInfo.READYOUT }}</p>
              <p>预离</p>
            </div>
            <div>
              <p>{{ houseInfo.FREE }}</p>
              <p>空闲</p>
            </div>
            <div>
              <p>{{ houseInfo.dIRTY }}</p>
              <p>脏房</p>
            </div>
          </div>
        </div>
      </template>
    </cyui-layout>
    <van-popup
      v-model="show"
      closeable
      close-icon="close"
      position="bottom"
      :style="{ height: '70%' }">
      <div class='my-popup' v-if="houseDetails">
        <van-cell title="入住房源" :value="houseDetails.roomNo" />
        <van-cell title="租客姓名" :value="houseDetails.name" />
        <van-cell title="租客电话" :value="houseDetails.phone" />
        <van-cell title="证件号码" :value="houseDetails.idCardNo" />
        <van-cell title="入住日期" :value="houseDetails.startDate" />
        <van-cell title="入住天数" :value="houseDetails.day" />
        <van-cell title="离店日期" :value="houseDetails.endDate" />
        <van-cell title="租金" :value="houseDetails.price" />
        <div class='my-button'>
          <van-button color='#D1A36F' @click='houseOut'>快速退房</van-button>
          <van-button color='#cccccc' @click='show=false'>返回</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import common from '@mixins/common'
  import scrollList from '@mixins/scrollList'
  import getPageList from '@mixins/getPageList'
  import filters from '@filters/filters'
  import { getBuildingApi, getHouseApi, getHouseCheckOut, getHouseClean } from '@api/house'
  import { getDaysBetween } from '@utils/utils'
  import { Dialog, Notify } from 'vant';

  export default {
    name: 'Epidemic',
    mixins: [common, filters, scrollList, getPageList],
    data(){
      return{
        headerTitle: '防疫点动态图',
        tabIndex: 0,
        show: false,
        buildList: [],
        houseInfo: null,
        houseDetails: null,
        buildingId: '',
        houseId: ''
      }
    },
    computed: {
      ...mapGetters('user', [
        'projectInfo'
      ]),
      roomColor(){
        return (state) => {
          if(state === '出租'){
            return '#FF0000 '
          }else if(state === '预退'){
            return '#EEC403'
          }else if(state === '空闲'){
            return '#77DD57'
          }else {
            return '#4534D7'
          }
        }
      }
    },
    mounted(){
      this.initData();
      console.log(111, 'abc')
    },
    methods:{
      async initData(){
        this.$store.dispatch('base/SetLoading', true)
        const { dcProjectId } = this.projectInfo;
        // 楼栋
        let buildResult = await getBuildingApi({ projectId: dcProjectId });
        this.buildList = buildResult.data.result || [];

        this.buildingId = buildResult.data.result.length > 0 ? buildResult.data.result[0].itemValue : '';

        await this.getHouseInfo();
        this.$store.dispatch('base/SetLoading', false)
      },

      // 楼层
      getHouseInfo(){
        this.$store.dispatch('base/SetLoading', true)
        const { dcProjectId } = this.projectInfo;
        getHouseApi({projectId: dcProjectId, building: this.buildingId})
          .then((res) => {
            this.houseInfo = res.data.result || {};
            this.$store.dispatch('base/SetLoading', false)
          })
      },

      toggleBuilding(option, index){
        if(index === this.tabIndex) return
        this.tabIndex = index;
        const { itemValue } = option;
        this.buildingId = itemValue
        this.getHouseInfo()
      },

      goDetail(options){
        const { state, houseId, roomNo, name, phone, idCardNo, startDate, endDate, price } = options;
        this.houseId = houseId;
        if(state === '出租' || state === '预退'){
          this.show = true;
          this.houseDetails = {
            roomNo,
            name,
            phone,
            idCardNo: idCardNo.replace(/^(.{6})(?:\d+)(.{4})$/,  "\$1****\$2"),
            startDate,
            day: getDaysBetween(startDate, endDate),
            endDate,
            price
          }
        }else if(state === '脏房'){
          Dialog.confirm({
            message: `请确定${roomNo}已打扫消毒`,
            beforeClose: async (action, done) => {
              if(action === 'confirm'){
                let result = await getHouseClean({id: this.houseId});
                if(result.data.code === '10000'){
                  done();
                  this.getHouseInfo();
                  Notify({ type: 'primary', message: '打扫成功' });
                }
              }else {
                done();
              }
            }
          })
        }
      },
      houseOut(){
        Dialog.confirm({
          message: '请确定是否立刻退房',
          beforeClose: async (action, done) => {
            if(action === 'confirm'){
              let result = await getHouseCheckOut({id: this.houseId})
              if(result.data.code === '10000'){
                done();
                this.show = false;
                this.getHouseInfo();
                Notify({ type: 'primary', message: '退房成功' })
              }
            }else {
              done();
            }
          }
        })
      }
    }
  }
</script>

<style scoped lang='less'>
.cyui-scroller-panel{
  padding: 0 12px;
}

.all{
  margin-top: 23.5px;
  border: 1Px solid #10AEB5;
  border-radius: 4px;
  color: #10AEB5;
  display: flex;
  div{
    p:first-child{
      font-size: 24px;
    }
    p:last-child{
      font-size: 12px;
    }
  }
  .all-title{
    min-width: 88px;
    height: 70px;
    background: #10AEB5;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  .all-message{
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
  }
}
.tabs{
  margin-top: 12.5px;
  font-size: 15px;
  border: 1Px solid #10AEB5;
  border-radius: 4px;
  display: flex;
  .tab-pane{
    flex: 1;
    text-align: center;
    height: 36px;
    line-height: 36px;
    border-right: 0.5px solid #E5E5E5;
    color: #10AEB5;
  }
  .tab-pane-active{
    background: #10AEB5;
    color: #fff;
  }
}
.info{
  margin-top: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  .info-layer{
    min-width: 88px;
    border-right: 1Px solid #ccc;
    font-size: 15px;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .info-room{
    padding-top: 7px;
    padding-left: 8px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    p{
      width: 45px;
      height: 22px;
      line-height: 22px;
      font-size: 12px;
      text-align: center;
      margin-right: 5.5px;
      margin-bottom: 8px;
      color: #fff;
    }
  }
}

.legend{
  height: 52.5px;
  color: #333;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .legend-title{
    font-size: 15px;
  }
  .legend-message{
    display: flex;
    font-size: 14px;
    p{
      margin-left: 12px;
      i{
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 1.5px;
        margin-right: 8px;
      }
    }
  }
}
.foot{
  color: #D1A36F;
  border-color: #D1A36F;
  border-radius: 0;
  margin-top: 0;
  .all-title{
    background: #D1A36F;
  }
}

.my-popup{
  margin-top: 35px;
  .my-button{
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
  }
  .van-button{
    width: 137.5px;
    height: 48px;
  }
}
</style>
