<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="routeMetaTitle" @go-back="goBack">
        </cyui-header>
      </template>
      <template #default>
        <van-cell-group class='cell-group form'>
          <van-cell title="选择调拨申请" required class="picker" @click="onApplyShow">
            <template #default>
              <div class="pickerText" :class="{disabled:applyText===''}">{{applyText || '请选择'}} <van-icon name="arrow" /></div>
            </template>
          </van-cell>
        </van-cell-group>
      </template>
      <template #footer>
        <div class="footer">
          <van-button block type="primary" size="large" @click="onNext">下一步</van-button>
        </div>
      </template>
    </cyui-layout>
    <van-popup v-model="applyShow" position="bottom">
      <van-picker show-toolbar value-key="title" :columns="applyList" @confirm="onSelect" @cancel="applyShow = false" />
    </van-popup>
  </div>
</template>

<script>
  import common from '@mixins/common'
  import { mapGetters } from 'vuex'
  import * as assetsApi from '@api/assets'

  export default {
    name: 'AssetsDispatchOut',
    mixins: [common],
    computed: {
      ...mapGetters('user', [
        'projectInfo'
      ])
    },
    data() {
      return {
        applyShow: false,
        applyText: '',
        allocateApplyId: '',
        applyList: [],
      }
    },
    methods: {
      onApplyShow() {
        assetsApi.getOutAllocateApplyList({
          projectId: this.projectInfo.dcProjectId,
        }).then(res=>{
          this.applyList = res.data.data.result || [];
          if (this.applyList.length) {
            this.applyShow = true;
          } else {
            this.$toast('无调拨申请记录')
          }

        })

      },
      onSelect(item) {
        if (!item) {
          return
        }
        this.allocateApplyId = item.allocateApplyId;
        this.applyText = item.title;
        this.applyShow = false;
      },
      onNext() {
        if (!this.allocateApplyId) {
          this.$toast('请选择调拨申请')
          return
        }
        this.$router.push({name:'AssetsDispatchOutDetail', query: {allocateApplyId: this.allocateApplyId}});
      },
    }
  }
</script>

<style lang="less" scoped>
  @import "./common.less";
  .cyui-layout-wrapper {
    background: #fff;
  }

  .footer {
    width: 100%;
    padding: 10px;
  }
</style>
