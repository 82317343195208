<template>
  <div class="cyui-layout">
    <div class="cyui-layout-wrapper">
      <div class="cyui-login-box">
        <div class="cyui-login-title">
          <div class="cyui-login-title-icon">
            <img src="../../assets/images/login/img_001.png" alt="">
          </div>
          <div class="cyui-login-title-text">登录</div>
        </div>
        <div class="cyui-login-form">
          <van-cell-group>
            <van-field type="tel"
                       v-model="phone"
                       clearable
                       autocomplete="off"
                       placeholder="请输入11位手机号">
              <template #label>
                <div class="cyui-phone-prefix">+86</div>
                <div class="cyui-phone-prefix-arrow cyui-phone-prefix-arrow-down"></div>
              </template>
            </van-field>
            <van-field type="tel"
                       v-model="phone"
                       clearable
                       autocomplete="off"
                       placeholder="请输入11位手机号"
                       style="width: 0; height: 0; margin: 0; padding: 0; overflow: hidden; opacity: 0;">
              <template #label>
                <div class="cyui-phone-prefix">+86</div>
                <div class="cyui-phone-prefix-arrow cyui-phone-prefix-arrow-down"></div>
              </template>
            </van-field>
            <van-field type="password"
                       v-model="password"
                       clearable
                       autocomplete="off"
                       placeholder="请输入密码">
            </van-field>
          </van-cell-group>
        </div>
        <div class="cyui-login-submit">
          <span class="cyui-btn cyui-btn-primary cyui-btn-radius" @click="handleLogin">登录</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { debounce } from 'lodash'
  import crypto from '@/mixins/crypto'
  import isMobilePhone from 'validator/lib/isMobilePhone'

  export default {
    name: 'Login',
    mixins: [crypto],
    components: {},
    data() {
      return {
        phone: '',
        password: ''
      }
    },
    props: {},
    computed: {},
    methods: {
      validatePhone() { // 验证手机号
        if (this.phone === '' || !isMobilePhone(this.phone, 'zh-CN')) {
          let msg = this.phone === '' ? '请输入手机号!' : '手机号格式有误!'
          this.$toast(msg)
          return false
        } else {
          return true
        }
      },
      validatePassword() { // 验证密码
        // let pattern = /^(?=.*\d)(?=.*[a-zA-Z]).{8,16}$/
        // if (this.password === '' || !pattern.test(this.password)) {
        if (this.password === '') {
          // let msg = this.password === '' ? '请输入密码!' : '用户密码不正确!'
          let msg = '请输入密码!'
          this.$toast(msg)
          return false
        } else {
          return true
        }
      },
      handleLogin: debounce(function() { // 登录，提交，防抖
        if (this.validatePhone() && this.validatePassword()) {
          // let password = this.aesEncrypt(this.password)
          let { phone, password } = this
          this.$store.dispatch('user/HandleLogin', {
            phone,
            password
          }).then((response) => {
            let result = response.data
            if (result.code === '10000') {
              let { redirect } = this.$route.query
              if (redirect && redirect !== '/404') { // 判断是否存在要进入的页面
                this.$router.replace({
                  path: redirect
                })
              } else {
                this.$router.replace({
                  path: '/'
                })
              }
              // this.$router.go(-1)
            }
          }).catch((error) => {
            this.$toast(error.message)
          })
        }
      }, 300)
    },
    watch: {},
    created() {
    },
    mounted() {
    },
    beforeRouteEnter(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    },
    beforeRouteUpdate(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    }
  }
</script>

<style lang="less" scoped>
  @import "../../assets/styles/themes/default/index";
  @import "../../assets/styles/mixins/index";

  @loginPrefixCls: ~"@{css-prefix}login";

  .@{css-prefix}layout-wrapper {
    background-image: url("../../assets/images/login/bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .@{loginPrefixCls}-box {
    padding: 0 44px;

    .@{loginPrefixCls}-title {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      height: 40px;
      padding-top: 265px;

      &-icon {
        width: 26px;
        margin-right: 10px;

        > img {
          display: block;
          width: 100%;
        }
      }

      &-text {
        display: block;
        line-height: 1;
        font-size: 15px;
        color: #FFFFFF;
      }
    }

    .@{loginPrefixCls}-form {
      margin-top: 34px;
    }

    .@{loginPrefixCls}-submit {
      margin-top: 50px;

      .@{css-prefix}btn-primary {
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        color: #FFFFFF;
        background-color: #D1A36F;
        border: none;
        border-radius: 40px;
      }
    }
  }

  .@{loginPrefixCls}-form {
    & /deep/ .van-cell-group {
      background-color: transparent;

      &::after {
        display: none;
      }

      .van-cell {
        padding: 8px 20px;
        background-color: rgba(225, 225, 225, 0.32);
        border-radius: 40px;

        &:not(:last-child) {
          margin-bottom: 35px;

          &::after {
            display: none;
          }
        }
      }

      .van-cell__title {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 50px;
        font-size: 15px;
        color: #FFFFFF;

        .@{css-prefix}phone-prefix-arrow {
          position: relative;
          display: block;
          width: 15px;
          height: 15px;
          margin-left: 10px;
          visibility: hidden;

          &:after {
            content: " ";
            .setArrow(right, 6px, #FFFFFF, 2px);
            position: absolute;
            top: 50%;
            left: 50%;
          }

          &-down {
            visibility: visible;

            &:after {
              transform: translate(-50%, -75%) rotate(135deg);
            }
          }
        }
      }

      .van-field__control {
        font-size: 15px;
        color: #FFFFFF;
      }
    }
  }
</style>
