<template>
  <div class='cyui-card cyui-record-item' @click='goDetail'>
    <div class='cyui-card-header'>
      <div class='cyui-card-header-line cyui-hairline-bottom'>
        <div class='cyui-card-header-content'
             v-if='headerContentField.isShow'>
          <div class='cyui-flexbox'>
            <div v-if='headerContentField.icon'>
              <svg-icon :icon-class='`${headerContentField.icon.svgName}`'></svg-icon>
            </div>
            <div class='cyui-flexbox-item cyui-ellipsis'
                 :style='{ color: headerContentField.color }'>
              {{ headerContentField.title }}
              {{ data[headerContentField.key] }}
            </div>
          </div>
        </div>
        <div v-if='headerExtraField.isShow'
             @click.stop='checkOut'
             class='cyui-card-header-extra'
             :style='{ color: headerExtraField.color }'>
          <template v-if='headerExtraField.filterMethod' >
            {{ headerExtraField['title'] }}
            {{ ((headerExtraField.filterMethod)(`${data[headerExtraField.key]}`)) }}
          </template>
          <template v-else>
            {{ headerExtraField['title'] }}
          </template>
        </div>
      </div>
    </div>
    <div class='cyui-card-body'>
      <div class='cyui-record-info'>
        <template v-for='(item, index) in bodyFields'>
          <div :key='`bodyFields${index}`'>
            <template v-if='item.filterMethod'>
              {{ item.title }}
              {{ ((item.filterMethod)(`${data[item.key]}`) || '-') }}
            </template>
            <template v-else>
              {{ item['title'] }}
              {{ data[item.key] || '-' }}
            </template>
          </div>
        </template>
      </div>
    </div>
    <div class='cyui-card-footer'>
      <div class='cyui-card-footer-line cyui-hairline-top'>
        <div class='cyui-card-footer-content'
             v-if='footerContentField.isShow'>
          <div class='cyui-flexbox'>
            <div class='cyui-flexbox-item cyui-ellipsis'
                 :style='{ color: footerContentField.color }'>
              {{ footerContentField.title }}
            </div>
          </div>
        </div>
        <div v-if='footerExtraField.isShow'
             class='cyui-card-footer-extra'
             :class="{
                'orange-color': (footerExtraField.getColorMethod ? (footerExtraField.getColorMethod)(`${data[footerExtraField.key]}`) === 'orange' : false),
                'green-color': (footerExtraField.getColorMethod ? (footerExtraField.getColorMethod)(`${data[footerExtraField.key]}`) === 'green' : false),
                'primary-color': (footerExtraField.getColorMethod ? (footerExtraField.getColorMethod)(`${data[footerExtraField.key]}`) === 'primary' : false),
              }"
             :style='{color: footerExtraField.color}'>
          <template v-if='footerExtraField.filterMethod'>
            {{ ((footerExtraField.filterMethod)(`${data[footerExtraField.key]}`, `${data[footerExtraField.key2]}`) || '-') }}
          </template>
          <template v-else>
            {{ data[footerExtraField.key] || '-' }}
          </template>
        </div>
        <div class='cyui-card-footer-arrow cyui-card-footer-arrow-right'
             :class="{
                'orange-color': (footerExtraField.getColorMethod ? (footerExtraField.getColorMethod)(`${data[footerExtraField.key]}`) === 'orange' : false),
                'green-color': (footerExtraField.getColorMethod ? (footerExtraField.getColorMethod)(`${data[footerExtraField.key]}`) === 'green' : false),
                'primary-color': (footerExtraField.getColorMethod ? (footerExtraField.getColorMethod)(`${data[footerExtraField.key]}`) === 'primary' : false),
              }">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import filters from '@filters/filters'

  export default {
    name: 'FieldRecordInfo',
    mixins: [filters],
    components: {},
    data() {
      return {}
    },
    props: {
      data: {
        type: Object,
        default() {
          return {}
        }
      },
      headerContentField: {
        type: Object,
        default() {
          return {
            isShow: false,
            title: '',
            key: '',
            icon: {
              type: 'svg',
              svgName: ''
            },
            color: ''
          }
        }
      },
      headerExtraField: {
        type: Object,
        default() {
          return {
            isShow: false,
            title: '',
            key: '',
            icon: {
              type: 'svg',
              svgName: ''
            },
            color: ''
          }
        }
      },
      footerContentField: {
        type: Object,
        default() {
          return {
            isShow: false,
            title: '',
            key: '',
            icon: {
              type: 'svg',
              svgName: ''
            },
            color: ''
          }
        }
      },
      footerExtraField: {
        type: Object,
        default() {
          return {
            isShow: false,
            title: '',
            key: '',
            icon: {
              type: 'svg',
              svgName: ''
            },
            color: ''
          }
        }
      },
      bodyFields: {
        type: Array,
        default() {
          return []
        }
      }
    },
    computed: {},
    methods: {
      goDetail() {
        this.$emit('go-detail', this.data)
      },
      checkOut() {
        this.$emit('check-out', this.data)
      },
      async initData() {
      }
    },
    filters: {},
    watch: {},
    created() {
      this.initData()
    },
    mounted() {
    }
  }
</script>

<style lang='less' scoped>
  @import '../../../assets/styles/themes/default/index';
  @import '../../../assets/styles/mixins/index';

  .@{css-prefix}record-item {
    border: 1px solid #EFEFEF;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    &:not(:first-child) {
      margin-top: 10px;
    }

    .@{css-prefix}card-header {
      min-height: 34px;
      font-size: 12px;
      color: #333333;

      &-content {
        img {
          position: relative;
          top: -1px;
          display: block;
          width: 12px;
          height: 12px;
          margin-right: 8px;
        }
      }

      &-extra {
        font-size: 9px;
        color: #666666;
      }

      &-arrow {
        position: relative;
        display: block;
        width: 15px;
        height: 15px;
        margin-left: 5px;
        visibility: hidden;

        &:after {
          content: " ";
          .setArrow(right, 8px, #cccccc, 1PX);
          position: absolute;
          top: 50%;
          left: 50%;
        }

        &-right {
          visibility: visible;

          &:after {
            transform: translate(-75%, -50%) rotate(45deg);
          }
        }

      }
    }

    .@{css-prefix}card-footer {
      min-height: 38px;
      font-size: 12px;
      color: #333333;

      &-extra {
        font-size: 12px;
        color: #999999;

        &.orange-color {
          color: #FF6600;
        }

        &.green-color {
          color: #55B526;
        }

        &.primary-color {
          color: #D2A36D;
        }
      }

      &-arrow {
        position: relative;
        top: -1px;
        display: block;
        width: 13px;
        height: 13px;
        margin-left: 5px;
        visibility: hidden;

        &:after {
          content: " ";
          .setArrow(right, 8px, #cccccc, 1PX);
          position: absolute;
          top: 50%;
          left: 50%;
        }

        &-right {
          visibility: visible;

          &:after {
            transform: translate(-75%, -50%) rotate(45deg);
          }
        }

        &.orange-color {
          &::after {
            border-color: #FF6600;
          }
        }

        &.green-color {
          &::after {
            border-color: #55B526;
          }
        }

        &.primary-color {
          &::after {
            border-color: #D2A36D;
          }
        }
      }
    }

    .@{css-prefix}record-info {
      padding: 5px 15px;
      line-height: 24px;
      font-size: 9px;
      color: #666666;
    }
  }

</style>
