import request from '@utils/request'
// import qs from 'qs'

/**
 * Custom-获取列表数据
 * url:'/service/getCustomizedServiceList'
 * method:GET
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 *   pageSize: '' //  分页大小
 *   pageNum: '' //  当前页数
 *   projectId: '' //  项目id
 *   status: '' //  进度 2:进行中 3:已完成 全部则不传
 * }
 * @returns {*}
 */
export const getListAPI = (params = {}) => {
  return request.get('/service/getCustomizedServiceList', { params })
}

/**
 * Custom-获取详情数据
 * url:'/service/getCustomizedServiceDetail'
 * method:GET
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 *   id: '' //  ID
 * }
 * @returns {*}
 */
export const getDetailAPI = (params = {}) => {
  return request.get('/service/getCustomizedServiceDetail', { params })
}

/**
 * Custom-完成服务
 * url:'/service/completeCustomizedService'
 * method:GET
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 *   id: '' //  ID
 * }
 * @returns {*}
 */
export const submitCustomAPI = (params = {}) => {
  return request.post('/service/completeCustomizedService', params)
}
