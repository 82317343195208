<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="routeMetaTitle" @go-back="goBack">
        </cyui-header>
      </template>
      <template #default>
        <div class="page">
          <div class="list">
            <div class="item" v-for="(item,index) in detailList" :key="index">
              <div class="title"><span>申请明细：第{{ index+1 }}条</span></div>
              <van-cell-group class='cell-group'>
                <van-cell title="资产属性"  :value="productPropertyMap[item.productProperty]||'-'"></van-cell>
                <van-cell title="资产名称" :value="item.productName||'-'"></van-cell>
                <van-cell title="主规格" :value="item.standard||'-'"></van-cell>
                <van-cell title="资产编号" :value="item.productNo||'-'"></van-cell>
                <van-cell title="调出仓库" :value="outSpaceName||'-'"></van-cell>
                <van-cell title="调入价格/元" :value="item.inPrice||'-'"></van-cell>
                <van-cell title="单位" :value="item.unit||'-'" ></van-cell>
                <van-cell title="调入数量" :value="item.inNum||'-'"></van-cell>
                <van-cell title="调出仓库" :value="spaceName||'-'"></van-cell>
              </van-cell-group>
            </div>
          </div>
          <van-cell-group class='cell-group'>
            <van-cell title="调出管理员" :value="outKeeper||'-'"></van-cell>
            <van-cell title="入库人" :value="stockUser||'-'"></van-cell>
            <van-cell title="调拨总金额/元" :value="totalPrice||'-'"></van-cell>
          </van-cell-group>
          <van-cell-group class='cell-group form' >
            <template #title>
              <div class="cell-group-title"><span>其他信息</span></div>
            </template>
            <!--<div class="remark0">-->
            <!--  <div class="title">说明</div>-->
            <!--  <div class="value">{{ remark0 || '-'}}</div>-->
            <!--</div>-->
            <van-field class="vtextarea" type='textarea' label='备注' v-model='remark' rows='3' autosize
                       input-align='left' placeholder="请输入"
            />
            <van-cell title="入库人签字">
              <template #default>
                <div class="signPanel">
                  <img :src="stockUserSignUrl">
                </div>
              </template>
            </van-cell>
            <van-cell title="入库照片">
              <template #default>
                <div class="uploadPanel">
                  <van-uploader v-model="stockInImgList" :show-upload="false" :deletable="false" />
                </div>
              </template>
            </van-cell>
          </van-cell-group>
        </div>
      </template>
    </cyui-layout>
  </div>
</template>

<script>
  import common from '@mixins/common'
  import { mapGetters } from 'vuex'
  import _ from 'lodash'
  import * as assetsApi from '@api/assets'

  const productPropertyList = [
    {label: "易耗品", value: 1},
    {label: "低值品", value: 2},
    {label: "固定资产", value: 3},
  ]
  const productPropertyMap = _.mapValues(_.keyBy(productPropertyList, i=>i.value), j=>j.label);

  export default {
    name: 'AssetsInLogDetail2',
    mixins: [common],
    computed: {
      ...mapGetters('user', [
        'userInfo',
        'projectInfo',
      ])
    },
    data() {
      return {
        from: '',
        sourceId: '',
        sourceTitle: '',
        sourceType: '',
        inApplicant:'',
        spaceFullCode: '',
        spaceName: '',
        remark0: '',
        remark: '',
        stockUser: '',
        detailList: [],
        stockUserSignUrl: '',
        stockInImgList: [],

        outKeeper: '',
        outSpaceName: '',
        productPropertyMap,
        totalPrice: '',
      }
    },
    created() {
      this.id = this.$route.query.id;
      this.getData();
    },
    methods: {
      getData(){
        assetsApi.getStockInDetail(this.id).then(res=>{
          let item = res.data.data;
          this.sourceId = item.sourceId;
          this.sourceTitle = item.sourceTitle;
          this.sourceType = item.sourceType;
          this.detailList = item.detailList || [];
          this.spaceFullCode = item.spaceFullCode;
          this.spaceName = item.spaceName;
          this.stockMethod = item.stockMethod;
          this.projectId = item.projectId;
          this.stockUser = item.stockUser;
          this.totalPrice = item.totalAmount;
          this.stockUserSignUrl = item.stockUserSignUrl;
          this.stockInImgList = item.stockInImgList ? item.stockInImgList.map(i=>{return {url:i.fileUrl}}) : [];
          this.remark = item.remark;
        })
      },
    }
  }
</script>

<style lang="less" scoped>
  @import "./common.less";
  .cyui-layout-wrapper {
    background: #F6F6F6;
  }
  .page {
    padding: 10px;
  }
  .cell-group {
    margin-bottom: 10px;
  }
  .list {
    margin-bottom: 10px;
    .item {
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 3px 0 5px;
        margin-bottom: 10px;
        font-size: 14px;
        color: #6D6868;
        /deep/ .van-icon {
          font-size: 18px;
          color: #999999;
        }
      }
    }
  }
  .form {
    .remark0 {
      display: flex;
      position: relative;
      display: flex;
      box-sizing: border-box;
      width: 100%;
      padding: 10px 16px;
      overflow: hidden;
      color: #323233;
      font-size: 14px;
      line-height: 24px;
      background-color: #fff;
      &::after {
        position: absolute;
        box-sizing: border-box;
        content: ' ';
        pointer-events: none;
        right: 16px;
        bottom: 0;
        left: 16px;
        border-bottom: 1px solid #ebedf0;
        transform: scaleY(0.5);
      }
      .title {
        flex: none;
        white-space: nowrap;
      }
      .value {
        flex: 1;
        position: relative;
        overflow: hidden;
        color: #969799;
        text-align: right;
        vertical-align: middle;
        word-wrap: break-word;
      }
    }
    .signPanel {
      //background: #F5F5F5;
      border-radius: 6px;
      overflow: hidden;
      height: 120px;
      position: relative;
      .clear {
        position: absolute;
        right: 5px;
        top: 5px;
        padding: 3px 5px;
        color: #999;
        &:active {
          color: #ccc;
        }
      }
    }
    .uploadPanel {
      text-align: left;
    }
  }
  .footer {
    width: 100%;
    padding: 10px;
  }
</style>
