<template>
  <div class='cyui-layout-wrapper'>
    <cyui-layout>
      <template #header>
        <cyui-header v-if='isCustomHeader'
                     :title='headerTitle'
                     @go-back='goBack'
                     @on-close='handleClosePage'>
          <template #headerRight>
            <div></div>
          </template>
        </cyui-header>
      </template>
      <template #default>
        <div class='cyui-driections-navbar'>
          <van-form @submit='onSubmit'>
            <van-field readonly
                       clickable
                       v-model='form.createBy'
                       label='发起人'
                       input-align='right' />
            <van-field readonly
                       clickable
                       v-model='form.createTime'
                       label='发起时间'
                       input-align='right' />
            <van-field readonly
                       clickable
                       v-model='form.correctTypeName'
                       label='纠正类型'
                       input-align='right' />
            <van-field readonly
                       clickable
                       rows='3'
                       autosize
                       maxlength='100'
                       type='textarea'
                       v-model='form.correctDesc'
                       label='问题描述'
                       input-align='right' />
            <van-field readonly
                       clickable
                       label='图片'
                       input-align='right'>
              <template #input>
                <img class='image'
                     v-for='(item, index) in form.fileList'
                     @click='reviewImg(form.fileList)'
                     :key='index'
                     :src='item'
                     alt=''>
              </template>
            </van-field>
            <van-divider></van-divider>
            <div v-if="[2,3,4].includes(form.correctType)">
              <van-notice-bar v-if="form.correctType == 4" :scrollable="false" text="该费用录入已退回，请到智慧平台系统核查"/>
              <!--费用科目-->
              <van-field readonly
                         clickable
                         name='picker'
                         v-model='cost.typeName'
                         label='费用科目'
                         placeholder='请选择'
                         input-align='right'
                         right-icon='arrow'
                         @click='handleShowTypeName'
                         required
                         :rules='[{ required: true }]' />
              <!-- kemu -->
              <van-popup v-model='showFeeType'
                         position='bottom'>
                <van-picker title='费用科目'
                            show-toolbar
                            :columns='feeTypes'
                            @confirm='feeTypeChange'
                            @cancel='showFeeType = false' />
              </van-popup>
              <van-field clickable
                         v-model='cost.description'
                         placeholder="请输入"
                         label='费用描述'
                         rows='3'
                         autosize
                         maxlength='100'
                         type='textarea'
                         input-align='right'
                         :rules='[{ required: true }]'
                         :disabled="form.correctType == 4"
                         required />
              <!-- 关联房源 -->
              <van-field readonly
                         clickable
                         name='picker'
                         v-model='cost.roomNo'
                         label='关联房源'
                         placeholder='请选择'
                         input-align='right'
                         right-icon='arrow'
                         @click='handleShowRoom'
                         required
                         :disabled="form.correctType == 4"
                         :rules='[{ required: true }]' />
              <!-- 房源 -->
              <van-popup v-model='showRoom'
                         position='bottom'>
                <van-search placeholder="请输入房号"
                            v-model="roomNoSearch"
                            @search="listRooms"></van-search>
                <van-picker title='关联房源'
                            value-key="roomNo"
                            show-toolbar
                            :columns='rooms'
                            @confirm='roomChange'
                            @cancel='showRoom = false' />
              </van-popup>

              <van-field clickable
                         v-model='cost.amount'
                         label='金额'
                         placeholder="请输入金额"
                         input-align='right'
                         :disabled="form.correctType == 4"
                         :rules='[{ required: true }]'
                         required />
              <!-- 费用承担着 -->
              <van-field readonly
                         clickable
                         name='picker'
                         v-model='cost.payerSource'
                         label='费用承担者'
                         placeholder='请选择'
                         input-align='right'
                         right-icon='arrow'
                         @click='handleShowPayerSource'
                         required
                         :rules='[{ required: true }]' />
              <!-- 费用承担者 -->
              <van-popup v-model='showPaySource'
                         position='bottom'>
                <van-picker title='费用承担者'
                            show-toolbar
                            :columns='paySources'
                            @confirm='feeChargerChange'
                            @cancel='showPaySource = false' />
              </van-popup>

              <van-field readonly
                         clickable
                         name='picker'
                         label='费用发生时间'
                         v-model='cost.costDate'
                         placeholder='请选择'
                         input-align='right'
                         right-icon='arrow'
                         @click='handleShowDate'
                         required
                         :disabled="form.correctType == 4"
                         :rules='[{ required: true }]' />
              <!-- 时间选择 -->
              <van-popup v-model='showDatePicker'
                         round
                         position='bottom'>
                <van-datetime-picker v-model='costDate'
                                     type='date'
                                     title='选择年月日'
                                     @confirm='changeDate'
                                     @cancel='showDatePicker = false' />
              </van-popup>
              <van-field label='备注'
                         v-model='cost.remark'
                         rows='3'
                         autosize
                         maxlength='100'
                         type='textarea'
                         placeholder='请输入'
                         input-align='right'
                         error-message-align='left'
                         :disabled="form.correctType == 4"
                         show-word-limit />
            </div>
            <div v-if="form.correctType == 1">
              <van-cell-group inset
                              class='cell-group'>
                <van-cell title="租客姓名"
                          :value="detail.customerName" />
                <van-cell title="房号"
                          :value="detail.roomNo" />
                <van-cell title="开始时间"
                          :value="detail.contractStartTime" />
                <van-cell title="结束时间"
                          :value="detail.contractEndTime" />
                <van-cell title="退房类型">
                  <template #default>
                    <span v-if='detail.customerType == 1'
                          style='color: #21D788'>正常退房</span>
                    <span v-if='detail.customerType == 2'
                          style='color: #FDA93E'>提前退房</span>
                    <span v-if='detail.customerType == 3'
                          style='color: #FDA93E'>提前退房（逾期）</span>
                  </template>
                </van-cell>
                <van-cell title="离店日期"
                          :value="detail.leaveTime" />
                <van-cell title="退房原因"
                          title-style='flex:0.5;'
                          :value="detail.personalReason" />
                <!--          <van-cell title="最后一次收款日期" :value="detail.latestPayRentDate" />-->
                <!--          <van-cell title="最后一次收款账号类型" :value="detail.accountType" />-->
                <!--          <van-cell title="退款时间" is-link :value="date" @click="show = true" />-->
                <!--<van-action-sheet v-if='detail.status==4' v-model="show" >-->
                <!--  <van-datetime-picker-->
                <!--    v-model="checkOutPayDate"-->
                <!--    @cancel='show = false'-->
                <!--    @confirm='onConfirm'-->
                <!--    type="date"-->
                <!--    title="选择年月日"-->
                <!--    :min-date="minDate"-->
                <!--    :max-date="maxDate"-->
                <!--    style='z-index: 99999'-->
                <!--  />-->
                <!--</van-action-sheet>-->
                <van-cell title="退款时间"
                          title-style='flex:0.5;'
                          value="7个工作日内" />
              </van-cell-group>
              <van-cell-group inset
                              class='cell-group'>
                <van-cell>
                  <template #title>
                    <span style='color: #999999'>水费标准：{{ detail.waterPrice }}元/吨</span>
                  </template>
                  <template #default>
                    <span style='color: #999999'>电费标准：{{ detail.wattPrice }}元/度</span>
                  </template>
                </van-cell>
                <van-cell title="签约时水表度数"
                          :value="(detail.checkInWaterDegree || '') + '吨'" />
                <van-cell title="签约时电表度数"
                          :value="(detail.checkInElectricityDegree || '') + '度'" />
                <van-cell title="当前水表抄表度数"
                          :value="(detail.waterMaterDegree || '') + '吨'" />
                <van-cell title="当前电表抄表度数"
                          :value="(detail.wattMaterDegree || '') + '度'" />
              </van-cell-group>
              <van-cell-group inset
                              class='cell-group'>
                <van-cell :border='false'>
                  <template #title>
                    <span style='font-size: 14px;'>水表图片</span>
                  </template>
                  <template #default>
                    <span style='font-size: 14px;display:block;width: 100%;text-align: left;color: #323233'>电表图片</span>
                  </template>
                </van-cell>
                <van-cell>
                  <template #title>
                    <span style='font-size: 14px;display:block;'>
                      <van-image width="140"
                                 height="77"
                                 lazy-load
                                 :src="detail.wattMaterDegreeImg" />
                    </span>
                  </template>
                  <template #default>
                    <span style='font-size: 14px;display:block;width: 100%;text-align: left;color: #323233'>
                      <van-image width="140"
                                 height="77"
                                 lazy-load
                                 :src="detail.waterMaterDegreeImg" />
                    </span>
                  </template>
                </van-cell>
              </van-cell-group>
              <van-cell-group inset
                              class='cell-group'>
                <van-cell :border='false'>
                  <template #title>
                    <span style='font-size:15px;font-weight: 400;'>退房费明细</span>
                  </template>
                </van-cell>
                <van-cell v-if='detail.customerType == 2 || detail.customerType == 3'
                          :border='false'>
                  <template #title>
                    <span v-if="detail.customerType == 2"
                          style='font-size:15px;font-weight: 400;'>剩余月份租金明细</span>
                    <span v-if="detail.customerType == 3"
                          style='font-size:15px;font-weight: 400;'>退房逾期租金明细</span>
                  </template>
                  <template #label>
                    <span v-if='detail.customerType == 2'>{{ (detail.afterCheckOutRentStartDate ? detail.afterCheckOutRentStartDate : '')
                          + (detail.afterCheckOutRentStartDate || detail.afterCheckOutRentEndDate ? '~' : '') +
                          (detail.afterCheckOutRentEndDate ? detail.afterCheckOutRentEndDate : '')
                      }}</span>
                    <span v-if='detail.customerType == 3'>{{ (detail.overdueStartDate ? detail.overdueStartDate : '') +
                        (detail.overdueStartDate || detail.leaveTime ? '~' : '') +
                        (detail.leaveTime ? detail.leaveTime : '')
                    }}</span>
                  </template>
                </van-cell>
                <van-cell v-if="detail.customerType == 3"
                          :border='false'>
                  <template #title>
                    <span style='color: #666666;'>月总租金</span>
                  </template>
                  <template #default>
                    <span style='color: #666666;'>{{ detail.monthRentAmount }}元</span>
                  </template>
                </van-cell>
                <van-cell v-if="detail.customerType == 3"
                          :border='false'>
                  <template #title>
                    <span style='color: #666666;'>日均租金</span>
                  </template>
                  <template #default>
                    <span style='color: #666666;'>{{ detail.dailyRentAmount }}元</span>
                  </template>
                </van-cell>
                <van-cell v-if="detail.customerType == 3">
                  <template #title>
                    <span style='color: #666666;'>滞纳金</span>
                  </template>
                  <template #default>
                    <span style='color: #666666;'>{{ zhinajin }}元</span>
                  </template>
                  <template #label>
                    <span style='color: #666666;'>({{ detail.monthRentAmount || '' }}/{{ detail.monthMaxDay || '' }}天*{{
                        zhinaday || ''
                    }}={{ zhinajin || '' }}元)</span>
                  </template>
                </van-cell>
                <van-cell v-if='detail.customerType == 3'
                          title='逾期租金'>
                  <template #default>
                    <span style='color: #FF3333;'>{{ detail.rentBreakAmount }}元</span>
                  </template>
                </van-cell>
                <van-cell v-if="detail.customerType == 2 || detail.customerType == 3"
                          title="违约金">
                  <template #default>
                    <span style='color: #FF3333;'>{{ detail.breakAmount }}元</span>
                  </template>
                </van-cell>
                <van-cell v-if="detail.customerType == 2"
                          title="已收租金">
                  <template #default>
                    <span style='color: #323233;'>{{ detail.rentPaidAmount }}元</span>
                  </template>
                </van-cell>

                <van-cell v-if="detail.customerType == 2"
                          title="应收租金">
                  <template #default>
                    <span style='color: #323233;'>{{ detail.rentAmount }}元</span>
                  </template>
                </van-cell>
                <van-cell v-if='detail.customerType == 2'
                          title='违约租金'>
                  <template #default>
                    <span style='color: #323233;'>{{ detail.rentBreakAmount }}元</span>
                  </template>
                </van-cell>
                <van-cell v-if="detail.customerType == 2"
                          title="应退租金">
                  <template #default>
                    <span style='color: #DBAE74;'>{{ detail.afterRentBreakAmount }}元</span>
                  </template>
                </van-cell>
                <van-cell title="退还押金">
                  <template #default>
                    <span style='color: #DBAE74;'>{{ detail.inDeposit }}元</span>
                  </template>
                </van-cell>
                <!-- <van-cell
            v-if="type === 'detail'"
            title="已收水费">
            <template #default>
              <span  style='color: #DBAE74;'>{{ waterPaidAmount }}元</span>
            </template>
          </van-cell> -->
                <van-field v-model="detail.waterPaidAmount"
                           placeholder="请输入已收水费"
                           input-align="right">
                  <template #label>
                    <span style='color:#323233;'>已收水费</span>
                  </template>
                  <template #extra>
                    <span style='color:#DBAE74;padding: 0 10px;'>元</span>
                  </template>
                </van-field>
                <!-- <van-cell
            v-if="type === 'detail'"
            title="应收水费">
            <template #default>
              <span  style='color: #DBAE74;'>{{ waterTotalAmount }}元</span>
            </template>
          </van-cell> -->
                <van-field v-model="detail.waterTotalAmount"
                           type="number"
                           placeholder="请输入应收水费"
                           input-align="right">
                  <template #label>
                    <span style='color:#323233;'>应收水费</span>
                  </template>
                  <template #extra>
                    <span style='color:#DBAE74;padding: 0 10px;'>元</span>
                  </template>
                </van-field>
                <van-cell title="水费结余">
                  <template #default>
                    <span style='color: #DBAE74;'>{{ waterReturnAmount }}元</span>
                  </template>
                </van-cell>
                <!-- <van-cell
            v-if="type === 'detail'"
            title="已收电费">
            <template #default>
              <span  style='color: #DBAE74;'>{{ powerPaidAmount }}元</span>
            </template>
          </van-cell> -->
                <van-field v-model="detail.powerPaidAmount"
                           placeholder="请输入已收电费"
                           input-align="right">
                  <template #label>
                    <span style='color:#323233;'>已收电费</span>
                  </template>
                  <template #extra>
                    <span style='color:#DBAE74;padding: 0 10px;'>元</span>
                  </template>
                </van-field>
                <!-- <van-cell
            v-if="type === 'detail'"
            title="应收电费">
            <template #default>
              <span  style='color: #DBAE74;'>{{ powerTotalAmount }}元</span>
            </template>
          </van-cell> -->
                <van-field v-model="detail.powerTotalAmount"
                           placeholder="请输入应收电费"
                           input-align="right">
                  <template #label>
                    <span style='color:#323233;'>应收电费</span>
                  </template>
                  <template #extra>
                    <span style='color:#DBAE74;padding: 0 10px;'>元</span>
                  </template>
                </van-field>
                <van-cell title="电费结余">
                  <template #default>
                    <span style='color: #DBAE74;'>{{ powerReturnAmount }}元</span>
                  </template>
                </van-cell>
                <!-- <van-cell
            v-if="type === 'detail'"
            title="水电结余费">
            <template #default>
              <span  style='color: #DBAE74;'>{{ detail.waterElectricity }}元</span>
            </template>
          </van-cell> -->
                <van-field v-model="waterElectricity"
                           :disabled='true'
                           input-align="right">
                  <template #label>
                    <span style='color:#323233;'>水电结余费</span>
                  </template>
                  <template #extra>
                    <span style='color:#DBAE74;padding: 0 10px;'>元</span>
                  </template>
                </van-field>
                <van-cell title='挂账总金额'
                          v-if='detail.chargeAmount !== 0'>
                  <template #default>
                    <span style='color: #FF3333;'>{{ detail.chargeAmount }}元</span>
                  </template>
                </van-cell>
                <!-- <van-cell title="赔偿费">
            <template #default>
              <span style='color: #FF3333;'>{{ detail.damages }}元</span>
            </template>
          </van-cell> -->
                <van-field v-model="detail.damages"
                           placeholder="请输入赔偿费"
                           input-align="right">
                  <template #label>
                    <span style='color:#323233;'>赔偿费</span>
                  </template>
                  <template #extra>
                    <span style='color:#FF3333;padding: 0 10px;'>元</span>
                  </template>
                </van-field>
                <!-- <van-cell title="打扫费">
            <template #default>
              <span style='color: #FF3333;'>{{ detail.cleanFee }}元</span>
            </template>
          </van-cell> -->
                <van-field v-model="detail.cleanFee"
                           placeholder="请输入打扫费"
                           input-align="right">
                  <template #label>
                    <span style='color:#323233;'>打扫费</span>
                  </template>
                  <template #extra>
                    <span style='color:#FF3333;padding: 0 10px;'>元</span>
                  </template>
                </van-field>
                <!-- <van-cell title="额外增值费">
            <template #default>
              <span style='color: #FF3333;'>{{ detail.otherFee }}元</span>
            </template>
          </van-cell> -->
                <van-field v-model="detail.otherFee"
                           placeholder="请输入额外增值费"
                           input-align="right">
                  <template #label>
                    <span style='color:#323233;'>额外增值费</span>
                  </template>
                  <template #extra>
                    <span style='color:#FF3333;padding: 0 10px;'>元</span>
                  </template>
                </van-field>
                <van-cell title="应退还总费用"
                          title-style='flex:2.5;'>
                  <template #default>
                    <!--yingtuihuan 等于 returnPaidAmount一致-->
                    <span style='color: #DBAE74;'>{{ yingtuihuan }}元</span>
                  </template>
                  <template #label>
                    <span v-if="detail.customerType == 1"
                          style='color: #DBAE74;'>({{ detail.inDeposit }}+{{
                        ~~waterElectricity
                    }}={{ yingtuihuan }}元)</span>
                    <span v-if="detail.customerType == 2"
                          style='color: #DBAE74;'>({{ detail.afterRentBreakAmount }}+{{
                        detail.inDeposit
                    }}+{{ ~~waterElectricity }}={{ yingtuihuan }}元)</span>
                    <span v-if="detail.customerType == 3"
                          style='color: #DBAE74;'>({{ detail.inDeposit }}+{{
                        ~~waterElectricity
                    }}={{ yingtuihuan }}元)</span>
                  </template>
                </van-cell>
                <van-cell title="应缴纳总费用"
                          title-style='flex:2.5;'>
                  <template #default>
                    <!--yingjiaona 等于 rentTotalAmount-->
                    <span style='color: #FF3333;'>-{{ yingjiaona }}元</span>
                  </template>
                  <template #label>
                    <span v-if="detail.customerType == 1 && detail.chargeAmount !== 0"
                          style='color: #FF3333;'>({{
                        detail.damages
                    }}+{{ detail.cleanFee }}+{{ detail.otherFee }}+{{ detail.chargeAmount }}={{ yingjiaona }}元)</span>
                    <span v-else-if="detail.customerType == 2 && detail.chargeAmount !== 0"
                          style='color: #FF3333;'>({{detail.breakAmount}}+{{ detail.damages }}+{{ detail.cleanFee }}+{{ detail.otherFee }}+{{ detail.chargeAmount }}={{ yingjiaona }}元)</span>
                    <span v-else-if="detail.customerType == 3 && detail.chargeAmount !== 0"
                          style='color: #FF3333;'>({{detail.breakAmount}}+{{ detail.breakAmount }}+{{ detail.damages }}+{{ detail.cleanFee }}+{{detail.otherFee}}+{{ detail.chargeAmount }}={{ yingjiaona }}元)</span>
                    <span v-else-if="detail.customerType == 1"
                          style='color: #FF3333;'>({{ detail.damages }}+{{ detail.cleanFee }}+{{ detail.otherFee }}={{ yingjiaona }}元)</span>
                    <span v-else-if="detail.customerType == 2"
                          style='color: #FF3333;'>({{ detail.breakAmount }}+{{detail.damages }}+{{ detail.cleanFee }}+{{ detail.otherFee }}={{ yingjiaona }}元)</span>
                    <span v-else-if="detail.customerType == 3"
                          style='color: #FF3333;'>({{ detail.breakAmount }}+{{ detail.rentBreakAmount }}+{{ detail.damages }}+{{ detail.cleanFee }}+{{ detail.otherFee }}={{ yingjiaona }}元)</span>
                  </template>
                </van-cell>
                <van-cell title="实际总退房费用"
                          title-style='flex:2.5;'>
                  <template #default>
                    <!--shiji 等于 checkOutAmount-->
                    <span style='color: #323233;'>{{ shiji }}元</span>
                  </template>
                  <template #label>
                    <span v-if="detail.customerType == 1"
                          style='color: #323233;'>({{ yingtuihuan }}-{{ yingjiaona }}={{
                        shiji
                    }}元)</span>
                    <span v-if="detail.customerType == 2 || detail.customerType == 3"
                          style='color: #323233;'>({{ yingtuihuan
                    }}-{{ yingjiaona }}={{ shiji }}元)</span>
                  </template>
                </van-cell>
              </van-cell-group>

            </div>
            <div v-if="form.correctType ==5">
              <van-field readonly
                         name='renter'
                         v-model='rent.tenantName'
                         label='租客'
                         input-align='right' />
              <van-field readonly
                         name='payTime'
                         v-model="payDate"
                         label='支付时间'
                         input-align='right' />
              <van-field readonly
                         name='roomNo'
                         v-model='rent.roomNo'
                         label='房号'
                         input-align='right' />
              <!-- 结算方式 -->
              <van-field readonly
                         clickable
                         name='picker'
                         v-model='rent.accountType'
                         label='结算方式'
                         placeholder='请选择'
                         input-align='right'
                         right-icon='arrow'
                         @click='handleShowSettlement'
                         required
                         :rules='[{ required: true }]' />
              <!-- 结算方式 -->
              <van-popup v-model='showSettlement'
                         position='bottom'>
                <van-picker title='结算方式'
                            show-toolbar
                            :columns='settlements'
                            @confirm='settlementConfirm'
                            @cancel='showSettlement = false' />
              </van-popup>
              <van-field readonly
                         clickable
                         name='picker'
                         label='付款时间'
                         v-model='rent.factPayDate'
                         placeholder='请选择'
                         input-align='right'
                         right-icon='arrow'
                         @click='handleShowRentAmountDate'
                         required
                         :rules='[{ required: true }]' />
              <!-- 时间选择 -->
              <van-popup v-model='showRentAmountDatePicker'
                         round
                         position='bottom'>
                <van-datetime-picker v-model='factPayDate'
                                     type='datetime'
                                     title='选择年月日'
                                     @confirm='changeRentAmountDate'
                                     @cancel='showRentAmountDatePicker = false' />
              </van-popup>
              <div v-if="rent.factRentAmount || rent.factRentAmount === 0">
                <van-divider></van-divider>
                <van-field readonly
                          name='kemu'
                          v-model="kemu1"
                          label='费用科目'
                          input-align='right' />
                <van-field clickable
                          v-model='rent.factRentAmount'
                          label='金额'
                          placeholder="请输入金额"
                          input-align='right'
                          :disabled="form.correctStatus == 3"
                          :rules='[{ required: true }]'
                          required />
              </div>
              <div v-if="rent.factDepositAmount && rent.factDepositAmount === 0">
                <van-divider></van-divider>
                <van-field readonly
                          name='kemu'
                          v-model="kemu2"
                          label='费用科目'
                          input-align='right' />
                <van-field clickable
                          v-model='rent.factDepositAmount'
                          label='金额'
                          placeholder="请输入金额"
                          input-align='right'
                          :disabled="form.correctStatus == 3"
                          :rules='[{ required: true }]'
                          required />
              </div>

            </div>
            <div style='margin: 16px;'
                 v-if='form.correctStatus != 3 && form.correctType != 4'>
              <van-button round
                          block
                          type='info'
                          color='#D8B084'
                          native-type='submit'>提 交</van-button>
            </div>
          </van-form>
        </div>
      </template>
    </cyui-layout>
  </div>
</template>

<script>
import common from '@mixins/common'
import { getBillCorrectionDetail, submitCorrection, getFeeTypes, getCheckoutDetail, getHouseList, getAccountType } from '@api/billCorrect'
import { getStorage } from '@utils/storage.js'
import { mapGetters } from 'vuex'
import { ImagePreview } from 'vant'
import store from '@store'
import { cloneDeep } from 'lodash'
import dayjs from 'dayjs'

export default {
  name: 'BillDetail',
  mixins: [common],
  data () {
    return {
      detail: {},
      kemu1: '预收租金',
      kemu2: '押金',
      headerTitle: '详情',
      userInfo: JSON.parse(getStorage('user_info', 'localStorage')),
      showRoom: false,
      showDatePicker: false,
      showFeeType: false,
      feeTypes: [],
      showPaySource: false,
      paySources: ['租客', '业主'],
      costDate: '',
      // waterPaidAmount: 0, // 已收水费
      // waterTotalAmount: undefined, // 应收水费
      // powerPaidAmount: 0, // 已收电费
      // powerTotalAmount: undefined, // 应收电费
      roomNoSearch: '',
      rooms: [],
      correctRecordDetails: [],
      // 空间一级分类
      // 层级
      originCost: {},
      originDetail: {},
      originRent: {},
      form: {},
      cost: {},
      rent: {},
      showSettlement: false,
      settlements: [],
      showRentAmountDatePicker: false,
      factPayDate: '',
      payDate: ''
    }
  },
  watch: {
    'cost.type': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '费用科目', this.originCost, 'type')
    },
    'cost.description': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '费用描述', this.originCost, 'description')
    },
    'cost.houseId': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '关联房源', this.originCost, 'houseId')
    },
    'cost.amount': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '金额', this.originCost, 'amount')
    },
    'cost.payerSource': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '费用承担者', this.originCost, 'payerSource')
    },
    'cost.costDate': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '费用发生时间', this.originCost, 'costDate')
    },
    'detail.waterPaidAmount': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '已收水费', this.originDetail, 'waterPaidAmount')
    },
    'detail.waterTotalAmount': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '应收水费', this.originDetail, 'waterTotalAmount')
    },
    'detail.powerPaidAmount': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '已收电费', this.originDetail, 'powerPaidAmount')
    },
    'detail.powerTotalAmount': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '应收电费', this.originDetail, 'powerTotalAmount')
    },
    'detail.damages': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '赔偿费', this.originDetail, 'damages')
    },
    'detail.cleanFee': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '打扫费', this.originDetail, 'cleanFee')
    },
    'detail.otherFee': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '额外增值费', this.originDetail, 'otherFee')
    },
    'rent.accountType': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '结算方式', this.originRent, 'accountType')
    },
    'rent.factRentAmount': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '预收租金金额', this.originRent, 'factRentAmount')
    },
    'rent.factDepositAmount': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '押金金额', this.originRent, 'factDepositAmount')
    },
    'rent.factPayDate': function (newVal, oldVal) {
      this.handleUpdateValue(newVal, oldVal, '付款时间', this.originRent, 'factPayDate')
    }
  },
  computed: {
    ...mapGetters('user', [
      'projectInfo'
    ]),
    yingtuihuan () {
      if (this.detail.customerType == 1) {
        return (this.detail.inDeposit * 1 + this.waterElectricity * 1).toFixed(2)
      } else if (this.detail.customerType == 2 || this.detail.customerType == 3) {
        return (this.detail.afterRentBreakAmount * 1 + this.detail.inDeposit * 1 + this.waterElectricity * 1).toFixed(2)
      } else {
        return 0
      }
    },
    yingjiaona () {
      console.log(this.detail, 'detail')
      if (this.detail.customerType == 1 && this.detail.chargeAmount !== 0) {
        return (this.detail.damages * 1 + this.detail.cleanFee * 1 + this.detail.otherFee * 1 + this.detail.chargeAmount * 1).toFixed(2)
      } else if (this.detail.customerType == 2 && this.detail.chargeAmount !== 0) {
        return (this.detail.breakAmount * 1 + this.detail.damages * 1 + this.detail.cleanFee * 1 + this.detail.otherFee * 1 + this.detail.chargeAmount * 1).toFixed(2)
      } else if (this.detail.customerType == 3 && this.detail.chargeAmount !== 0) {
        return (this.detail.breakAmount * 2 + this.detail.damages * 1 + this.detail.cleanFee * 1 + this.detail.otherFee * 1 + this.detail.chargeAmount * 1).toFixed(2)
      } else if (this.detail.customerType == 1) {
        return (this.detail.damages * 1 + this.detail.cleanFee * 1 + this.detail.otherFee * 1).toFixed(2)
      } else if (this.detail.customerType == 2) {
        return (this.detail.breakAmount * 1 + this.detail.damages * 1 + this.detail.cleanFee * 1 + this.detail.otherFee * 1).toFixed(2)
      } else if (this.detail.customerType == 3) {
        return (this.detail.breakAmount * 1 + this.detail.damages * 1 + this.detail.cleanFee * 1 + this.detail.otherFee * 1 + this.detail.rentBreakAmount * 1).toFixed(2)
      } else {
        return 0
      }
    },
    shiji () {
      return (this.yingtuihuan * 1 - this.yingjiaona * 1).toFixed(2)
    },
    zhinaday () {
      return this.detail.overdueDays * 1
    },
    zhinajin () {
      // return (this.detail.monthRentAmount / 30 * this.zhinaday).toFixed(2)
      return this.detail.rentBreakAmount * 1
    },
    // 水费结余
    waterReturnAmount () {
      return this.detail.waterPaidAmount - this.detail.waterTotalAmount || 0
    },
    // 电费结余
    powerReturnAmount () {
      return this.detail.powerPaidAmount - this.detail.powerTotalAmount || 0
    },
    // 水电结余费
    waterElectricity: {
      get () {
        return this.waterReturnAmount + this.powerReturnAmount || 0
      },
      set (val) {
        return val
      }
    }
  },
  created () {
    this.listFeeTypes()
    this.listAccountTypes()
    this.getDetail()
  },
  mounted () {
  },
  methods: {
    handleUpdateValue (newVal, oldVal, fieldName, source, originField) {
      if (this.form.correctType === 1 || this.form.correctType === 3 || (this.form.correctType === 5 && oldVal) || (this.form.correctType === 2 && oldVal)) {
        if (newVal != source[originField]) {
          let _obj_ = {
            projectId: this.projectInfo.dcProjectId,
            fieldName: fieldName,
            originalValue: source[originField],
            updateValue: newVal,
            recordId: this.form.id,
            createBy: store.getters['user/userInfo'].nickName
          }
          let findIdx = this.correctRecordDetails.findIndex(item => item.fieldName === fieldName)
          if (findIdx > -1) {
            this.correctRecordDetails.splice(findIdx, 1, _obj_)
          } else {
            this.correctRecordDetails.push(_obj_)
          }
        }
      }
    },
    listAccountTypes() {
      getAccountType().then(res=>{
        // console.log(res.data.data)
        this.settlements = res.data.data
      })
    },
    listFeeTypes () {
      getFeeTypes({
        dcProjectId: this.projectInfo.dcProjectId
      }).then(res => {
        // console.log(res)
        this.feeTypes = res.data.data
      })
    },
    listRooms () {
      getHouseList({
        dcProjectId: this.projectInfo.dcProjectId,
        roomNo: this.roomNoSearch
      }).then(res => {
        const callback = res.data
        console.log(callback)
        this.rooms = callback.result
        // console.log(res)

      })
    },
    getDetail () {
      this.$store.dispatch('base/SetLoading', true)
      getBillCorrectionDetail({
        correctRecordId: this.$route.query.id
      }).then(res => {
        const cb = res.data
        if (cb.code === 200) {
          this.form = cb.data.correctRecord
          if (this.form.correctType === 3) { this.listRooms() }
          if (this.form.correctType) {
            this.form.correctTypeName = this.typeFilter(this.form.correctType)
          }
          if (this.form.fileList) {
            this.form.fileList = this.form.fileList.split(',')
          }
          if (cb.data.bill && typeof cb.data.bill === 'string') {
            getCheckoutDetail(cb.data.bill).then(res => {
              if (res.data.data) {
                this.detail = res.data.data
                this.originDetail = cloneDeep(res.data.data)
              }
            })
            // this.detail = cb.data.bill
          } else if (cb.data.bill && typeof cb.data.bill === 'object') {
            this.cost = cb.data.bill
            if (this.cost.costDate) {
              this.cost.costDate = dayjs(this.cost.costDate).format('YYYY/MM/DD')
              this.costDate = new Date(this.cost.costDate)
            }
            this.originCost = cloneDeep(cb.data.bill)
          } else if (cb.data.rentCorrectDTO) {
            this.rent = cb.data.rentCorrectDTO
            this.originRent = cloneDeep(cb.data.rentCorrectDTO)
            if (this.rent.startDate) {
              this.payDate = `${this.rent.startDate} ~ ${this.rent.endDate}`
            }
            this.factPayDate = new Date(this.rent.factPayDate)
          }
        }
      }).finally(() => {
        this.$store.dispatch('base/SetLoading', false)
      })
    },
    onSubmit () {
      // console.log(this.form, this.cost, this.correctRecordDetails)
      const params = {
        correctRecord: this.form,
        correctRecordDetails: this.correctRecordDetails
      }

      if (params.correctRecord.fileList && params.correctRecord.fileList.length) {
        params.correctRecord.fileList = params.correctRecord.fileList.join(',')
      }
      // 非退房纠正
      if ([2,3].includes(this.form.correctType)) {
        params.cost = this.cost
        if (!params.cost.projectId) {
          params.cost.projectId = this.projectInfo.dcProjectId
        }
      } else if (this.form.correctType === 1) { // 退房纠正
        params.powerPaidAmount = this.detail.powerPaidAmount
        params.powerReturnAmount = this.powerReturnAmount
        params.powerTotalAmount = this.detail.powerTotalAmount
        params.waterElectricity = this.waterElectricity
        params.waterPaidAmount = this.detail.waterPaidAmount
        params.waterReturnAmount = this.waterReturnAmount
        params.waterTotalAmount = this.detail.waterTotalAmount
        params.additionalFee = this.detail.otherFee
        params.damagesAmount = this.detail.damages
        params.cleanAmount = this.detail.cleanFee
      } else if (this.form.correctType === 5) {
        // 账单纠正
        params.rentCorrectDTO = this.rent
      }

      console.log(params)
      submitCorrection(params).then(res=>{
        const cb = res.data
        if (cb.code ===200) {
          this.$toast('提交成功')
          this.goBack()
        }
      })
    },
    // 图片预览
    reviewImg (imgList) {
      const result = imgList.map(item => {
        return item
      })
      ImagePreview(result)
    },
    changeDate (e) {
      this.cost.costDate = dayjs(this.costDate).format('YYYY-MM-DD')
      this.showDatePicker = false
    },
    timeFormat (time) {
      let year = time.getFullYear()
      let month = time.getMonth() + 1
      let date = time.getDate()
      let hour = time.getHours()
      if (month < 10) month = '0' + month
      if (date < 10) date = '0' + date
      if (hour < 10) hour = '0' + hour
      return year + '-' + month + '-' + date + ' ' + hour + ':00:00'
    },
    handleShowTypeName () {
      if (this.form.correctType !== 4) {
        this.showFeeType = true
      }
    },
    handleShowPayerSource () {
      if (this.form.correctType !== 4) {
        this. showPaySource = true
      }
    },
    handleShowDate(){
      if (this.form.correctType !== 4) {
        this.showDatePicker = true
      }
    },
    handleShowRoom() {
      if (this.form.correctType === 3) {
        this.showRoom = true
      }
    },
    roomChange (room) {
      console.log(room)
      this.cost.houseId = room.id
      this.cost.roomNo = room.roomNo
      this.roomNoSearch = ''
      this.showRoom = false
    },
    feeTypeChange (item) {
      console.log(item)
      this.cost.typeName = item.text
      this.cost.type = item.value
      this.showFeeType = false
    },
    feeChargerChange (item) {
      this.cost.payerSource = item
      this.showPaySource = false
    },
    typeFilter (val) {
      switch (parseInt(val)) {
        case 1: return '退房纠正'
        case 2: return '其他费用纠正'
        case 3: return '补录纠正'
        case 4: return '退回纠正'
        case 5: return '账单纠正'
        default: return '--'
      }
    },
    // 账单纠正
    handleShowSettlement() {
      if (this.form.correctStatus !=3) {
        this.showSettlement = true
      }
    },
    settlementConfirm(item) {
      console.log(item)
      this.rent.accountType = item.text
      // this.rent.settlement = item.value
      this.showSettlement = false
    },
    handleShowRentAmountDate() {
      if (this.form.correctStatus !=3) {
        this.showRentAmountDatePicker = true
      }
    },
    changeRentAmountDate(e) {
      this.rent.factPayDate = dayjs(this.factPayDate).format('YYYY-MM-DD HH:mm:ss')
      this.showRentAmountDatePicker = false
    }
  },
  filters: {}
  //

}
</script>

<style lang='less' scoped>
.cyui-layout-wrapper {
  height: 100%;
  overflow: auto;

  .detail-id {
    width: 200px;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .cyui-driections-navbar {
    .image {
      width: 100px;
      height: auto;
      margin-right: 10px;
    }

    .steps {
      margin-top: 10px;
      height: auto;
      background-color: #ffffff;
      padding-top: 20px;

      .steps_header {
        width: 95%;
        margin: 0 auto;
        display: flex;
        font-size: 15px;
        font-weight: 550;
        justify-content: space-between;
        align-items: center;

        .steps_header_btn {
          color: #5296f6;
          width: 60px;
          height: 28px;
          line-height: 28px;
          text-align: center;
          font-weight: 400;
          border: 1px solid #5296f6;
          border-radius: 15px;
        }
      }

      .steps_content {
        margin-top: 20px;
      }
    }
  }
}

/deep/ .van-field__label {
  color: #282828;
}

/deep/ .van-field__control {
  color: #666666;
}

.cell-group {
  margin-top: 15px;
}
</style>
