import request from '@utils/request'
// import qs from 'qs'

/**
 * Appointment-获取列表数据
 * url:'/appointment/getAppointmentsPage'
 * method:GET
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 *   pageSize: '' //  分页大小
 *   pageNum: '' //  当前页数
 *   projectId: '' //  项目id
 *   state: '' //  完成状态 0:未完成 1：已完成  *不传代表未完成+已完成
 * }
 * @returns {*}
 */
export const getListAPI = (params = {}) => {
  return request.get('/appointment/getAppointmentsPage', { params })
}

/**
 * Appointment-完成接待
 * url:'/appointment/finishAppointment'
 * method:GET
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 *   id: '' //  ID
 * }
 * @returns {*}
 */
export const submitAppointmentAPI = (params = {}) => {
  return request.post('/appointment/finishAppointment', params)
}

export const getBookListAPI = (data) => {
  return request.post('/appointment/getBookList', data)
}

export const getBookRecordAPI = (data) => {
  return request.post('/baseStewardAdmit/getListOfPage', data)
}

export const admitBookAPI = (data = {}) => {
  return request.post('/appointment/admitBook', data)
}

export const updateBookAPI = (data = {}) => {
  return request.post('/appointment/updateBook', data)
}

export const addBookAPI = (data = {}) => {
  return request.post('/appointment/addBook', data)
}
