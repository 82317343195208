import * as types from './mutations_types'
import { setStorage } from '@utils/storage'

export default {
  [types.SET_APPOINTMENT_LIST](state, data) {
    state.appointmentList = data
    setStorage('appointment_list', state.appointmentList)
  },

  [types.UPDATE_APPOINTMENT_DETAIL](state, data) {
    let index = state.appointmentList.findIndex(i=>i.id = data.id);
    if (index>-1) {
      let res =  state.appointmentList[index];
      res = Object.assign(res, data);
      state.appointmentList[index] = res;
      let list = [...state.appointmentList];
      state.appointmentList = list;
      setStorage('appointment_list', list)
    }
  }
}
