export { sexMap } from './common'

/**
 * 状态
 2 未完成
 3 已完成
 * @type {{}}
 */
export const statusMap = {
  '2': {
    colorType: 'orange',
    text: '未完成'
  },
  '3': {
    colorType: 'gray',
    text: '已完成'
  }
}
