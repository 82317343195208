<template>
  <cyui-field-record-info class='cyui-maintain-item'
                          :data='detailInfo'
                          :header-extra-field='headerExtraField'
                          :footer-content-field='footerContentField'
                          :footer-extra-field='footerExtraField'
                          :body-fields='bodyFields'
                          @go-detail='goDetail'></cyui-field-record-info>
</template>

<script>
  import filters from '@filters/filters'
  import { dateFilter } from '@utils/utils'
  import { statusMap } from '@/dictionaries/maintain'

  export default {
    name: 'MaintainListItem',
    mixins: [filters],
    components: {},
    data() {
      return {
        headerExtraField: {
          isShow: true,
          title: '预约时间：',
          key: 'createTime',
          filterMethod: dateFilter
        },
        footerContentField: {
          isShow: true,
          title: '维修进度'
        },
        footerExtraField: {
          isShow: true,
          title: '',
          key: 'status',
          filterMethod: (data) => statusMap[data]['text'],
          getColorMethod: (data) => statusMap[data]['colorType']
        },
        bodyFields: [
          {
            title: '房号：',
            key: 'spaceName'
          },
          {
            title: '姓名：',
            key: 'contactsName'
          },
          {
            title: '联系方式：',
            key: 'contactsMobile'
          },
          {
            title: '问题类型：',
            key: 'questionCategoryName'
          },
          {
            title: '期望上门时间：',
            key: 'expectRepairDate',
            filterMethod: dateFilter
          },
          {
            title: '实收金额：',
            key: 'amountShiShou',
            getColorMethod: () => 'red'
          }
        ]
      }
    },
    props: {
      detailInfo: {
        type: Object,
        require: true,
        default() {
          return {}
        }
      }
    },
    computed: {},
    methods: {
      goDetail() {
        this.$emit('go-detail', this.detailInfo)
      },
      async initData() {
      }
    },
    filters: {},
    watch: {},
    created() {
      this.initData()
    },
    mounted() {
    }
  }
</script>

<style lang='less' scoped>
  @import '../../../assets/styles/themes/default/index';
  @import '../../../assets/styles/mixins/index';

  .@{css-prefix}maintain-item {
  }

</style>
