import Layout from '@views/layout/layout'

const dailyReportRouter = {
  path: '/dailyReport',
  component: Layout,
  name: 'dailyReport',
  redirect: '/dailyReport/list',
  meta: {
    title: '日报列表',
    noCache: true,
    requireAuth: true
  },
  children: [
    {
      path: 'list',
      name: 'dailyReportList',
      components: {
        default: () => import('@views/dailyReport/list')
      },
      props: {
        default: (route) => ({
          status: route.query.status
        })
      },
      meta: {
        title: '日报列表',
        noCache: true,
        requireAuth: true
      }
    },
    {
      path: 'detail',
      name: 'dailyReportDetail',
      components: {
        default: () => import('@views/dailyReport/detail')
      },
      props: {
        default: (route) => ({
          id: route.query.id
        })
      },
      meta: {
        title: '日报详情',
        noCache: true,
        requireAuth: true
      }
    },
    {
      path: 'form',
      name: 'dailyReportForm',
      components: {
        default: () => import('@views/dailyReport/form')
      },
      props: {
        default: (route) => ({
          id: route.query.id
        })
      },
      meta: {
        title: '日报填写',
        noCache: true,
        requireAuth: true
      }
    }
  ]
}

export default dailyReportRouter