/**
 * 称呼
 * 0 女
 * 1 男
 * @type {{}}
 */
export const sexMap = {
  '0': '女士',
  '1': '先生'
}
