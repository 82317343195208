<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="routeMetaTitle" @go-back="goBack" @on-close="handleClosePage">
        </cyui-header>
      </template>
      <template #default>
        <div class="details">
          <div class="title">资产明细</div>
          <table>
            <tr>
              <td>#</td>
              <td>资产名称</td>
              <td>主规格</td>
              <td>数量</td>
              <td>单位</td>
              <td class="required"><i>*</i>此次入库数量</td>
            </tr>
            <tr v-for="(item,index) in detailList" :key="index">
              <td>{{ index+1 }}</td>
              <td>{{ item.productName }}</td>
              <td>{{ item.standard }}</td>
              <td>{{ item.inNumTotal }}</td>
              <td>{{ item.unit }}</td>
              <td class="input">
                <van-stepper v-model="item.inNum" integer :min="0" :max="item.inNumTotal" button-size="20px" />
                <!--<input v-model="item.num" placeholder="请输入" type="number" :max="item.inNumTotal" />-->
              </td>
            </tr>
          </table>
        </div>
        <van-cell-group inset class='cell-group form'>
          <van-cell title="选择该批资产的进入仓库" required class="picker"  @click="onInSpaceListShow">
            <template #default>
              <div class="pickerText" :class="{disabled:spaceName ===''}">{{spaceName  || '请选择'}} <van-icon name="arrow" /></div>
            </template>
          </van-cell>
          <van-cell title="此次入库方式" required class="picker" v-if="sourceType==1" @click="stockMethodShow=true">
            <template #default>
              <div class="pickerText" :class="{disabled:stockMethodName===''}">{{stockMethodName || '请选择'}} <van-icon name="arrow" /></div>
            </template>
          </van-cell>
          <van-field class="vtextarea" v-model="remark" label="备注" placeholder="请输入"  type="textarea" rows="3" autosize show-word-limit maxlength="200"/>
          <van-cell title="入库人签字" required>
            <template #default>
              <div class="signPanel">
                <vue-esign ref="esign" bgColor="#F5F5F5" :isClearBgColor="false" format="image/jpeg" />
                <div class="clear" @click.stop="onClearSign">清空画布</div>
              </div>
            </template>
          </van-cell>
          <van-cell title="上传照片" required>
            <template #default>
              <div class="uploadPanel">
                <van-uploader v-model="stockInImgList" :after-read='afterFileRead' />
              </div>
            </template>
          </van-cell>
        </van-cell-group>
      </template>
      <template #footer>
        <div class="footer">
          <van-button block type="primary" size="large" @click="onSubmit">提交</van-button>
        </div>
      </template>
    </cyui-layout>
    <van-popup v-model="inSpaceListShow" position="bottom">
      <van-picker show-toolbar value-key="spaceName" :columns="inSpaceList" @confirm="onInSpaceListSelect" @cancel="inSpaceListShow = false" />
    </van-popup>
    <van-popup v-model="stockMethodShow" position="bottom">
      <van-picker show-toolbar :columns="stockMethodList" @confirm="onSelectStockMethod" @cancel="stockMethodShow = false" />
    </van-popup>
    <van-dialog v-model="gdListShow" title="注意" confirm-button-text="我已知晓" @confirm="onGdListConfirm">
      <div class="gdList">
        <div class="title">此次盘点资产中，有以下固定资产被赋予资产编号</div>
        <table>
          <thead>
          <tr>
            <td>资产名称</td>
            <td>主规格</td>
            <td>资产编号</td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) in gdList" :key="index">
            <td>{{ item.productName || '-' }}</td>
            <td>{{ item.standard || '-' }}</td>
            <td>{{ item.productNo || '-' }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </van-dialog>
  </div>
</template>

<script>
  import common from '@mixins/common'
  import vueEsign from 'vue-esign'
  import * as assetsApi from '@api/assets'
  import { mapGetters } from 'vuex'
  import imageCompression from 'browser-image-compression'

  export default {
    name: 'AssetsInDetail',
    mixins: [common],
    components: {
      vueEsign
    },
    computed: {
      ...mapGetters('user', [
        'userInfo',
        'projectInfo',
      ])
    },
    data() {
      return {
        sourceId: '',
        sourceTitle: '',
        sourceType: '',
        detailList: [],
        spaceFullCode: '',
        spaceName: '',
        stockMethod: '',
        projectId: '',
        ourUser: '',
        remark: '',
        stockUserSignUrl: '',
        stockInImgList: [],

        inSpaceListShow: false,
        inSpaceList:[],
        stockMethodShow: false,
        stockMethodName: '',
        stockMethodList: [
          {value:1,text:'部分入库'},
          {value:2,text:'全部入库'},
        ],
        gdListShow: false,
        gdList: [],
      }
    },
    created() {
      this.sourceId = this.$route.query.sourceId;
      this.sourceTitle = this.$route.query.sourceTitle;
      this.sourceType = this.$route.query.sourceType;
      this.projectId = this.projectInfo.dcProjectId;
      this.getData();
    },
    methods: {
      getData() {
        assetsApi.getStockInDetailInit({sourceId:this.sourceId,sourceType: this.sourceType}).then(res=>{
          let item = res.data.data;
          if (item.detailList) {
            for (let detail of item.detailList) {
              detail.inNum = 0;
            }
            this.detailList = item.detailList || [];
          }
        })
      },
      onInSpaceListShow() {
        let { dcProjectId } = this.projectInfo;
        assetsApi.getSpaceListByRole(dcProjectId).then(res=>{
          if (res.data.data.result) {
            this.inSpaceList = res.data.data.result
          }
        })
        this.inSpaceListShow=true;
      },
      onInSpaceListSelect(item) {
        this.spaceFullCode = item.spaceFullCode;
        this.spaceName = item.spaceFullName;
        this.inSpaceListShow=false;
      },
      onSelectStockMethod(item) {
        this.stockMethod = item.value;
        this.stockMethodName = item.text;
        this.stockMethodShow = false;
      },
      onClearSign(){
        this.$refs.esign.reset();
        this.stockUserSignUrl = '';
      },
      afterFileRead(item) {
        let up = this.$toast.loading({forbidClick: true,duration:0})
        imageCompression(item.file, {
          maxSizeMB: 0.2,
          initialQuality: 0.7
        }).then(file=>{
          assetsApi.fileUpload(item).then(res=>{
            this.$toast.clear()
            const cb = res.data
            if (cb.code === 200) {
              item.fileType = 1;
              item.url = cb.data.url;
              item.fileUrl = cb.data.url;
              item.status = 'success'
              item.message = '上传成功'
            } else {
              item.status = 'failed';
              item.message = '上传失败';
            }
          }).catch(e=>{
            this.$toast.clear()
            item.status = 'failed';
            item.message = '上传失败';
          })
        }).catch(()=>{
          this.$toast.clear()
          item.status = 'failed';
          item.message = '上传失败';
        });
      },
      async onSubmit() {
        if (!this.spaceFullCode) {
          this.$toast('请选择进入仓库')
          return
        }
        if (this.sourceType==1 && !this.stockMethod) {
          this.$toast('请选择入库方式')
          return
        }
        if (!this.stockUserSignUrl) {
          let base64Str;
          try {
            base64Str = await this.$refs.esign.generate();
            if (base64Str) {
              base64Str = base64Str.substring(23);
            }
          } catch (e) {
            this.$toast('请签名')
            return
          }
          let res = await assetsApi.fileUploadBase64(base64Str);
          this.stockUserSignUrl = res.data.data.src;
        }
        if (this.stockInImgList.filter(i=>i.status=='success').length == 0) {
          this.$toast('请上传图片')
          return
        }

        this.$dialog.confirm({
          message: '是否确认提交？',
        }).then(()=>{
          assetsApi.inStock({
            sourceType: this.sourceType,
            sourceId: this.sourceId,
            sourceTitle: this.sourceTitle,
            spaceFullCode: this.spaceFullCode,
            spaceName: this.spaceName,
            projectId: this.projectId,
            stockMethod: this.stockMethod,
            stockUserSignUrl: this.stockUserSignUrl,
            stockInImgList: this.stockInImgList.map(i=>{return {fileUrl:i.fileUrl}}),
            ourUser: '',// 采购入库不用传outKeeper
            remark: this.remark,
            detailList: this.detailList,
          }).then(res=>{
            if (res.data.data && res.data.data.guDingStockList && res.data.data.guDingStockList.length) {
              this.gdList = res.data.data.guDingStockList;
              this.gdListShow = true;
            } else {
              this.$router.replace({name: 'AssetsInSuccess',query:{sourceType: this.sourceType}})
            }
          })
        }).catch(() => {
          // on cancel
        });
      },
      onGdListConfirm() {
        this.gdListShow = false;
        this.$router.replace({name: 'AssetsInSuccess',query:{sourceType: this.sourceType}})
      },
    }
  }
</script>

<style lang="less" scoped>
  @import "./common.less";
  .details {
    padding: 0 15px;
    margin-bottom: 10px;
    .title {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: #6D6868;
      padding: 20px 0 5px 0;
    }
    table {
      width: 100%;
      border-spacing: 0 7px;
      font-size: 10px;
      tr {
        background: #fff;
        box-shadow: 0 1px 1px 0 #ccc;
        td {
          padding: 10px 5px;
          text-align: center;
          &:first-child {
            padding-left: 8px;
          }
          &:last-child {
            padding-right: 8px;
          }
          &.required {
            padding-left: 0;
            > i {
              color: red;
            }
          }
          &.input {
            padding: 5px;
            input {
              border: none;
              width: 50px;
              height: 100%;
              display: inline-block;
              text-align: center;
              &::-webkit-input-placeholder {
                color: #ccc;
              }
            }
          }

        }
      }
    }
  }
  .form {
    .signPanel {
      //background: #F5F5F5;
      border-radius: 6px;
      overflow: hidden;
      height: 120px;
      position: relative;
      .clear {
        position: absolute;
        right: 5px;
        top: 5px;
        padding: 3px 5px;
        color: #999;
        &:active {
          color: #ccc;
        }
      }
    }
    .uploadPanel {
      text-align: left;
    }
  }
  .footer {
    width: 100%;
    padding: 10px;
  }
</style>
