
  import common from '@mixins/common'
  import { mapGetters } from 'vuex'
  import _ from 'lodash'
  import * as assetsApi from '@api/assets'
  import imageCompression from 'browser-image-compression'
  import { ImagePreview } from 'vant'

  const productPropertyList = [
    { label: "易耗品", value: 1 },
    { label: "低值品", value: 2 },
    { label: "固定资产", value: 3 }
  ]
  const productPropertyMap = _.mapValues(_.keyBy(productPropertyList, i => i.value), j => j.label)

  export default {
    name: 'AssetsCheckIndex',
    mixins: [common],
    computed: {
      ...mapGetters('user', [
        'userInfo',
        'projectInfo'
      ])
    },
    data() {
      return {
        projectId: '',
        projectName: '',
        spaceFullCode: '',
        spaceFullName: '',
        spaceType: '',
        checkId: '',
        productList: [
        ],

        currentItem: null,
        saveCheckReasonShow: false,
        reason: '',

        productPropertyMap,
        productListShow: false,
        productListGets: [],
        productListSearchKeyword: '',
        productListSearchPageNum: 1,
        productListSearchPageSize: 99,
        productListSearchTotal: 0,
        productListResultShow: false,
        currentProduct: {
          id: '',
          productName: '',
          productProperty: '',
          standard: '',
          productNo: '',
          count: 0,
          mode: 0,
          checks: {},
          dingDeptId: 0,
          dingDeptName: '',
          assetsTypeName: '',
          addImgType: 0,
          pictureUrls: [],
          standardDesId: '',
          standardDesName: ''
        },
        stockInImgList: [],
        gdListShow: false,
        gdList: [],

        deptListShow: false,
        deptList: [],

        outSpaceListShow: false,
        outSpaceList: [],
        currentProductIndex: -1,
        currentProductProperty: 0,
        outSpaceListPageNum: 1,
        outSpaceListPageSize: 10,
        outSpaceListTotal: 0,
        checks: {},

        ownerDesListShow: false,
        ownerDesList: [],
        addOwnerDesDialogShow: false,
        addOwnerDesValue: '',

        standardDesListShow: false,
        standardDesList: [],
        addStandardDesDialogShow: false,
        addStandardDesValue: ''
      }
    },
    mounted() {
      this.applicationPerson = this.userInfo.nickName
      this.projectId = this.$route.query.projectId
      this.projectName = this.$route.query.projectName
      this.spaceFullCode = this.$route.query.spaceFullCode
      this.spaceFullName = this.$route.query.spaceFullName
      this.spaceType = this.$route.query.spaceType
      this.getData()
    },
    methods: {
      onPreview(url) {
        ImagePreview([url])
      },
      getData() {
        assetsApi.getCheckOrInit({
          projectId: this.projectId,
          projectName: this.projectName,
          spaceFullCode: this.spaceFullCode,
          spaceName: this.spaceFullName,
          spaceType: this.spaceType
        }).then(res => {
          let item = res.data.data
          this.checkId = item.id
          if (res.data.data.detailList) {
            this.productList = item.detailList
          }
        })
      },
      onCheckNumChange: _.debounce((item, val) => {
        assetsApi.modifyCheckNum({
          id: item.id,
          checkNum: val
        })
      }, 700),
      onSaveCheck(item) {
        this.currentItem = item
        if (item.stockNum === item.checkNum) {
          this.reason = ''
          this.doSaveCheck()
        } else {
          this.reason = this.currentItem.reason || ''
          this.onShowSaveCheckReason()
        }
      },
      doSaveCheck() {
        assetsApi.saveCheck({
          id: this.currentItem.id,
          checkNum: this.currentItem.checkNum,
          reason: this.reason
        }).then(res => {
          this.$toast('保存成功')
          this.getData()
        })
      },
      onShowSaveCheckReason() {
        this.saveCheckReasonShow = true
      },
      onSaveCheckReason(action, done) {
        if (action === 'confirm') {
          if (this.reason == '') {
            this.$toast('请填写原因')
            done(false)
            return
          }
          assetsApi.saveCheck({
            id: this.currentItem.id,
            checkNum: this.currentItem.checkNum,
            reason: this.reason
          }).then(res => {
            done()
            this.$toast('保存成功')
            this.getData()
          }).catch(e => {
            done(false)
          })
        } else {
          done()
        }
      },

      onAddItem() {
        this.currentProduct = {}
        this.productListSearchKeyword = ''
        this.productListGets = []
        this.productListResultShow = false
        this.productListShow = true
      },
      onProductListSearch() {
        let params = {
          productName: this.productListSearchKeyword,
          pageNum: this.productListSearchPageNum,
          pageSize: this.productListSearchPageSize
        }
        assetsApi.getProductList(params).then(res => {
          this.productListGets = res.data.data
          this.productListSearchTotal = res.data.count
          this.productListResultShow = true
        })
      },
      onProductListSearchPage() {
        this.onProductListSearch()
      },
      onProductListSelect(product) {
        this.currentProduct = {
          id: product.id,
          productName: product.productName,
          productProperty: product.productProperty,
          standard: product.standard,
          productNo: '',
          count: product.productProperty === 3 ? 1 : (product.count || 0),
          mode: 0,
          checks: {},
          dingDeptId: 0,
          dingDeptName: '',
          assetsTypeName: product.assetsTypeName,
          addImgType: product.addImgType || 0,
          pictureUrls: [],
          standardDesId: '',
          standardDesName: ''
        }
        this.productListResultShow = false
      },
      onProductSelectConfirm(action, done) {
        if (action === 'confirm') {
          if (!this.currentProduct.id) {
            this.$toast('未选择资产')
            done(false)
            return
          }
          if (this.currentProduct.productProperty === 3 && this.currentProduct.mode === 0 && !this.currentProduct.dingDeptId) {
            this.$toast('未选择编码部门')
            done(false)
            return
          }
          if (this.currentProduct.productProperty === 3 && this.currentProduct.mode === 1 && !this.currentProduct.productNo) {
            this.$toast('未选择已有编号')
            done(false)
            return
          }
          if (this.currentProduct.addImgType == 1 && this.currentProduct.pictureUrls.length == 0) {
            this.$toast('未上传资产照片')
            done(false)
            return
          }
          let data = {
            checkId: this.checkId,
            productId: this.currentProduct.id,
            productNo: '',
            dingDeptId: 0,
            count: this.currentProduct.count,
            pictureUrl: this.currentProduct.pictureUrls.length ? this.currentProduct.pictureUrls[0].url : '',
            standard: this.currentProduct.standard,
            standardDesId: this.currentProduct.standardDesId,
            standardDesName: this.currentProduct.standardDesName
          }
          if (this.currentProduct.productProperty === 3) {
            if (this.currentProduct.mode === 0) {
              data.dingDeptId = this.currentProduct.dingDeptId
            } else if (this.currentProduct.mode === 1) {
              data.productNo = this.currentProduct.productNo
            }
          }
          assetsApi.addCheckAdditional(data).then(res => {
            done()
            this.currentProduct = {}
            this.productListSearchKeyword = ''
            this.productListGets = []
            this.productListResultShow = false
            this.productListShow = false
            this.getData()
            let gdList = res.data.data && res.data.data.detailList
            if (gdList && gdList.length) {
              this.gdList = gdList
              this.gdListShow = true
            } else {
              this.$toast('添加成功')
            }
          }).catch(e => {
            done(false)
          })
        } else {
          done()
        }
      },
      onGdListConfirm() {
        this.gdListShow = false
      },
      onSubmit() {
        this.$dialog.confirm({
          message: '是否确认提交？'
        }).then(async () => {
          this.doCommit()
        }).catch(() => {
          // on cancel
        })
      },
      doCommit() {
        assetsApi.commitCheck({
          id: this.checkId,
          status: 1
        }).then(res => {
          this.$router.replace({ name: 'AssetsCheckSuccess' })
        })
      },

      onDeptListShow() {
        assetsApi.getDingDeptList().then(res => {
          if (res.data.data) {
            this.deptList = res.data.data
          }
        })
        this.deptListShow = true
        this.$nextTick(() => {
          let value = this.currentProduct.dingDeptId
          let selectIndex = this.deptList.findIndex(i => i.dingDeptId === value)
          this.$refs.deptList.setIndexes([selectIndex])
        })
      },
      onDeptListSelect(item) {
        this.currentProduct.dingDeptId = item.dingDeptId
        this.currentProduct.dingDeptName = item.dingDeptName
        this.deptListShow = false
      },

      onOutSpaceListShow() {
        if (!this.currentProduct.id) {
          this.$toast('请先搜索资产')
          return
        }
        this.currentProductProperty = this.currentProduct.productProperty
        this.outSpaceList = []
        this.outSpaceListPageNum = 0
        this.outSpaceListTotal = 0
        this.checks = this.currentProduct.checks || {}
        this.outSpaceListShow = true
        this.onOutSpaceListPage()
      },
      onOutSpaceListPage() {
        let productId = this.currentProduct.id
        assetsApi.getStockProductList({
          notInSpaceFullCode: this.spaceFullCode,
          productId,
          minCount: 0,
          pageNum: this.productListSearchPageNum,
          pageSize: this.productListSearchPageSize
        }).then(res => {
          let list = res.data.data || []
          this.outSpaceListTotal = res.data.count
          this.outSpaceList = list
        })
      },
      onProductCheck(item) {
        let obj = {
          ...item,
          stockId: item.id
        }
        let checked = this.checks[item.id]
        if (!checked) {
          this.checks = {
            [item.id]: obj
          }
        }
      },
      onProductCancel() {
        this.outSpaceListShow = false
        this.checks = {}
      },
      onProductConfirm() {
        this.outSpaceListShow = false
        this.currentProduct.checks = this.checks
        for (let key in this.checks) {
          let obj = this.checks[key]
          if (obj) {
            this.currentProduct.productNo = obj.productNo
            this.currentProduct.pictureUrls = [{ url: obj.pictureUrl }]
          }
        }
      },
      onCurrentProductModeChange(val) {
        if (val === 0) {
          this.currentProduct.productNo = ''
          this.currentProduct.pictureUrls = []
        } else {
          this.currentProduct.dingDeptId = 0
          this.currentProduct.dingDeptName = ''
        }
      },
      afterFileRead(item) {
        let up = this.$toast.loading({ forbidClick: true, duration: 0 })
        imageCompression(item.file, {
          maxSizeMB: 0.2,
          initialQuality: 0.7
        }).then(file => {
          assetsApi.fileUpload(item).then(res => {
            this.$toast.clear()
            const cb = res.data
            if (cb.code === 200) {
              item.fileType = 1
              item.url = cb.data.url
              item.fileUrl = cb.data.url
              item.status = 'success'
              item.message = '上传成功'
            } else {
              item.status = 'failed'
              item.message = '上传失败'
            }
          }).catch(e => {
            this.$toast.clear()
            item.status = 'failed'
            item.message = '上传失败'
          })
        }).catch(() => {
          this.$toast.clear()
          item.status = 'failed'
          item.message = '上传失败'
        })
      },
      onOwnerDesListShow() {
        if (!this.currentProduct.id) {
          this.$toast('请先选择商品')
          return
        }
        this.getOwnerDesList()
        this.ownerDesListShow = true
      },
      getOwnerDesList() {
        assetsApi.getStandardDesList({
          sourceType: 'ownerType',
          sourceId: -1
        }).then(res => {
          this.ownerDesList = res.data.data
        })
      },
      onOwnerDesListSelect(item) {
        this.currentProduct.ownerType = item.id
        this.currentProduct.ownerTypeName = item.name
        this.ownerDesListShow = false
      },
      onAddOwnerDes() {
        this.addOwnerDesValue = ''
        this.addOwnerDesDialogShow = true
      },
      onAddOwnerDesCancel() {
        this.addOwnerDesDialogShow = false
      },
      onAddOwnerDesSubmit(action, done) {
        if (action === 'confirm') {
          if (this.addOwnerDesValue == '') {
            this.$toast('请输入名称')
            done(false)
            return
          }
          assetsApi.addStandardDes({
            sourceId: -1,
            sourceType: 'ownerType',
            name: this.addOwnerDesValue
          }).then(res => {
            done()
            this.$toast('添加成功')
            this.getOwnerDesList()
          }).catch(() => {
            done(false)
          })
        } else {
          done()
        }
      },
      onStandardDesListShow() {
        if (!this.currentProduct.id) {
          this.$toast('请先选择商品')
          return
        }
        this.getStandardDesList()
        this.standardDesListShow = true
      },
      getStandardDesList() {
        assetsApi.getStandardDesList({
          sourceType: 'ppggxq',
          sourceId: this.currentProduct.id
        }).then(res => {
          this.standardDesList = res.data.data
        })
      },
      onStandardDesListSelect(item) {
        this.currentProduct.standardDesId = item.id
        this.currentProduct.standardDesName = item.name
        this.standardDesListShow = false
      },
      onAddStandardDes() {
        this.addStandardDesValue = ''
        this.addStandardDesDialogShow = true
      },
      onAddStandardDesCancel() {
        this.addStandardDesDialogShow = false
      },
      onAddStandardDesSubmit(action, done) {
        if (action === 'confirm') {
          if (this.addStandardDesValue == '') {
            this.$toast('请输入名称')
            done(false)
            return
          }
          assetsApi.addStandardDes({
            sourceId: this.currentProduct.id,
            sourceType: 'ppggxq',
            name: this.addStandardDesValue
          }).then(res => {
            done()
            this.$toast('添加成功')
            this.getStandardDesList()
          }).catch(() => {
            done(false)
          })
        } else {
          done()
        }
      }
    }
  }
