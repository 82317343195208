<template>
<div class='page'>
  <div class='card'>
    <div class='card-top'>
      <p class='title'>扫码支付账单</p>
      <p class='price'>¥<span>{{ info.totalAmount }}</span></p>
      <p class='des'>支付金额</p>
    </div>
    <div class='card-bottom'>
      <p class='user'>{{ info.tenantName }}-{{ info.tenantPhone }}</p>
      <p class='project'>{{ info.shareText }}</p>
      <div class='qrCode'>
        <img :src='info.payQrCode' alt=''>
      </div>
      <div class='des'>
        <p>
          <img src='../../assets/images/icon-alipay.png' alt=''>
          支付宝
        </p>
        <p>
          <img src='../../assets/images/icon-wechat.png' alt=''>
          微信支付
        </p>
      </div>
    </div>
  </div>
  <div class='share'>
    <div class='title'>其它邀请方式</div>
    <div class='link'>
      <div class='item' @click='onCopy'>
        <a>
          <img src='../../assets/images/icon-link.png' alt=''>
          <p>复制链接</p>
        </a>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  import { getCost, getPayQrCodeByCost } from '@/api/clean'
  export default {
    name: 'CleanPayment',
    props: ['id'],
    data() {
      return {
        info: {}
      }
    },
    mounted() {
      this.getPage()
    },
    methods: {
      getPage() {
        getCost({ id: this.id }).then(res => {
          if (res.data.code === 200) {
            getPayQrCodeByCost({ costId: res.data.result.id }).then(res => {
              if (res.data.code === '10000') {
                this.info = res.data.data
              }
            })
          }
        })
      },
      onCopy() {
        let input = document.createElement('input')
        input.value = this.info.payQrUrl
        document.body.appendChild(input)
        input.select()
        document.execCommand('Copy')
        document.body.removeChild(input)
        this.$toast('复制成功')
      }
    }
  }
</script>

<style lang='less' scoped>
.page{
  width: 100%;
  height: 100%;
  background: #71A3F9;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  .card{
    width: 322px;
    height: 415px;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 100px;
    transform: translateX(-50%);
    background: url("~@/assets/images/payment.png") no-repeat left top / 100% 100%;
    padding: 0 30px;
    .card-top{
      height: 150px;
      border-bottom: 1px solid #E5E5E5;
      box-sizing: border-box;
      .title{
        font-size: 16px;
        font-family: PingFang SC, PingFang SC;
        color: #333;
        margin-top: 16px;
      }
      .price{
        margin-top: 25px;
        font-size: 14px;
        span{
          font-size: 34px;
          font-weight: 600;
          margin-left: 5px;
        }
      }
      .des{
        font-size: 14px;
      }
    }
    .card-bottom{
      height: 266px;
      .user{
        margin-top: 20px;
        font-size: 14px;
        color: #333;
      }
      .project{
        font-size: 14px;
        color: #999;
        margin-top: 8px;
      }
      .qrCode{
        margin-top: 15px;
        img{
          width: 105px;
          height: 105px;
          //padding: 9px;
          border: 1px dashed #ccc;
          background: #fff;
        }
      }
      .des{
        display: flex;
        justify-content: center;
        margin-top: 18px;
        p{
          padding: 0 8px;
          img{
            width: 21px;
            height: 21px;
            vertical-align: middle;
          }
        }
      }
    }
  }
  .share{
    width: 270px;
    position: absolute;
    left: 50%;
    top: 550px;
    transform: translateX(-50%);
    color: #fff;
    .title{
      height: 20px;
      text-align: center;
      background: url("~@/assets/images/icon-line.png") no-repeat center center / 100% 1px;
    }
    .link{
      display: flex;
      justify-content: center;
      text-align: center;
      padding-top: 14px;
      .item{
        a{
          color: #fff;
          img{
            width: 42px;
            height: 42px;
          }
        }
      }
    }
  }
}
</style>
