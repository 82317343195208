<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="routeMetaTitle" @go-back="goBack">
        </cyui-header>
      </template>
      <template #default>
        <div class="page">
          <div class="list">
            <div class="item" v-for="(item,index) in detailList" :key="index">
              <div class="title"><span>申请明细：第{{ index+1 }}条</span></div>
              <van-cell-group class='cell-group'>
                <van-cell title="资产属性"  :value="productPropertyMap[item.productProperty]||'-'"></van-cell>
                <van-cell title="资产名称" :value="item.productName||'-'"></van-cell>
                <van-cell title="主规格" :value="item.standard||'-'"></van-cell>
                <van-cell title="资产编号" :value="item.productNo||'-'"></van-cell>
                <van-cell title="调出仓库" :value="item.outStockName||'-'"></van-cell>
                <van-cell title="调入价格/元" :value="item.inPrice||'-'"></van-cell>
                <van-cell title="单位" :value="item.unit||'-'" ></van-cell>
                <van-cell title="调入数量" v-if="item.productProperty===3">
                  <template #default>
                    <van-stepper v-model="item.inNumTotal" integer :min="0" :max="1" />
                  </template>
                </van-cell>
                <van-cell title="调入数量" v-else>
                  <template #default>
                    <van-stepper v-model="item.inNumTotal" integer :min="0" />
                  </template>
                </van-cell>
              </van-cell-group>
            </div>
          </div>
          <van-cell-group class='cell-group'>
            <van-cell title="调入仓库" required is-link @click="onInSpaceListShow">
              <template #default>
                <div class="pickerText" :class="{disabled:spaceName===''}">{{spaceName || '请选择'}} </div>
              </template>
            </van-cell>
            <van-cell title="调出管理员" :value="outKeeper||'-'"></van-cell>
            <van-cell title="入库人" :value="stockUser||'-'"></van-cell>
            <van-cell title="调拨总金额/元" :value="totalPrice"></van-cell>
          </van-cell-group>
          <van-cell-group class='cell-group form' >
            <template #title>
              <div class="cell-group-title"><span>其他信息</span></div>
            </template>
            <div class="remark0">
              <div class="title">说明</div>
              <div class="value">{{ remark0 || '-'}}</div>
            </div>
            <van-field class="vtextarea" type='textarea' label='备注' v-model='remark' rows='3' autosize
                       input-align='left' placeholder="请输入"
            />
            <van-cell title="入库人签字" required>
              <template #default>
                <div class="signPanel">
                  <vue-esign ref="esign" bgColor="#F5F5F5" :isClearBgColor="false" format="image/jpeg" />
                  <div class="clear" @click.stop="onClearSign">清空画布</div>
                </div>
              </template>
            </van-cell>
            <van-cell title="上传照片" required>
              <template #default>
                <div class="uploadPanel">
                  <van-uploader v-model="stockInImgList" :after-read='afterFileRead' />
                </div>
              </template>
            </van-cell>
          </van-cell-group>
        </div>
      </template>
      <template #footer>
        <div class="footer">
          <van-button block type="primary" size="large" @click="onConfirm">提交</van-button>
        </div>
      </template>
    </cyui-layout>
    <van-popup v-model="inSpaceListShow" position="bottom">
      <van-picker ref="inSpaceList" show-toolbar value-key="spaceName" :columns="inSpaceList" @confirm="onInSpaceListSelect" @cancel="inSpaceListShow = false" />
    </van-popup>
    <van-dialog v-model="gdListShow" title="注意" confirm-button-text="我已知晓" @confirm="onGdListConfirm">
      <div class="gdList">
        <div class="title">此次盘点资产中，有以下固定资产被赋予资产编号</div>
        <table>
          <thead>
          <tr>
            <td>资产名称</td>
            <td>主规格</td>
            <td>资产编号</td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) in gdList" :key="index">
            <td>{{ item.productName || '-' }}</td>
            <td>{{ item.standard || '-' }}</td>
            <td>{{ item.productNo || '-' }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </van-dialog>
  </div>
</template>

<script>
  import common from '@mixins/common'
  import { mapGetters } from 'vuex'
  import _ from 'lodash'
  import * as assetsApi from '@api/assets'
  import moment from 'moment'
  import vueEsign from 'vue-esign'
  import imageCompression from 'browser-image-compression'

  const productPropertyList = [
    {label: "易耗品", value: 1},
    {label: "低值品", value: 2},
    {label: "固定资产", value: 3},
  ]
  const productPropertyMap = _.mapValues(_.keyBy(productPropertyList, i=>i.value), j=>j.label);

  export default {
    name: 'AssetsInDetail2',
    mixins: [common],
    components: {
      vueEsign
    },
    computed: {
      ...mapGetters('user', [
        'userInfo',
        'projectInfo',
      ]),
      totalPrice() {
        let sum = 0;
        this.detailList.forEach(product => {
          try {
            let price = Math.round(parseFloat((product.inPrice || 0)) * 100);
            sum += price * (product.inNumTotal || 0);
          } catch (e){

          }
        })
        return sum / 100;
      }
    },
    data() {
      return {
        from: '',
        sourceId: '',
        sourceTitle: '',
        sourceType: '',
        inApplicant:'',
        spaceFullCode: '',
        spaceName: '',
        remark: '',
        stockUser: '',
        detailList: [],
        stockUserSignUrl: '',
        stockInImgList: [],

        outKeeper: '',
        remark0: '',
        productPropertyMap,
        inSpaceListShow: false,
        inSpaceList:[],
        gdListShow: false,
        gdList: [],
      }
    },
    created() {
      this.sourceId = this.$route.query.sourceId;
      this.sourceTitle = this.$route.query.sourceTitle;
      this.sourceType = this.$route.query.sourceType;
      this.stockUser = this.userInfo.nickName;
      this.projectId = this.projectInfo.dcProjectId;
      this.outTime = moment().format('YYYY-MM-DD HH:mm:ss');
      this.getData();
    },
    methods: {
      getData(){
        assetsApi.getStockInDetailInit({sourceId:this.sourceId,sourceType: this.sourceType}).then(res=>{
          let item =  res.data.data;

          this.outKeeper = item.outUser;
          this.remark0 = item.applyDesc;
          // let sum = 0;
          for (let product of item.detailList) {
            // let price = Math.round(parseFloat(product.inPrice) * 100);
            // sum += price * product.inNumTotal;
            product.outStockId = item.outSpaceFullCode;
          }
          this.detailList = item.detailList;
          // this.totalPrice = sum / 100;
        })
      },
      onInSpaceListShow() {
        let { dcProjectId } = this.projectInfo;
        assetsApi.getSpaceListByRole(dcProjectId).then(res=>{
          if (res.data.data.result) {
            this.inSpaceList = res.data.data.result;
            this.inSpaceListShow=true;
            this.$nextTick(()=>{
              let value = this.spaceFullCode;
              let selectIndex = this.inSpaceList.findIndex(i=>i.spaceFullCode===value);
              this.$refs.inSpaceList.setIndexes([selectIndex]);
            })
          } else {
            this.inSpaceListShow=true;
          }
        })

      },
      onInSpaceListSelect(item) {
        this.spaceFullCode = item.spaceFullCode;
        this.spaceName = item.spaceFullName;
        this.inSpaceListShow=false;
      },
      onClearSign(){
        this.$refs.esign.reset();
        this.stockUserSignUrl = '';
      },
      afterFileRead(item) {
        let up = this.$toast.loading({forbidClick: true,duration:0})
        imageCompression(item.file, {
          maxSizeMB: 0.2,
          initialQuality: 0.7
        }).then(file=>{
          assetsApi.fileUpload(item).then(res=>{
            this.$toast.clear()
            const cb = res.data
            if (cb.code === 200) {
              item.fileType = 1;
              item.url = cb.data.url;
              item.fileUrl = cb.data.url;
              item.status = 'success'
              item.message = '上传成功'
            } else {
              item.status = 'failed';
              item.message = '上传失败';
            }
          }).catch(e=>{
            this.$toast.clear()
            item.status = 'failed';
            item.message = '上传失败';
          })
        }).catch(()=>{
          this.$toast.clear()
          item.status = 'failed';
          item.message = '上传失败';
        });
      },
      async onConfirm() {
        if (!this.spaceFullCode) {
          this.$toast('请选择调入仓库')
          return
        }
        if (!this.stockUserSignUrl) {
          let base64Str;
          try {
            base64Str = await this.$refs.esign.generate();
            if (base64Str) {
              base64Str = base64Str.substring(23);
            }
          } catch (e) {
            this.$toast('请签名')
            return
          }
          let res = await assetsApi.fileUploadBase64(base64Str);
          this.stockUserSignUrl = res.data.data.src;
        }
        if (this.stockInImgList.filter(i=>i.status=='success').length == 0) {
          this.$toast('请上传图片')
          return
        }
        this.$dialog.confirm({
          message: '请确认是否提交？',
        }).then(()=>{
          assetsApi.inStock({
            sourceType: this.sourceType,
            sourceId: this.sourceId,
            sourceTitle: this.sourceTitle,
            spaceFullCode: this.spaceFullCode,
            spaceName: this.spaceName,
            projectId: this.projectId,
            stockUserSignUrl: this.stockUserSignUrl,
            stockInImgList: this.stockInImgList.map(i=>{return {fileUrl:i.fileUrl}}),
            ourUser: this.outKeeper,
            remark: this.remark,
            detailList: this.detailList,
          }).then(res=>{
            if (res.data.data && res.data.data.guDingStockList && res.data.data.guDingStockList.length) {
              this.gdList = res.data.data.guDingStockList;
              this.gdListShow = true;
            } else {
              this.$router.replace({name: 'AssetsInSuccess',query:{sourceType: this.sourceType}})
            }
          })
        }).catch(() => {
          // on cancel
        });
      },
      onGdListConfirm() {
        this.gdListShow = false;
        this.$router.replace({name: 'AssetsInSuccess',query:{sourceType: this.sourceType}})
      },
    }
  }
</script>

<style lang="less" scoped>
  @import "./common.less";
  .cyui-layout-wrapper {
    background: #F6F6F6;
  }
  .page {
    padding: 10px;
  }
  .cell-group {
    margin-bottom: 10px;
  }
  .list {
    margin-bottom: 10px;
    .item {
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 3px 0 5px;
        margin-bottom: 10px;
        font-size: 14px;
        color: #6D6868;
        /deep/ .van-icon {
          font-size: 18px;
          color: #999999;
        }
      }
    }
  }
  .form {
    .remark0 {
      display: flex;
      position: relative;
      display: flex;
      box-sizing: border-box;
      width: 100%;
      padding: 10px 16px;
      overflow: hidden;
      color: #323233;
      font-size: 14px;
      line-height: 24px;
      background-color: #fff;
      &::after {
        position: absolute;
        box-sizing: border-box;
        content: ' ';
        pointer-events: none;
        right: 16px;
        bottom: 0;
        left: 16px;
        border-bottom: 1px solid #ebedf0;
        transform: scaleY(0.5);
      }
      .title {
        flex: none;
        white-space: nowrap;
      }
      .value {
        flex: 1;
        position: relative;
        overflow: hidden;
        color: #969799;
        text-align: right;
        vertical-align: middle;
        word-wrap: break-word;
      }
    }
    .signPanel {
      //background: #F5F5F5;
      border-radius: 6px;
      overflow: hidden;
      height: 120px;
      position: relative;
      .clear {
        position: absolute;
        right: 5px;
        top: 5px;
        padding: 3px 5px;
        color: #999;
        &:active {
          color: #ccc;
        }
      }
    }
    .uploadPanel {
      text-align: left;
    }
  }
  .footer {
    width: 100%;
    padding: 10px;
  }
</style>
