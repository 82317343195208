<template>
  <div class="cyui-list-item cyui-list-multiple cyui-house-list-item" @click="goDetail">
    <div class="cyui-list-line">
      <div class="cyui-list-thumb">
        <span class="cyui-house-list-tag">房号</span>
      </div>
      <div class="cyui-list-content">
        <div class="cyui-house-list-title">{{ detailInfo.projectName }}</div>
        <div class="cyui-house-list-brief">{{ detailInfo.roomNo }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import filters from '@filters/filters'

  export default {
    name: 'HouseListItem',
    mixins: [filters],
    components: {},
    data() {
      return {}
    },
    props: {
      detailInfo: {
        type: Object,
        require: true,
        default() {
          return {}
        }
      }
    },
    computed: {},
    methods: {
      goDetail() {
        this.$emit('go-detail', this.detailInfo)
      },
      async initData() {
      }
    },
    filters: {},
    watch: {},
    created() {
      this.initData()
    },
    mounted() {
    }
  }
</script>

<style lang="less" scoped>
  @import '../../../assets/styles/themes/default/index';
  @import '../../../assets/styles/mixins/index';

  .@{css-prefix}house-list-item {
    .@{css-prefix}house-list-tag {
      position: relative;
      display: block;
      width: 18px;
      padding: 4px 0;
      line-height: 20px;
      text-align: center;
      font-size: 12px;
      color: #FFFFFF;
      border-radius: 2px;
      background-color: #D8B084;
    }

    .@{css-prefix}house-list-title {
      line-height: 28px;
      font-size: 14px;
      font-weight: bold;
      color: #D8B084;
    }

    .@{css-prefix}house-list-brief {
      line-height: 24px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
    }
  }

</style>
