<template>
  <div class='cyui-layout-wrapper'>
    <cyui-layout>
      <template #header>
        <cyui-header v-if='isCustomHeader' :title='headerTitle'
                     @go-back='goBack' @on-close='handleClosePage'>
          <template #headerRight>
            <div></div>
          </template>
        </cyui-header>
        <div class='cyui-maintain-navbar'>
          <van-search v-model='searchKey.roomOrContactsName' placeholder='请输入房号或姓名' show-action
                      @search='onSearch'>
            <template #action>
              <div @click='onSearch'>搜索</div>
            </template>
          </van-search>
          <cyui-navbar v-model='categoryInfo.value' :swipe-threshold='2'
                       show-title-number :list-data='categoryInfo.listData'
                       @change='handleTabChange'>
          </cyui-navbar>
        </div>
      </template>
      <template #default>
        <div class='cyui-scroller-panel'>
          <cyui-scroller class='wrapper' ref='scroller'
                         :scrollbar='scrollbarConfig'
                         :pullDownRefresh='pullDownRefreshConfig'
                         :pullUpLoad='pullUpLoadConfig' :startY='parseInt(startY)'
                         @pullingDown='handlePullingDown' @pullingUp='handlePullingUp'>
            <template #default>
              <div class='cyui-maintain-list' v-if='listData.length'>
                <maintain-list-item v-for='(item, index) in listData'
                                    :key='index' :detail-info='item' @go-detail='goDetail(item)'>
                </maintain-list-item>
              </div>
            </template>
          </cyui-scroller>
        </div>
        <no-data :msg='noDataMessage'
                 v-if='listData.length === 0 && isLoading === false'>
          <template #btn>
            <div
              class='cyui-btn cyui-btn-default cyui-btn-inline cyui-btn-radius'
              @click='handleRefreshPage' v-if='isRefreshPage'>
              刷新重试
            </div>
          </template>
        </no-data>
      </template>
      <template #footer>
        <div class='cyui-actionbar'>
          <div class='cyui-actionbar-item cyui-actionbar-btns'>
            <button type='button'
                    class='cyui-btn cyui-btn-primary cyui-btn-block'
                    @click='handleGoGD'>
              发起工单
            </button>
          </div>
        </div>
      </template>
    </cyui-layout>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import common from '@mixins/common'
  import scrollList from '@mixins/scrollList'
  import getPageList from '@mixins/getPageList'
  import filters from '@filters/filters'
  import { MaintainListItem } from './components'
  import { getListAPI } from '@api/maintenance'

  const categoryListData = [
    {
      id: '',
      label: '全部',
      number: 0,
      children: []
    },
    {
      id: '1',
      label: '待接单',
      number: 0,
      children: []
    },
    {
      id: '2',
      label: '待处理',
      number: 0,
      children: []
    },
    {
      id: '3',
      label: '处理中',
      number: 0,
      children: []
    },
    {
      id: '4',
      label: '已完成',
      number: 0,
      children: []
    },
    {
      id: '5',
      label: '已取消',
      number: 0,
      children: []
    },
    {
      id: '6',
      label: '已关闭',
      number: 0,
      children: []
    }
  ]

  export default {
    name: 'MaintainList',
    mixins: [common, filters, scrollList, getPageList],
    components: {
      MaintainListItem
    },
    data() {
      return {
        headerTitle: '维修记录',
        categoryInfo: { // 分类数据
          value: '', //
          listData: categoryListData
        },
        initParams: { // 初始化参数
          page: 1,
          size: 20
        },
        searchKey: {} // 搜索参数
      }
    },
    props: {
      status: {
        type: [Number, String],
        default: ''
      }
    },
    computed: {
      ...mapGetters('user', [
        'projectInfo'
      ])
    },
    methods: {
      // 进入详情
      goDetail(data) {
        let { id } = data
        this.$router.push({
          name: 'maintenanceDetail',
          query: {
            id
          }
        })
      },
      // 创建工单
      handleGoGD() {
        this.$router.push({
          name: 'maintenanceIndex'
        })
      },
      handleTabChange({ name }) {
        if (name !== this.status) {
          this.$router.replace({
            name: 'MaintainList',
            query: {
              status: name
            }
          })
        }
      },
      async getListData({
                          isPullDown,
                          isShowLoading
                        } = {
        isPullDown: false,
        isShowLoading: true
      }) { // 获取列表数据
        try {
          this.isLoading = true
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let {
            status,
            listData
          } = this
          let {
            page,
            size,
            roomOrContactsName
          } = this.searchKey
          let { dcProjectId } = this.projectInfo
          let response = await getListAPI({
            pageNum: page,
            pageSize: size,
            roomOrContactsName,
            type: 1,
            projectId: dcProjectId,
            status
          })
          let result = response.data
          // console.log(result)
          if (`${result.code}` == 200) { // 请求成功
            let resultData = result.data || []
            // let hasMore = resultData.length > 0
            let hasMore = page < result.pages
            this.listData = !isPullDown
              ? [...listData, ...resultData]
              : [...resultData]
            if (!hasMore) {
              this.isLastPage = true
              // this.$toast('没有更多数据了！')
            }
            await this.handleCallback({
              isError: false,
              hasMore
            })
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '获取列表失败!'
            })
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '获取列表失败!'
          })
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async initData() {
        await this.resetParams()
        await this.getListData()
      },
      onSearch() {
        this.resetParams()
        this.getListData()
      }
    },
    filters: {},
    watch: {
      initParams: {
        handler: function(newVal, oldVal) {
          if (JSON.stringify(newVal) !== JSON.stringify(this.searchKey)) {
            this.searchKey = JSON.parse(JSON.stringify(newVal))
          }
        },
        deep: true,
        immediate: true
      },
      status: {
        handler: function(newVal, oldVal) {
          this.searchKey = JSON.parse(JSON.stringify(this.initParams))
          this.categoryInfo.value = newVal
          this.initData()
        },
        immediate: true
      }
    },
    created() {
      const { token, projectId, userId, userName } = this.$route.query
      if (token && projectId) {
        this.$store.commit('user/SET_TOKEN', token)
        this.$store.commit('user/SET_USER_INFO', { dcProjectId: projectId, userId, userName })
        this.$store.commit('user/SET_PROJECT_INFO', { dcProjectId: projectId })
      }
    },
    mounted() {
    },
    beforeRouteEnter(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    },
    beforeRouteUpdate(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    }
  }
</script>

<style lang='less' scoped>
  @import '../../assets/styles/themes/default/index';
  @import '../../assets/styles/mixins/index';

  @maintainPrefixCls: ~'@{css-prefix}maintain';

  .@{css-prefix}scroller-panel {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .sticky-btn {
      border-radius: 50%;
      position: absolute;
      top: 300px;
      z-index: 99;
      width: 50px;
      height: 50px;

      img {
        width: 50px;
        height: 50px;
      }
    }

    & /deep/ .pulldown-wrapper {
      top: 5px !important;
    }
  }

  .@{maintainPrefixCls} {
    &-navbar {
      padding: 0 5px;
      background-color: #ffffff;

      & /deep/ .cyui-navbar {
        background-color: #ffffff;

        &-body {
          .van-tabs {
            &.van-tabs--line {
              .van-tabs__wrap {
                .van-tab {
                  flex: none;
                  padding: 0 10px;

                  &.van-tab--active {
                    .@{css-prefix}navbar-title {
                      font-weight: normal;
                      color: #ffffff;
                      border-color: #d19c70;
                      background-color: #d19c70;

                      &-number {
                        background: #e5563f;
                      }
                    }
                  }
                }
              }
            }

            .van-tabs__nav--line {
              .van-tabs__line {
                display: none;
              }
            }
          }
        }

        &-title {
          position: relative;
          display: block;
          min-width: 74px;
          height: 26px;
          padding: 0 12px;
          line-height: 26px;
          font-size: 12px;
          color: #b9b9b9;
          text-align: center;
          border-radius: 14px;
          border: 1px solid #cfcfcf;
          background-color: #f1f1f1;

          &-number {
            position: absolute;
            top: 2px;
            right: 0;
            transform: translate3d(50%, -50%, 0);
            min-width: 18px;
            height: 18px;
            padding: 0 5px;
            line-height: 18px;
            border-radius: 18px;
            background: #e5563f;
          }
        }
      }
    }

    &-list {
      padding: 10px 10px 0;
    }
  }

  .@{css-prefix}actionbar {
    position: relative;
    height: 65px;
    background-color: transparent;

    .@{css-prefix}actionbar-btns {
      padding: 10px 43px 15px;

      .@{css-prefix}btn {
        height: 40px;
        line-height: 40px;
        background-color: #ffffff;
        font-size: 15px;
        border: 1px solid #d1a36f;
        border-radius: 20px;
        color: #d1a36f;

        &.@{css-prefix}btn-primary {
          color: #ffffff;
          background-color: #d1a36f;
        }

        &.@{css-prefix}btn-ghost {
        }
      }

      .@{css-prefix}btn + .@{css-prefix}btn {
        margin-left: 30px;
      }
    }
  }
</style>
