<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="routeMetaTitle" @go-back="goBack">
        </cyui-header>
      </template>
      <template #default>
        <div class="page">
          <van-cell-group class='cell-group'>
            <van-cell title="申请人" :value="applicationPerson||'-'"></van-cell>
            <van-cell title="申请部门" :value="projectName||'-'"></van-cell>
          </van-cell-group>
          <van-cell-group class='cell-group'>
            <van-cell title="领用类型" :value="receiveTypeMap[receiveType]||'-'"></van-cell>
            <van-field class="vtextarea" type='textarea' readonly label='申领原因' v-model='receiveReason' rows='3' autosize
                       input-align='left'
            />
          </van-cell-group>
          <div class="list">
            <div class="item" v-for="(item,index) in productList" :key="index">
              <div class="title"><span>申请明细：第{{ index+1 }}条</span></div>
              <van-cell-group class='cell-group'>
                <van-cell title="资产属性"  :value="productPropertyMap[item.productProperty]||'-'"></van-cell>
                <van-cell title="资产名称" :value="item.productName||'-'"></van-cell>
                <van-cell title="主规格" :value="item.standard||'-'"></van-cell>
                <van-cell title="资产编号" :value="item.productNo||'-'"></van-cell>
                <van-cell title="单位" :value="item.unit||'-'" ></van-cell>
                <van-cell title="申领仓库" :value="item.outSpaceName||'-'"></van-cell>
                <van-cell title="库存数量" :value="item.stockNum||'-'"></van-cell>
                <van-cell title="数量">
                  <template #default>
                    <van-stepper v-model="item.receiveNum" disabled />
                  </template>

                </van-cell>
                <van-cell title="投放位置" :value="item.inSpaceName||'-'"></van-cell>
              </van-cell-group>
            </div>
          </div>
          <van-cell-group class='cell-group form'>
            <van-cell title="上传附件">
              <template #default>
                <div class="uploadPanel">
                  <van-uploader v-model="stockInImgList" :show-upload="false" :deletable="false" />
                </div>
              </template>
            </van-cell>
          </van-cell-group>
        </div>
      </template>
    </cyui-layout>
  </div>
</template>

<script>
  import common from '@mixins/common'
  import { mapGetters } from 'vuex'
  import _ from 'lodash'
  import * as assetsApi from '@api/assets'
  import moment from 'moment'
  import { getAllocateOutDetail } from '@api/assets'

  const productPropertyList = [
    {label: "易耗品", value: 1},
    {label: "低值品", value: 2},
    {label: "固定资产", value: 3},
  ]
  const productPropertyMap = _.mapValues(_.keyBy(productPropertyList, i=>i.value), j=>j.label);

  const receiveTypeList = [
    {label: "维修领用", value: 1},
    {label: "保洁领用", value: 2},
    {label: "办公使用", value: 3},
    {label: "客户维系", value: 4},
  ];
  const receiveTypeMap = _.mapValues(_.keyBy(receiveTypeList, i=>i.value), j=>j.label);

  export default {
    name: 'AssetsTakeLogDetail',
    mixins: [common],
    computed: {
      ...mapGetters('user', [
        'userInfo',
        'projectInfo',
      ])
    },
    data() {
      return {
        id: '',
        applicationPerson:'',
        projectName:'',
        receiveType:'',
        receiveReason:'',
        attachment:'',
        productList: [],

        receiveTypeMap,
        productPropertyMap,
        stockInImgList: [],
      }
    },
    created() {
      this.id = this.$route.query.id;
      this.getData();
    },
    methods: {
      getData(){
        assetsApi.getTakeDetail(this.id).then(res=>{
          let item = res.data.data;
          this.applicationPerson = item.applicationPerson;
          this.projectId = item.projectId;
          this.projectName = item.projectName;
          this.receiveReason = item.receiveReason;
          this.receiveType = item.receiveType;
          this.productList = item.takeDetailVos;
          if (item.fileList) {
            item.fileList.forEach(i=>{
              i.url = i.fileUrl;
            });
            this.stockInImgList = item.fileList;
          }
        })

      },
    }
  }
</script>

<style lang="less" scoped>
  @import "./common.less";
  .cyui-layout-wrapper {
    background: #F6F6F6;
  }
  .page {
    padding: 10px;
  }
  .cell-group {
    margin-bottom: 10px;
  }
  .list {
    margin-bottom: 10px;
    .item {
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 3px 0 5px;
        margin-bottom: 10px;
        font-size: 14px;
        color: #6D6868;
        /deep/ .van-icon {
          font-size: 18px;
          color: #999999;
        }
      }
      /deep/ .van-stepper__input:disabled {
        color: #333;
        -webkit-text-fill-color: #333;
      }
    }
  }
  .rejectPanel {
    display: flex;
    flex-direction: column;
    .tip {
      font-size: 17px;
      color: #999999;
      padding: 0 10px;
    }
    /deep/ .van-field {
      &__label {
        color: #999;
      }
      &__value {
        border: none;
        background: #f5f5f5;
      }
    }
  }
  .form {
    .uploadPanel {
      text-align: left;
    }
  }
  .footer {
    width: 100%;
    padding: 10px;
  }
</style>
