<template>
  <div>
    <div class="DateRangePicker">
      <div class="title" v-if="title">{{ title }}</div>
      <div class="daterange" @click="onShow">
        <van-icon name="notes-o" />
        <div class="value">{{ startDate || '开始日期' }}</div>
        <div class="tip">至</div>
        <div class="value">{{ endDate || '结束日期' }}</div>
        <van-icon name="cross" size="16px" style="margin-right: 10px" color="#ccc" @click.stop="onClear" />
      </div>
    </div>
    <van-popup v-model="show" position="bottom" style="height:60vh;">
      <div class="van-picker__toolbar">
        <button type="button" class="van-picker__cancel" @click="onCancel">取消</button>
        <div class="van-ellipsis van-picker__title"></div>
        <button type="button" class="van-picker__confirm" @click="onConfirm">确认</button>
      </div>
      <van-calendar ref="calendar" :poppable="false" :show-title="false" type="range" :allow-same-day="true" :default-date="null" :min-date="minDate" :max-date="maxDate"
                    @confirm="onResult" :show-confirm="false" />
    </van-popup>
  </div>
</template>

<script>

  import moment from 'moment'

  export default {
    name: 'DateRangePicker',
    props:{
      title: {
        type: String,
        default: ''
      },
      value: {
        type: Array,
        default: null
      }
    },
    computed: {
      startDate() {
        if (this.value && this.value[0]) {
          return moment(this.value[0]).format('YYYY-MM-DD')
        } else {
          return  null
        }
      },
      endDate() {
        if (this.value && this.value[1]) {
          return moment(this.value[1]).format('YYYY-MM-DD')
        } else {
          return  null
        }
      }
    },
    data() {
      return {
        show: false,
        minDate: moment().add(-5, 'year').startOf('year').toDate(),
        maxDate: moment().add(5, 'year').endOf('year').toDate(),
        tempValue: null,
      }
    },
    methods: {
      onShow(){
        this.show = true;

        this.$nextTick(()=>{
          if (this.$refs.calendar) {
            console.log(this.value)
            this.$refs.calendar.reset(this.value)
            if (this.value && this.value[0]) {
              this.$refs.calendar.scrollToDate(this.value[0])
            } else {
              this.$refs.calendar.scrollToDate(new Date())
            }

          }
        })
      },
      onCancel() {
        this.show = false;
      },
      onResult(e) {
        console.log(e)
        if (e && e.length) {
          this.tempValue = [e[0], moment(e[1]).endOf('day').toDate()];
        } else {
          this.tempValue = e;
        }
      },
      onClear() {
        this.tempValue = null;
        this.$emit('input', null);
        this.$emit('change', null);
      },
      onConfirm() {
        this.$emit('input', this.tempValue);
        this.$emit('change', this.tempValue);
        this.show = false;
      }
    }
  }
</script>

<style lang="less" scoped>
  .DateRangePicker {
    padding: 15px 15px;
    display: flex;
    align-items: center;
    .title {
      font-size: 17px;
      font-weight: 400;
      margin-right: 10px;
    }
    .daterange {
      width: 0;
      flex: 1;
      height: 36px;
      background: #FFFFFF;
      border: 1px solid #CCCCCC;
      border-radius: 2px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      font-size: 14px;
      .van-icon {
        font-size: 17px;
        color: #ccc;
      }
      .van-icon-notes-o {
        margin-left: 15px;
      }
      .van-icon-cross {
        margin-right: 10px;
      }
      .value {
        flex: 1;
        padding: 0 10px;
        color: #ccc;
        text-align: center;
      }
      .tip {
        color: #333;
      }
    }
  }
</style>
