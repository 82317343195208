<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="routeMetaTitle" @go-back="goBack">
        </cyui-header>
      </template>
      <template #default>
        <div class="page">
          <img src="./images/dispatchApplySuccess.png">
          <div class="tip">调拨申请已发起</div>
          <div class="backBtn"><van-button type="primary" round block @click="goBack()">返回</van-button></div>
        </div>
      </template>
    </cyui-layout>
  </div>
</template>

<script>
  import common from '@mixins/common'

  export default {
    name: 'AssetsDispatchApplySuccess',
    mixins: [common],
    data() {
      return {

      }
    },
    methods: {

    }
  }
</script>

<style lang="less" scoped>
  @import "./common.less";

  .page {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20vh;

    img {
      width: 108px;
      height: auto;
      margin-bottom: 6px;
    }

    .tip {
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
      color: #333333;
    }
  }
</style>
