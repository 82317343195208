<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="routeMetaTitle" @go-back="goBack">
        </cyui-header>
      </template>
      <template #default>
        <div class="page">

          <div class="list">
            <div class="item" v-for="(item,index) in productList" :key="index">
              <div class="title"><span>申请明细：第{{ index+1 }}条</span></div>
              <van-cell-group class='cell-group'>
                <van-cell title="资产属性"  :value="productPropertyMap[item.productProperty]||'-'"></van-cell>
                <van-cell title="资产名称" :value="item.productName||'-'"></van-cell>
                <van-cell title="主规格" :value="item.standard||'-'"></van-cell>
                <van-cell title="资产编号" :value="item.productNo||'-'"></van-cell>
                <van-cell title="调出仓库" :value="item.outSpaceName||'-'"></van-cell>
                <van-cell title="调出价格/元" :value="item.outPrice||'-'"></van-cell>
                <van-cell title="单位" :value="item.unit||'-'" ></van-cell>
                <van-cell title="调出数量" :value="item.outNum||'-'"></van-cell>
              </van-cell-group>
            </div>
          </div>
          <van-cell-group class='cell-group'>
            <van-cell title="调出管理员" :value="outKeeper||'-'"></van-cell>
            <van-cell title="调出时间" :value="outTime||'-'"></van-cell>
          </van-cell-group>
          <van-cell-group class='cell-group'>
            <van-cell title="调入项目" :value="inProjectName||'-'"></van-cell>
            <van-cell title="调入管理员" :value="inApplicant||'-'"></van-cell>
            <van-cell title="调拨总金额/元" :value="totalPrice||'-'"></van-cell>
          </van-cell-group>
          <van-cell-group class='cell-group'>
            <template #title>
              <div class="cell-group-title"><span>其他信息</span></div>
            </template>
            <van-field class="vtextarea" type='textarea' readonly label='备注' v-model='remark' rows='3' autosize
                       input-align='left'
            />
          </van-cell-group>
        </div>
      </template>
    </cyui-layout>
  </div>
</template>

<script>
  import common from '@mixins/common'
  import { mapGetters } from 'vuex'
  import _ from 'lodash'
  import * as assetsApi from '@api/assets'

  const productPropertyList = [
    {label: "易耗品", value: 1},
    {label: "低值品", value: 2},
    {label: "固定资产", value: 3},
  ]
  const productPropertyMap = _.mapValues(_.keyBy(productPropertyList, i=>i.value), j=>j.label);

  export default {
    name: 'AssetsDispatchOutLogDetail',
    mixins: [common],
    computed: {
      ...mapGetters('user', [
        'userInfo',
        'projectInfo',
      ])
    },
    data() {
      return {
        from: '',
        allocateOutId: '',
        inApplicant:'',
        inSpaceCode: '',
        inProjectName: '',
        productList: [],
        outKeeper: '',
        outSpaceProjectId: '',
        outTime: '',
        remark: '',
        productPropertyMap,
        totalPrice: '',
        outSpaceListShow: false,
        outSpaceList:[],
      }
    },
    created() {
      this.allocateOutId = this.$route.query.allocateOutId;
      this.getData();
    },
    methods: {
      getData(){
        assetsApi.getAllocateOutDetail(this.allocateOutId).then(res=>{
          let item = res.data.data.result;
          this.inApplicant = item.inApplicant;
          this.inProjectName = item.inProjectName;
          this.totalPrice = item.totalPrice;
          this.productList = item.productList;
          this.outKeeper = item.outKeeper;
          this.outTime = item.outTime;
          this.remark = item.remark;
        })
      },
    }
  }
</script>

<style lang="less" scoped>
  @import "./common.less";
  .cyui-layout-wrapper {
    background: #F6F6F6;
  }
  .page {
    padding: 10px;
  }
  .cell-group {
    margin-bottom: 10px;
  }
  .list {
    margin-bottom: 10px;
    .item {
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 3px 0 5px;
        margin-bottom: 10px;
        font-size: 14px;
        color: #6D6868;
        /deep/ .van-icon {
          font-size: 18px;
          color: #999999;
        }
      }
    }
  }
  .rejectPanel {
    display: flex;
    flex-direction: column;
    .tip {
      font-size: 17px;
      color: #999999;
      padding: 0 10px;
    }
    /deep/ .van-field {
      &__label {
        color: #999;
      }
      &__value {
        border: none;
        background: #f5f5f5;
      }
    }
  }
  .footer {
    width: 100%;
    padding: 10px;
  }
</style>
