<template>
  <div class="cyui-layout-wrapper" style="background-color: #fff;">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="routeMetaTitle" @go-back="goBack">
        </cyui-header>
      </template>
      <template #default>
        <div class="cyui-menu-box">
          <div class="cyui-flexbox cyui-flexbox-wrap cyui-menu">
            <div class="cyui-menu-item" v-for="(item, index) in menuListData" :key="index" @click="goModule(item)">
              <template v-if="item.isShow">
                <div class='cyui-menu-icon' :style='{backgroundColor: item.backgroundColor,width: "48px",height: "48px"}'>
                  <svg-icon :icon-class='item.svgName' v-if="item.size" :style="{width: item.size,height: item.size}"></svg-icon>
                  <svg-icon :icon-class='item.svgName' v-else :style='{width: "48px",height: "48px"}'></svg-icon>
                </div>
                <div class="cyui-menu-label">{{ item.name }}</div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </cyui-layout>
  </div>
</template>

<script>

  import common from '@mixins/common'

  export default {
    name: 'AssetsIndex',
    mixins: [common],
    data() {
      return {
        menuListData: [
          {
            id: '1',
            name: '入库',
            svgName: 'assets_in',
            backgroundColor: 'transparent',
            routeName: 'AssetsIn',
            isShow: true
          },
          {
            id: '2',
            name: '入库记录',
            svgName: 'assets_in_log',
            backgroundColor: 'transparent',
            routeName: 'AssetsInLog',
            isShow: true
          },
          {
            id: '3',
            name: '盘点',
            svgName: 'assets_check_select',
            backgroundColor: 'transparent',
            routeName: 'AssetsCheckSelect',
            isShow: true
          },
          {
            id: '4',
            name: '盘点记录',
            svgName: 'assets_check_log',
            backgroundColor: 'transparent',
            routeName: 'AssetsCheckLog',
            isShow: true
          },
          {
            id: '5',
            name: '申请领用',
            svgName: 'assets_take',
            backgroundColor: 'transparent',
            routeName: 'AssetsTake',
            isShow: true
          },
          {
            id: '6',
            name: '领用记录',
            svgName: 'assets_take_log',
            backgroundColor: 'transparent',
            routeName: 'AssetsTakeLog',
            isShow: true
          },
          {
            id: '7',
            name: '处置',
            svgName: 'assets_dispose',
            backgroundColor: 'transparent',
            routeName: 'AssetsDispose',
            isShow: true
          },
          {
            id: '8',
            name: '处置记录',
            svgName: 'assets_dispose_log',
            backgroundColor: 'transparent',
            routeName: 'AssetsDisposeLog',
            isShow: true
          },
          {
            id: '9',
            name: '调拨',
            svgName: 'assets_dispatch',
            backgroundColor: 'transparent',
            routeName: 'AssetsDispatch',
            isShow: true
          },
          {
            id: '10',
            name: '添加物资',
            svgName: 'assets_in',
            backgroundColor: 'transparent',
            routeName: 'AssetsAdd',
            isShow: true
          },
          {
            id: '11',
            name: '添加房内资产',
            svgName: 'assets_in',
            backgroundColor: 'transparent',
            routeName: 'AssetsAdd2',
            isShow: true
          }
        ]
      }
    },
    methods: {
      goModule(data = {}) {
        let { routeName } = data
        this.$router.push({
          name: routeName
        })
      }
    },
    created() {
      const { token, projectId, projectName, userId, userName } = this.$route.query
      if (token && projectId) {
        this.$store.commit('user/SET_TOKEN', token)
        this.$store.commit('user/SET_USER_INFO', { dcProjectId: projectId, userId, userName, projectName })
        this.$store.commit('user/SET_PROJECT_INFO', { dcProjectId: projectId, projectName })
      }
    }
  }
</script>

<style lang="less" scoped>
  @import "./common.less";

  @menuPrefixCls: ~'@{css-prefix}menu';
  .@{menuPrefixCls}-box {
    width: 100%;
    padding: 20px 10px;
  }

  .@{menuPrefixCls} {
    align-items: flex-start;
    &-item {
      width: 25%;
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .@{css-prefix}menu-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        line-height: 34px;
        margin: 0 auto 5px auto;
        border-radius: 100%;
        overflow: hidden;
        .svg-icon {
          display: block;
          width: 20px;
          height: 20px;
          fill: #FFFFFF;
          // vertical-align: middle;
        }
      }

      .@{css-prefix}menu-label {
        line-height: 1.2;
        font-size: 12px;
        color: #333333;
        text-align: center;
        white-space: pre-wrap;
      }
    }
  }
</style>
