<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="routeMetaTitle" @go-back="goBack">
        </cyui-header>
        <van-search show-action v-model="searchKey.condition" placeholder="输入券码信息或操作人进行搜索" @clear="onClearSearch"
                    @search="onSearch">
          <template #action>
            <div class="searchBtn" @click="onSearch">搜索</div>
          </template>
        </van-search>
        <van-tabs v-model="tabValue" @change="handleTabChange">
          <van-tab title="优惠券">
          </van-tab>
          <van-tab title="实体商品">
          </van-tab>
        </van-tabs>
      </template>
      <template #default>
        <div class="cyui-scroller-panel">
          <cyui-scroller
            class="wrapper"
            ref="scroller"
            :scrollbar="scrollbarConfig"
            :pullDownRefresh="pullDownRefreshConfig"
            :pullUpLoad="pullUpLoadConfig"
            :startY="parseInt(startY)"
            @pullingDown="handlePullingDown"
            @pullingUp="handlePullingUp"
          >
            <template #default>
              <div class="list">
                <div class="card" v-for="(item,index) in listData" :key="index">
                  <template v-if="tabValue==0">
                    <div class="row">
                      <div class="label">券码类型</div>
                      <div class="value">{{item.purposeTypeName}}</div>
                    </div>
                    <div class="row">
                      <div class="label">券码名称</div>
                      <div class="value">{{item.couponName}}</div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="row">
                      <div class="label">商品名称</div>
                      <div class="value">{{item.goodsName}}</div>
                    </div>
                  </template>

                  <div class="row">
                    <div class="label">券码信息</div>
                    <div class="value">{{item.cdKey}}</div>
                  </div>
                  <div class="row">
                    <div class="label">所属人</div>
                    <div class="value" v-if="tabValue==0">{{item.couponUserName}}</div>
                    <div class="value" v-else>{{item.userName}}</div>
                  </div>

                  <div class="row">
                    <div class="label">核销人</div>
                    <div class="value" v-if="tabValue==0">{{item.createByName}}</div>
                    <div class="value" v-else>{{item.receiveConfirmUserName}}</div>
                  </div>
                  <div class="row">
                    <div class="label">核销时间</div>
                    <div class="value" v-if="tabValue==0">{{item.createTime}}</div>
                    <div class="value" v-else>{{item.receiveTime}}</div>
                  </div>
                </div>
              </div>
            </template>
          </cyui-scroller>
        </div>
        <no-data
          :msg="noDataMessage"
          v-if="listData.length === 0 && isLoading === false"
        >
          <template #btn>
            <div
              class="cyui-btn cyui-btn-default cyui-btn-inline cyui-btn-radius"
              @click="handleRefreshPage"
              v-if="isRefreshPage"
            >
              刷新重试
            </div>
          </template>
        </no-data>
      </template>
    </cyui-layout>
  </div>
</template>

<script>
  import common from '@mixins/common'
  import scrollList from '@mixins/scrollList'
  import getPageList from '@mixins/getPageList'
  import { mapGetters } from 'vuex'
  import * as couponApi from '@api/coupon'
  import { getScanGoodsList } from '@api/coupon'

  export default {
    name: 'CouponScanList',
    mixins: [common,scrollList, getPageList],
    components: {
    },
    computed: {
      ...mapGetters('user', [
        'projectInfo'
      ])
    },
    data() {
      return {
        searchKey: {
          page: 1,
          size: 20,
          condition: '',
        }, // 搜索参数
        listData: [],
        tabValue: 0,
      }
    },
    created() {
      const { token, projectId } = this.$route.query
      if (token && projectId) {
        this.$store.commit('user/SET_TOKEN', token)
        this.$store.commit('user/SET_USER_INFO', { projectId })
        this.$store.commit('user/SET_PROJECT_INFO', { projectId })
        this.initData()
      }
    },
    methods: {
      async initData() {
        await this.resetParams()
        await this.getListData()
      },
      handleTabChange() {
        this.listData = [];
        this.onSearch()
      },
      onClearSearch() {
        this.searchKey.condition = '';
        this.onSearch()
      },
      onSearch() {
        this.searchKey.page = 1;
        this.getListData({isPullDown:true})
      },
      async getListData ({ isPullDown, isShowLoading } = { isPullDown: false, isShowLoading: true }) { // 获取列表数据
        try {
          this.isLoading = true
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true);
          let { page, size, condition } = this.searchKey;
          let response
          if (this.tabValue == 0) {
            response = await  couponApi.getScanList({
              pageNum: page,
              pageSize: size,
              condition: condition,
            })
          } else {
            response = await  couponApi.getScanGoodsList({
              pageNum: page,
              pageSize: size,
              condition: condition,
            })
          }
          let result = response.data
          // console.log(result)
          if (`${result.code}` === '10000') { // 请求成功
            let hasMore = false
            let resultData = result.data || []
            hasMore = page < result.pages
            if (!isPullDown) {
              this.listData.push(...resultData);
            } else {
              this.listData = [...resultData];
            }
            if (!hasMore) {
              this.isLastPage = true
            }
            await this.handleCallback({
              isError: false,
              hasMore,
              // noDataMessage: ' ',
            })
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '获取列表失败!'
            })
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '获取列表失败!'
          })
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
    }
  }
</script>

<style lang="less" scoped>
  @import '../../assets/styles/themes/default/index.less';
  .cyui-scroller-panel {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    & /deep/ .pulldown-wrapper {
      top: 5px !important;
    }
  }
  .searchBtn {
    color: @brand-primary2;
  }
  .list {
    padding: 10px 12px;
    .card {
      background: #fff;
      box-shadow: 0 4px 10px rgba(240,237,237,0.5);
      border-radius: 10px;
      padding: 10px 10px 20px 10px;
      margin-bottom: 10px;
      position: relative;
      &:last-child {
        margin-bottom: 0;
      }
      .row{
        display: flex;
        padding: 5px 0;
        font-size: 15px;
        line-height: 20px;
        .label {
          flex: 0 0 95px;
          white-space: nowrap;
          margin-right: 5px;
          color: #999999;
        }
        .value {
          color: #333333;
          &.status {
            color: @brand-primary2;
          }
        }
      }
      .actions{
        position: absolute;
        bottom: 20px;
        right: 10px;
        /deep/ .van-button {
          width: 70px;
          height: 36px;
          line-height: 36px;
          border-radius: 6px;
        }
      }
    }
  }
</style>
