<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="routeMetaTitle" @go-back="goBack">
        </cyui-header>
      </template>
      <template #default>
        <div class="page">
          <van-cell-group class='cell-group'>
            <van-cell title="区域" :value="spaceFullName||'请选择'" @click="getSpaceList()"></van-cell>
          </van-cell-group>
          <div class="list">
            <div class="item" v-for="(item,index) in productList" :key="index">
              <div class="name">{{item.productName||'-'}}</div>
              <div class="infos">
                <div class="img"><van-image width="100%" height="100%" fit="cover" lazy-load :src="item.pictureUrl" @click="onPreview(item.pictureUrl)"></van-image></div>
                <div class="attrs">
                  <van-row>
                    <van-col span="15">主规格：{{item.standard||'-'}}</van-col>
                    <van-col span="9">单位：{{item.unit||'-'}}</van-col>
                    <van-col span="24">库存数量：{{item.count||'-'}}</van-col>
                    <van-col span="24">资产编号：{{item.productNo||'-'}}</van-col>
                    <van-col span="24">品牌规格详情：{{item.standardDesName||'-'}}</van-col>
                    <van-col span="24" v-if="item.productProperty == 3">权属：{{item.ownerTypeName||'-'}}</van-col>
                  </van-row>
                </div>
              </div>
            </div>
            <van-empty v-if="!productList.length" description="无数据"></van-empty>
          </div>
        </div>
      </template>
      <template #footer>
        <van-pagination v-if="total>pageSize" v-model="page" :total-items="total" :items-per-page="pageSize" @change="getData"/>
        <div class="footer">
          <van-button block type="primary" size="large" @click="onAddItem" :disabled="!spaceFullCode">添加物资</van-button>
        </div>
      </template>
    </cyui-layout>
    <!-- 空间类型 -->
    <van-popup v-model='spaceListShow'
               round
               position='bottom'>
      <van-cascader v-model='spaceId'
                    title='空间选择'
                    :options='spaceList'
                    :field-names='fieldNames'
                    @close='spaceListShow=false'
                    @change='spaceChange'
                    @finish='spaceFinish'
      />
    </van-popup>
<!--    <van-popup v-model="spaceListShow" position="bottom" :close-on-click-overlay="false">-->
<!--      <van-picker-->
<!--        show-toolbar-->
<!--        value-key="spaceFullName"-->
<!--        :columns="spaceList"-->
<!--        @confirm="onSelectSpace"-->
<!--        @cancel="spaceListShow = false"-->
<!--      />-->
<!--    </van-popup>-->
    <van-dialog title="添加" v-model="productListShow" show-cancel-button :before-close="onProductSelectConfirm">
      <div class="productListPanel">
        <van-search v-model="productListSearchKeyword" show-action shape="round" @search="onProductListSearch" placeholder="请输入资产名称" @focus="productListResultShow=false">
          <template #action>
            <div class="searchBtn" @click="onProductListSearch">搜索</div>
          </template>
        </van-search>
        <div class="productListResultPop" v-if="productListResultShow">
          <van-cell v-for="(item,index) in productListGets" :key="item.id" clickable @click="onProductListSelect(item)">
            <template #title>
              <div class="productListResultTitle">
                <span>{{item.productName}}</span>
                <span>{{item.standards}}</span>
                <!--<span>{{'库存：'+item.count}}</span>-->
              </div>
            </template>
          </van-cell>
          <van-empty v-if="!productListGets.length" description="无数据"></van-empty>
        </div>
        <!--<van-pagination v-model="productListSearchPageNum" :total-items="productListSearchTotal" :items-per-page="productListSearchPageSize" @change="onProductListSearchPage"/>-->
        <van-cell-group class='cell-group'>
          <van-cell title="资产名称" :value="currentProduct.productName||'-'"></van-cell>
          <van-cell title="资产属性" :value="productPropertyMap[currentProduct.productProperty]||'-'"></van-cell>
          <van-cell title="主规格" :value="currentProduct.standard||'-'"></van-cell>
          <van-cell title="权属" :value="currentProduct.ownerTypeName||''" is-link @click="onOwnerDesListShow" v-if="currentProduct.productProperty == 3"></van-cell>
          <van-cell title="品牌规格详情" :value="currentProduct.standardDesName||''" is-link @click="onStandardDesListShow"></van-cell>
          <van-cell title="资产类型" :value="currentProduct.assetsTypeName||'-'"></van-cell>
          <van-cell title="编码部门" v-if="currentProduct.productProperty===3" required :value="currentProduct.dingDeptName||'请选择'" @click="onDeptListShow"></van-cell>
          <van-cell title="入库数量" required>
            <template #default>
              <van-stepper v-model="currentProduct.count" integer :min="1" :max="currentProduct.productProperty===3&&currentProduct.mode===1?1:undefined" />
            </template>
          </van-cell>
          <van-cell title="资产照片" :required="currentProduct.addImgType==1">
            <template #default>
              <div class="uploadPanel">
                <van-uploader max-count="1" v-model="currentProduct.pictureUrls" :after-read='afterFileRead' />
              </div>
            </template>
          </van-cell>
        </van-cell-group>
      </div>
    </van-dialog>
    <van-dialog v-model="gdListShow" title="注意" confirm-button-text="我已知晓" @confirm="onGdListConfirm">
      <div class="gdList">
        <div class="title">此次添加资产中，有以下固定资产被赋予资产编号</div>
        <table>
          <thead>
          <tr>
            <td>资产名称</td>
            <td>主规格</td>
            <td>资产编号</td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) in gdList" :key="index">
            <td>{{ item.productName || '-' }}</td>
            <td>{{ item.standard || '-' }}</td>
            <td>{{ item.productNo || '-' }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </van-dialog>
    <van-popup v-model="deptListShow" position="bottom">
      <van-picker ref="deptList" show-toolbar value-key="dingDeptName" :columns="deptList" @confirm="onDeptListSelect" @cancel="deptListShow = false" />
    </van-popup>

    <van-popup v-model="standardDesListShow" position="bottom">
      <van-picker ref="standardDesList" show-toolbar value-key="name" :columns="standardDesList" @confirm="onStandardDesListSelect" @cancel="standardDesListShow = false" />
      <div class="addBtn">
        <van-button block plain type="primary" icon="add-o" @click="onAddStandardDes">新增</van-button>
      </div>
    </van-popup>

    <!-- 权属 -->
    <van-popup v-model="ownerDesListShow" position="bottom">
      <van-picker ref="standardDesList" show-toolbar value-key="name" :columns="ownerDesList" @confirm="onOwnerDesListSelect" @cancel="ownerDesListShow = false" />
      <div class="addBtn">
        <van-button block plain type="primary" icon="add-o" @click="onAddOwnerDes">新增</van-button>
      </div>
    </van-popup>
    <van-dialog v-model="addOwnerDesDialogShow" title="新增"  show-cancel-button :before-close="onAddOwnerDesSubmit">
      <van-field v-model="addOwnerDesValue" label="名称" placeholder="请输入名称"></van-field>
    </van-dialog>

    <!-- 品牌规格详情 -->
    <van-dialog v-model="addStandardDesDialogShow" title="新增"  show-cancel-button :before-close="onAddStandardDesSubmit">
      <van-field v-model="addStandardDesValue" label="名称" placeholder="请输入名称"></van-field>
    </van-dialog>
  </div>
</template>

<script>
  import common from '@mixins/common'
  import { mapGetters } from 'vuex'
  import _ from 'lodash'
  import * as assetsApi from '@api/assets'
  import imageCompression from 'browser-image-compression'
  import { ImagePreview } from 'vant'
  import { getAllSpaceByProjectId, getAllSpaceByProjectld } from '@api/assets'

  const productPropertyList = [
    { label: "易耗品", value: 1 },
    { label: "低值品", value: 2 },
    { label: "固定资产", value: 3 }
  ]
  const productPropertyMap = _.mapValues(_.keyBy(productPropertyList, i => i.value), j => j.label)

  export default {
    name: 'AssetsCheckIndex',
    mixins: [common],
    computed: {
      ...mapGetters('user', [
        'userInfo',
        'projectInfo'
      ])
    },
    data() {
      return {
        projectId: '',
        projectName: '',
        spaceFullCode: '',
        spaceFullName: '',
        spaceType: '',
        spaceListShow: false,
        fieldNames: {
          text: 'spaceName',
          value: 'spaceId',
          children: 'children'
        },
        spaceId: '',
        spaceList: [],

        checkId: '',
        productList: [
        ],
        page: 1,
        total: 0,
        pageSize: 10,

        currentItem: null,
        saveCheckReasonShow: false,
        reason: '',

        productPropertyMap,
        productListShow: false,
        productListGets: [],
        productListSearchKeyword: '',
        productListSearchPageNum: 1,
        productListSearchPageSize: 99,
        productListSearchTotal: 0,
        productListResultShow: false,
        currentProduct: {
          id: '',
          productName: '',
          productProperty: '',
          standard: '',
          productNo: '',
          count: 1,
          mode: 0,
          checks: {},
          dingDeptId: 0,
          dingDeptName: '',
          assetsTypeName: '',
          addImgType: 0,
          pictureUrls: [],
          standardDesId: '',
          standardDesName: ''
        },
        stockInImgList: [],
        gdListShow: false,
        gdList: [],

        deptListShow: false,
        deptList: [],

        checks: {},

        ownerDesListShow: false,
        ownerDesList: [],
        addOwnerDesDialogShow: false,
        addOwnerDesValue: '',

        standardDesListShow: false,
        standardDesList: [],
        addStandardDesDialogShow: false,
        addStandardDesValue: ''
      }
    },
    mounted() {
      this.applicationPerson = this.userInfo.nickName
      let { dcProjectId, projectName } = this.projectInfo
      this.projectId = dcProjectId
      this.projectName = projectName
      this.getSpaceList()
    },
    methods: {
      onPreview(url) {
        ImagePreview([url])
      },
      getSpaceList() {
        assetsApi.getAllSpaceByProjectId(this.projectId).then(res => {
          let list = res.data.data || []
          this.spaceList = this.arrayToTree(list)
          if (!this.spaceList.length) {
            this.$toast('暂无区域')
          } else {
            this.spaceListShow = true
          }
        })
      },
      arrayToTree(data) {
        const map = new Map()
        const roots = []

        data.forEach(nodeData => {
          const { spaceId, spaceName, spaceType, spaceFullCode, spaceFullName, projectId } = nodeData
          const node = { spaceId, spaceName, spaceType, spaceFullCode, spaceFullName, projectId }
          map.set(spaceId, node)
        })

        data.forEach(nodeData => {
          const { spaceId, parentId } = nodeData
          const node = map.get(spaceId)
          const parent = parentId ? map.get(parentId) : null
          if (parent) {
            if (!parent.children) {
              parent.children = []
            }
            parent.children.push(node)
          } else {
            roots.push(node)
          }
        })

        return roots
      },
      spaceChange(e) {
        this.spaceFullName = e.selectedOptions[e.selectedOptions.length - 1].spaceFullName
        this.spaceFullCode = e.selectedOptions[e.selectedOptions.length - 1].spaceFullCode
        this.spaceType = e.selectedOptions[e.selectedOptions.length - 1].spaceType
      },
      spaceFinish() {
        this.spaceListShow = false
        this.getData()
      },
      onSelectSpace(item) {
        this.spaceFullCode = item.spaceFullCode
        this.spaceFullName = item.spaceFullName
        this.spaceType = item.spaceType
        this.spaceListShow = false
        this.getData()
      },
      getData() {
        assetsApi.getAddList({
          pageNum: this.page,
          pageSize: this.pageSize,
          projectId: this.projectId,
          spaceFullCode: this.spaceFullCode
        }).then(res => {
          this.total = res.data.count
          this.productList = res.data.data
        })
      },

      onAddItem() {
        this.currentProduct = {}
        this.productListSearchKeyword = ''
        this.productListGets = []
        this.productListResultShow = false
        this.productListShow = true
      },
      onProductListSearch() {
        let params = {
          productName: this.productListSearchKeyword,
          pageNum: this.productListSearchPageNum,
          pageSize: this.productListSearchPageSize
        }
        assetsApi.getProductList(params).then(res => {
          this.productListGets = res.data.data
          this.productListSearchTotal = res.data.count
          this.productListResultShow = true
        })
      },
      onProductListSearchPage() {
        this.onProductListSearch()
      },
      onProductListSelect(product) {
        this.currentProduct = {
          id: product.id,
          productName: product.productName,
          productProperty: product.productProperty,
          standard: product.standard,
          unit: product.unit,
          productNo: '',
          count: 1,
          mode: 0,
          checks: {},
          dingDeptId: 0,
          dingDeptName: '',
          assetsTypeName: product.assetsTypeName,
          addImgType: product.addImgType || 0,
          pictureUrls: [],
          standardDesId: '',
          standardDesName: ''
        }
        this.productListResultShow = false
      },
      onProductSelectConfirm(action, done) {
        if (action === 'confirm') {
          if (!this.currentProduct.id) {
            this.$toast('未选择资产')
            done(false)
            return
          }
          if (this.currentProduct.productProperty === 3 && !this.currentProduct.dingDeptId) {
            this.$toast('未选择编码部门')
            done(false)
            return
          }
          if (this.currentProduct.addImgType == 1 && this.currentProduct.pictureUrls.length == 0) {
            this.$toast('未上传资产照片')
            done(false)
            return
          }
          let data = {
            spaceFullCode: this.spaceFullCode,
            spaceName: this.spaceFullName,
            projectId: this.projectId,
            projectName: this.projectName,
            productId: this.currentProduct.id,
            productNo: '',
            dingDeptId: 0,
            count: this.currentProduct.count,
            pictureUrl: this.currentProduct.pictureUrls.length ? this.currentProduct.pictureUrls[0].url : '',
            productName: this.currentProduct.productName,
            productProperty: this.currentProduct.productProperty,
            unit: this.currentProduct.unit,
            standard: this.currentProduct.standard,
            standardDesId: this.currentProduct.standardDesId,
            standardDesName: this.currentProduct.standardDesName
          }
          if (this.currentProduct.productProperty === 3) {
            data.dingDeptId = this.currentProduct.dingDeptId
            data.ownerType = this.currentProduct.ownerType
            data.ownerTypeName = this.currentProduct.ownerTypeName
          }
          assetsApi.addAsset(data).then(res => {
            done()
            this.currentProduct = {}
            this.productListSearchKeyword = ''
            this.productListGets = []
            this.productListResultShow = false
            this.productListShow = false
            this.getData()
            let gdList = res.data.data && res.data.data.detailList
            if (gdList && gdList.length) {
              this.gdList = gdList
              this.gdListShow = true
            } else {
              this.$toast('添加成功')
            }
          }).catch(e => {
            done(false)
          })
        } else {
          done()
        }
      },
      onGdListConfirm() {
        this.gdListShow = false
      },
      onDeptListShow() {
        assetsApi.getDingDeptList().then(res => {
          if (res.data.data) {
            this.deptList = res.data.data
          }
        })
        this.deptListShow = true
        this.$nextTick(() => {
          let value = this.currentProduct.dingDeptId
          let selectIndex = this.deptList.findIndex(i => i.dingDeptId === value)
          this.$refs.deptList.setIndexes([selectIndex])
        })
      },
      onDeptListSelect(item) {
        this.currentProduct.dingDeptId = item.dingDeptId
        this.currentProduct.dingDeptName = item.dingDeptName
        this.deptListShow = false
      },

      afterFileRead(item) {
        let up = this.$toast.loading({ forbidClick: true, duration: 0 })
        imageCompression(item.file, {
          maxSizeMB: 0.2,
          initialQuality: 0.7
        }).then(file => {
          assetsApi.fileUpload(item).then(res => {
            this.$toast.clear()
            const cb = res.data
            if (cb.code === 200) {
              item.fileType = 1
              item.url = cb.data.url
              item.fileUrl = cb.data.url
              item.status = 'success'
              item.message = '上传成功'
            } else {
              item.status = 'failed'
              item.message = '上传失败'
            }
          }).catch(e => {
            this.$toast.clear()
            item.status = 'failed'
            item.message = '上传失败'
          })
        }).catch(() => {
          this.$toast.clear()
          item.status = 'failed'
          item.message = '上传失败'
        })
      },
      onOwnerDesListShow() {
        if (!this.currentProduct.id) {
          this.$toast('请先选择商品')
          return
        }
        this.getOwnerDesList()
        this.ownerDesListShow = true
      },
      getOwnerDesList() {
        assetsApi.getStandardDesList({
          sourceType: 'ownerType',
          sourceId: -1
        }).then(res => {
          this.ownerDesList = res.data.data
        })
      },
      onOwnerDesListSelect(item) {
        this.currentProduct.ownerType = item.id
        this.currentProduct.ownerTypeName = item.name
        this.ownerDesListShow = false
      },
      onAddOwnerDes() {
        this.addOwnerDesValue = ''
        this.addOwnerDesDialogShow = true
      },
      onAddOwnerDesCancel() {
        this.addOwnerDesDialogShow = false
      },
      onAddOwnerDesSubmit(action, done) {
        if (action === 'confirm') {
          if (this.addOwnerDesValue == '') {
            this.$toast('请输入名称')
            done(false)
            return
          }
          assetsApi.addStandardDes({
            sourceId: -1,
            sourceType: 'ownerType',
            name: this.addOwnerDesValue
          }).then(res => {
            done()
            this.$toast('添加成功')
            this.getOwnerDesList()
          }).catch(() => {
            done(false)
          })
        } else {
          done()
        }
      },
      onStandardDesListShow() {
        if (!this.currentProduct.id) {
          this.$toast('请先选择商品')
          return
        }
        this.getStandardDesList()
        this.standardDesListShow = true
      },
      getStandardDesList() {
        assetsApi.getStandardDesList({
          sourceType: 'ppggxq',
          sourceId: this.currentProduct.id
        }).then(res => {
          this.standardDesList = res.data.data
        })
      },
      onStandardDesListSelect(item) {
        this.currentProduct.standardDesId = item.id
        this.currentProduct.standardDesName = item.name
        this.standardDesListShow = false
      },
      onAddStandardDes() {
        this.addStandardDesValue = ''
        this.addStandardDesDialogShow = true
      },
      onAddStandardDesCancel() {
        this.addStandardDesDialogShow = false
      },
      onAddStandardDesSubmit(action, done) {
        if (action === 'confirm') {
          if (this.addStandardDesValue == '') {
            this.$toast('请输入名称')
            done(false)
            return
          }
          assetsApi.addStandardDes({
            sourceId: this.currentProduct.id,
            sourceType: 'ppggxq',
            name: this.addStandardDesValue
          }).then(res => {
            done()
            this.$toast('添加成功')
            this.getStandardDesList()
          }).catch(() => {
            done(false)
          })
        } else {
          done()
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  @import "./common.less";
  .cyui-layout-wrapper {
    background: #F6F6F6;
    /deep/ .van-pagination__item--disabled,
    /deep/ .van-pagination__item--disabled:active {
      background: #fff;
      opacity: 1;
      color: rgba(100, 101, 102, 0.5);
    }
  }
  .page {
    padding: 10px;
  }
  .cell-group {
    margin-bottom: 10px;
  }
  .list {
    margin-bottom: 10px;
    .item {
      padding: 15px;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0px 4px 10px rgba(240,237,237,0.5);
      margin-bottom: 15px;
      position: relative;
      .name {
        font-size: 15px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 15px;
      }
      .infos {
        display: flex;
        .img {
          width: 140px;
          height: 105px;
          border: 1px solid #CCCCCC;
          border-radius: 6px;
          overflow: hidden;
          margin-right: 20px;
        }
        .attrs {
          width: 0;
          flex: 1;
          font-size: 10px;
          font-weight: 600;
          line-height: 25px;
          color: #333333;
          .stepper {
            color: @brand-primary2;
            display: flex;
            align-items: center;
          }
        }
      }
      .actions {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
        font-size: 12px;
        /deep/ .van-button {
          width: 60px;
          height: 60px;
          background: @brand-round-btn-bg;
          box-shadow: 0px 4px 10px rgba(0,0,0,0.16);
          border-radius: 50%;
        }
      }
      .isSave {
        position: absolute;
        top: 0;
        right: 0;
        width: 58px;
        height: 58px;
      }
    }

  }
  .rejectPanel {
    display: flex;
    flex-direction: column;
    .tip {
      font-size: 17px;
      color: #999999;
      padding: 0 10px;
    }
    /deep/ .van-field {
      &__label {
        color: #999;
      }
      &__value {
        border: none;
        background: #f5f5f5;
      }
    }
  }
  .productListPanel {
    padding-top: 0;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .productListResultPop {
      z-index: 9999;
      background: #fff;
      position: absolute;
      top: 46px;
      left: 15px;
      width: 60vw;
      max-height: 30vh;
      overflow-y: auto;
      border: 1px solid #ccc;
      /deep/ .van-empty__image {
        display: none;
      }
    }
    /deep/ .van-radio-group--horizontal {
      justify-content: flex-end;
    }
    /deep/ .van-radio--horizontal{
      margin-right: 2px;
    }
    /deep/ .van-radio__label{
      margin-left: 2px;
    }

    .productListResultRow {
      display: flex;
      align-items: center;
      > span {
        padding: 0px 3px;
        display: block;
        word-break: break-all;
      }
      .c1 {
        width: 0;
        flex: 1;
      }
      .c2 {
        white-space: nowrap;
        text-align: center;
      }
      .c3 {
        min-width: 60px;
        flex-shrink: 0;
        white-space: nowrap;
        text-align: center;
      }
    }
  }
  .form {
    .uploadPanel {
      text-align: left;
    }
  }
  .footer {
    background: #fff;
    .addBtn {

      /deep/ .van-button {
        border: none;
        border-radius: 10px;
        &__content {
          justify-content: flex-start;
        }
      }
      margin-bottom: 10px;
    }
    width: 100%;
    padding: 10px;
  }
</style>
