import { cancelSignContractAPI } from '@api/signContract'

export default {
  data() {
    return {}
  },
  computed: {},
  methods: {
    async cancelSignContractData({ isShowLoading, contractId } = {
      isShowLoading: true,
      contractId: ''
    }) { // 完成接待
      try {
        this.isLoading = true
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
        let response = await cancelSignContractAPI({
          contractId
        })
        let result = response.data
        // console.log(result)
        if (`${result.code}` === '10000') { // 请求成功
          // 接口请求处理成功之后回跳
          return Promise.resolve(response)
        } else {
          // 请求失败
          await this.handleCallback({
            isError: true,
            errorMessage: result.msg || '取消签约失败!'
          })
          return Promise.reject(new Error(result.msg || '取消签约失败!'))
        }
      } catch (error) {
        // console.log(error)
        await this.handleCallback({
          isError: true,
          errorMessage: error.message || '取消签约失败!'
        })
        return Promise.reject(error)
      } finally {
        this.isLoading = false
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
      }
    }
  }
}
