import request from '@utils/request'

/**
 * PriceBreak-获取房源详情
 * url:'/breakPrice/getHouseDetail'
 * method:GET
 * @Header Authorization: 登录接口返回的token
 * @Query params:{}
 * @returns {*}
 */
export const getApplyDetailApi = (params = {}) => {
  return request.get('/breakPrice/getHouseDetail', { params })
}

/**
 * PriceBreak-获取价格详情
 * url:'/breakPrice/getHousePriceDetail'
 * method:GET
 * @Header Authorization: 登录接口返回的token
 * @Query params:{}
 * @returns {*}
 */
export const getApplyPriceDetailApi = (params = {}) => {
  return request.get('/breakPrice/getHousePriceDetail', { params })
}

/**
 * PriceBreak-获取价格详情
 * url:'/breakPrice/getHousePriceDetail'
 * method:GET
 * @Header Authorization: 登录接口返回的token
 * @Query params:{}
 * @returns {*}
 */
export const setApplySubmitApi = (data = {}) => {
  return request.post('/breakPrice/submitBreakPrice', data)
}
