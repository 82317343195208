<template>
  <div class="cyui-layout">
    <div class="cyui-layout-wrapper">
      <cyui-layout>
        <template #header>
          <cyui-header>
            <template #headerLeft>
              <div></div>
            </template>
            <template #headerRight>
              <div></div>
            </template>
          </cyui-header>
        </template>
        <template #default>
          <div class="letter-container">
            <p><br /></p>
            <p class="text-center"><strong>房屋委托服务合同终止及房屋交接告知函</strong></p>
            <p class="text-left mt-10">尊敬的<u>{{ letterInfo.tenantName || '' }}</u>先生/女士</p>
            <p class="text-left">
              <span> </span>
            </p>
            <p class="text-indent-2 mt-10">
              <span>您好！您与我司于</span><u>{{ letterInfo.delegateStartDate || '' }}</u><span>签订的《房屋委托服务合同》（以下简称“合同”）即将于</span><u>{{ letterInfo.delegateEndDate || '' }}</u><span>到期。为确保合同终止及房屋交接事宜的顺利进行，现特此函告如下：</span>
            </p>
            <p>一、合同终止</p>
            <p class="text-indent-2"><strong>根据合同约定，双方签订的合同将于<u>{{ letterInfo.delegateEndDate || '' }}</u>正式终止。</strong>我司承诺，将在<u>{{ letterInfo.delegateEndDate || '' }}</u>前将位于无锡市新吴区江溪路28号金科米兰的<u>{{ letterInfo.roomNo || '' }}</u>（房号）委托服务房屋归还给您。</p>
            <p>二、房屋交接</p>
            <p class="text-indent-2">我司将严格按照合同约定，将房屋及其附属家具家电等物品交予您。</p>
            <p>三、费用结算</p>
            <p>1.租金回报款：我司给您支付的租金回报款将截止到<u>{{ letterInfo.delegateEndDate || '' }}</u>；</p>
            <p>2.相关费用：水费、电费、网络费等相关费用将按照房屋交还时间进行结算，最迟不晚于合同结束期。</p>
            <p>四、交接事宜协商及联系方式</p>
            <p class="text-indent-2">
              <strong>为了确保房屋交接的顺利进行，我们诚挚邀请您于<u>{{ letterInfo.delegateEndDate || '' }}</u>前至无锡市新吴区江溪路28号金科米兰三楼尔家客服中心与我们共同协商办理房屋交接事宜。</strong>我们相信通过双方的共同努力，一定能够妥善处理合同终止及房屋交接的相关事宜。
            </p>
            <p class="text-indent-2">
              如您对以上事项有任何疑问或需要进一步了解，请随时联系我们。我们的联系方式如下：
            </p>
            <p class="text-indent-2"><strong>联系人：<u> {{ letterInfo.contactPerson || '' }} </u>，电话号码   <u>0510-85011313，18106171293 </u> 。</strong></p>
            <p class="text-indent-2">感谢您一直以来对我们的信任与支持！期待在未来的日子里，我们能够继续为您提供优质的服务。</p>
            <p class="text-right mt-20">
              <span>上海尔家商业管理有限公司</span>
            </p>
            <p class="text-right">
              <span>{{ letterInfo.noticeDate || '' }}</span>
            </p>
            <p><br /></p>
          </div>
        </template>
      </cyui-layout>
    </div>
  </div>
</template>

<script>
import { getLetterInfoApi } from '@/api/h5.js'

export default {
  data () {
    return {
      letterInfo: {}
    }
  },

  mounted () {
    this.loadData()
  },

  methods: {
    loadData() {
      const houseId = this.$route.query.houseId
      if (houseId) {
        getLetterInfoApi({ houseId }).then(res => {
          const { code, result } = res.data
          if (code === 200) {
            this.letterInfo = result
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.letter-container {
  padding: 12px;
  color: #000;
  line-height: 2;
  .mt-10 {
    margin-top: 10px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .text-indent-2 {
    text-indent: 2em;
  }
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
</style>
