<template>
  <div class='container'>
    <div class='header-title'>
      <div class='pay-name'>
        <div style='font-size: 18px;font-weight: 550'>付款给{{ projectName }}</div>
        <div style='font-size: 14px'>费用类型：{{ typeName }}</div>
      </div>
      <div class='pay-img'>
        <van-image width='40'
                   height='40'
                   radius='5'
                   :src="require('../../assets/images/payExpence/erjia.png')" />
      </div>
    </div>
    <div class='pay-content'>
      <div class='pay-title'>付款金额</div>
      <div class='pay-amount'>
        <span class='pay-icon'>¥</span>
        <input ref='scanTextbox' class='pay-input' @click='stopKeyborad' :readonly='true' @blur='show=false'
               v-model='form.amount'
        >
      </div>
      <div>
        <div v-if="form.remark===''" class='add-remark' @click='showRemark=true'>
          添加备注
        </div>
        <div class='remark' v-else>
          {{ form.remark }}
          <span class='update' @click='showRemark=true'>修改</span>
        </div>
      </div>
      <div class='payment'>
        <!--        <van-button :disabled="form.amount===''" class='button' type='primary'-->
        <!--                    @click='onSubmit'>-->
        <!--          付款-->
        <!--        </van-button>-->
      </div>
    </div>

    <van-dialog @confirm='onConfirm' v-model='showRemark' title='添加备注' show-cancel-button>
      <div class='remark-input'>
        <input v-model='remark' type='text' class='input'>
      </div>
    </van-dialog>

    <van-number-keyboard
      :show='show'
      theme='custom'
      :extra-key="['.']"
      close-button-text='付款'
      @blur='show = false'
      @input='numberInput'
      @delete='onDelete'
      @close='onClose'
    />
  </div>
</template>

<script>
  import { getProjectName, selectTypeName, tenantPayOtherFeeNonHouse } from '@api/expense'
  import { removeStorage, setStorage, getStorage } from '@utils/storage'
  import { prohibitNegative } from '@utils/utils'

  export default {
    name: '',
    components: {},
    props: {},
    data() {
      return {
        form: {
          amount: '',
          remark: ''
        },
        remark: '',
        typeName: '',
        projectName: '',
        projectId: '',
        showPicker: false,
        showRemark: false,
        show: false,
        columns: []
        // contractData: []
      }
    },
    computed: {},
    watch: {},
    created() {
      this.initData()
    },
    mounted() {
    },
    methods: {
      async initData() {
        let url = window.location.href
        let obj = {},
          index = url.indexOf('?'), // 看url有没有参数
          params = url.substr(index + 1) // 截取url参数部分 name = aaa & age = 20

        if (index !== -1) { // 有参数时
          let parr = params.split('&') // 将参数分割成数组 ["name = aaa", "age = 20"]
          for (let i of parr) { // 遍历数组
            let arr = i.split('=') // 1） i name = aaa   arr = [name, aaa]  2）i age = 20  arr = [age, 20]
            obj[arr[0]] = arr[1] // obj[arr[0]] = name, obj.name = aaa   obj[arr[0]] = age, obj.age = 20
          }
          this.form.amount = getStorage('amount') || ''
          this.form.remark = getStorage('remark') || ''
          this.remark = getStorage('remark') || ''
          let { projectId, type } = obj
          this.projectId = projectId
          getProjectName({ projectId }).then(res => {
            let result = res.data
            if (result.code === '10000') {
              console.log(result.data, '1')
              this.projectName = result.data
            }
          })
          selectTypeName({ type }).then(res => {
            let result = res.data
            if (result.code === '10000') {
              this.typeName = result.data
              document.title = this.typeName + '-' + '费用缴纳'
            }
          })
        }
      },
      numberInput(e) {
        let amount = this.form.amount + e
        this.form.amount = this.prohibitNegative(amount, 2, 8)
        setStorage('amount', this.form.amount)
        console.log(this.form.amount)
      },
      onDelete() {
        this.form.amount = this.form.amount.slice(0, this.form.amount.length - 1)
        setStorage('amount', this.form.amount)
      },
      onClose() {
        if (this.form.amount === '') {
          this.$toast('请输入金额')
        } else {
          this.onSubmit()
        }
      },
      stopKeyborad() {
        this.show = true
        this.$refs.scanTextbox.setAttribute('readonly', 'readonly')
        if (this.$refs.scanTextbox) {
          this.$refs.scanTextbox.focus()
          this.$refs.scanTextbox = null
        }
        setTimeout(() => {
          if (this.$refs.scanTextbox) {
            this.$refs.scanTextbox.removeAttribute('readonly')
            this.$refs.scanTextbox = null
          }
        }, 200)
      },
      onConfirm() {
        this.form.remark = this.remark
        setStorage('remark', this.form.remark)
      },
      onSubmit() {
        let { tenantId, type } = this.$route.query
        let { projectId } = this
        let params = {
          ...this.form,
          tenantId,
          projectId,
          type
        }
        tenantPayOtherFeeNonHouse({ ...params }).then(res => {
          let result = res.data
          if (result.code === '10000') {
            let { id, totalAmount } = result.data
            this.$router.push({
              name: 'paymentIndex',
              query: {
                erjiaOderId: id,
                erjiaTotalAmount: totalAmount
              }
            })
          }
        })
      },
      clean() {
        removeStorage('phone', 'localStorage')
        removeStorage('tenantId', 'localStorage')
        this.$router.push({
          name: 'expenseType'
        })
      }
    }
  }
</script>

<style scoped lang='less'>
  .container {
    .header-title {
      height: 100px;
      width: 100%;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .pay-img {
        border-radius: 5px;
      }
    }

    .pay-content {
      position: relative;
      padding: 20px;
      background-color: #FFFFFF;
      height: 100%;
      border-radius: 20px 20px 0px 0px;

      .pay-title {
        font-size: 15px
      }

      .pay-amount {
        margin: 15px 5px 5px 5px;
        border-bottom: 1px solid #f8f8f8;

        .pay-icon {
          font-size: 30px;
          font-weight: 600;
        }

        .pay-input {
          width: 200px;
          font-size: 40px;
          font-weight: 600;
          height: 50px;
          border: none;
          margin-left: 10px;
        }
      }

      .add-remark {
        color: #456291;
        font-size: 15px;
        margin-top: 20px;
      }

      .remark {
        color: #6D6868;
        font-size: 15px;
        margin-top: 20px;

        .update {
          color: #456291;
          font-size: 15px;
          margin-left: 5px;
        }
      }

      .payment {
        border-radius: 10px;
        margin-top: 400px;
        margin-left: 200px;

        .button {
          font-weight: 600;
          width: 100px;
          height: 80px;
          background-color: #07c160;
          border: #07c160;
          border-radius: 10px;
        }
      }
    }

    .remark-input {
      margin: 20px;

      .input {
        border: none;
        width: 100%;
        font-size: 15px;
        height: 30px;
        background-color: #f8f8f8;
      }

    }

    .van-dialog__confirm {

    }

    /deep/ .van-dialog__confirm, .van-dialog__confirm:active {
      color: #00C15F;
    }

    /deep/ .van-key--blue {
      position: relative;
      background-color: #07c160;
      height: 155px;
    }

    /deep/ .van-key--delete {
      height: 48px;
    }

    ///deep/ .van-key--large {
    //  height: 100px;
    //}

    /deep/ .van-field__label {
      color: #333333;
    }

    .van-button--round {
      border-radius: 10px;
    }

    .van-button--normal {
      padding: 0 60px;
      font-size: 16px;
    }

    .van-button--info {
      background-color: #669AF0;
      border: 0.02667rem solid #669AF0;
    }
  }
</style>
