import { mapState } from 'vuex'
import { isCustomHeader } from '@config/config'
import keepAlive from '@mixins/keepAlive'

// import CyuiLayout from '@components/Layout/Layout'
// import CyuiHeader from '@components/Header/index'
// import Scroller from '@components/Scroller'
import NoData from '@components/NoData/NoData.vue'

export default {
  mixins: [keepAlive],
  components: {
    // CyuiLayout,
    // CyuiHeader,
    // Scroller,
    NoData
  },
  data() {
    return {
      isCustomHeader,
      routeMetaTitle: '',
    }
  },
  created() {
    this.routeMetaTitle = this.$route.meta?.title || '';
  },
  computed: {
    ...mapState('organization', {
      organizationId: state => state.organizationId
    })
  },
  methods: {
    goBack(count = -1) { // 页面回退
      if (typeof count !== 'number') {
        count = -1;
      }
      // console.log(this.$router)
      let routerName = this.$route.name
      let rootRouterName = ['UserIndex']
      if (rootRouterName.indexOf(routerName) !== -1 || (this.from && this.from === 'workbench')) {
        console.log('返回首页')
      } else {
        this.$router.go(count)
      }
    },
    handleClosePage() { // 关闭当前页面
      // console.log('close')
      console.log('返回首页')
    },
    formatAccessoryType(type) {
      // 格式化附件图标
      let iconName = ''
      let regImage = /(png|jpg|gif|jpeg|webp)$/
      let regPDF = /(pdf)$/
      let regPPT = /(pptx|ppt|pps|pot|ppa)$/
      let regExcel = /(xls|xlsx)$/
      let regWord = /(doc|docx)$/
      let regTxt = /(txt)$/
      let regZip = /(zip|rar|arj|z)$/
      if (regImage.test(type)) {
        iconName = 'image'
      } else if (regPDF.test(type)) {
        iconName = 'pdf'
      } else if (regPPT.test(type)) {
        iconName = 'ppt'
      } else if (regExcel.test(type)) {
        iconName = 'excel'
      } else if (regWord.test(type)) {
        iconName = 'word'
      } else if (regTxt.test(type)) {
        iconName = 'txt'
      } else if (regZip.test(type)) {
        iconName = 'zip'
      }
      return iconName
    }
  },
  watch: {
    /* organizationId: { // 判断页面缓存中是否有机构ID
      handler: function (newVal, oldVal) {
        let { name, fullPath } = this.$route
        if (name !== 'OrganizationList' && newVal === '') {
          this.$router.replace({
            name: 'OrganizationList',
            query: { redirect: fullPath }
          })
        }
      },
      immediate: true
    } */
  }
}
