<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader"
                     :title="headerTitle"
                     @go-back="goBack"
                     @on-close="handleClosePage">
          <template #headerRight>
            <div></div>
          </template>
        </cyui-header>
      </template>
      <template #default>
        <div class="cyui-flexbox cyui-detail-header">
          <div class="cyui-flexbox-item cyui-detail-header-content">
            <div class="cyui-detail-header-title">
              维修{{ statusMap[detailInfo.status] && statusMap[detailInfo.status]['text'] }}
            </div>
            <div class="cyui-detail-header-brief">
              <span>维修类型</span>
              <span>{{ detailInfo.typeName || '-' }}</span>
            </div>
            <div class="cyui-detail-header-brief">
              <span>预约时间</span>
              <span>{{ dateFilter(detailInfo.visitingTime) }}</span>
            </div>
          </div>
          <div class="cyui-detail-header-extra">
            <div class="cyui-detail-header-status">
              <svg-icon icon-class="status_1" v-if="`${detailInfo.status}` === '0'"></svg-icon>
              <svg-icon icon-class="status_6" v-else-if="`${detailInfo.status}` === '1'"></svg-icon>
              <svg-icon icon-class="status_5" v-else-if="`${detailInfo.status}` === '2'"></svg-icon>
              <svg-icon icon-class="status_3" v-else-if="`${detailInfo.status}` === '3'"></svg-icon>
            </div>
          </div>
        </div>
        <div class="cyui-section">
          <div class="cyui-card cyui-section-item">
            <div class="cyui-card-body" style="padding: 0;">
              <div class="cyui-section-client">
                <cyui-field-list-info :fields="clientInfo.baseInfoFields"
                                      :data="clientInfo.baseInfo"
                                      :title-width="`${formatPx2Rem(140)}rem`"></cyui-field-list-info>
              </div>
            </div>
          </div>
          <div class="cyui-card cyui-section-item">
            <div class="cyui-card-header">
              <div class="cyui-card-header-line">
                <div class="cyui-card-header-content">
                  需求备注
                </div>
              </div>
            </div>
            <div class="cyui-card-body">
              <div class="cyui-section-remark" v-if="detailInfo">
                <div class="cyui-section-remark-content">
                  {{ demandInfo.remark }}
                </div>
                <div class="cyui-flexbox cyui-flexbox-wrap cyui-section-remark-pic"
                     v-if="demandInfo.videoFilesList.length + demandInfo.imgFilesList.length">
                  <template v-for="(item,index) in demandInfo.videoFilesList">
                    <div class="cyui-section-remark-pic-item"
                         :key="`video${index}`"
                         @click="handleVideoPreview(item)">
                      <video
                        ref="myVideo"
                        class="cyui-video-box"
                        :src="item.url"
                      ></video>
                      <div class="cyui-video-overlay">
                        <svg-icon icon-class="video"></svg-icon>
                      </div>
                    </div>
                  </template>
                  <template v-for="(item,index) in demandInfo.imgFilesList">
                    <div class="cyui-section-remark-pic-item"
                         :key="`pic${index}`"
                         @click="handleImagePreview({startPosition: index}, demandInfo.imgFilesList)">
                      <img :src="item.url" alt="">
                    </div>
                  </template>
                </div>
              </div>
              <div class="no-data" v-else>暂无数据</div>
            </div>
          </div>
          <div class="cyui-card cyui-section-item" v-if="detailInfo.status > 1 ">
            <div class="cyui-card-header">
              <div class="cyui-card-header-line">
                <div class="cyui-card-header-content">
                  维修详情补充
                </div>
              </div>
            </div>
            <div class="cyui-card-body">
              <div class="cyui-section-field">
                <cyui-field-list-info :fields="supplementInfo.baseInfoFields"
                                      :data="supplementInfo.baseInfo"
                                      :title-width="`${formatPx2Rem(150)}rem`"></cyui-field-list-info>
              </div>
              <div class="cyui-section-service" v-if="detailInfo">
                <div class="cyui-section-service-visits cyui-section-service-resolve">
                  <van-cell-group>
                    <template #default>
                      <van-cell class="cyui-section-service-visits-comment">
                        <template #title>
                          <div :style="{ width: `${formatPx2Rem(150)}rem` }">问题详情：</div>
                        </template>
                        <template #label>
                          <div>{{ supplementInfo.remark }}</div>
                        </template>
                      </van-cell>
                    </template>
                  </van-cell-group>
                </div>
              </div>
              <div class="cyui-section-remark" v-if="detailInfo">
                <div class="cyui-flexbox cyui-flexbox-wrap cyui-section-remark-pic"
                     v-if="supplementInfo.videoFilesList.length + supplementInfo.imgFilesList.length">
                  <template v-for="(item,index) in supplementInfo.videoFilesList">
                    <div class="cyui-section-remark-pic-item"
                         :key="`supplementVideo${index}`"
                         @click="handleVideoPreview(item)">
                      <video
                        ref="myVideo"
                        class="cyui-video-box"
                        :src="item.url"
                      ></video>
                      <div class="cyui-video-overlay">
                        <svg-icon icon-class="video"></svg-icon>
                      </div>
                    </div>
                  </template>
                  <template v-for="(item,index) in supplementInfo.imgFilesList">
                    <div class="cyui-section-remark-pic-item"
                         :key="`supplementPic${index}`"
                         @click="handleImagePreview({startPosition: index}, supplementInfo.imgFilesList)">
                      <img :src="item.url" alt="">
                    </div>
                  </template>
                </div>
              </div>
              <div class="cyui-section-service" v-if="detailInfo">
                <div class="cyui-section-service-visits cyui-section-service-resolve">
                  <van-cell-group>
                    <template #default>
                      <van-cell class="cyui-section-service-visits-comment">
                        <template #title>
                          <div :style="{ width: `${formatPx2Rem(150)}rem` }">解决措施：</div>
                        </template>
                        <template #label>
                          <div>{{ detailInfo.repairmanResolvent }}</div>
                        </template>
                      </van-cell>
                    </template>
                  </van-cell-group>
                </div>
              </div>
              <div class="no-data" v-else>暂无数据</div>
            </div>
          </div>
          <div class="cyui-card cyui-section-item" v-if="`${detailInfo.isEvaluate}` === '1'">
            <div class="cyui-card-header">
              <div class="cyui-card-header-line">
                <div class="cyui-card-header-content">
                  服务评价
                </div>
              </div>
            </div>
            <div class="cyui-card-body">
              <div class="cyui-section-service" v-if="detailInfo">
                <div class="cyui-section-service-rate">
                  <van-cell-group>
                    <van-cell v-for="(item, index) in serviceInfo.rateInfoFields"
                              :key="`rateInfo${index}`">
                      <template #title>
                        <div :style="{ width: `${formatPx2Rem(160)}rem` }">{{item.title}}</div>
                      </template>
                      <template #default>
                        <van-rate v-model="serviceInfo.rateInfo[item.key]" :icon="img002" :void-icon="img003" readonly/>
                      </template>
                    </van-cell>
                  </van-cell-group>
                </div>
                <div class="cyui-section-service-visits">
                  <van-cell-group>
                    <template #title>
                      <div>是否会再次使用该服务？</div>
                    </template>
                    <template #default>
                      <van-cell>
                        <template #title>
                          <div :style="{ width: `${formatPx2Rem(90)}rem` }">回答：</div>
                        </template>
                        <template #default>
                          <div>{{ detailInfo.isReuse | filterIsReuse }}</div>
                        </template>
                      </van-cell>
                      <van-cell>
                        <template #title>
                          <div :style="{ width: `${formatPx2Rem(90)}rem` }">原因：</div>
                        </template>
                        <template #default>
                          <div>{{ fieldEmptyFilter(detailInfo.reason) }}</div>
                        </template>
                      </van-cell>
                      <van-cell class="cyui-section-service-visits-comment">
                        <template #title>
                          <div :style="{ width: `${formatPx2Rem(150)}rem` }">用户评价：</div>
                        </template>
                        <template #label>
                          <div>{{ fieldEmptyFilter(detailInfo.proposal) }}</div>
                        </template>
                      </van-cell>
                    </template>
                  </van-cell-group>
                </div>
              </div>
              <div class="no-data" v-else>暂无数据</div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
      </template>
    </cyui-layout>
    <van-popup v-model="videoPopupInfo.show"
               closeable
               close-icon="clear"
               @close="handleVideoPause">
      <div class="cyui-popup-video"
           @click="videoPopupInfo.show = false">
        <div class="cyui-popup-video-body">
          <cyui-player ref="cyuiPlayer" :options="videoPopupInfo.options"></cyui-player>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import { ImagePreview } from 'vant'
  import common from '@mixins/common'
  import filters from '@filters/filters'
  import { formatPx2Rem, isJSON } from '@utils/index'
  import { isEmpty, dateFilter } from '@utils/utils'
  import { statusMap } from '@/dictionaries/maintain'
  import { getDetailAPI } from '@api/maintain'
  import img002 from '@assets/images/img_002.png'
  import img003 from '@assets/images/img_003.png'

  export default {
    name: 'MaintainDetail',
    mixins: [common, filters],
    components: {},
    data() {
      return {
        img002,
        img003,
        headerTitle: '维修详情',
        clientInfo: { // 客户信息
          baseInfoFields: [ // 基本信息
            {
              title: '姓名',
              key: 'contacts',
              type: 'value',
              icon: {
                type: 'svg',
                svgName: 'detail_name'
              },
              color: 'default'
            },
            {
              title: '联系方式',
              key: 'phone',
              type: 'value',
              icon: {
                type: 'svg',
                svgName: 'detail_phone'
              },
              color: 'default'
            },
            {
              title: '提交时间',
              key: 'createTime',
              type: 'value',
              icon: {
                type: 'svg',
                svgName: 'detail_time'
              },
              color: 'default'
            }
          ],
          baseInfo: {}
        },
        supplementInfo: { // 维修详情补充
          baseInfoFields: [ // 基本信息
            {
              title: '维修类型：',
              key: 'repairmanTypeName',
              type: 'value',
              color: 'default'
            }
          ],
          baseInfo: {},
          videoFilesList: [],
          imgFilesList: []
        },
        serviceInfo: { // 服务评价
          rateInfoFields: [ // 评价
            {
              title: '维修效率：',
              key: 'fractionEfficiency'
            },
            {
              title: '服务态度：',
              key: 'fractionAttitude'
            },
            {
              title: '服务品质：',
              key: 'fractionQuality'
            }
          ],
          rateInfo: {}
        },
        statusMap,
        isLoading: false, // 是否在加载数据
        isRefreshPage: false, // 是否需要刷新页面
        noDataMessage: '', // 列表为空时的提示文案
        detailInfo: {}, // 详情信息
        demandInfo: { // 需求备注
          remark: '',
          videoFilesList: [],
          imgFilesList: []
        },
        videoPopupInfo: { // 视频播放
          show: false,
          options: {}
        }
      }
    },
    props: {
      id: {
        type: [Number, String],
        default: ''
      }
    },
    computed: {},
    methods: {
      formatPx2Rem,
      // 日期格式化
      dateFilter,
      // 空字段过滤
      fieldEmptyFilter(data, returnEmpty = '无') {
        return !isEmpty(data) ? data : returnEmpty
      },
      // 格式化基本信息
      formatBaseInfo() {
        let { detailInfo } = this
        this.clientInfo.baseInfo = {
          contacts: this.fieldEmptyFilter(detailInfo.contacts),
          phone: this.fieldEmptyFilter(detailInfo.phone),
          createTime: this.fieldEmptyFilter(detailInfo.createTime)
        }
      },
      // 格式化需求备注
      formatDemandInfo() {
        let { detailInfo } = this
        let fileList = isJSON(detailInfo.fileList) ? JSON.parse(detailInfo.fileList) : []
        this.demandInfo.remark = this.fieldEmptyFilter(detailInfo.remark)
        this.demandInfo.videoFilesList = fileList.filter(item => `${item.type}` === '1')
        this.demandInfo.imgFilesList = fileList.filter(item => `${item.type}` === '0')
      },
      // 格式化维修详情补充
      formatSupplementInfo() {
        let { detailInfo } = this
        let repairmanFileList = isJSON(detailInfo.repairmanFileList) ? JSON.parse(detailInfo.repairmanFileList) : []
        this.supplementInfo.baseInfo = {
          repairmanTypeName: this.fieldEmptyFilter(detailInfo.repairmanTypeName)
        }
        this.supplementInfo.remark = this.fieldEmptyFilter(detailInfo.repairmanRemark)
        this.supplementInfo.videoFilesList = repairmanFileList.filter(item => `${item.type}` === '1')
        this.supplementInfo.imgFilesList = repairmanFileList.filter(item => `${item.type}` === '0')
      },
      // 格式化评分信息
      formatRateInfo() {
        let { detailInfo } = this
        this.serviceInfo.rateInfo = {
          fractionEfficiency: this.fieldEmptyFilter(detailInfo.fractionEfficiency, 0),
          fractionAttitude: this.fieldEmptyFilter(detailInfo.fractionAttitude, 0),
          fractionQuality: this.fieldEmptyFilter(detailInfo.fractionQuality, 0)
        }
      },
      // 视频播放
      handleVideoPreview(data) {
        this.videoPopupInfo.options = {
          url: data.url,
          fitVideoSize: 'fixWidth',
          videoInit: true
        }
        this.videoPopupInfo.show = true
        this.$nextTick(() => {
          this.$refs.cyuiPlayer.player.play()
        })
      },
      // 暂停视频播放
      handleVideoPause() {
        this.$nextTick(() => {
          this.$refs.cyuiPlayer.player.pause()
        })
      },
      handleImagePreview(options = {}, images = []) {
        ImagePreview({
          images: images.map(item => item.url),
          closeable: true,
          ...options
        })
      },
      async handleCallback({ isError, errorMessage = undefined }) { // 回调处理
        if (isError) {
          this.$toast(errorMessage || '获取失败!')
          /* if (errorMessage && errorMessage.includes('timeout')) {
            // 判断是否是网络请求超时
            this.isRefreshPage = true
            this.noDataMessage = '网络请求超时'
          } else if (errorMessage && errorMessage.includes('502')) {
            // 判断是否是服务器错误
            this.isRefreshPage = true
            this.noDataMessage = '服务器错误，502错误'
          } */
        }
      },
      async getDetailData({ isShowLoading } = { isShowLoading: true }) { // 获取详情数据
        try {
          this.isLoading = true
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
          let { id } = this
          let response = await getDetailAPI({
            id
          })
          let result = response.data
          // console.log(result)
          if (`${result.code}` === '10000') { // 请求成功
            let resultData = result.data || {}
            this.detailInfo = resultData
            this.formatBaseInfo()
            this.formatDemandInfo()
            this.formatSupplementInfo()
            this.formatRateInfo()
            await this.handleCallback({
              isError: false
            })
          } else {
            // 请求失败
            await this.handleCallback({
              isError: true,
              errorMessage: result.msg || '获取列表失败!'
            })
          }
        } catch (error) {
          // console.log(error)
          await this.handleCallback({
            isError: true,
            errorMessage: error.message || '获取列表失败!'
          })
        } finally {
          this.isLoading = false
          if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
        }
      },
      async initData() {
        await this.getDetailData()
      }
    },
    filters: {},
    watch: {},
    created() {
    },
    mounted() {
      this.initData()
    },
    beforeRouteEnter(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    },
    beforeRouteUpdate(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    }
  }
</script>

<style lang="less" scoped>
  @import '../../assets/styles/themes/default/index';
  @import '../../assets/styles/mixins/index';

  @sectionPrefixCls: ~'@{css-prefix}section';

  .@{css-prefix}detail-header {
    width: 100%;
    height: 100px;
    background-color: #D8B084;

    &-content {
      padding-left: 18px;
      line-height: 24px;
      color: #ffffff;

      .@{css-prefix}detail-header-title {
        margin-bottom: 10px;
        font-size: 16px;
      }

      .@{css-prefix}detail-header-brief {
        font-size: 12px;

        > span {
          &:not(:last-of-type) {
            margin-right: 10px;
          }
        }
      }
    }

    &-extra {
      padding-right: 30px;
      line-height: 24px;
      font-size: 16px;
      color: #ffffff;

      .@{css-prefix}detail-header-status {
        position: relative;

        .svg-icon {
          display: block;
          width: 54px;
          height: 54px;
          vertical-align: middle;
        }
      }
    }
  }

  .@{sectionPrefixCls} {
    &-item {
      overflow: hidden;

      .@{css-prefix}card-header {
        min-height: 50px;

        &-line {
          .hairline('bottom', 1px, #EEEEEE);

          .@{css-prefix}card-header-thumb {
            margin-right: 10px;

            .svg-icon {
              display: block;
              width: 18px;
              height: 18px;
              vertical-align: middle;
            }
          }

          .@{css-prefix}card-header-content {
            font-size: 15px;
            font-weight: bold;
            color: #333333;
          }
        }
      }

      .@{css-prefix}card-body {
        padding: 15px;
      }

      & + & {
        margin-top: 12px;
      }
    }
  }

  .@{sectionPrefixCls}-client {
    padding: 0 15px;
    background-color: #FFFFFF;

    & /deep/ .van-cell-group {
      .van-cell:not(:last-child)::after {
        left: 30px;
        right: 0;
        border-color: #EEEEEE;
      }
    }
  }

  .@{sectionPrefixCls}-remark {
    padding: 0 5px;
    line-height: 24px;
    color: #333333;
    font-size: 12px;
    overflow: hidden;

    &-content {
      padding: 10px 0 20px;
    }

    &-pic {
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 -5px;
      padding: 10px 0;
      .hairline('top', 1PX, #EEEEEE);

      &-item {
        position: relative;
        display: block;
        width: 86px;
        height: 86px;
        padding: 10px 5px 0;
        overflow: hidden;

        .@{css-prefix}video-box {
          background-color: #000000;
          width: 100%;
          height: 100%;
        }

        .@{css-prefix}video-overlay {
          position: absolute;
          left: 0;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          padding: 10px 5px 0;

          .svg-icon {
            display: block;
            width: 24px;
            height: 24px;
            fill: #FFFFFF;
            vertical-align: middle;
          }
        }

        > img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }
    }
  }

  // 服务评价
  .@{sectionPrefixCls}-service {
    & /deep/ .van-cell-group {
      &::after {
        display: none;
      }

      .van-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 50px;
        padding: 0;

        &.@{css-prefix}opinion {
          .van-field {
            padding: 0;
          }
        }
      }

      .van-cell__title {
        flex: none;
        padding: 0 0 0 5px;
        line-height: 24px;
        font-size: 12px;
        color: #333333;
      }

      .van-cell__value {
        text-align: left;
        font-size: 12px;
        color: #999999;
      }

      .van-cell__label {
        margin-top: 5px;
        font-size: 12px;
        color: #999999;
        line-height: 24px;
      }

      .van-cell:not(:last-child)::after {
        left: 0;
        right: 0;
        border: none;
      }
    }

    &-rate {
      & /deep/ .van-cell__value {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .van-rate {
          &__item {
            &:not(:last-child) {
              padding-right: 18px;
            }
          }
        }
      }
    }

    &-visits {
      padding-top: 10px;

      & /deep/ .van-cell-group {
        &__title {
          padding: 0 5px;
          line-height: 40px;
          font-size: 12px;
          color: #333333;
        }

        .van-cell {
          min-height: 32px;

          &.@{sectionPrefixCls}-service-visits-comment {
            .van-cell__title {
              flex: 1;
            }
          }
        }
      }
    }

    &-resolve {
      position: relative;
      margin-top: 10px;
      padding: 15px 0 10px;
      .hairline('top', 1PX, #EEEEEE);
    }
  }

  .@{sectionPrefixCls}-field {
    padding: 0 5px;
    line-height: 24px;
    color: #333333;
    font-size: 12px;
    overflow: hidden;
  }

  .@{css-prefix}actionbar {
    position: relative;
    height: 65px;
    background-color: transparent;

    .@{css-prefix}actionbar-btns {
      padding: 10px 43px 15px;

      .@{css-prefix}btn {
        height: 40px;
        line-height: 40px;
        background-color: #FFFFFF;
        font-size: 15px;
        border: 1px solid #D1A36F;
        border-radius: 20px;
        color: #D1A36F;

        &.@{css-prefix}btn-primary {
          color: #FFFFFF;
          background-color: #D1A36F;
        }

        &.@{css-prefix}btn-ghost {
        }
      }

      .@{css-prefix}btn + .@{css-prefix}btn {
        margin-left: 30px;
      }
    }
  }

  .@{css-prefix}popup-video {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
    overflow: hidden;

    &-body {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 50px 0 0;

      & /deep/ .xgplayer-skin-default {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  .no-data {
    position: relative;
    left: 0;
    top: 0;
    font-size: 14px;
    transform: none;
  }

</style>
