<template>
  <div class="cyui-layout">
    <router-view name="headerView"></router-view>
    <keep-alive :include="keepAliveComponents">
      <router-view :key="key"></router-view>
    </keep-alive>
    <router-view name="tabbarView"></router-view>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import store from '@store'

  export default {
    name: 'Layout',
    components: {},
    props: {},
    data() {
      return {}
    },
    computed: {
      ...mapGetters('keepAlive', [
        'routes'
      ]),
      keepAliveComponents() {
        let array = []
        if (this.routes) {
          array = this.routes.filter(r => !r.meta.noCache).map(h => h.componentName)
        }
        return array
      },
      key() {
        return this.$route.fullPath
      }
    },
    created() {
    },
    watch: {
      cachedViews: {
        handler: function(newVal, oldVal) {
          // console.log(newVal)
        }
      }
    },
    methods: {},
    beforeRouteEnter(to, from, next) {
      // console.log(to)
      // console.log(from)
      store.dispatch('keepAlive/AddCachedView', to)
      next()
    },
    beforeRouteUpdate(to, from, next) {
      // console.log(to)
      // console.log(from)
      next()
    },
    beforeRouteLeave(to, from, next) {
      // console.log(to)
      // console.log(from)
      store.dispatch('keepAlive/DeleteCachedView', from)
      next()
    }
  }
</script>
<style lang="less" scoped>
  @import '../../assets/styles/themes/default/index';
  @import '../../assets/styles/mixins/index';
</style>
