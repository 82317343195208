<template>
  <div class="cyui-layout-wrapper">
    <cyui-layout>
      <template #header>
        <cyui-header v-if="isCustomHeader" :title="headerTitle"
          @go-back="goBack" @on-close="handleClosePage">
          <template #headerRight>
            <div></div>
          </template>
        </cyui-header>
        <div class="cyui-driections-navbar">
          <div class="system-title">
            <van-icon name="label" size="30" />
            <span>入门必读</span>
          </div>
          <div class="system-content">
            <div class="content" v-for="item in shouceList" :key="item.id">
              <a @click="handelClick_open(item)">{{item.question}}</a>
              <!-- <div @click="handelClick_open(item)">{{item.question}}</div> -->
            </div>
          </div>
        </div>
      </template>
    </cyui-layout>
  </div>
</template>

<script>
import common from '@mixins/common'
import { getquestionDocList } from '@api/directions'

export default {
  name: 'DirectionsSystem',
  mixins: [common],
  data () {
    return {
      headerTitle: '',
      shouceList: [],
      dataList: [
        {
          name: '系统操作手册',
          id: 1
        },
        {
          name: '相关业务使用手册',
          id: 2
        },
        {
          name: 'A手册',
          id: 3
        },
      ]
    }
  },
  props: {},

  methods: {
    async handleCallback ({ isError, errorMessage = undefined }) { // 回调处理
      if (isError) {
        this.$toast(errorMessage || '获取失败!')
        /* if (errorMessage && errorMessage.includes('timeout')) {
          // 判断是否是网络请求超时
          this.isRefreshPage = true
          this.noDataMessage = '网络请求超时'
        } else if (errorMessage && errorMessage.includes('502')) {
          // 判断是否是服务器错误
          this.isRefreshPage = true
          this.noDataMessage = '服务器错误，502错误'
        } */
      }
    },
    async getDetailData ({ isShowLoading } = { isShowLoading: true }) { // 获取详情数据
      try {
        this.isLoading = true
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', true)
        let id = this.$route.query.id
        // console.log(this.$route.query.id, '我是傻逼');
        id == 1 ? this.headerTitle = '系统操作手册' : id == 2 ? this.headerTitle = '相关业务使用手册' : ''
        // console.log(id);
        let response = await getquestionDocList({
          type: id
        })
        let result = response.data
        // console.log(result)
        if (`${result.code}` === '10000') { // 请求成功
          let resultData = result.data || []
          this.shouceList = resultData
          console.log(this.shouceList, '手册全页');
          await this.handleCallback({
            isError: false
          })
        } else {
          // 请求失败
          await this.handleCallback({
            isError: true,
            errorMessage: result.msg || '获取列表失败!'
          })
        }
      } catch (error) {
        // console.log(error)
        await this.handleCallback({
          isError: true,
          errorMessage: error.message || '获取列表失败!'
        })
      } finally {
        this.isLoading = false
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', false)
      }
    },
    async initData () {
      await this.getDetailData()
    },
    handelClick_open (data) {
      window.open(data.docUrl)
    },
    // 进入详情
    goDetail (data) {
      let { id } = data
      if (id == 1) {
        this.$router.push({
          name: 'DirectionsSystem',
          query: {
            id
          }
        })
      } else if (id == 2) {
        this.$router.push({
          name: 'HouseDetail',
          query: {
            id
          }
        })
      }

    },
    // 
    // 
  },
  filters: {},
  // 
  created () {
  },
  mounted () {

    this.initData()
  },
  beforeRouteEnter (to, from, next) {
    // console.log(to)
    // console.log(from)
    next()
  },
  beforeRouteUpdate (to, from, next) {
    // console.log(to)
    // console.log(from)
    next()
  }
}
</script>

<style lang="less" scoped>
.cyui-layout-wrapper {
  .system-title {
    width: 95%;
    height: 60px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    span {
      font-size: 14px;
    }
  }
  .system-content {
    width: 92%;
    margin: 0 auto;
    .content {
      height: 30px;
      display: flex;
      align-items: center;

      a {
        font-size: 16px;
        text-decoration: underline;
      }
    }
  }
}
</style>
